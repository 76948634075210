const screenCardHeight = 100
const screenCardMargin = 8
const Theme = {
	pixelsPerMs: 0.004,
	pageSideMargin: '100px',
	screenCardMargin: (screenCardMargin + 'px') as string,
	screenCardHeight: (screenCardHeight + 'px') as string,
	screenContainerHeight: (screenCardHeight + screenCardMargin + 'px') as string,
	editorRowHeight: '20px',

	popoverWidth: '80vw',
	popoverHeight: '45vh',
	popoverMaxWidth: '90vw',
	popoverMaxHeight: '90vh',
	// colors
	colors: {
		white: '#FFFFFF',
		darkGrey: '#606060',
		darkGreyBorder: '#707070',
		grey: '#d7d7d7',
		lightGrey: '#e2e2e2',
		lightGreyBorder: '#cecece',
		black: '#272b2e',
	},
}
export default Theme
export type ThemeType = typeof Theme
