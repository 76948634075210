import axios from 'axios'
import { createEntities, createMiddleware } from 'redux-tiles'
import simulationTiles from '../features/simulations/tiles'
import mapTiles from '../features/maps/tiles'
import type { TileSelectors, TileActions } from '../types/tiles'
import { AxiosRequestConfig } from 'axios'
import { config } from '../config'
const tiles = [...simulationTiles, ...mapTiles]
const topLevel = 'reduxTiles'
const {
	actions,
	reducer,
	selectors,
}: {
	actions: TileActions
	selectors: TileSelectors
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	reducer: any
} = createEntities(tiles, topLevel)
const { middleware, waitTiles } = createMiddleware({
	actions,
	selectors,
	// Add an "axios" object to the thunk middleware, wrapping the actual calls to axios so we only have to pass the endpoint
	axios: {
		get(url: string, ...params: [AxiosRequestConfig]) {
			return axios.get(config.simulationsApiUrl + '/api' + url, ...params)
		},

		post(url: string, ...params: [AxiosRequestConfig]) {
			return axios.post(config.simulationsApiUrl + '/api' + url, ...params)
		},

		put(url: string, ...params: [AxiosRequestConfig]) {
			return axios.put(config.simulationsApiUrl + '/api' + url, ...params)
		},

		delete(url: string, ...params: [AxiosRequestConfig]) {
			return axios.delete(config.simulationsApiUrl + '/api' + url, ...params)
		},

		patch(url: string, ...params: [AxiosRequestConfig]) {
			return axios.patch(config.simulationsApiUrl + '/api' + url, ...params)
		},
	},
})
export { topLevel, actions, reducer, selectors, middleware, waitTiles }
