import styled from 'styled-components'
import Markdown from '../../../common/Markdown'
import MarkdownInput from '../../../common/MarkdownInput'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Label, Input, Button, CardBody } from 'reactstrap'
import React, { useState, useEffect, useCallback } from 'react'
import { List, StyledCard } from './sharedComponents'
import {
	Activity as MissionGuideActivity,
	SupportedVendor,
	VENDOR_LOOKUP,
} from '@mission.io/mission-guide'

export type ActivityWithDropId = MissionGuideActivity & {
	dropId: string
}
/**
 * ActivityList - A drag and drop list used to display and edit a list of activities.
 *
 * @param {{items: Array<MissionGuideActivity>}} items - the current list
 * @param {{id: string}} id - the `react-beautiful-dnd` list id
 * @param {{onEdit: (activity: ?MissionGuideActivity, index: number) => void}} - set the activity at the given index to the given activity. If the
 *            activity is null, delete the activity from the list.
 */

export default function ActivityList({
	items,
	id,
	onEdit,
}: {
	items: Array<ActivityWithDropId>
	id: string
	onEdit: (activity: MissionGuideActivity | null | undefined, index: number) => void
}): JSX.Element {
	const [isAddingItem, setIsAddingItem] = useState(false)
	return (
		<Droppable droppableId={id}>
			{(provided) => (
				<List ref={provided.innerRef}>
					{items.map((item, index) => (
						<Draggable key={item.dropId} draggableId={item.dropId} index={index}>
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}>
									<MissionActivityForm
										activity={item}
										onEdit={(activity: MissionGuideActivity | null | undefined) =>
											onEdit(activity, index)
										}
									/>
								</div>
							)}
						</Draggable>
					))}
					{provided.placeholder}
					{isAddingItem ? (
						<MissionActivityForm
							onEdit={(activity: MissionGuideActivity | null | undefined) => {
								onEdit(activity, items.length)
								setIsAddingItem(false)
							}}
						/>
					) : (
						<Button onClick={() => setIsAddingItem(true)} outline color="primary">
							+ Add Activity +
						</Button>
					)}
				</List>
			)}
		</Droppable>
	)
}

/**
 * MissionActivityForm - A component used to view and edit an Activity
 *
 * @param {{activity?: ?MissionGuideActivity}} activity - the activity to display, null or undefined if creating an activity
 * @param {{onEdit: (newActivity: ?MissionGuideActivity) => void}} onEdit - called when the activity needs to be updated to the newActivity, if newActivity
 *                       is null or undefined then the activity should be deleted
 */
function MissionActivityForm({
	activity,
	onEdit,
}: {
	activity?: ActivityWithDropId | null | undefined
	onEdit: (newActivity: MissionGuideActivity | null | undefined) => void
}): JSX.Element {
	const [title, setTitle] = useState('')
	const [url, setUrl] = useState('')
	const [description, setDescription] = useState('')
	const [vendor, setVendor] = useState<SupportedVendor>(SupportedVendor.OTHER)
	const [isEditing, setIsEditing] = useState(false)
	const resetActivity = useCallback(() => {
		setTitle(activity?.title || '')
		setUrl(activity?.url || '')
		setDescription(activity?.description || '')
		setVendor(activity?.vendor || SupportedVendor.OTHER)
	}, [activity])
	useEffect(() => {
		resetActivity()
	}, [resetActivity])
	const { icon: vendorIcon, name: vendorName } = VENDOR_LOOKUP[vendor] || {
		icon: '',
		name: vendor || 'unknown',
	}

	if (isEditing || !activity) {
		return (
			<StyledCard>
				<CardBody>
					<StyledLabel>
						Vendor: {vendorIcon ? <VendorThumbnail src={vendorIcon} alt={vendorName} /> : null}
						<Input
							type="select"
							value={vendor}
							onChange={(event) => setVendor(event.target.value as SupportedVendor)}>
							{Object.keys(VENDOR_LOOKUP).map((vendor) => (
								<option value={vendor} key={vendor}>
									{VENDOR_LOOKUP[vendor as SupportedVendor].name}
								</option>
							))}
						</Input>
					</StyledLabel>
					<StyledLabel>
						Title:{' '}
						<Input type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
					</StyledLabel>
					<StyledLabel>
						Url: <Input type="text" value={url} onChange={(event) => setUrl(event.target.value)} />
					</StyledLabel>
					<StyledLabel>Description:</StyledLabel>
					<MarkdownInput value={description} onChange={setDescription} />

					<Buttons>
						<Button
							onClick={() => {
								onEdit({
									vendor,
									title,
									url,
									description,
								})
								setIsEditing(false)
							}}
							color="primary">
							Save
						</Button>
						<ButtonGroup>
							<Button
								onClick={() => {
									if (!activity) {
										onEdit(activity)
									} else {
										const noIdActivity: MissionGuideActivity & {
											dropId?: string
										} = { ...activity }
										delete noIdActivity.dropId
										onEdit(noIdActivity)
									}

									resetActivity()
									setIsEditing(false)
								}}
								color="warning">
								Cancel
							</Button>

							{activity ? (
								<Button
									onClick={() => {
										onEdit(null)
										setIsEditing(false)
									}}
									color="danger">
									Delete
								</Button>
							) : null}
						</ButtonGroup>
					</Buttons>
				</CardBody>
			</StyledCard>
		)
	}

	return (
		<StyledCard onClick={() => setIsEditing(true)}>
			<CardBody>
				<MissionGroupHeader>
					{vendorIcon ? <VendorImage src={vendorIcon} alt={vendorName} /> : vendorName}
				</MissionGroupHeader>
				<Title>
					{title} {url ? <a href={url}>{url}</a> : ''}
				</Title>
				<Markdown>{description}</Markdown>
			</CardBody>
		</StyledCard>
	)
}

const StyledLabel = styled(Label)`
	display: block;
`
const Buttons = styled.div`
	margin-top: 8px;
	display: flex;
	flow-direction: row;
	justify-content: space-between;
`
const Title = styled.div`
	font-weight: bold;
`
const VendorImage = styled.img`
	height: 2em;
`
const MissionGroupHeader = styled.div`
	border-bottom: 1px solid purple;
	padding-bottom: 8px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`
const VendorThumbnail = styled.img`
	height: 1em;
`
const ButtonGroup = styled.div`
	> * {
		margin-left: 8px;
	}
`
