import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FaTrash, FaEdit } from 'react-icons/fa'
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap'
import { deleteCharacter } from '../../reducers/characters'
import { deleteCharacter as deleteCharacterFromServer } from './CharacterRoutes'
import type { Character } from '../../reducers/characters'
type Props = {
	character: Character
	onEditCharacter: (character: Character) => void
}
export default function CharacterBox({ character, onEditCharacter }: Props): JSX.Element {
	const dispatch = useDispatch()
	const [deleteModal, toggleDeleteModal] = useState(false)
	const { _id, name, url, description } = character
	return (
		<CharacterDiv>
			<ImgDiv src={url} alt={name} />
			<Details>
				<p>{name}</p>
				<p>{description}</p>
			</Details>
			<ButtonsColumn>
				<EditButton
					onClick={() => {
						onEditCharacter(character)
					}}
				/>
				<DeleteButton onClick={() => toggleDeleteModal(true)} />
			</ButtonsColumn>
			<Modal isOpen={deleteModal}>
				<ModalHeader>Are you sure you want to delete {name}?</ModalHeader>
				<ModalFooter>
					<Button
						onClick={() => {
							deleteCharacterFromServer(_id).then((canDispatch: boolean) => {
								if (canDispatch) {
									dispatch(deleteCharacter(_id))
								}
							})
							toggleDeleteModal(false)
						}}>
						Delete
					</Button>
					<Button color="primary" onClick={() => toggleDeleteModal(false)}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</CharacterDiv>
	)
}
const CharacterDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #eaeaea;
	margin: 5px;
`
const ImgDiv = styled.img`
	width: 15%;
`
const Details = styled.div`
	flex: 1;
	margin: 2px 5px;
	font-size: 1.5rem;
	text-align: center;
`
const ButtonsColumn = styled.div`
	width: 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
const EditButton = styled(FaEdit)`
	cursor: pointer;
	width: 40%;
	height: 40%;
	color: blue;
`
const DeleteButton = styled(FaTrash)`
	cursor: pointer;
	width: 40%;
	height: 40%;
	color: red;
`
