import React, { Component } from 'react'
import {
	FormGroup,
	Label,
	Col,
	Input,
	Button,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
} from 'reactstrap'
import { FaAngleDown, FaAngleUp, FaTrash } from 'react-icons/fa'
import styled from 'styled-components'
import { connect } from 'react-redux'
import MediaInformation from './MediaInformation'
import SelectIcon from './SelectIcon'
import { MapIcon, allMapObjectClassifications } from '../../../../../types'
import { mapEntries } from '../../../../../helpers/functions'
import type {
	MapObject,
	PlanetType,
	ShipType,
	ContactType,
	ObjectContentType,
} from '../../../../../types'
import './MapObjectForm.css'
import type { ReduxStore } from '../../../../../types/ReduxStore'
import {
	MapObjectsKey,
	debounceUpdateServerObject,
	updateMapObjectStore,
} from '../../../../../reducers/mapObjects'
import { InputType } from 'reactstrap/types/lib/Input'
import { Spec } from 'immutability-helper'
import type { State as MapObjectsState } from '../../../../../reducers/mapObjects'
import { Collapse } from '../../../../../common/Collapse'

type Props = {
	newItem: boolean
	deleteObject?: () => void
	objectClassification: MapObjectsKey
	mapId: string
	hideDelete?: boolean
	objectId: string
}
type ReduxProps<U extends MapObject> = {
	object: U
	debounceUpdateServerObject: (category: string, id: string) => void
	updateMapObjectStore: (updateObject: Spec<MapObjectsState>) => void
}
type AllProps<U extends MapObject> = Props & ReduxProps<U>
type State = {
	showDetails: boolean
	name: string
	subtitle: string | null | undefined
	description: string
	mass: string
	x: number
	y: number
	url: string | null | undefined
	width: number | null | undefined
	height: number | null | undefined
	type: PlanetType | ShipType | ContactType | undefined
	icon: MapIcon | null
	dropdownOpen: boolean
	objectContent: ObjectContentType[]
}

class MapObjectForm extends Component<AllProps<MapObject>, State> {
	constructor(props: AllProps<MapObject>) {
		super(props)
		const icon = 'icon' in props.object ? props.object.icon : null
		const state: State = {
			showDetails: props.newItem,
			name: props.object.name,
			subtitle: props.object.subtitle,
			description: props.object.description,
			mass: props.object.mass,
			x: props.object.location.x,
			y: props.object.location.y,
			url: icon ? icon.url : null,
			width: icon ? icon.width : null,
			height: icon ? icon.height : null,
			type: 'type' in props.object ? props.object.type : undefined,
			icon,
			dropdownOpen: false,
			objectContent: props.object.objectContent,
		}
		this.state = state
	}

	componentDidUpdate = (prevProps: AllProps<MapObject>) => {
		if (this.props.newItem && !prevProps.newItem)
			this.setState({
				showDetails: true,
			})
	}
	toggleDetails = () => {
		this.setState((prevState) => ({
			showDetails: !prevState.showDetails,
		}))
	}

	render(): JSX.Element {
		if (!this.props.object) {
			return (
				<div className="MapObjectForm">
					<h3>...Loading</h3>
				</div>
			)
		}

		const { object } = this.props
		return (
			<div className="MapObjectForm" id={object._id}>
				<FormGroup row>
					<Col sm={1}>
						<Toggler show={this.state.showDetails} onClick={this.toggleDetails} />
					</Col>
					<Label sm={3}>Name</Label>
					<Col sm={6}>
						<Input
							name="name"
							value={object.name}
							type="text"
							onChange={(e) => this.changeInput('name', e.target.value)}
						/>
					</Col>
					{!this.props.hideDelete && (
						<Col sm={2}>
							<Button onClick={this.props.deleteObject} color="danger">
								<FaTrash size={20} />
							</Button>
						</Col>
					)}
				</FormGroup>

				<Collapse isOpen={this.state.showDetails}>
					{this.renderFormGroup('Subtitle', 'subtitle')}
					{this.renderFormGroup('X', 'x', 'number')}
					{this.renderFormGroup('Y', 'y', 'number')}
					{'type' in object && this.props.objectClassification !== 'other' && (
						<FormGroup row>
							<Label sm={3}>Type</Label>
							<Col>
								<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} sm={3}>
									<DropdownToggle caret>
										{this.state.type
											? allMapObjectClassifications[this.props.objectClassification][
													this.state.type
											  ]?.displayName
											: 'Select...'}
									</DropdownToggle>
									<DropdownMenu>
										{mapEntries(
											allMapObjectClassifications[this.props.objectClassification] as Record<
												string,
												{ id: string; displayName: string }
											>,
											(type) => {
												return (
													<DropdownItem
														key={type.id}
														onClick={() => this.changeInput('type', type.id)}>
														{type.displayName}
													</DropdownItem>
												)
											}
										)}
									</DropdownMenu>
								</Dropdown>
							</Col>
						</FormGroup>
					)}
					{'icon' in object && <SelectIcon icon={object.icon} onChange={this.changeInput} />}
					<MediaInformation
						onChange={(objectInfo) => this.changeInput('objectContent', objectInfo)}
						mediaList={this.state.objectContent}
					/>
				</Collapse>
			</div>
		)
	}

	renderFormGroup = (
		label: string,
		stateKey: keyof State,
		type: InputType = 'text'
	): JSX.Element => {
		const { object } = this.props
		return (
			<FormGroup row>
				<Label sm={3}>{label}</Label>
				<Col sm={9}>
					<Input
						name={stateKey}
						value={
							stateKey === 'x' || stateKey === 'y'
								? object.location[stateKey] || ''
								: // @ts-expect-error we know `stateKey` is in `object`
								  object[stateKey] || ''
						}
						type={type}
						onChange={(e) => this.changeInput(stateKey, e.target.value)}
					/>
				</Col>
			</FormGroup>
		)
	}
	toggle = () => {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
		}))
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- this `any` could probably be fixed with a generic type
	changeInput = (key: keyof State, newValue: any) => {
		let newValueAsNumber: number | null = null

		if (['x', 'y'].includes(key)) {
			newValueAsNumber = parseInt(newValue, 10)
			this.setState(
				// @ts-expect-error typescript is expecting all keys of state on this object
				{
					[key]: newValueAsNumber || newValue,
				},
				this.sendUpdate
			)
			this.props.updateMapObjectStore({
				[this.props.objectClassification]: {
					[this.props.objectId]: {
						location: {
							[key as string]: {
								$set: newValueAsNumber || newValue,
							},
						},
					},
				},
			})
			return
		} else if (['url', 'width', 'height'].includes(key)) {
			newValueAsNumber = ['width', 'height'].includes(key) ? parseInt(newValue, 10) : null
			this.setState(
				// @ts-expect-error typescript is expecting all keys of state on this object
				{
					[key]: newValueAsNumber || newValue,
				},
				this.sendUpdate
			)
			const defaultIconData = {
				width: 25,
				height: 25,
				url: newValue,
			}
			this.props.updateMapObjectStore({
				[this.props.objectClassification]: {
					[this.props.objectId]: {
						icon:
							'icon' in this.props.object
								? {
										[key as string]: {
											$set: newValue,
										},
								  }
								: {
										$set: defaultIconData,
								  },
					},
				},
			})
			return
		}

		this.setState(
			// @ts-expect-error typescript is expecting all keys of state on this object
			{
				[key as string]: newValue,
			},
			this.sendUpdate
		)
		this.props.updateMapObjectStore({
			[this.props.objectClassification]: {
				[this.props.objectId]: {
					[key as string]: {
						$set: newValue,
					},
				},
			},
		})
	}
	sendUpdate = () => {
		this.props.debounceUpdateServerObject(this.props.objectClassification, this.props.object._id)
	}
}

function mapStateToProps(state: ReduxStore, ownProps: Props) {
	return {
		object: state.mapObjects[ownProps.objectClassification][ownProps.objectId],
	}
}

const mapDispatchToProps = {
	debounceUpdateServerObject,
	updateMapObjectStore,
}
const TOGGLE_ICON_SIZE = 25

const Toggler = ({ show, onClick }: { show: boolean; onClick: () => void }) => {
	const props = {
		onClick,
		className: 'clickable',
		size: TOGGLE_ICON_SIZE,
	}
	return <ToggleStyle>{show ? <FaAngleUp {...props} /> : <FaAngleDown {...props} />}</ToggleStyle>
}

const ToggleStyle = styled.div`
	:hover {
		background-color: #ededed;
	}
	border-radius: 4px;
	height: ${TOGGLE_ICON_SIZE}px;
	width: ${TOGGLE_ICON_SIZE}px;
`
export default connect(mapStateToProps, mapDispatchToProps)(MapObjectForm)
