import { PAUSE_STATE } from './ActionPlayer'
import type { ActionPlayerState, PausedState } from './ActionPlayer'

/**
 * Starts a pause state - either 'PAUSED' or 'SOFT_PAUSE'.
 * Sets the current pausedState to the newState, and sets the pendingState to be the
 * previous pausedState.
 */
export function startPause(draft: ActionPlayerState, newState: 'PAUSED' | 'SOFT_PAUSE'): void {
	if (draft.pausedState.state === PAUSE_STATE.PAUSED) {
		if (newState === PAUSE_STATE.SOFT_PAUSE) {
			// This logic matched in mission-server but I believe that is incorrect
			const newPendingState: PausedState = {
				state: newState,
				pendingState: draft.pausedState.pendingState,
			}
			draft.pausedState.pendingState = newPendingState
		}
	} else {
		const newPausedState = {
			state: newState,
			pendingState: draft.pausedState,
		}
		draft.pausedState = newPausedState
	}
}

/**
 * Takes in the draft and an optional `providedPauseToRemove`. If `providedPauseToRemove` is not specified, remove the top pause from
 * the state. If `providedPauseToRemove` *is* specified, recursively find and then remove the first layer of the pause state that
 * matches `providedPauseToRemove`. If the given pause type is not found in the pausedState, set the pause state to `PAUSE_STATES.PLAYING`.
 * @param {BothJrMissionStates} draft The mission's full state
 * @param {'PAUSED' | 'SOFT_PAUSE'} providedPauseToRemove (optional) The specific pause type to remove from the state
 */
export function endPause(
	draft: ActionPlayerState,
	providedPauseToRemove: ('PAUSED' | 'SOFT_PAUSE') | null | undefined
): void {
	if (!('pendingState' in draft.pausedState)) {
		if (draft.pausedState.state !== PAUSE_STATE.PLAYING) {
			draft.pausedState = {
				state: PAUSE_STATE.PLAYING,
			}
		}

		return
	}

	// Remove providedPauseToRemove if available, otherwise remove the top level pause
	const pauseToRemove = providedPauseToRemove || draft.pausedState.state
	draft.pausedState = removeNestedPause(draft.pausedState, pauseToRemove)
}

/**
 * Recursive function for removing a specific pause type from the pausedState.
 * If we have nested and not found what we were looking for, or all we received was the PLAYING state,
 * we return it.
 * If we find the pause we were looking for, we return its pending state, which removes said pause from
 * the paused state.
 * Else, we recurse to find the paused state we are looking for.
 */
function removeNestedPause(
	pausedState: PausedState,
	pauseToRemove: 'PAUSED' | 'SOFT_PAUSE'
): PausedState {
	if (pausedState.state === PAUSE_STATE.PLAYING) {
		return pausedState
	} else if (pausedState.state === pauseToRemove) {
		return pausedState.pendingState
	} else {
		return {
			...pausedState,
			pendingState: removeNestedPause(pausedState.pendingState, pauseToRemove),
		}
	}
}
