import type { TileParams } from '../types/tiles'
import axios from 'axios'
import { toast } from 'react-toastify'
import type { Entity } from '@mission.io/navigation-map-server'
import { Dispatch } from 'redux'
import { config } from '../config'
const NAVIGATION_ENTITIES_URL = `${config.simulationsApiUrl}/api/navigation/entities`
type EntityWithType = Entity & {
	type: 'ENTITY'
}
type EntityMeta = {
	name: string
	_id: string
}
type EntityMetaLookup = Record<string, EntityMeta>
type EntityLookup = Record<string, Entity>
export type EntityStore = {
	fetching: Record<string, boolean>
	entityMeta: EntityMetaLookup | null | undefined
	entities: EntityLookup
}
enum ActionType {
	SET_ENTITY_META = 'SET_ENTITY_META',
	SET_ENTITIES = 'SET_ENTITIES',
	SET_FETCHING_ENTITY_DATA = 'SET_FETCHING_ENTITY_DATA',
	REFRESH_ENTITY_STORE = 'REFRESH_ENTITY_STORE',
}
type SetFetchingAction = {
	type: 'SET_FETCHING_ENTITY_DATA'
	payload: Record<string, boolean>
}
type SetEntityMetaAction = {
	type: 'SET_ENTITY_META'
	payload: EntityMetaLookup
}
type SetEntitiesAction = {
	type: 'SET_ENTITIES'
	payload: Entity[]
}
type RefreshEntityStoreAction = {
	type: 'REFRESH_ENTITY_STORE'
}

function setFetching(fetching: Record<string, boolean>): SetFetchingAction {
	return {
		type: ActionType.SET_FETCHING_ENTITY_DATA,
		payload: fetching,
	}
}

function setEntityMeta(meta: EntityMetaLookup): SetEntityMetaAction {
	return {
		type: ActionType.SET_ENTITY_META,
		payload: meta,
	}
}

function setEntities(entities: Entity[]): SetEntitiesAction {
	return {
		type: ActionType.SET_ENTITIES,
		payload: entities,
	}
}

export function refreshStore(): RefreshEntityStoreAction {
	return {
		type: ActionType.REFRESH_ENTITY_STORE,
	}
}
export function fetchEntityMeta(): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		const state = getState()

		if (state.entities.fetching.meta) {
			return
		}

		dispatch(
			setFetching({
				meta: true,
			})
		)
		axios
			.get(`${NAVIGATION_ENTITIES_URL}/meta`)
			.then(({ data }: { data: { meta?: EntityMeta[]; error?: unknown } }) => {
				if (data.meta) {
					const formattedMeta: EntityMetaLookup = {}
					data.meta.forEach((item) => (formattedMeta[item._id] = item))
					dispatch(setEntityMeta(formattedMeta))
				}

				if (data.error) {
					console.error(`Error while fetching Entity Meta Data: ${data.error}`)
				}
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
				} else {
					console.error(errData.error)
				}
			})
			.finally(() =>
				dispatch(
					setFetching({
						meta: false,
					})
				)
			)
	}
}
export function fetchEntities(ids: string[]): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		const state = getState()
		ids = ids.filter((id) => !state.entities.fetching[id])

		if (ids.length === 0) {
			return
		}

		const fetchingData: Record<string, boolean> = {}
		ids.forEach((id: string) => (fetchingData[id] = true))
		dispatch(setFetching(fetchingData))
		axios
			.get(NAVIGATION_ENTITIES_URL, {
				params: {
					ids: ids.join(' '),
				},
			})
			.then(({ data }) => {
				dispatch(setEntities(data.entities))
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
				} else {
					console.error(errData.message)
				}
			})
			.finally(() => {
				const fetching: Record<string, boolean> = {}
				ids.forEach((id) => {
					fetching[id] = false
				})
				dispatch(setFetching(fetching))
			})
	}
}
export function updateEntity(entity: EntityWithType) {
	return ({ dispatch }: { dispatch: Dispatch }) => {
		return axios
			.post(NAVIGATION_ENTITIES_URL, {
				entity,
			})
			.then(({ data }) => {
				if (data.error) {
					console.error(data.error)
				}

				if (data.entity) {
					dispatch(setEntities([data.entity]))
					return data.entity
				} else {
					throw new Error(`Server did not return a map: ${data.error || 'Unknown'}`)
				}
			})
	}
}
export function copyEntity(entityId: string): (arg0: TileParams<void>) => Promise<unknown> {
	return ({ dispatch }: TileParams<void>) => {
		return axios
			.post(`${NAVIGATION_ENTITIES_URL}/copy/${entityId}`)
			.catch((error) => {
				let message = ''

				if (typeof error === 'object') {
					message = error.message || ''
				} else if (typeof error === 'string') {
					message = error
				}

				toast.error(`The following error occurred while copying entity "${entityId}": ${message}`)
			})
			.finally(() => {
				dispatch(refreshStore())
			})
	}
}
const initialState: EntityStore = {
	fetching: {},
	entityMeta: null,
	entities: {},
}
type Actions =
	| SetEntityMetaAction
	| SetFetchingAction
	| SetEntitiesAction
	| RefreshEntityStoreAction
export default function entitiesReducer(
	state: EntityStore = initialState,
	action: Actions
): EntityStore {
	switch (action.type) {
		case ActionType.SET_ENTITY_META:
			return { ...state, entityMeta: { ...state.entityMeta, ...action.payload } }

		case ActionType.SET_FETCHING_ENTITY_DATA:
			return { ...state, fetching: { ...state.fetching, ...action.payload } }

		case ActionType.SET_ENTITIES:
			const newEntities: Record<string, Entity> = {}
			action.payload.forEach((entity) => (newEntities[String(entity._id)] = entity))
			return { ...state, entities: { ...state.entities, ...newEntities } }

		case ActionType.REFRESH_ENTITY_STORE:
			return initialState

		default:
			return state
	}
} // selectors

export type EntityResponse = {
	id: string | null | undefined
	entity: Entity | null | undefined
	fetching: boolean | null | undefined
}
export type EntityMetaResponse = {
	meta: EntityMeta[] | null | undefined
	fetching: boolean | null | undefined
}
export function getEntity(
	id: string | null | undefined
): (state: { entities: EntityStore }) => EntityResponse {
	return (state: { entities: EntityStore }) => {
		const entityStore = state.entities
		return {
			id,
			entity: id != null ? entityStore.entities[id] : id,
			fetching: id != null ? entityStore.fetching[id] : id,
		}
	}
}
export function getEntities(
	ids: string[] | null | undefined
): (state: { entities: EntityStore }) => EntityResponse[] | null | undefined {
	return (state: { entities: EntityStore }) => {
		if (!ids) {
			return null
		}

		const entityStore = state.entities
		return ids.map(
			(id: string): EntityResponse => ({
				id,
				entity: entityStore.entities[id],
				fetching: entityStore.fetching[id],
			})
		)
	}
}
export function getEntityMeta(state: { entities: EntityStore }): EntityMetaResponse {
	const entityStore = state.entities
	let meta = null

	if (entityStore.entityMeta) {
		meta = Object.values(entityStore.entityMeta) as EntityMeta[]
	}

	return {
		meta,
		fetching: entityStore.fetching.meta,
	}
}
