import React from 'react'
import { Prompt } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'

/**
 * A component that can be used to block navigation when the user has unsaved changes.
 * It can be used in two ways:
 * 1. By passing the props `shouldBlock` and `message`, `shouldBlock` being a boolean that determines whether to block
 *  navigation, and `message` being the message to display in the browser's confirmation dialog.
 * 2. By passing a `getBlockMessage` prop, which is a function that returns a string to display in the browser's
 *   confirmation dialog, or null to allow the navigation to continue.
 */
export function BlockNavigation(
	props:
		| {
				shouldBlock: boolean
				message: string
		  }
		| {
				getBlockMessage: () => string | null
		  }
): JSX.Element {
	const beforeUnloadFunction =
		'getBlockMessage' in props
			? props.getBlockMessage
			: () => (props.shouldBlock ? props.message : null)
	useBeforeunload(beforeUnloadFunction)
	const promptProps =
		'getBlockMessage' in props
			? {
					message: () => {
						// In the api for `Prompt`, returning `true` will allow the navigation to continue.
						return props.getBlockMessage() || true
					},
			  }
			: {
					when: props.shouldBlock,
					message: props.message,
			  }
	return <Prompt {...promptProps} />
}
