import React from 'react'
import type { ClientQuestion } from '@mission.io/question-toolkit'
import { EditQuestions } from '@mission.io/question-views'
type Props = {
	questions: ClientQuestion[]
	add: (arg0: ClientQuestion) => void
	update: (arg0: ClientQuestion, arg1: number) => void
	remove: (arg0: number) => void
	id?: string
}
export default function QuestionsForm(props: Props): JSX.Element {
	return (
		<div css="* { box-sizing: content-box;}">
			{/* @ts-expect-error these types need to be fixed */}
			<EditQuestions {...props} />
		</div>
	)
}
