import React, { useState } from 'react'
import styled from 'styled-components'
import { IdMap } from '../../../../types/util'
import {
	Modal as BootstrapModal,
	ModalHeader,
	ModalBody,
	Card,
	UncontrolledTooltip,
} from 'reactstrap'
import { Button } from '@mission.io/styles'
import { LiteracyEventTask } from '@mission.io/mission-toolkit/actions'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { useTeams } from '../../../simulations/Teams'
import {
	FullStateLiteracyEventForActionPlayer,
	FullStateReadingContextForActionPlayer,
} from '../ActionPlayer'
import { getTimeFormat } from '../../../../helpers/functions'
import { isArray, lowerCase, startCase } from 'lodash'
import Markdown from '../../../../common/Markdown'

type LiteracyEventsModalProps = {
	literacyEvents: IdMap<FullStateLiteracyEventForActionPlayer>
	closeModal: () => void
	dismissLiteracyEvent: (literacyEventId: string) => void
}

/**
 * A Modal that displays Literacy Events
 *
 * @param {IdMap<FullStateLiteracyEventForActionPlayer>} literacyEvents - All of the literacy events to display
 * @param {() => void} closeModal - a function to be called to close the modal
 * @param {(literacyEventId: string) => void} dismissLiteracyEvent - a function to be called to remove a literacyEvent from the action player state
 * @returns {React$Node}
 */
export default function LiteracyEventsModal({
	literacyEvents,
	closeModal,
	dismissLiteracyEvent,
}: LiteracyEventsModalProps): JSX.Element | null {
	return (
		<BootstrapModal isOpen={true} centered={true} size="xl">
			<ModalHeader toggle={closeModal}>Literacy Events</ModalHeader>
			<ModalBody>
				{Object.keys(literacyEvents).map((literacyEventId) => (
					<LiteracyEvent
						literacyEvent={literacyEvents[literacyEventId]}
						key={literacyEventId}
						onDismiss={() => dismissLiteracyEvent(literacyEventId)}
					/>
				))}
				{!Object.keys(literacyEvents).length && <div>No Literacy Events found</div>}
			</ModalBody>
		</BootstrapModal>
	)
}

/**
 *
 * A collapsible card that displays a literacy event. Provides a way for literacy event to be removed from the action player state.
 *
 * @param {FullStateLiteracyEventForActionPlayer} literacyEvent - the literacy event to display
 * @param {() => void} onDismiss - a function to be called to remove the literacyEvent
 * @returns {React$Node}
 */
const LiteracyEvent = ({
	literacyEvent,
	onDismiss,
}: {
	literacyEvent: FullStateLiteracyEventForActionPlayer
	onDismiss: () => void
}) => {
	const [showMore, setShowMore] = useState(false)
	return (
		<Card className="p-2 m-2 bg-cloud-blue">
			<DropdownHeaderRow
				showMore={showMore}
				setShowMore={setShowMore}
				title={`Literacy Event Action Id: ${literacyEvent.actionId}`}
				onDismiss={onDismiss}
			/>
			{showMore && (
				<div>
					<div>
						Data Appearance Type:{' '}
						{isArray(literacyEvent.dataAppearance)
							? literacyEvent.dataAppearance.map((item) => startCase(lowerCase(item.type))).join()
							: startCase(lowerCase(literacyEvent.dataAppearance.type))}
					</div>
					{!Object.keys(literacyEvent.readingContext).length
						? 'No reading contexts found'
						: 'Reading Contexts: '}
					{Object.keys(literacyEvent.readingContext).map((readingContextId) => (
						<ReadingContext
							readingContext={literacyEvent.readingContext[readingContextId]}
							key={readingContextId}
							getReadingContextNameForId={(readingContextId) =>
								literacyEvent.readingContext[readingContextId]?.title || ''
							}
						/>
					))}
				</div>
			)}
		</Card>
	)
}

/**
 * A collapsible card that displays a reading context.
 *
 * @param {FullStateReadingContextForActionPlayer} readingContext - the reading context to display
 * @param {(readingContextId: string) => string} getReadingContextNameForId - gets the name for the reading context with the given id
 * @returns {React$Node}
 */
const ReadingContext = ({
	readingContext,
	getReadingContextNameForId,
}: {
	readingContext: FullStateReadingContextForActionPlayer
	getReadingContextNameForId: (readingContextId: string) => string
}) => {
	const [showMore, setShowMore] = useState(false)

	return (
		<Card className="p-2 m-2">
			<DropdownHeaderRow
				showMore={showMore}
				setShowMore={setShowMore}
				title={
					<div className="flex flex-row items-center gap-2">
						<div className="text-xl font-bold">
							{readingContext.title || 'No Title'}
							{' -  '}
						</div>
						{readingContext.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL ? (
							<div>All teams</div>
						) : (
							<TeamDisplay teamIds={readingContext.assignment.teams} idPrefix={readingContext.id} />
						)}
					</div>
				}
			/>
			{showMore && (
				<>
					<div className="m-2 gap-2 flex flex-column">
						<div>Relevance: {readingContext.relevance}</div>
						<Markdown>{readingContext.text}</Markdown>
						<div className="flex flex-row items-center gap-2">
							{readingContext.media.map((mediaItem) => (
								<div key={mediaItem.url}>
									{mediaItem.type === 'IMAGE' ? (
										<img
											src={mediaItem.url}
											alt=""
											css="max-width: 200px; max-height: 200px;"
											title={mediaItem.url}
										/>
									) : mediaItem.type === 'VIDEO' ? (
										<video src={mediaItem.url} css="max-height: 200px; " />
									) : (
										<div>{mediaItem.url}</div>
									)}
								</div>
							))}
						</div>
					</div>

					<div>
						{!Object.keys(readingContext.tasks).length ? (
							<div>No tasks found</div>
						) : (
							<>
								{readingContext.taskLists.map((taskList, index) => (
									<div key={taskList.list.map((item) => item.id).join()}>
										Tasks for Student {index + 1}:
										{taskList.list?.map((readingTask) => (
											<ReadingTask
												key={readingTask.id}
												readingTask={readingTask}
												getReadingContextNameForId={getReadingContextNameForId}
											/>
										))}
									</div>
								))}
							</>
						)}
					</div>
				</>
			)}
		</Card>
	)
}

/**
 * Displays a bade for each provided teamId. A tooltip with the team name is displayed on hover.
 *
 * @param {Array<string>} teamIds - the teamIds to display
 * @param {string} idPrefix - a string to be added as a prefix to the current teamId. The combination will be used as a key to ensure that we don't have any duplicate keys on the page
 * @returns {React$Node}
 */
const TeamDisplay = ({ teamIds, idPrefix }: { teamIds: Array<string>; idPrefix: string }) => {
	const teams = useTeams()
	return (
		<div className="flex flex-row items-center gap-2">
			Teams:{' '}
			{teamIds.map((teamId) => {
				const team = teams.find((current) => current._id === teamId)
				const iconId = `${idPrefix}${team?.name}`
				return (
					<div key={iconId} id={iconId}>
						{team?.icon ? (
							<div className="self-center">
								<img src={team.icon} width={25} alt={`${team.name} icon`} />
								<UncontrolledTooltip target={`[id^='${iconId}']`} placement="top">
									<span>{team.name}</span>
								</UncontrolledTooltip>
							</div>
						) : (
							'Team not found'
						)}
					</div>
				)
			})}
		</div>
	)
}

/**
 * Displays a reading task
 *
 * @param {LiteracyEventTask}  readingTask - the reading task to be displayed
 * @param {(readingContextId: string) => string} getReadingContextNameForId - gets the name for the reading context with the given id
 * @returns {React$Node}
 */
const ReadingTask = ({
	readingTask,
	getReadingContextNameForId,
}: {
	readingTask: LiteracyEventTask<string>
	getReadingContextNameForId: (readigContextId: string) => string
}) => {
	return (
		<Card className="p-2 m-2">
			<div>Type: {startCase(lowerCase(readingTask.type))}</div>
			{readingTask.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE && readingTask.options && (
				<>
					<div>Prompt: {readingTask.prompt}</div>
					<div>
						Options:{' '}
						<div className="mx-2">
							{readingTask.options.map((option) => (
								<ul key={option.id} style={{ color: option.correct ? 'green' : 'black' }}>
									<li>
										<Markdown>{option.text}</Markdown>
										<div className="ml-3">
											{option.explanation && <Markdown>{option.explanation}</Markdown>}
										</div>
									</li>
								</ul>
							))}
						</div>
					</div>
				</>
			)}
			{readingTask.type === LITERACY_EVENT.TASK.TYPE.SPEAKING && (
				<>
					<Markdown>{`Prompt: ${readingTask.prompt}`}</Markdown>
					<div>
						Time Before Allowing Completion:{' '}
						{getTimeFormat(readingTask.timeBeforeAllowingCompletion, true)}
					</div>
					<div>Target Type: {startCase(lowerCase(readingTask.target.type))}</div>
					{readingTask.target.type ===
						LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.STUDENT && (
						<div className="m-2">
							<div className="p-2 bg-horizon-blue rounded w-fit text-white">
								<div>
									<Markdown>{readingTask.target.feedbackPrompt}</Markdown>
								</div>
								<AffirmativeFakeButton
									className="!bg-success m-2"
									style={{ backgroundColor: 'pink' }}>
									<Markdown>{readingTask.target.affirmativeButtonText}</Markdown>
								</AffirmativeFakeButton>
								<NegativeFakeButton className="m-2">
									<Markdown>{readingTask.target.negativeButtonText}</Markdown>
								</NegativeFakeButton>
							</div>
							<div>
								Target Restrictions:{' '}
								<div className="m-2">
									{readingTask.target.restrictions.inTeam && (
										<TeamDisplay
											teamIds={readingTask.target.restrictions.inTeam}
											idPrefix={readingTask.id}
										/>
									)}
									{readingTask.target.restrictions.assignedReadingContext && (
										<div>
											Assigned Reading Context:{' '}
											{readingTask.target.restrictions.assignedReadingContext
												.map((readingContextId) => getReadingContextNameForId(readingContextId))
												.join()}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</Card>
	)
}

/**
 *
 * A reusable Header for the dropdown cards with a show more /less button.
 *
 * @param {boolean} showMore - whether the dropdown is currently showing more or not
 * @param {(arg0: boolean) => void} setShowMore - sets whether the dropdown is open or not
 * @param  {string | JSX.Element} title - the Title for the dropdown
 * @param {() => void} onDismiss optional function that is called when dismiss is clicked. If no onDismiss function is provided, the button will not be shown.
 * @returns {React$Node}
 */
const DropdownHeaderRow = ({
	showMore,
	setShowMore,
	title,
	onDismiss,
}: {
	showMore: boolean
	setShowMore: (arg0: boolean) => void
	title: string | JSX.Element
	onDismiss?: () => void
}) => {
	return (
		<div className="flex flex-row justify-between">
			<div>{title}</div>
			<div>
				<Button onClick={() => setShowMore(!showMore)} className="mr-1">
					Show {showMore ? 'Less' : 'More'}
				</Button>
				{onDismiss && <Button onClick={onDismiss || undefined}>Dismiss</Button>}
			</div>
		</div>
	)
}

const AffirmativeFakeButton = styled(Button)`
	background: lightseagreen;
`

const NegativeFakeButton = styled(Button)`
	background: orangered;
`
