import React from 'react'
import styled from 'styled-components'
import type { OnSelect } from '../types'
import { Handles, HANDLE_SIZE } from '../constants'
type Props = {
	x: number
	y: number
	width: number
	height: number
	visible: boolean
	selectHandle: OnSelect
	_id: string
	scaling: boolean | null | undefined
	rotation: number
}
export default function BoundingBox({
	x,
	y,
	width,
	height,
	visible,
	scaling,
	selectHandle,
	rotation,
	_id,
}: Props): JSX.Element {
	let handles: Array<JSX.Element> = []
	const rotationCenterX = x + width / 2
	const rotationCenterY = y + height / 2
	const rotationTransform = `rotate(${rotation}, ${rotationCenterX} ${rotationCenterY})`

	if (scaling) {
		const handleSize = HANDLE_SIZE
		handles = [
			<Handle
				x={x + (width - handleSize) / 2}
				y={y - handleSize / 2}
				width={handleSize}
				height={handleSize}
				key={'top'}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.TOP])}
				onMouseUp={() => selectHandle && selectHandle()}
				orientation={90 - rotation}
				transform={rotationTransform}
			/>,
			<Handle
				x={x + (width - handleSize / 2)}
				y={y - handleSize / 2}
				width={handleSize}
				height={handleSize}
				key={'top-right'}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.TOP, Handles.RIGHT])}
				onMouseUp={() => selectHandle && selectHandle()}
				orientation={45 - rotation}
				transform={rotationTransform}
			/>,
			<Handle
				x={x + (width - handleSize) / 2}
				y={y + height - handleSize / 2}
				width={handleSize}
				height={handleSize}
				key={'bottom'}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.BOTTOM])}
				onMouseUp={() => selectHandle && selectHandle()}
				orientation={270 - rotation}
				transform={rotationTransform}
			/>,
			<Handle
				x={x + (width - handleSize / 2)}
				y={y + height - handleSize / 2}
				width={handleSize}
				height={handleSize}
				key={'bottom-right'}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.BOTTOM, Handles.RIGHT])}
				onMouseUp={() => selectHandle && selectHandle()}
				orientation={315 - rotation}
				transform={rotationTransform}
			/>,
			<Handle
				x={x - handleSize / 2}
				y={y + (height - handleSize) / 2}
				width={handleSize}
				height={handleSize}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.LEFT])}
				onMouseUp={() => selectHandle && selectHandle()}
				key={'left'}
				orientation={180 - rotation}
				transform={rotationTransform}
			/>,
			<Handle
				x={x - handleSize / 2}
				y={y + height - handleSize / 2}
				width={handleSize}
				height={handleSize}
				key={'bottom-left'}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.BOTTOM, Handles.LEFT])}
				onMouseUp={() => selectHandle && selectHandle()}
				orientation={225 - rotation}
				transform={rotationTransform}
			/>,
			<Handle
				x={x + width - handleSize / 2}
				y={y + (height - handleSize) / 2}
				width={handleSize}
				height={handleSize}
				key={'right'}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.RIGHT])}
				onMouseUp={() => selectHandle && selectHandle()}
				orientation={0 - rotation}
				transform={rotationTransform}
			/>,
			<Handle
				x={x - handleSize / 2}
				y={y - handleSize / 2}
				width={handleSize}
				height={handleSize}
				key={'top-left'}
				onMouseDown={() => selectHandle && selectHandle(_id, [Handles.TOP, Handles.LEFT])}
				onMouseUp={() => selectHandle && selectHandle()}
				orientation={135 - rotation}
				transform={rotationTransform}
			/>,
		]
	}

	return (
		<>
			<SelectionArea
				x={x}
				y={y}
				width={width}
				height={height}
				$visible={visible}
				transform={rotationTransform}
			/>
			{handles}
		</>
	)
}
/**
 * getCursor - get the cursor type based on the orientation (angle) of the handle from the global x-axis
 *
 * @param  {number} orientation - the angle of the handle from the global x-axis
 * @returns string - cursor type
 */

function getCursor(orientation: number): string {
	orientation = (orientation + 360) % 360

	if (orientation < 45) {
		return 'e-resize'
	}

	if (orientation < 90) {
		return 'ne-resize'
	}

	if (orientation < 135) {
		return 'n-resize'
	}

	if (orientation < 180) {
		return 'nw-resize'
	}

	if (orientation < 225) {
		return 'w-resize'
	}

	if (orientation < 270) {
		return 'sw-resize'
	}

	if (orientation < 315) {
		return 's-resize'
	}

	return 'se-resize'
}

const SelectionArea = styled.rect<{ $visible: boolean }>`
	stroke: ${(props) => (props.$visible ? 'darkgreen' : 'Transparent')};
	stroke-width: 3px;
	stroke-dasharray: 3, 3;
	fill: None;
	pointer-events: None;
	draggable: false;
`
const Handle = styled.rect<{ orientation: number }>`
	stroke: black;
	stroke-width: 1px;
	fill: white;
	draggable: false;
	&:hover {
		cursor: ${(props) => getCursor(props.orientation)};
	}
`
