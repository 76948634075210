export const SAVE_DELAY = 2000
export const REDUX_FORM_NAMES = {
	DETAILS: 'details',
	CARD: 'card',
}

export const ONE_SECOND = 1000
export const ONE_MINUTE = 60 * ONE_SECOND
export const ONE_HOUR = 60 * ONE_MINUTE

export const AUTOMATED_REDUX_FORM_NAMES = {
	DETAILS: 'automatedDetails',
}
export const MISSION_TYPES: {
	JUNIOR: 'K-3'
	JUNIOR_PLUS: '4+'
} = {
	JUNIOR: 'K-3',
	JUNIOR_PLUS: '4+',
}
export const TEMPORARY_ID_PREFIX = 'SIM_CREATOR_ID_'
