import React from 'react'
import { Button } from 'reactstrap'
import classnames from 'classnames'

type Props = {
	className?: string
	crumbs: string[]
	onFolderClick: (crumbs: string[]) => void
}
export default function FolderBreadcrumbs(props: Props): JSX.Element {
	const { className, crumbs, onFolderClick } = props
	const crumbItems = []

	for (let i = 0; i < crumbs.length; i++) {
		const key = `breadcrumb=${i}`

		if (i !== crumbs.length - 1) {
			const localCrumbs = crumbs.slice(0, i + 1)
			crumbItems.push(
				<li className="breadcrumb-item" key={key}>
					<Button
						css="&&{padding: 0; border: none;}"
						color="link"
						onClick={(e) => {
							e.preventDefault()
							onFolderClick(localCrumbs)
						}}>
						{crumbs[i]}
					</Button>
				</li>
			)
		} else {
			crumbItems.push(
				<li className="breadcrumb-item active" key={key}>
					{crumbs[i]}
				</li>
			)
		}
	}

	return (
		<nav className={classnames(className)}>
			<ol className="breadcrumb">{crumbItems}</ol>
		</nav>
	)
}
