import { useRef, useEffect, useCallback, useState, useLayoutEffect, MutableRefObject } from 'react'
import uuid from 'uuid/v4'

/**
 * A hook to get the previous value of a variable
 */
export function usePrevious<T>(value: T): T | null | undefined {
	const ref = useRef<T | null | undefined>()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}
type Cancellable<T> = {
	promise: Promise<T>
	cancel: () => void
}

class CancelledError extends Error {
	constructor() {
		super('Promise was cancelled')
	}
}

/**
 * Wraps a given promise in another promise which checks to see if it has been canceled or not before resolving.
 * @param {Promise<*>} promise
 */
export function makeCancellable<T>(promise: Promise<T>): Cancellable<T> {
	let isCanceled = false
	const wrappedPromise = new Promise<T>((resolve, reject) => {
		promise
			.then((val) => (isCanceled ? reject(new CancelledError()) : resolve(val)))
			.catch((error) => reject(isCanceled ? new CancelledError() : error))
	})
	return {
		promise: wrappedPromise,

		cancel() {
			isCanceled = true
		},
	}
}

/**
 * A hook which returns a decorator for a promise in react. If a promise is called
 * using the decorator returned from this hook, then the promise will properly cancel
 * when its component un-mounts.
 * PLEASE NOTE: This hook will return promises that will fail when component un-mounts.
 * So you MUST include catches for any promises called using this decorator.
 *
 * Example Usage:
 *   const cancellablePromise = useCancellablePromise()
 *   cancellablePromise(new Promise(() => {...})).then(...).catch()
 */
export function useCancellablePromise<T>(): (promise: Promise<T>) => Promise<T> {
	const promises = useRef<Array<Cancellable<T>>>([])
	useEffect(() => {
		return function cancel() {
			promises.current.forEach((p) => p.cancel())
			promises.current = []
		}
	}, [])
	const cancellablePromise = useCallback((p: Promise<T>): Promise<T> => {
		const cPromise = makeCancellable(p)
		promises.current && promises.current.push(cPromise)
		return cPromise.promise
	}, [])
	return cancellablePromise
}

/**
 * Gets a unique id that exists for the lifetime of the component. We can delete this hook when we upgrade to React 18
 * where it is included with react.
 */
export function useId(): string {
	const [id] = useState(() => uuid())
	return id
}

type Dimensions = {
	x: number | null
	y: number | null
	width: number | null
	height: number | null
}

/**
 * Get the dimensions of an object
 * @returns a ref to attach to an element, and the dimensions of the element
 */
export function useDimensions<E extends Element>(): [MutableRefObject<E | null>, Dimensions] {
	const ref = useRef<E>(null)
	const [dimensions, setDimensions] = useState<Dimensions>({
		x: null,
		y: null,
		width: null,
		height: null,
	})
	useLayoutEffect(() => {
		function updateDimensions() {
			setDimensions(ref.current?.getBoundingClientRect().toJSON())
		}

		updateDimensions()

		window.addEventListener('resize', updateDimensions)
		return () => {
			window.removeEventListener('resize', updateDimensions)
		}
	}, [])

	return [ref, dimensions]
}
