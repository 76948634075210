import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from '../../common/Spinner'
import { actions, selectors } from '../../setup/tiles'
import OldSimulation from './OldSimulation'
import AutomatedSimulation from '../automatedSimulations/AutomatedSimulation'
import { setEditorSimulation, useEditorSimulation } from '../../reducers/simulationEditor'
import type { Simulation as SimulationType } from '../../types'
import type { AutomatedSimulation as AutomatedSimulationType } from '../../types/AutomatedSimulation'
import { useAutomatedSimulation } from '../automatedSimulations/queries'
import { ReduxStore } from '../../types/ReduxStore'
type SomeSimulation =
	| {
			automated: true
			simulation: AutomatedSimulationType
	  }
	| {
			automated: false
			simulation: SimulationType | null | undefined
	  }

/**
 * A hook that updates the `simulationEditor` redux store when the id changes
 */
function useUpdateEditorSimulation(
	id: string,
	automatedSimulation: AutomatedSimulationType | null | undefined
) {
	// Get the current editor simulation
	const editorSimulation = useEditorSimulation()
	const dispatch = useDispatch()
	// If the id changed and it's an automated simulation, we need to reset the simulationEditor store.
	// This effect can't be in any of the `AutomatedSimulation` files because those are conditionally rendered sometimes,
	// causing this effect to run too often which causes a bug
	useEffect(() => {
		/**
		 * Tells whether an automated simulation is valid for the editor simulation store. Currently it just needs
		 * to exist and have actions on it, because on the home screen not all fields are initially loaded for the simulation
		 */
		function isValidAutomatedSimulationForEditor(
			simulation: AutomatedSimulationType | null | undefined
		): simulation is AutomatedSimulationType {
			return !!simulation && !!simulation.actions
		}

		if (isValidAutomatedSimulationForEditor(automatedSimulation) && editorSimulation?._id !== id) {
			dispatch(setEditorSimulation(automatedSimulation, true))
		}
	}, [id, dispatch, editorSimulation, automatedSimulation])
}

const useSimulation = (id: string): SomeSimulation => {
	const dispatch = useDispatch()
	const { data: automatedSimulation } = useAutomatedSimulation(id)
	useUpdateEditorSimulation(id, automatedSimulation)
	const oldSimulation: SimulationType | null | undefined = useSelector(
		(state: ReduxStore) => selectors.simulations.store(state)[id]
	)
	// Get status of old simulation
	const { fetched: fetchedOldSimulation, isPending: oldSimulationIsPending } = useSelector(
		(state: ReduxStore) => selectors.simulations.get(state, id)
	)
	useEffect(() => {
		if (oldSimulation) {
			return
		}

		if (!fetchedOldSimulation && !oldSimulationIsPending) {
			dispatch(actions.simulations.get(id))
		}
	}, [oldSimulation, fetchedOldSimulation, oldSimulationIsPending, dispatch, id])
	return automatedSimulation
		? {
				automated: true,
				simulation: automatedSimulation,
		  }
		: {
				automated: false,
				simulation: oldSimulation,
		  }
}

export default function Simulation(): JSX.Element {
	const { id } = useParams<{
		id: string
	}>()
	const result = useSimulation(id)

	if (result.automated) {
		return <AutomatedSimulation simulation={result.simulation} />
	}

	const simulation: SimulationType | null | undefined = result.simulation

	if (!simulation) {
		return <Spinner />
	}

	return <OldSimulation simulation={simulation} />
}
