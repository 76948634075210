import React from 'react'
import { FaPlay } from 'react-icons/fa'
import { MenuItem } from './styles'
type Props = {
	onClick: () => void
}
export default function Preview({ onClick }: Props): JSX.Element {
	return (
		<MenuItem onClick={onClick}>
			<FaPlay color={'blue'} />
		</MenuItem>
	)
}
