import React, { Component } from 'react'
import { Button, FormGroup, Label, FormText, Row, Col, Input } from 'reactstrap'
import type { CardEffect, OutcomeType } from '../../../types'
import { Modal as AssetModal } from '../../../common/AssetManager'
import type { File } from '../../../common/AssetManager'
type Props = {
	card: CardEffect
	onChange: (option: CardEffect) => void
	delete: () => void
	cardList: ReadonlyArray<{
		cardId: string
		cardName: string
	}>
}
type State = {
	videoChooserOpen: boolean
}
export default class CardEffectCard extends Component<Props, State> {
	state: State = {
		videoChooserOpen: false,
	}

	render(): JSX.Element {
		const { card, onChange } = this.props
		return (
			<Row className="w-100">
				<Col sm={2}>
					<FormGroup>
						<Label>Action Type</Label>
						<Input
							sm={2}
							type="select"
							value={card.type}
							onChange={(e) => {
								const option = e.currentTarget.value
								let newOption: CardEffect

								if (option === 'CARD_SWITCH') {
									newOption = {
										cardId: this.props.cardList[0].cardId,
										type: option,
										text: card.text,
										outcome: card.outcome,
									}
								} else if (option === 'VIDEO') {
									newOption = {
										mediaUrl: '',
										type: option,
										text: card.text,
										outcome: card.outcome,
									}
								} else {
									newOption = {
										cardId: this.props.cardList[0].cardId,
										mediaUrl: '',
										type: 'VIDEO_CARD_SWITCH',
										text: card.text,
										outcome: card.outcome,
									}
								}

								onChange(newOption)
							}}>
							<option value={'CARD_SWITCH'}>Card Switch</option>
							<option value={'VIDEO'}>Video</option>
							<option value={'VIDEO_CARD_SWITCH'}>Video / Card Switch</option>
						</Input>
					</FormGroup>
				</Col>
				<Col sm={3}>
					<FormGroup>
						<Label>Button Text</Label>
						<Input
							type="text"
							value={card.text}
							onChange={(e) => {
								onChange({ ...this.props.card, text: e.currentTarget.value })
							}}
						/>
					</FormGroup>
				</Col>
				{card.type !== 'CARD_SWITCH' && (
					<Col sm={2}>
						<FormGroup>
							<Label>{!card.mediaUrl ? 'Video' : <Link url={card.mediaUrl} />}</Label>
							<FormText>{!card.mediaUrl ? 'a video' : <Video url={card.mediaUrl} />}</FormText>
							<div className="d-flex align-items-baseline mb-2">
								<Button
									className="mr-2"
									color="primary"
									size="sm"
									onClick={this.toggleVideoChooserOpen}>
									{card.mediaUrl ? 'Edit' : 'Choose video'}
								</Button>
								{card.mediaUrl && (
									<Button
										className="mr-2"
										color="danger"
										size="sm"
										onClick={() => {
											onChange({ ...card, mediaUrl: '' })
										}}>
										Delete
									</Button>
								)}
							</div>
						</FormGroup>
						<AssetModal
							collection={'videos'}
							isOpen={this.state.videoChooserOpen}
							onClose={this.toggleVideoChooserOpen}
							onFileClick={(file: File) => {
								onChange({ ...card, mediaUrl: file.url })
								this.toggleVideoChooserOpen()
							}}
						/>
					</Col>
				)}
				{card.type !== 'VIDEO' && (
					<Col sm={2}>
						<FormGroup>
							<Label>Card</Label>
							<Input
								sm={2}
								type="select"
								value={card.cardId}
								onChange={(e) => {
									onChange({ ...card, cardId: e.currentTarget.value })
								}}>
								{this.props.cardList.map((card) => {
									return (
										<option key={card.cardId} value={card.cardId}>
											{card.cardName}
										</option>
									)
								})}
							</Input>
						</FormGroup>
					</Col>
				)}
				<Col sm={2}>
					<FormGroup>
						<Label>Outcome</Label>
						<Input
							type="select"
							value={card.outcome}
							onChange={(e) => {
								onChange({ ...card, outcome: e.currentTarget.value as OutcomeType })
							}}>
							<option value={'CORRECT'}>Correct</option>
							<option value={'NEUTRAL'}>Neutral</option>
							<option value={'INCORRECT'}>Incorrect</option>
						</Input>
					</FormGroup>
				</Col>
				<Col sm={1}>
					<Button
						className="mr-2 mt-4"
						color="danger"
						size="sm"
						onClick={() => {
							this.props.delete()
						}}>
						Delete
					</Button>
				</Col>
			</Row>
		)
	}

	toggleVideoChooserOpen: () => void = () => {
		this.setState((state) => ({
			videoChooserOpen: !state.videoChooserOpen,
		}))
	}
}

function Link({ url }: { url: string }) {
	const results = url.split('/')
	return (
		<a href={url} target="_blank" rel="noopener noreferrer">
			{results[results.length - 1]}
		</a>
	)
}

function Video({ url }: { url: string }) {
	return <video width="120" height="90" controls src={url} />
}
