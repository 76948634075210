import React from 'react'
import styled from 'styled-components'
export default function Spinner(): JSX.Element {
	return (
		<SpinnerHolder>
			<Spin className="spinner-border" role="status">
				<span className="sr-only">Loading...</span>
			</Spin>
		</SpinnerHolder>
	)
}

const SpinnerHolder = styled.div`
	width: fit-content;
	margin: 30px auto 30px auto;
`
const Spin = styled.div`
	height: 4rem !important;
	width: 4rem !important;
`
