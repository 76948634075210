import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { OverlayStyled } from '../Screen'
import type { ActionPlayerAction } from '../sharedTypes'
import type { DeathState, ReachedPhasesAndCheckpoints } from '../ActionPlayer'
import type { EventResult } from '@mission.io/mission-toolkit/actions'

/**
 * The dialog that displays options for a teacher to revive the crew after they die in a mission
 * @returns { React$Node} a react component
 */

export default function ReviveDialog({
	playActions,
	deathState,
	dispatch,
	reachedPhasesAndCheckpoints,
}: {
	playActions: (arg0: EventResult<string>) => void
	deathState: DeathState
	dispatch: (arg0: ActionPlayerAction) => unknown
	reachedPhasesAndCheckpoints: Array<ReachedPhasesAndCheckpoints>
}): JSX.Element {
	const handleSkipAhead = () => {
		dispatch({
			type: 'CHEAT_DEATH',
		})

		if (deathState?.onCheatDeath) {
			playActions(deathState.onCheatDeath)
		}
	}

	const [msgError, setMsgError] = useState<string | null>(null)

	const handleRevive = () => {
		const lastCheckpoint = reachedPhasesAndCheckpoints[reachedPhasesAndCheckpoints.length - 1]

		if (lastCheckpoint) {
			dispatch({
				type: 'REVIVE',
				payload: {
					previousState: lastCheckpoint.previousState,
				},
			})
			playActions([lastCheckpoint.id])
		} else setMsgError('There is no previous Phase or Checkpoint selected')
	}

	return (
		<OverlayStyled>
			<div>
				<Button size="lg" onClick={handleRevive} css="margin: 36px; min-width: 130px;">
					Revive
				</Button>
				<div css="display: block !important; height: fit-content !important; text-align: center;">
					<h1>You are dead</h1>
					<h5>{msgError}</h5>
				</div>
				<Button size="lg" onClick={handleSkipAhead} css="margin: 36px; min-width: 130px;">
					Skip Ahead
				</Button>
			</div>
		</OverlayStyled>
	)
}
