import { runWithConfirmationMessage } from '../../../../helpers/uiFunctions'
import React, { Component } from 'react'
import styled from 'styled-components'
import { FormGroup, Label, Input, Button, Col, Row } from 'reactstrap'
import { Modal as AssetModal } from '../../../../common/AssetManager'
import MapObjectsContainer from '../MapObjects'
import type { NormalizedMap as Map, MapDataContentType } from '../../../../types'
import type { File } from '../../../../common/AssetManager'
import MarkDownInput from '../../../../common/MarkdownInput'
import MediaInformation from '../MapObjects/MapObjectForm/MediaInformation'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { Collapse } from '../../../../common/Collapse'

type Props = {
	map: Map
	onUpdate: (map: Map) => void
	deleteMap: () => unknown
	objectDelete?: (objectId: string) => void
	isAutomated?: boolean
	mapDataContainerClassName?: string
	mapObjectContainerClassName?: string
}

type State = {
	name: string
	description: string
	backgroundImage: string | null | undefined
	color: string | undefined
	backgroundImageChooserOpen: boolean
	data: MapDataContentType[]
	showData: boolean
}

export default class MapForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			name: props.map.name,
			description: props.map.description,
			color: props.map.color || '',
			backgroundImage: props.map.backgroundImage || null,
			backgroundImageChooserOpen: false,
			data: props.map.data,
			showData: false,
		}
	}

	render(): JSX.Element {
		return (
			<>
				<div className={this.props.mapDataContainerClassName}>
					<FormGroup>
						<Label for="name">Name</Label>
						<Row>
							<Col sm={9}>
								<Input
									name="name"
									type="text"
									value={this.state.name}
									onChange={(e) => {
										this.changeInput('name', e.target.value)
									}}
								/>
							</Col>
							<Col sm={3}>
								<Button
									onClick={() =>
										runWithConfirmationMessage(
											`Are you sure you want to delete the map "${this.state.name}"?`,
											this.props.deleteMap
										)
									}
									color="danger">
									Delete Map
								</Button>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Label for="description">
							Teacher Tips (Visible on Teacher Station and Mission Guide)
						</Label>
						<MarkDownInput
							value={this.state.description}
							onChange={(newValue: string) => this.changeInput('description', newValue)}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="color">Color</Label>
						<Input
							name="color"
							type="text"
							value={this.state.color}
							onChange={(e) => {
								this.changeInput('color', e.target.value)
							}}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="backgroundImage">
							Map Background Image (Adding a background map image will remove the color)
						</Label>
						<Row>
							<Button
								name="backgroundImage"
								className="ml-4"
								color="primary"
								size="sm"
								onClick={() =>
									this.setState((state) => ({
										backgroundImageChooserOpen: !state.backgroundImageChooserOpen,
									}))
								}>
								{this.state.backgroundImage ? 'Edit' : 'Choose a background image'}
							</Button>
							{this.state.backgroundImage && (
								<Button
									className="ml-3"
									color="danger"
									size="sm"
									onClick={() => {
										this.changeInput('backgroundImage', null)
									}}>
									Delete
								</Button>
							)}
						</Row>

						<AssetModal
							collection={'backgroundImages'}
							isOpen={this.state.backgroundImageChooserOpen}
							onClose={() =>
								this.setState((state) => ({
									backgroundImageChooserOpen: !state.backgroundImageChooserOpen,
								}))
							}
							onFileClick={(file: File) => {
								this.changeInput('backgroundImage', file.url)
								this.setState({ backgroundImageChooserOpen: false })
							}}
						/>
					</FormGroup>
				</div>
				<div className={this.props.mapObjectContainerClassName}>
					<FormGroup row>
						<h2>Map Data ({this.state.data.length})</h2>
						<Col sm={1}>
							<Toggler
								show={this.state.showData}
								onClick={() => this.setState({ showData: !this.state.showData })}
							/>
						</Col>
					</FormGroup>
					<Collapse isOpen={this.state.showData}>
						<MediaInformation
							mediaList={this.state.data}
							onChange={(newData) => {
								this.changeInput('data', newData)
							}}
							includePrescannedField={false}
						/>
					</Collapse>
					<Hr />
					<h2>Map Objects</h2>
					<MapObjectsContainer
						objects={this.props.map.objects}
						mapId={this.props.map._id}
						objectDelete={this.props.objectDelete}
						onUpdate={(objects) => {
							this.props.onUpdate({
								...this.props.map,
								...this.getMapState(this.state),
								objects: objects,
							})
						}}
						isAutomated={this.props.isAutomated}
					/>
				</div>
			</>
		)
	}

	/**
	 * When we update the props, we do not want to update the props with the entire contents of the state.
	 * Especially since we have a non map related state field.  This function gets the state properties that
	 * we need on props and returns them so we can update props without any other unnecessary fields.
	 */
	getMapState: (state: State) => {
		name: string
		color: string | undefined | null
		backgroundImage: string | undefined | null
		description: string
		data: MapDataContentType[]
	} = (state: State) => {
		return {
			name: state.name,
			color: state.color,
			backgroundImage: state.backgroundImage,
			description: state.description,
			data: state.data,
		}
	}

	changeInput: <T extends keyof State>(key: T, newValue: State[T]) => void = <
		T extends keyof State
	>(
		key: T,
		newValue: State[T]
	) => {
		// @ts-expect-error For some reason the key is not getting typed correctly
		const newState: Pick<State, T> = {
			[key]: newValue,
		}
		this.setState(newState, () => {
			this.props.onUpdate({
				...this.props.map,
				...this.getMapState(this.state),
			})
		})
	}
}

const Hr = styled.hr`
	border-top: 2px solid rgb(0, 0, 0, 0.2);
`

const TOGGLE_ICON_SIZE = 25
const Toggler = ({ show, onClick }: { show: boolean; onClick: () => void }) => {
	const props = {
		onClick,
		className: 'clickable',
		size: TOGGLE_ICON_SIZE,
	}
	return <ToggleStyle>{show ? <FaAngleUp {...props} /> : <FaAngleDown {...props} />}</ToggleStyle>
}

const ToggleStyle = styled.div`
	:hover {
		background-color: #ededed;
	}
	border-radius: 4px;
	height: ${TOGGLE_ICON_SIZE}px;
	width: ${TOGGLE_ICON_SIZE}px;
`
