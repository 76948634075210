import React, { useContext, useEffect } from 'react'
import BoundingBox from './BoundingBox'
import type { Box, ParentData } from '../types'
import EditorContext from '../context'
import { isVideo, isGif } from '../utilities'
import styled from 'styled-components'
import { usePositioningData } from '../hooks'
type Props = Box &
	ParentData & {
		_id: string
		url: string
		isSelectable: number
	}
export default function Media(props: Props): JSX.Element | null {
	const { width, parentWidth, height, parentHeight, url, _id } = props
	const { onSelect, selected, showVideoWarning, showGifWarning } = useContext(EditorContext)
	const isUrlVideo = isVideo(url)
	const isGifUrl = isGif(url)
	const positioningData = usePositioningData(props)
	useEffect(() => {
		if (isUrlVideo) {
			showVideoWarning()
		} else if (isGifUrl) {
			showGifWarning()
		}
	}, [url, isUrlVideo, isGifUrl, showVideoWarning, showGifWarning])

	if (width * parentWidth === 0 || height * parentHeight === 0) {
		return null
	}

	return (
		<>
			{isVideo(url) ? (
				<foreignObject {...positioningData} onMouseDown={() => onSelect(_id)}>
					<StyledVideo src={url} autoPlay loop controls={false} />
				</foreignObject>
			) : (
				<image
					{...positioningData}
					href={url}
					onMouseDown={() => onSelect(_id)}
					preserveAspectRatio="none"
				/>
			)}
			<BoundingBox
				{...positioningData}
				visible={_id === selected}
				scaling={_id === selected}
				_id={_id}
				selectHandle={onSelect}
			/>
		</>
	)
}
const StyledVideo = styled.video`
	width: 100%;
	height: 100%;
	object-fit: fill;
	pointer-events: none;
`
