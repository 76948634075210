import React, { useMemo } from 'react'
import {
	CondensedActionGraph,
	CondensedActionGraphNode,
	NotableEvent,
} from '@mission.io/mission-toolkit'
import {
	DEFAULT_GRAPH_CONFIG,
	FAILURE_COLOR,
	GraphWrapper,
	SizedGraph,
	StyledNode,
	SUCCESS_COLOR,
} from './graphHelpers'
import { useSetSelectedActions } from './Context'

type NotableEventNode = { id: string; notableEventData: NotableEvent<string> }

const START_MISSION_COLOR = 'lightgreen'
const DEFAULT_NODE_COLOR = 'lightblue'

/**
 * NotableEventsGraph - render the notable events in a graph described by the condensedActinGraph
 *
 * @param {CondensedActionGraph<string>} condensedActionGraph - the graph with the notable events to render
 *
 * @return {JSX.Element}
 */
export function NotableEventsGraph({
	condensedActionGraph,
}: {
	condensedActionGraph: CondensedActionGraph<string>
}): JSX.Element {
	const setSelectedActions = useSetSelectedActions()

	const { graphConfig, graph } = useMemo(() => {
		const actionViewGenerator = (notableEvent: NotableEventNode): JSX.Element => {
			return (
				<StyledNode
					style={{
						backgroundColor:
							notableEvent.notableEventData.type === 'START_MISSION'
								? START_MISSION_COLOR
								: notableEvent.notableEventData.type === 'DEATH'
								? FAILURE_COLOR
								: notableEvent.notableEventData.type === 'END_MISSION'
								? SUCCESS_COLOR
								: DEFAULT_NODE_COLOR,
					}}>
					{notableEvent.notableEventData.type}
				</StyledNode>
			)
		}

		const graphConfig = {
			...DEFAULT_GRAPH_CONFIG,
			node: {
				...DEFAULT_GRAPH_CONFIG.node,
				viewGenerator: actionViewGenerator,
			},
		}

		const condensedNodeLookup: Record<string, CondensedActionGraphNode<string>> = {}
		condensedActionGraph.nodes.forEach((node) => {
			condensedNodeLookup[String(node.id)] = node
		})

		const parentCondensedNodeToChildCondensedNode: Record<string, string[]> = {}
		condensedActionGraph.links.forEach(({ source, target }) => {
			parentCondensedNodeToChildCondensedNode[source] ??= []
			parentCondensedNodeToChildCondensedNode[source].push(target)
		})

		const nodes: Array<NotableEventNode> = []
		const links: Array<{ source: string; target: string; color?: string }> = []
		condensedActionGraph.nodes.forEach((node) => {
			if (!node.notableEvents.length) {
				return
			}

			const notableEvents = node.notableEvents.map((event, index) => ({
				id: generateNotableEventId(node.id, index),
				notableEventData: event,
			}))
			nodes.push(...notableEvents)

			for (let i = 0; i < notableEvents.length - 1; i++) {
				// link nodes internally
				links.push({
					source: notableEvents[i].id,
					target: notableEvents[i + 1].id,
				})
			}

			const sourceNodeId = notableEvents[notableEvents.length - 1].id

			// if the children of this condensed node has no notable events, then we have to traverse the graph until we find notable events to link to
			const handledCondensedNodes = new Set(parentCondensedNodeToChildCondensedNode[node.id])
			const condensedNodesToConnectTo = Array.from(handledCondensedNodes)

			let currentCondensedNodeId
			while ((currentCondensedNodeId = condensedNodesToConnectTo.pop())) {
				const nodeToConnectTo = condensedNodeLookup[currentCondensedNodeId]
				if (!nodeToConnectTo) {
					continue
				}

				if (nodeToConnectTo.notableEvents.length) {
					// connect last notable event to the first notable event in the children
					links.push({
						source: sourceNodeId,
						target: generateNotableEventId(currentCondensedNodeId, 0),
						color: 'blue',
					})
					continue
				}

				// continue to the children of the children
				const childCondensedNodes = parentCondensedNodeToChildCondensedNode[nodeToConnectTo.id]
				if (!childCondensedNodes) {
					continue
				}

				for (const child of childCondensedNodes) {
					if (handledCondensedNodes.has(child)) {
						continue
					}
					handledCondensedNodes.add(child)
					condensedNodesToConnectTo.push(child)
				}
			}
		})

		return { graphConfig, graph: { nodes, links } }
	}, [condensedActionGraph])

	return (
		<>
			<GraphWrapper>
				<SizedGraph
					id="notable-events-graph"
					data={graph}
					config={graphConfig}
					onClickNode={(_, node: NotableEventNode) => {
						setSelectedActions(new Set([node.notableEventData.actionId]))
					}}
				/>
			</GraphWrapper>
		</>
	)
}

function generateNotableEventId(inCondensedNode: string, notableEventIndex: number): string {
	return `${inCondensedNode}-${notableEventIndex}`
}
