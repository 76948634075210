import React from 'react'
import { Modal as BootstrapModal, ModalHeader, ModalBody } from 'reactstrap'
import { startCase } from 'lodash'
import AssetManager from './AssetManager'
import type { Collection } from './AssetManager'
import type { File } from './FileDetails'
import styles from './Modal.module.css'
import type { FileRestriction } from '../../features/automatedSimulations/helpers/algorithms'

type Props = {
	collection: Collection
	isOpen: boolean
	onClose: () => void
	onFileClick?: (file: File) => void
	// accepted file extensions should be lowercase in order to be allowed
	restrictions?: FileRestriction
}
export default function Modal(props: Props): JSX.Element {
	const { collection, isOpen, onClose, ...rest } = props
	const title = `Manage ${startCase(collection)}`
	return (
		<BootstrapModal className={styles.modal} isOpen={isOpen}>
			<ModalHeader toggle={onClose}>{title}</ModalHeader>
			<ModalBody className={styles.modalBody}>
				<AssetManager {...rest} collection={collection} />
			</ModalBody>
		</BootstrapModal>
	)
}
