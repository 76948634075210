import type { WorldComponent, CollisionType, ParentData, Box } from './types'
import { createTemporaryId } from '../../helpers/functions'
import {
	PRIMITIVE_TYPES,
	DEFAULT_COLLISION,
	DEFAULT_DRAWING_DATA,
	EditorType,
	COLLISION_TYPES,
	CAMERA_WIDTH,
	CAMERA_HEIGHT,
	Handles,
} from './constants'

/**
 * project (linear algebra) - project an the vector [x, y] onto a vector rotated theta from the standard cartesian plane
 *
 * @param  {number} x - the x coordinate of the vector to project
 * @param  {number} y - the y coordinate of the vector to project
 * @param  {number} theta - the theta of the vector project onto
 * @returns {{x: number, y: number}} - the projected vector
 */
function project(
	x: number,
	y: number,
	theta: number
): {
	x: number
	y: number
} {
	const magnitude = x * Math.cos(theta) + y * Math.sin(theta)
	return {
		x: magnitude * Math.cos(theta),
		y: magnitude * Math.sin(theta),
	}
}

/**
 *
 * Changes the component's x, y, width, and height to reflect a handle being dragged
 *
 * @param {Number} deltaX the displacement of the mouse in the x direction
 * @param {Number} deltaY the displacement of the mouse in the y direction
 * @param {WorldComponent} startingObject the object which handles are being moved
 * @param {String[]} handles the handle direction(s) which are being dragged
 *
 * @return {WorldComponent} a component with the x, y, width, and height changed to reflect the handle drag
 *
 */
export function handleHandleMovement<U extends WorldComponent>(
	deltaX: number,
	deltaY: number,
	startingObject: U,
	handles: Handles[]
): U {
	const rotationDegrees = startingObject.rotation || 0
	const rotation = (rotationDegrees * Math.PI) / 180
	const normal = rotation + Math.PI / 2
	const inverse = rotationDegrees > 90 && rotationDegrees <= 270 ? -1 : 1
	const newObject = { ...startingObject }
	let { x, y, width, height } = startingObject
	const { x: xProjX, y: yProjX } = project(deltaX, deltaY, rotation)
	const xMag = Math.sqrt(xProjX ** 2 + yProjX ** 2)
	const xDir = Math.sign(xProjX)
	const xSlackX = (xMag / 2) * (1 - Math.cos(rotation))
	const xSlackY = (xMag / 2) * Math.sin(rotation)

	if (handles.includes(Handles.LEFT)) {
		width -= xMag * xDir * inverse
		x += (xMag - xSlackX) * xDir * inverse
		y += xSlackY * xDir * inverse
	} else if (handles.includes(Handles.RIGHT)) {
		width += xMag * xDir * inverse
		x -= xSlackX * xDir * inverse
		y += xSlackY * xDir * inverse
	}

	const { x: xProjY, y: yProjY } = project(deltaX, deltaY, normal)
	const yMag = Math.sqrt(xProjY ** 2 + yProjY ** 2)
	const yDir = Math.sign(yProjY)
	const ySlackX = (yMag / 2) * Math.cos(normal)
	const ySlackY = (yMag / 2) * (1 - Math.sin(normal))

	if (handles.includes(Handles.TOP)) {
		height -= yMag * yDir * inverse
		x += ySlackX * yDir * inverse
		y += (yMag - ySlackY) * yDir * inverse
	} else if (handles.includes(Handles.BOTTOM)) {
		height += yMag * yDir * inverse
		x += ySlackX * yDir * inverse
		y -= ySlackY * yDir * inverse
	}

	if (width < 0) {
		x += width
		width = -width
	}

	newObject.x = x
	newObject.width = width

	if (height < 0) {
		y += height
		height = -height
	}

	newObject.y = y
	newObject.height = height
	return newObject
}

/**
 * create a default camera object
 *
 * @return {WorldComponent}  The camera object
 */
export function createDefaultCamera(): WorldComponent {
	return {
		x: 0,
		y: 0,
		width: CAMERA_WIDTH,
		height: CAMERA_HEIGHT,
		type: PRIMITIVE_TYPES.CAMERA,
		name: 'Camera',
		_id: createTemporaryId(),
		collision: { ...DEFAULT_COLLISION },
		...DEFAULT_DRAWING_DATA[EditorType.WORLD][PRIMITIVE_TYPES.CAMERA],
	}
}

/**
 * check if the collision is a collision type is collidable
 *
 * @param  {boolean | CollisionType} collision  the collisionType to check
 * @return {boolean}                 if the collision type is collidable
 */
export function isCollision(collision: boolean | CollisionType): boolean {
	if (typeof collision === 'object') {
		return collision.type !== COLLISION_TYPES.NONE
	}

	return collision
}

/**
 * get the bounding box of a worldObject
 *
 * @param {number} width the width of the object in the parents coordinate system
 * @param {number} parentWidth the width of the parent in the world's coordinate system
 * @param {number} height the height of the object in the parent's coordinate system
 * @param {number} parentHeight the height of the parent in the world's coordinate system
 * @param {number} realParentX the x position of the parent in the world's coordinate system
 * @param {number} realParentY the y position of the parent in the world's coordinate system
 *
 * @return {{width: number, height: number, x: number, y: number, rotation: number}} the bounding box
 */
export function getBoundingBox({
	width,
	parentWidth,
	height,
	parentHeight,
	x,
	y,
	realParentX,
	realParentY,
	rotation,
}: ParentData & Box): Box {
	return {
		width: width * parentWidth,
		height: height * parentHeight,
		x: realParentX + x * parentWidth,
		y: realParentY + y * parentHeight,
		rotation: rotation || 0,
	}
}

/**
 * check to see if a url points to a video
 *
 * @param  {string} url the media's url
 * @return {boolean}    true if the url points to a video, false otherwise
 */
export function isVideo(url: string): boolean {
	return (
		url.endsWith('.mp4') || url.endsWith('.ogg') || url.endsWith('.webm') || url.endsWith('.m4v')
	)
}

/**
 * check to see if a url points to a gif
 *
 * @param  {string} url the media's url
 * @return {boolean}    true if the url points to a gif, false otherwise
 */
export function isGif(url: string): boolean {
	return url.endsWith('.gif')
}

/**
 * resolveBoundingBox - set the x and y so that the width and height are always positive
 *
 * @param  {Number} x      the x position of the element
 * @param  {Number} y      the y position of the element
 * @param  {Number} width  the width of the element
 * @param  {Number} height the height of the element
 * @return {Box}           a box type with width and height being positive
 */
export function resolveBoundingBox(
	x: number,
	y: number,
	width: number,
	height: number
): {
	x: number
	y: number
	width: number
	height: number
} {
	if (width < 0) {
		x += width
		width = -width
	}

	if (height < 0) {
		y += height
		height = -height
	}

	return {
		x,
		y,
		width,
		height,
	}
}

/**
 * getNewMapUrl - get the url to create a new map
 *
 * @returns {string} - the url to create a new map
 */
export function getNewMapUrl(): string {
	return '/editor/world'
}

/**
 * getNewEntityUrl - get the url to create a new entity
 *
 * @returns {string} - the url to create a new entity
 */
export function getNewEntityUrl(): string {
	return '/editor/entity'
}

/**
 * getEditEntityUrl - get the url used to edit the entity by the id `entityId`
 *
 * @param  {string} entityId the id of the entity to edit
 * @returns {string} the url used to edit the entity
 */
export function getEditEntityUrl(entityId: string): string {
	return `${getNewEntityUrl()}/${entityId}`
}
