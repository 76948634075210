import { CATEGORY_TO_COLOR } from '../../helpers/styles'
import {
	ACTION_TYPE_TO_CATEGORY,
	ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT,
	TIME_LENGTH_FOR_REFERENCING_ACTION,
	type ScreenActionId,
	getTitle,
} from '../../actionDefinitions'
import type { ScreenAction, Action as SimAction } from '@mission.io/mission-toolkit/actions'
import { SCREEN_REFERENCE } from '../../helpers/constants'
import type { Action, Screen, DurationMap } from '../types'
import { DraggableType } from '../../DragDrop/types'

/**
 * Returns the color to display for the given action on the editor canvas or the simulation
 * @param {Action | Screen | SimAction} value
 */
function getColor(
	value: Action | Screen | SimAction<string> | null | void,
	isReference = false
): string {
	let color
	let actionType

	if (isReference) {
		actionType = SCREEN_REFERENCE
	} else if (!value) {
		color = '#000'
	} else if ('meta' in value) {
		// This is an action as defined on the action canvas with its simulation action type defined in the meta data
		actionType = value.meta.type
	} else if (value.type) {
		// This is an actual action as defined in a simulation
		actionType = value.type
	}

	if (actionType) {
		const category = ACTION_TYPE_TO_CATEGORY[actionType as keyof typeof ACTION_TYPE_TO_CATEGORY]

		if (category) {
			color = CATEGORY_TO_COLOR[category]
		}
	}

	if (color) return color
	return '#000'
}

/**
 * Gets the width of action on the editor, taking into account the seconds of time an action takes, and the apps
 * theme value for pixels per second
 * @param {Action | Screen} action
 * @param {ThemeType} theme
 */
function getWidth(action: Action | Screen, theme: { pixelsPerMs: number }): string {
	const actionMilliseconds = getActionTimeLength(action)
	return theme.pixelsPerMs * actionMilliseconds + 'px'
}

/**
 * Returns the length of the action is seconds
 * @param {Action} value
 */
function getActionTimeLength(value: Action | Screen, isReference = false): number {
	if (isReference) return TIME_LENGTH_FOR_REFERENCING_ACTION
	if (value.meta && value.meta.duration) return value.meta.duration
	else return ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
}

/**
 * Returns the length of a screen action
 * @param {ScreenAction} screen
 * @param {DurationMap} durations
 */
function getScreenTimeLength(screen: ScreenAction<string>, durations: DurationMap): number {
	if (screen.type === 'VIDEO_SCREEN' && !screen.looping)
		return (
			(durations.url[screen.url] || ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT) +
			screen.finalFrameDuration
		)

	if (screen.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN') {
		return Math.max(screen.editingTime, 30_000)
	}

	return screen.timeLimit || ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
}

/**
 * Given a ScreenAction type, its id, and the duration map, returns a ScreenType object for the DnD canvas
 * @param {ScreenAction} screen
 * @param {ScreenActionId} id
 * @param {DurationMap} durations
 */
export function fromScreenToScreenType(
	screen: ScreenAction<string>,
	id: ScreenActionId,
	durations: DurationMap
): Screen {
	const url: string | null = ('url' in screen && String(screen.url)) || null
	const screenType: Screen = {
		id,
		type: DraggableType.SCREEN_TYPE,
		title: getTitle(screen),
		meta: {
			duration: getScreenTimeLength(screen, durations),
			url: url,
			type: screen.type,
		},
	}

	if (screen.newPhase) {
		screenType.meta.newPhase = screen.newPhase
	}

	if (screen.checkpoint) {
		screenType.meta.checkpoint = screen.checkpoint
	}

	return screenType
}

/**
 * Figures out the start time in ms of that Action relative to the screen the action is apart of
 * @param {Action} value
 */
function getActionStartTime(value: Action): number {
	return value.position.xTimeInScreen
}

/**
 * Determines the screen which follows the given screen
 * @param {Screen[]} allScreens
 * @param {number} index
 */
function getNextScreenId(allScreens: Screen[], index: number): string | null | undefined {
	const nextScreen = allScreens[index + 1]
	return nextScreen ? nextScreen.id : null
}

export { getColor, getWidth, getActionTimeLength, getActionStartTime, getNextScreenId }
