import React, { ReactNode } from 'react'
import { Modal as BootstrapModal, ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'

export default function Modal({
	isOpen,
	onClose,
	children,
	title,
}: {
	isOpen: boolean
	onClose: () => void
	children: ReactNode
	title: string
}): JSX.Element {
	return (
		<StyledModal isOpen={isOpen}>
			<ModalHeader toggle={onClose}>{title}</ModalHeader>
			<ModalBody>{children}</ModalBody>
		</StyledModal>
	)
}
const StyledModal = styled(BootstrapModal)`
	min-width: 70%;
`
