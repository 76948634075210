/**
 * An error class that other custom errors should extend
 */
class BaseError extends Error {
	constructor(message: string) {
		super(message)
		// Make sure the name is the same as whichever error was created
		this.name = this.constructor.name
		// Omit the call to the error constructor from the stack trace
		// to keep the stack trace a little more clean
		Error.captureStackTrace(this, this.constructor)
	}
}
/**
 * An error class that produces warnings: meaning a user attempted to use a reducer to update the state and the state did not allow
 * changes based on User error.
 */

export class ReducerWarningError extends BaseError {
	constructor(message: string) {
		super(`Warning: ${message}`)
	}
}

/** */
export class ReducerFailedError extends BaseError {
	constructor(message: string) {
		super(`Error in code: ${message}, contact dev team`)
	}
}
