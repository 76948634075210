import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal as AssetModal } from '../../../common/AssetManager'
import styled from 'styled-components'
import {
	PROPERTIES_EDITOR,
	COLLISION_TYPES,
	CONTROL_TYPES,
	PRIMITIVE_TYPES,
	HEALTH_LOCATIONS,
	COLLISION_DAMAGE,
	MEDIA_FILE_TYPES,
} from '../constants'
import { isVideo } from '../utilities'
import type { CollisionType, ControlType, WorldComponent, HealthType } from '../types'
import { refreshStore as refreshEntityStore } from '../../../reducers/entities'
import type { File } from '../../../common/AssetManager'
import { useEntityMeta } from '../hooks'
import { FaPlus, FaEdit, FaSync } from 'react-icons/fa'
import { getNewEntityUrl, getEditEntityUrl } from '../utilities'
import { Basic as Dial } from 'react-dial-knob'
type Types = keyof typeof PROPERTIES_EDITOR
export type Data = Readonly<{
	[key: string]: (number | boolean | string | Data) | null | undefined
}>
type Props = {
	forId: string | null | undefined
	data: Data | null | undefined
	typing: Record<string, Types>
	changeHandlers: Record<string, (value: unknown, data: Data) => Data>
	onChange: (_id: string | null | undefined, data: Data) => void
	components: WorldComponent[]
	children: JSX.Element | null
}
type OnChange<T> = (key: string, value: T) => void
// route property types to the correct property editor
const TYPE_PROPERTY = {
	number: NumericProperty,
	[PROPERTIES_EDITOR.PROPORTION]: ProportionProperty,
	string: StringProperty,
	[PROPERTIES_EDITOR.COLOR]: ColorProperty,
	boolean: BooleanProperty,
	[PROPERTIES_EDITOR.COLLISION]: CollisionProperty,
	[PROPERTIES_EDITOR.CONTROL]: ControlledProperty,
	[PROPERTIES_EDITOR.ENTITY]: EntityProperty,
	[PROPERTIES_EDITOR.FOLLOW]: FollowProperty,
	[PROPERTIES_EDITOR.HEALTH]: HealthProperty,
	[PROPERTIES_EDITOR.MEDIA]: MediaProperty,
	[PROPERTIES_EDITOR.ROTATION]: RotationProperty,
	[PROPERTIES_EDITOR.INITIAL_VELOCITY]: InitialVelocityProperty,
	[PROPERTIES_EDITOR.IGNORE]: null,
}
/**
 * display property editors for every entry in data
 *
 * @param {?string} forId the _id of the data being edited
 * @param {{[key: string]: any}} data the properties to be edited
 * @param {{[key: string]: PropertyType}} typing tell which property editor to use for a key
 * @param {(_id: ?string, data: {[key: string]: any}) => void} onChange handle a change in one of the properties (data is the passed in data object but with the updated property)
 * @param {{[key: string]: (key: string, data: any) => void}} changeHandlers run customMiddleware for handling a change in property for a certain key
 * @param {components: WorldComponent[]} components a list of all the components the data being edited has access to
 */

export default function Properties({
	forId,
	data,
	typing,
	onChange,
	changeHandlers,
	components,
	children,
}: Props): JSX.Element | null {
	const properties: JSX.Element[] = []

	if (!data) {
		return null
	}

	Object.keys(data).forEach((key: string) => {
		const Property = TYPE_PROPERTY[typing[key] || typeof data[key]]

		if (!Property) {
			return
		}

		properties.push(
			<Property
				// @ts-expect-error typing is not done for this code
				objectKey={key}
				// @ts-expect-error typing is not done for this code
				key={key}
				// @ts-expect-error typing is not done for this code
				value={data[key]}
				// @ts-expect-error typing is not done for this code
				onChange={(key: string, value) => {
					if (changeHandlers[key]) {
						onChange(forId, changeHandlers[key](value, data))
						return
					}

					onChange(forId, { ...data, [key]: value })
				}}
				// @ts-expect-error typing is not done for this code
				components={components}
			/>
		)
	})
	return (
		<PropertiesStyled>
			{properties}
			{children}
		</PropertiesStyled>
	)
}
/**
 * display a property editor for a numeric property
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {number} value the current value of the property
 * @param {(key: string, data: number) => void} typing handle a change in the value
 */

function NumericProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: number
	onChange: OnChange<number>
}) {
	return (
		<PropertyRow>
			<label>{objectKey}: </label>
			<input
				type="number"
				value={value}
				onChange={(event) => {
					const value = event.target.value

					if (isNaN(Number(value))) {
						return
					}

					onChange(objectKey, Number(value))
				}}
			/>
		</PropertyRow>
	)
}

/**
 * display a property editor for a proportion property (ie: 0 <= value <= 1)
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {boolean} value the current value of the property
 * @param {(key: string, data: boolean) => void} typing handle a change in the value
 */
function ProportionProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: number
	onChange: OnChange<number>
}) {
	return (
		<PropertyRow>
			<label>{objectKey}: </label>
			<input
				type="number"
				min="0"
				max="1"
				value={value}
				onChange={(event) => {
					const value = event.target.value

					if (isNaN(Number(value))) {
						return
					}

					onChange(objectKey, Math.min(Math.max(Number(value), 0), 1))
				}}
			/>
		</PropertyRow>
	)
}

/**
 * display a property editor for a string property
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {string} value the current value of the property
 * @param {(key: string, data: string) => void} typing handle a change in the value
 */
function StringProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: number
	onChange: OnChange<string>
}) {
	return (
		<PropertyRow>
			<label>{objectKey}: </label>
			<input
				value={value}
				onChange={(event) => {
					onChange(objectKey, event.target.value)
				}}
			/>
		</PropertyRow>
	)
}

/**
 * display a property editor for a color property (ie. use the browser's color picker)
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {string} value the current value of the property
 * @param {(key: string, data: string) => void} typing handle a change in the value
 */
function ColorProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: number
	onChange: OnChange<string>
}) {
	return (
		<PropertyRow>
			<label>{objectKey}: </label>
			<input
				value={value}
				type="color"
				onChange={(event) => {
					onChange(objectKey, event.target.value)
				}}
			/>
		</PropertyRow>
	)
}

/**
 * display a property editor for a boolean property
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {boolean} value the current value of the property
 * @param {(key: string, data: boolean) => void} typing handle a change in the value
 */
function BooleanProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: boolean
	onChange: OnChange<boolean>
}) {
	return (
		<PropertyRow>
			<label>{objectKey}: </label>
			<input
				checked={value}
				type="checkbox"
				onChange={(event) => {
					onChange(objectKey, Boolean(event.target.checked))
				}}
			/>
		</PropertyRow>
	)
}

type Vec3 = {
	x: number
	y: number
	r: number
}

/**
 * display a property editor for initial velocity
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {Vec3} value the current value of the property
 * @param {(key: string, data: Vec3) => void} onChange handle a change in the value
 */
function InitialVelocityProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: Vec3
	onChange: OnChange<Vec3>
}) {
	return (
		<>
			<hr />
			<PropertyRow>
				<label>{objectKey}: </label>
				<PropertyColumn>
					{(Object.keys(value) as (keyof Vec3)[]).map((key) => {
						return (
							<NumericProperty
								key={key}
								objectKey={key}
								value={value[key]}
								onChange={(key: string, newValue: number) => {
									onChange(objectKey, { ...value, [key]: newValue })
								}}
							/>
						)
					})}
				</PropertyColumn>
			</PropertyRow>
		</>
	)
}

/**
 * display a property editor for collisionType, also handles if the collision type needs
 * to have a damage input as well
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {CollisionType} value the current value of the property
 * @param {(key: string, data: CollisionType) => void} typing handle a change in the value
 */
function CollisionProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: CollisionType
	onChange: OnChange<CollisionType>
}) {
	return (
		<div>
			<hr />
			<DropdownProperty
				objectKey={'Collision Type'}
				value={value.type}
				onChange={(_, newValue) => onChange(objectKey, { ...value, type: newValue })}
				possibleValues={(Object.keys(COLLISION_TYPES) as Array<keyof typeof COLLISION_TYPES>).map(
					(collisionType) => ({
						_id: collisionType,
						value: collisionType,
					})
				)}
			/>

			{COLLISION_DAMAGE[value.type] && (
				<NumericProperty
					objectKey={COLLISION_DAMAGE[value.type]}
					value={value.damageScale}
					onChange={(key: string, damageScale: number) => {
						onChange(objectKey, { ...value, damageScale })
					}}
				/>
			)}
		</div>
	)
}

/**
 * display a property editor for the ControlType
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {ControlType} value the current value of the property
 * @param {(key: string, data: ControlType) => void} typing handle a change in the value
 */
function ControlledProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: ControlType
	onChange: OnChange<ControlType>
}) {
	return (
		<div>
			<hr />
			<DropdownProperty
				objectKey={'Controlled by'}
				value={value.by}
				onChange={(_, newValue) => onChange(objectKey, { ...value, by: newValue })}
				possibleValues={(Object.keys(CONTROL_TYPES) as Array<keyof typeof CONTROL_TYPES>).map(
					(controlType) => ({
						_id: controlType,
						value: controlType,
					})
				)}
			/>
			{value.by !== CONTROL_TYPES.NONE ? (
				<NumericProperty
					objectKey={'scale'}
					value={value.scale}
					onChange={(key: string, scale: number) => {
						onChange(objectKey, { ...value, scale })
					}}
				/>
			) : null}
		</div>
	)
}

/**
 * display a property editor for health
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {HealthType} value the current value of the property
 * @param {(key: string, data: HealthType) => void} typing handle a change in the value
 */
function HealthProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: HealthType
	onChange: OnChange<HealthType>
}) {
	return (
		<div>
			<hr />
			<BooleanProperty
				objectKey={'Use Health'}
				value={value.useHealth}
				onChange={(_, newValue) => onChange(objectKey, { ...value, useHealth: newValue })}
			/>
			{value.useHealth ? (
				<BooleanProperty
					objectKey={'Use Ship Health'}
					value={value.isShipHealth}
					onChange={(_, newValue) => onChange(objectKey, { ...value, isShipHealth: newValue })}
				/>
			) : null}
			{value.useHealth ? (
				<>
					<NumericProperty
						objectKey={'Starting Health'}
						value={value.startingAmount}
						onChange={(_, newValue) => onChange(objectKey, { ...value, startingAmount: newValue })}
					/>
					<DropdownProperty
						objectKey={'Display Health Location'}
						value={value.show}
						onChange={(_, newValue) => onChange(objectKey, { ...value, show: newValue })}
						possibleValues={(
							Object.keys(HEALTH_LOCATIONS) as Array<keyof typeof HEALTH_LOCATIONS>
						).map((location) => ({
							_id: location,
							value: location,
						}))}
					/>
				</>
			) : null}
		</div>
	)
}

/**
 * display a property editor for entities (_id)
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {String} value the current value of the property
 * @param {(key: string, data: String) => void} typing handle a change in the value
 */
function EntityProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: string
	onChange: OnChange<string>
}) {
	const { meta: availableEntities } = useEntityMeta()
	const dispatch = useDispatch()
	return (
		<>
			<PropertyRow>
				<label>{objectKey}: </label>
				{!availableEntities ? (
					<span> Loading</span>
				) : (
					<select value={value} onChange={(e) => onChange(objectKey, e.target.value)}>
						{availableEntities.map((entity) => (
							<option value={entity._id} key={entity._id}>
								{entity.name}
							</option>
						))}
						<option value={''} key={'NONE'}>
							None
						</option>
					</select>
				)}
			</PropertyRow>
			<PropertyRow>
				<EntityButtons>
					<FaPlus
						title="Create Entity"
						onClick={() => {
							window.open(getNewEntityUrl(), '_blank')
						}}
					/>
					<FaSync
						title="Refresh Entities"
						onClick={() => {
							dispatch(refreshEntityStore())
						}}
					/>
					{value ? (
						<FaEdit
							title="Edit Entity"
							onClick={() => {
								window.open(getEditEntityUrl(value), '_blank')
							}}
						/>
					) : null}
				</EntityButtons>
			</PropertyRow>
		</>
	)
}

/**
 * display a property editor for follow property
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {String} value the current value of the property
 * @param {(key: string, data: String) => void} typing handle a change in the value
 */
function FollowProperty(props: {
	objectKey: string
	value: string
	onChange: OnChange<string>
	components: WorldComponent[]
}) {
	return (
		<DropdownProperty
			{...props}
			possibleValues={[
				...props.components
					.filter((component) => component.type !== PRIMITIVE_TYPES.CAMERA)
					.map((component) => ({
						_id: component._id,
						value: component.name || 'Anonymous',
					})),
				{
					_id: '',
					value: 'NONE',
				},
			]}
		/>
	)
}

/**
 * display a property editor for a key which has options to be selected from
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {String} value the current value of the property
 * @param {(key: string, data: String) => void} typing handle a change in the value
 * @param {{_id: string, value: string}} possibleValues the values which value can take on
 */
function DropdownProperty<T extends string = string>({
	objectKey,
	value,
	onChange,
	possibleValues,
}: {
	objectKey: string
	value: T
	onChange: OnChange<T>
	possibleValues: {
		_id: string
		value: T
	}[]
}) {
	return (
		<PropertyRow>
			<label>{objectKey}: </label>
			<select value={value} onChange={(e) => onChange(objectKey, e.target.value as T)}>
				{possibleValues.map((value) => (
					<option value={value._id} key={value._id}>
						{value.value || 'Anonymous'}
					</option>
				))}
			</select>
		</PropertyRow>
	)
}

/**
 * display a property editor for a media property
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {String} value the current value of the property
 * @param {(key: string, data: String) => void} typing handle a change in the value
 */
function MediaProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: string
	onChange: OnChange<string>
}) {
	const [isModalOpen, setIsModelOpen] = useState(false)
	return (
		<PropertyRow>
			<AssetModal
				collection="navigationMaps"
				isOpen={isModalOpen}
				onClose={() => setIsModelOpen(false)}
				restrictions={{
					allowedFileExtensions: MEDIA_FILE_TYPES,
					mime: 'image/*, video/*',
				}}
				onFileClick={(file: File) => {
					onChange(objectKey, file.url)
					setIsModelOpen(false)
				}}
			/>
			<label>media: </label>
			{isVideo(value) ? (
				<video
					src={value}
					loop
					autoPlay
					onClick={() => {
						setIsModelOpen(true)
					}}
				/>
			) : (
				<img src={value} alt="Preview" onClick={() => setIsModelOpen(true)} />
			)}
		</PropertyRow>
	)
}

/**
 * display a property editor for a rotation property
 *
 * @param {string} objectKey the key for the objects property which is being edited
 * @param {String} value the current value of the property (radians)
 * @param {(key: string, data: String) => void} typing handle a change in the value
 */
function RotationProperty({
	objectKey,
	value,
	onChange,
}: {
	objectKey: string
	value: string
	onChange: OnChange<number>
}) {
	return (
		<PropertyRow>
			<label>{objectKey}: </label>
			<PropertyRow>
				<Dial
					diameter={25}
					min={0}
					max={360}
					step={0.5}
					value={Number(value)}
					theme={{
						defaultColor: '#333',
						activeColor: '#f33',
					}}
					onValueChange={(value: number) => onChange(objectKey, value)}
				/>
				<input
					type="number"
					value={value}
					onChange={(event) => {
						const value = event.target.value

						if (isNaN(Number(value))) {
							return
						}

						onChange(objectKey, Number(value))
					}}
				/>
			</PropertyRow>
		</PropertyRow>
	)
}

const PropertyRow = styled.div`
	display: flex;
	margin: 4px;
	justify-content: space-between;

	> * {
		flex: 1 1 20px;
		min-width: 20px;
		max-width: 100%;
	}

	video,
	img {
		padding: 2px;
		border: 1px solid black;
		border-radius: 2px;
		width: 100%;
		height: auto;

		&:hover {
			cursor: pointer;
			background-color: lightblue;
		}
	}
`
const PropertyColumn = styled(PropertyRow)`
	flex-direction: column;
`
const PropertiesStyled = styled.div`
	border: 1px solid black;
	max-width: 20%;
	min-width: 20%;
`
const EntityButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * {
		color: blue;
		margin-right: 8px;

		&:hover {
			color: green;
			cursor: pointer;
		}
	}
`
