import React from 'react'
import { FormGroup, Label, Row, Col } from 'reactstrap'
import { Field } from 'redux-form'
import type { Guide, SubHeader } from '../../../types'
import { getJrIcon, getJrPlusIcon } from './icons'
import styled from 'styled-components'
type Props = {
	name: string
	guide: Guide | SubHeader
}
export default function StationSelector({ name, guide }: Props): JSX.Element {
	return (
		<div>
			{guide.type === 'STATION' && (
				<Row>
					<Col>
						<Row>
							<IconWrapper>
								<Icon className="m-1">{getJrIcon(guide.jrStation)}</Icon>
							</IconWrapper>
							<FormGroup className="w-75">
								<Label>Jr Station</Label>
								<Field
									type="select"
									name={name + '.jrStation'}
									component="select"
									className="form-control">
									{JR_STATIONS.map((option) => (
										<option value={option.type} key={option.type}>
											{option.display}
										</option>
									))}
								</Field>
							</FormGroup>
						</Row>
					</Col>
					<Col>
						<Row>
							<IconWrapper>
								<Icon className="m-1">{getJrPlusIcon(guide.jrPlusStation)}</Icon>
							</IconWrapper>
							<FormGroup className="w-75">
								<Label>Jr Plus Station</Label>
								<Field
									type="select"
									name={name + '.jrPlusStation'}
									component="select"
									className="form-control">
									{JR_PLUS_STATIONS.map((option) => (
										<option value={option.type} key={option.type}>
											{option.display}
										</option>
									))}
								</Field>
							</FormGroup>
						</Row>
					</Col>
				</Row>
			)}
		</div>
	)
}
const Icon = styled.div`
	width: 25px;
	height: 25px;
	svg {
		height: 100%;
		width: 100%;
	}
`
const IconWrapper = styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`
const JR_STATIONS = [
	{
		type: 'REPAIRS',
		display: 'Repairs',
	},
	{
		type: 'SCANNER',
		display: 'Scanner',
	},
	{
		type: 'TRANSPORTER',
		display: 'Transporter',
	},
	{
		type: 'THRUSTERS',
		display: 'Thrusters',
	},
	{
		type: 'POWER',
		display: 'Power',
	},
	{
		type: 'COMMUNICATION',
		display: 'Communication',
	},
	{
		type: 'DEFENSE',
		display: 'Defense',
	},
	{
		type: 'TRACTOR_BEAM',
		display: 'Tractor Beam',
	},
	{
		type: 'NONE',
		display: 'None',
	},
]
const JR_PLUS_STATIONS = [
	{
		type: 'DECKS',
		display: 'Decks',
	},
	{
		type: 'SENSOR',
		display: 'Sensor',
	},
	{
		type: 'TRANSPORTER',
		display: 'Transporter',
	},
	{
		type: 'THRUSTERS',
		display: 'Thrusters',
	},
	{
		type: 'POWER',
		display: 'Power',
	},
	{
		type: 'COMMUNICATION',
		display: 'Communication',
	},
	{
		type: 'DEFENSE',
		display: 'Defense',
	},
	{
		type: 'TRACTOR_BEAM',
		display: 'Tractor Beam',
	},
	{
		type: 'NONE',
		display: 'None',
	},
]
