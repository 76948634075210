import React from 'react'
import { Row, Col } from 'reactstrap'
import { ExpositionIcon, EffectIcon } from '../../../images/launchpadGuides'
import styled from 'styled-components'
import { Field } from 'redux-form'
import type { Exposition } from '../Expositions/ExpositionRow'
import type { Effect, Guide } from '../../../types/Simulation'
import { parseCamelCase } from './EffectSelector'
type Props = {
	content: Exposition | Effect | undefined
	guide: Guide
	name: string
}
export default function ExpositionEffectView({ content, guide, name }: Props): JSX.Element {
	const getDisplay = (effect: string): string => {
		const display = effect.split('.').pop()
		return parseCamelCase(display || '')
	}

	return (
		<Row>
			<Col className="pl-1" xs={3}>
				<div className="mt-1">
					{guide.type === 'EXPOSITION' ? <ExpositionIcon /> : <EffectIcon />}
					<ExpositionHeader className="m-1 d-inline">
						{guide.type === 'EXPOSITION' ? 'Exposition' : 'Effect'}
					</ExpositionHeader>
				</div>
			</Col>

			<Col className="mt-2" xs={3}>
				<h6 className="d-inline">Delay: &nbsp;&nbsp;</h6>
				{content && <p className="d-inline">{content.delay}</p>}
			</Col>
			<Col className="mt-2" xs={4}>
				<div>
					<h6 className="d-inline">{guide.type === 'EXPOSITION' ? 'Caption: ' : 'Effect: '}</h6>

					{guide.type === 'EXPOSITION' && content && (
						<div className="d-inline">
							{'caption' in content ? (
								<p className="d-inline">&nbsp;&nbsp;{content.caption}</p>
							) : (
								<div className="text-danger d-inline">
									&nbsp;&nbsp;You have not created a caption.
								</div>
							)}
						</div>
					)}
					{guide.type === 'AUTO_EFFECT' && content && (
						<p className="d-inline">&nbsp;&nbsp;{getDisplay(content.effect)}</p>
					)}
				</div>
			</Col>
			<Col xs={2}>
				<Field type="select" name={name + '.hidden'} component="select" className="form-control">
					{/* @ts-expect-error it would probably be fine to use the strings `false` and `true`, but keeping it this way to avoid functional changes */}
					<option value={false}>Show</option>
					{/* @ts-expect-error see above */}
					<option value={true}>Hide</option>
				</Field>
			</Col>
		</Row>
	)
}
const ExpositionHeader = styled.div`
	font-size: 1rem;
`
