import React, { Fragment, useState } from 'react'
import { prettifyTypeEnum } from '../../../helpers/functions'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { IoMdPlay, IoMdPause } from 'react-icons/io'
import { PAUSE_STATE } from '../ActionPlayer/ActionPlayer'
import { type ActiveStations, type ActionPlayerAction, STATION_ICONS } from './sharedTypes'

import type {
	DeathState,
	OverlayType,
	PausedState,
	ActionPlayerState,
	FullStateLiteracyEventForActionPlayer,
} from '../ActionPlayer/ActionPlayer'
import type { ActionMap, EventResult } from '@mission.io/mission-toolkit/actions'
import classNames from 'classnames'
import { getTitle } from '../actionDefinitions'
import { IdMap } from '../../../types/util'
import LiteracyEventsModal from './Overlays/LiteracyEventsModal'

export default function StatusBar({
	className,
	activeStations,
	playActions,
	dispatch,
	onClose,
	pausedState,
	deathState,
	overlays,
	actionPlayerState,
	actions,
	seenLiteracyEvents,
}: {
	activeStations: ActiveStations
	playActions: (arg0: EventResult<string>) => unknown
	dispatch: (arg0: ActionPlayerAction) => unknown
	onClose: () => unknown
	className?: string
	pausedState: PausedState | null | undefined
	deathState: DeathState | null | undefined
	overlays?: Array<OverlayType>
	actionPlayerState: ActionPlayerState
	actions: ActionMap<string>
	seenLiteracyEvents: IdMap<FullStateLiteracyEventForActionPlayer>
}): JSX.Element {
	const handleClick = () => {
		const pauseStatus =
			pausedState?.state === PAUSE_STATE.PLAYING ? PAUSE_STATE.PAUSED : PAUSE_STATE.PLAYING
		dispatch({
			type: 'SET_PAUSE',
			payload: {
				nextPauseState: pauseStatus,
			},
		})
	}

	const isPaused =
		pausedState &&
		(pausedState.state === PAUSE_STATE.SOFT_PAUSE || pausedState.state === PAUSE_STATE.PAUSED)

	const isDisabledPauseButton = (): boolean => {
		return !!(deathState?.status || (overlays && overlays.length > 0))
	}

	const [showLiteracyEventsModal, setShowLiteracyEventsModal] = useState(false)

	return (
		<>
			<div className={classNames(className, 'flex justify-between text-gray-a')}>
				<div className="flex pl-1 w-full [&>*]:px-2 [&>*]:border-r">
					<div className="min-w-80">
						<div>Active Stations</div>
						{(Object.keys(activeStations) as Array<keyof typeof activeStations>).map(
							(stationId) => {
								const Icon = STATION_ICONS[stationId]
								// We know that `stationId` exists in `activeStations` because we're iterating over the keys of `activeStations`
								const station = activeStations[stationId] as { onComplete?: EventResult<string> }
								return Icon ? (
									<Icon
										onClick={() => {
											if (!('onComplete' in station && station.onComplete)) {
												return
											}

											playActions(station.onComplete)
											dispatch({
												type: 'STATION_COMPLETED',
												payload: {
													stationId,
												},
											})
										}}
										style={{
											marginRight: '8px',
											cursor: 'pointer',
											height: 38,
											pointerEvents: isPaused ? 'none' : 'auto',
										}}
										title={
											'onComplete' in station
												? `Complete ${prettifyTypeEnum(stationId)} Station`
												: `${prettifyTypeEnum(stationId)} Station cannot be completed by students`
										}
										key={stationId}
									/>
								) : (
									'Error. Bad stationId: ' + stationId + '. Please tell the engineering team'
								)
							}
						)}
					</div>
					<div className="justify-between h-full text-center min-w-28">
						<div className="text-start">Status</div>
						<div>{isPaused ? 'Station Pause' : 'Playing'}</div>
					</div>
					<div>
						Investigations
						<ul className="flex gap-1">
							{actionPlayerState.investigations.map(({ culminatingMomentActionId }, i) => {
								const action = actions[culminatingMomentActionId]
								return (
									<Fragment key={culminatingMomentActionId}>
										<li
											className="bg-horizon-blue text-white size-8 flex items-center justify-center rounded-full"
											id={`investigation-${culminatingMomentActionId}`}>
											{i + 1}
										</li>
										<UncontrolledTooltip target={`investigation-${culminatingMomentActionId}`}>
											{action ? getTitle(action) : 'Unknown CM! Fix this action!!'}
										</UncontrolledTooltip>
									</Fragment>
								)
							})}
						</ul>
					</div>
					<div
						style={{ cursor: Object.keys(seenLiteracyEvents)?.length ? 'pointer' : 'auto' }}
						onClick={() => setShowLiteracyEventsModal(!!Object.keys(seenLiteracyEvents)?.length)}>
						Literacy Events
						<div>{Object.keys(seenLiteracyEvents)?.length}</div>
					</div>
				</div>

				<Button
					title="Only pauses media, does not pause timers"
					onClick={handleClick}
					disabled={isDisabledPauseButton()}
					className="h-fit m-auto mr-1">
					{isPaused ? <IoMdPlay color="white" /> : <IoMdPause color="white" />}
				</Button>
				<button className="mx-2" onClick={onClose}>
					Close Player
				</button>
			</div>
			{showLiteracyEventsModal && (
				<LiteracyEventsModal
					literacyEvents={seenLiteracyEvents}
					closeModal={() => setShowLiteracyEventsModal(false)}
					dismissLiteracyEvent={(literacyEventId) => {
						dispatch({
							type: 'DISMISS_LITERACY_EVENT',
							payload: literacyEventId,
						})
					}}
				/>
			)}
		</>
	)
}
