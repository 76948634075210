import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { useSelector } from 'react-redux'
import produce from 'immer'
import { PhaseCheckPointInput } from './FormPieces/FormComponents'
import type { EventResultChangeProps } from './FormPieces/BasicFormComponents'
import { EVENT_RESULT_SETTERS } from '../helpers/eventResults'
import { isScreenAction, selectors } from '../../../reducers/simulationEditor'
import type { GeneralFormState, FormState } from './types'
import FormContainer from './FormContainer'

import { KeysOfUnion } from '../../../helpers/types'
import { SCREEN_OVERLAY_TYPES, createModifiedAction, actionDefinitions } from '../actionDefinitions'
import type {
	ScreenAction,
	ActivateStationData,
	QuestionInfo,
} from '@mission.io/mission-toolkit/actions'
import type { ReadingContextOnChangeEvent } from './FormPieces/LiteracyEvent/ReadingContextEditor'

type FormProps = {
	formState: GeneralFormState
	setFormState: Dispatch<SetStateAction<FormState>>
	onLeave: (arg0: () => void) => () => void
}
export const VIDEO_OVERLAY = 'VIDEO_OVERLAY'
export const VIDEO_SCREEN = 'VIDEO_SCREEN'
export const IMAGE_OVERLAY = 'IMAGE_OVERLAY'
export const IMAGE_SCREEN = 'IMAGE_SCREEN'

const overlayFieldsToHide = new Set([
	'_id',
	'__v',
	'createdForSimulationId',
	'newPhase',
	'checkpoint',
])
const allFieldsToHide = new Set(['type', ...overlayFieldsToHide])
export default function Form({ formState, setFormState, onLeave }: FormProps): JSX.Element {
	const { modifiedAction, createdActions } = formState
	//* This function for setting state is for flow: We know that the component will only be rendered if state.type is 'GENERAL'
	const setGeneralFormState = useCallback(
		(cb: (arg0: GeneralFormState) => GeneralFormState) => {
			setFormState((state) => (state.type === 'GENERAL' ? cb(state) : state))
		},
		[setFormState]
	)
	const actionId = modifiedAction?._id
	const initialScreenId = useSelector(selectors.getInitialScreenId)
	const keys = (
		SCREEN_OVERLAY_TYPES.includes(modifiedAction.type) && actionId !== initialScreenId
			? Object.keys(modifiedAction).filter((key) => !overlayFieldsToHide.has(key))
			: Object.keys(modifiedAction).filter((key) => !allFieldsToHide.has(key))
	) as KeysOfUnion<typeof modifiedAction>[]

	// Order keys if an order is provided in actionDefinitions
	const orderedKeys = keys.sort((a, b) => {
		const fieldOrder = actionDefinitions[modifiedAction.type].fieldOrder
		if (fieldOrder) {
			// @ts-expect-error - we expect `a` to be the keys of `modifiedAction`
			const fieldAIndex = fieldOrder.indexOf(a)
			// @ts-expect-error - we expect `b` to be the keys of `modifiedAction`
			const fieldBIndex = fieldOrder.indexOf(b)
			if (fieldAIndex !== -1 && fieldBIndex !== -1) {
				return fieldAIndex - fieldBIndex
			}
			// If the field is not explicitly ordered, it should be at the end of the list
			return fieldAIndex === -1 ? 1 : -1
		}
		return 0
	})

	return (
		<div>
			{orderedKeys.map((property) => {
				return (
					<FormContainerWrapper
						{...{
							actionId,
							modifiedAction,
							property,
							value: modifiedAction[property as keyof typeof modifiedAction],
							createdActions,
							onLeave,
							setGeneralFormState,
						}}
						key={`${modifiedAction.type}_${property}`}
					/>
				)
			})}
			{isScreenAction(modifiedAction) && (
				<PhaseCheckPointInput
					value={modifiedAction}
					onChange={(updatedAction: ScreenAction<string>) => {
						setGeneralFormState((state) => ({ ...state, modifiedAction: updatedAction }))
					}}
				/>
			)}
		</div>
	)
}

const SPECIALIZED_ON_CHANGE_KEYS = { readingContexts: true }

function FormContainerWrapper({
	property,
	value,
	setGeneralFormState,
	onLeave,
	modifiedAction,
	actionId,
	createdActions,
}: {
	property: string
	value: unknown
	setGeneralFormState: (arg0: (arg0: GeneralFormState) => GeneralFormState) => unknown
	onLeave: (arg0: () => void) => () => void
	modifiedAction: GeneralFormState['modifiedAction']
	actionId: string
	createdActions: GeneralFormState['createdActions']
}) {
	// The normal onValueChange function. If `property` is an event result key, then this is overridden
	const normalOnValueChange = useCallback(
		(newValue: unknown) => {
			setGeneralFormState((state) => ({
				...state,
				modifiedAction: createModifiedAction(newValue, property, state.modifiedAction),
			}))
		},
		[setGeneralFormState, property]
	)
	const specializedOnValueChange = useCallback(
		(event: ReadingContextOnChangeEvent) => {
			if (
				event &&
				event instanceof Object &&
				event.hasOwnProperty('type') &&
				event.type === 'LITERACY_EVENT_READING_CONTEXT'
			) {
				setGeneralFormState((state) => ({
					...state,
					modifiedAction: {
						...state.modifiedAction,
						readingContexts: event.readingContexts,
						assignment: event.assignment,
					},
				}))
				return
			}
		},
		[setGeneralFormState]
	)
	const eventResultSetterOnChange = useCallback(
		(
			event:
				| {
						type: 'STATION_DATA'
						stationData: ActivateStationData<string>
				  }
				| EventResultChangeProps
				| {
						type: 'QUESTION_INFO'
						questionInfo: QuestionInfo<string>
				  }
		) => {
			if (event.type === 'STATION_DATA' || event.type === 'QUESTION_INFO') {
				const data = event.type === 'STATION_DATA' ? event.stationData : event.questionInfo
				setGeneralFormState((state) => ({
					...state,
					modifiedAction: { ...state.modifiedAction, [property]: data },
				}))
			} else if (event.type === 'EVENT_RESULT') {
				if (event.deletedActionId) {
					setGeneralFormState((state) => ({
						...state,
						createdActions: state.createdActions.filter((action) => {
							const id = action.type === 'SCREEN_REFERENCE' ? action.reference : action._id
							return id !== event.deletedActionId
						}),
					}))
				}

				setGeneralFormState((state) => {
					return produce(state, (draft) => {
						const action = draft.modifiedAction

						if (property in EVENT_RESULT_SETTERS) {
							EVENT_RESULT_SETTERS[property as keyof typeof EVENT_RESULT_SETTERS](
								action,
								event.modifiedEventResult,
								event
							)
						}
					})
				})
			}

			if ('newAction' in event) {
				setGeneralFormState((state) => ({
					...state,
					createdActions: event.newAction
						? [...state.createdActions, event.newAction]
						: state.createdActions,
				}))
			}
		},
		[property, setGeneralFormState]
	)
	const onValueChange = Object.keys(EVENT_RESULT_SETTERS).includes(property)
		? eventResultSetterOnChange
		: property in SPECIALIZED_ON_CHANGE_KEYS
		? specializedOnValueChange
		: normalOnValueChange
	return (
		<FormContainer
			// @ts-expect-error - types around Form are not correct
			label={property}
			value={value}
			onChange={onValueChange}
			// @ts-expect-error - types around Form are not correct
			onLeave={onLeave}
			action={modifiedAction}
			actionId={actionId}
			// @ts-expect-error - types around Form are not correct
			createdActions={createdActions}
		/>
	)
}
