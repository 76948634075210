import type { ScreenActionId } from './actionDefinitions'
import React, { useContext } from 'react'
export type Phase = {
	screenId: ScreenActionId
	display: string
}
type PhaseContextType = {
	currentPhase: ScreenActionId | null | undefined
	setCurrentPhase: (arg0: ScreenActionId) => void
	phases: Phase[] | null | undefined
}
export const PhaseContext = React.createContext<PhaseContextType | null>(null)
export function usePhaseContext(): PhaseContextType {
	const context = useContext(PhaseContext)

	if (!context) {
		throw new Error('PhaseContext must be used from within PhaseContextProvider')
	}

	return context
}
