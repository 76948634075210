import SpecialEffects from './SpecialEffects'
const specialEffectsList = [
	'com.rbfc.ss.effects.specialEffects.Accessing',
	'com.rbfc.ss.effects.specialEffects.ambiance.AlertLightsOnly',
	'com.rbfc.ss.effects.specialEffects.ambiance.BlueAmbiance',
	'com.rbfc.ss.effects.specialEffects.ambiance.Christmas',
	'com.rbfc.ss.effects.specialEffects.ambiance.DeepRedAlert',
	'com.rbfc.ss.effects.specialEffects.ambiance.NoAmbiance',
	'com.rbfc.ss.effects.specialEffects.ambiance.RedAlert',
	'com.rbfc.ss.effects.specialEffects.ambiance.RedAmbiance',
	'com.rbfc.ss.effects.specialEffects.ambiance.StealthAmbiance',
	'com.rbfc.ss.effects.specialEffects.ambiance.WhiteAmbiance',
	'com.rbfc.ss.effects.specialEffects.Call',
	'com.rbfc.ss.effects.specialEffects.CallConnect',
	'com.rbfc.ss.effects.specialEffects.CallDisconnect',
	'com.rbfc.ss.effects.specialEffects.Caught',
	'com.rbfc.ss.effects.specialEffects.ComputerBeep1',
	'com.rbfc.ss.effects.specialEffects.ComputerStartup',
	'com.rbfc.ss.effects.specialEffects.Explosion',
	'com.rbfc.ss.effects.specialEffects.ExplosionSmall', // 'com.rbfc.ss.effects.specialEffects.ExpositionIntro',
	'com.rbfc.ss.effects.specialEffects.Laser',
	'com.rbfc.ss.effects.specialEffects.MajorError',
	'com.rbfc.ss.effects.specialEffects.MetalCreak', // 'com.rbfc.ss.effects.specialEffects.Mute',
	'com.rbfc.ss.effects.specialEffects.PhaserCannon',
	'com.rbfc.ss.effects.specialEffects.PowerDown',
	'com.rbfc.ss.effects.specialEffects.PowerUp',
	'com.rbfc.ss.effects.specialEffects.Probe',
	'com.rbfc.ss.effects.specialEffects.Scanned',
	'com.rbfc.ss.effects.specialEffects.Servo',
	'com.rbfc.ss.effects.specialEffects.ShipPassby',
	'com.rbfc.ss.effects.specialEffects.StandDown',
	'com.rbfc.ss.effects.specialEffects.Thrusters',
	'com.rbfc.ss.effects.specialEffects.Torpedo',
	'com.rbfc.ss.effects.specialEffects.TractorBeam',
	'com.rbfc.ss.effects.specialEffects.Transport',
	'com.rbfc.ss.effects.specialEffects.WarpJump',
	'com.rbfc.ss.effects.specialEffects.WarpStop',
]
export const customSpecialEffectsList = [
	'com.rbfc.ss.effects.specialEffects.ambiance.BlueAmbiance',
	'com.rbfc.ss.effects.specialEffects.ambiance.StealthAmbiance',
	'com.rbfc.ss.effects.specialEffects.ambiance.RedAmbiance',
	'com.rbfc.ss.effects.specialEffects.ambiance.RedAlert',
	'com.rbfc.ss.effects.specialEffects.ambiance.DeepRedAlert',
	'com.rbfc.ss.effects.specialEffects.ComputerBeep1',
	'com.rbfc.ss.effects.specialEffects.Accessing',
	'com.rbfc.ss.effects.specialEffects.Scanned',
	'com.rbfc.ss.effects.specialEffects.MajorError',
	'com.rbfc.ss.effects.specialEffects.WarpJump',
	'com.rbfc.ss.effects.specialEffects.WarpStop',
	'com.rbfc.ss.effects.specialEffects.ShipPassby',
	'com.rbfc.ss.effects.specialEffects.Thrusters',
	'com.rbfc.ss.effects.specialEffects.PowerDown',
	'com.rbfc.ss.effects.specialEffects.PowerUp',
	'com.rbfc.ss.effects.specialEffects.Servo',
	'com.rbfc.ss.effects.specialEffects.Caught',
	'com.rbfc.ss.effects.specialEffects.TractorBeam',
	'com.rbfc.ss.effects.specialEffects.Transport',
	'com.rbfc.ss.effects.specialEffects.Torpedo',
	'com.rbfc.ss.effects.specialEffects.PhaserCannon',
	'com.rbfc.ss.effects.specialEffects.ExplosionSmall',
	'com.rbfc.ss.effects.specialEffects.Explosion',
	'com.rbfc.ss.effects.specialEffects.Pause',
	'com.rbfc.ss.effects.specialEffects.Play',
	'com.rbfc.ss.effects.specialEffects.Crash',
	'com.rbfc.ss.effects.specialEffects.CallCrew',
]
export { SpecialEffects, specialEffectsList }
