import React, { ChangeEvent, Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import {
	FormGroup,
	Label,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormText,
} from 'reactstrap'
import { isEqual } from 'lodash'
import { specialEffectsList, SpecialEffects } from './specialEffects'
import Expositions from './Expositions'
import MoversEditor from './movers/MoversEditor'
import CardDetails from './CardDetails'
import { Modal as AssetModal } from '../../common/AssetManager'
import { REDUX_FORM_NAMES } from '../../helpers/constants'
import { actions } from '../../setup/tiles'
import Guides from './LaunchpadGuide/Guides'
import { toast } from 'react-toastify'
import type { Card as CardType } from '../../types'
import type { File } from '../../common/AssetManager'
const metaFields = [
	'summary',
	'directorInstructions',
	'cardObjectives',
	'launchpadGuides',
	'mapId',
	'jumpPrep',
	'jumpEnd',
	'thumbnailUrl',
	'guideSummary',
	'proceed',
]
const otherFields = [
	'name',
	'mediaUrl',
	'audioUrl',
	'looping',
	'playNext',
	'continuePreviousAudio',
	'noExtraAudio',
	'movers',
	'effects',
	'expositions',
]
type Props = {
	simulationId: string
	card: CardType
	prevMapId: string | null | undefined
	change: (arg0: string, arg1: string | boolean | null) => unknown
	cardList: {
		cardId: string
		cardName: string
	}[]
}
type ReduxProps = {
	deleteCard: (arg0: { simulationId: string; cardId: string }) => unknown
}
type ReduxFormProps = {
	initialize: (arg0: Record<string, unknown>) => unknown
	dirty: boolean
	handleSubmit: () => unknown
}
type State = {
	videoChooserOpen: boolean
	songChooserOpen: boolean
	thumbnailChooserOpen: boolean
	deleteConfirmationOpen: boolean
	moverModalOpen: boolean
}

class Card extends Component<Props & ReduxFormProps & ReduxProps, State> {
	state: State = {
		videoChooserOpen: false,
		songChooserOpen: false,
		thumbnailChooserOpen: false,
		deleteConfirmationOpen: false,
		moverModalOpen: false,
	}

	render(): JSX.Element {
		const { simulationId, card, prevMapId } = this.props
		const { videoChooserOpen, songChooserOpen, thumbnailChooserOpen } = this.state
		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						position: 'sticky',
						top: '49px',
						zIndex: 15,
						background: 'white',
					}}>
					<Field id="cardName" name="name" component="input" className="h4" />
					<div>
						<Button
							color="primary"
							onClick={this.props.handleSubmit}
							className="mr-2"
							disabled={!this.props.dirty}>
							Save
						</Button>
						<Button color="danger" onClick={this.getToggleModalFunction('deleteConfirmationOpen')}>
							Delete Card
						</Button>
					</div>
				</div>
				<FormGroup>
					<Label>Video</Label>
					<FormText>Settings related to the card video.</FormText>

					<div className="d-flex align-items-baseline mb-2">
						<Button
							className="mr-2"
							color="primary"
							size="sm"
							onClick={this.getToggleModalFunction('videoChooserOpen')}>
							Choose Video
						</Button>
						<span>{card.mediaUrl}</span>
					</div>

					<AssetModal
						collection="videos"
						isOpen={videoChooserOpen}
						onClose={this.getToggleModalFunction('videoChooserOpen')}
						onFileClick={(file) => {
							this.props.change('mediaUrl', file.url)
							this.setState({
								videoChooserOpen: false,
							})
						}}
					/>

					<CheckBox
						fieldKey="looping"
						text="Looping"
						onChange={(checked) => {
							if (checked) {
								this.props.change(`proceed`, '')
								this.props.change(`playNext`, false)
							}
						}}
					/>

					<CheckBox
						fieldKey="playNext"
						text="Auto Play Next Card (When this video finishes, play the next card)"
						onChange={(checked) => {
							if (checked) {
								this.props.change(`proceed`, 'Will automatically proceed to the next card')
								this.props.change(`looping`, false)
							} else {
								this.props.change(`proceed`, '')
							}
						}}
					/>
				</FormGroup>
				{/* TODO change song options for a select perhaps later on. Redux Fields component may be a good fit */}
				<FormGroup>
					<Label>Song</Label>
					<FormText>Settings related to the card song.</FormText>

					<div className="d-flex align-items-baseline mb-2">
						<Button
							className="mr-2"
							color="primary"
							size="sm"
							onClick={() =>
								this.setState({
									songChooserOpen: true,
								})
							}>
							Choose Song
						</Button>

						{card.audioUrl && (
							<Button
								className="mr-2"
								color="danger"
								size="sm"
								onClick={() => {
									this.props.change('audioUrl', null)
									this.props.change(`continuePreviousAudio`, false)
									this.props.change(`noExtraAudio`, true)
								}}>
								Clear
							</Button>
						)}

						<span>{card.audioUrl ? card.audioUrl : 'No audio selected'}</span>
					</div>

					<AssetModal
						collection="songs"
						isOpen={songChooserOpen}
						onClose={() =>
							this.setState({
								songChooserOpen: false,
							})
						}
						onFileClick={(file: File) => {
							this.props.change('audioUrl', file.url)
							this.props.change(`continuePreviousAudio`, false)
							this.props.change(`noExtraAudio`, false)
							this.setState({
								songChooserOpen: false,
							})
						}}
					/>

					<CheckBox
						fieldKey="continuePreviousAudio"
						text="Continue Previous Song"
						onChange={(checked) => {
							if (checked) {
								this.props.change('audioUrl', null)
								this.props.change(`noExtraAudio`, false)
							}
						}}
					/>

					<CheckBox
						fieldKey="noExtraAudio"
						text="No Music"
						onChange={(checked) => {
							if (checked) {
								this.props.change('audioUrl', null)
								this.props.change(`continuePreviousAudio`, false)
							}
						}}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Thumbnail Image</Label>
					<FormText>Small picture representing the card.</FormText>

					<div className="d-flex align-items-baseline mb-2">
						<Button
							className="mr-2"
							color="primary"
							size="sm"
							onClick={this.getToggleModalFunction('thumbnailChooserOpen')}>
							Choose Thumbnail
						</Button>

						{card.thumbnailUrl && (
							<Button
								className="mr-2"
								color="danger"
								size="sm"
								onClick={() => {
									this.props.change('thumbnailUrl', null)
								}}>
								Clear
							</Button>
						)}

						<span>{card.thumbnailUrl}</span>
					</div>

					<AssetModal
						collection="cardThumbnails"
						isOpen={thumbnailChooserOpen}
						onClose={this.getToggleModalFunction('thumbnailChooserOpen')}
						onFileClick={(file) => {
							this.props.change('thumbnailUrl', file.url)
							this.setState({
								thumbnailChooserOpen: false,
							})
						}}
					/>
				</FormGroup>
				<Button onClick={this.getToggleModalFunction('moverModalOpen')}>Edit Movers</Button>
				<Modal
					isOpen={this.state.moverModalOpen}
					toggle={this.getToggleModalFunction('moverModalOpen')}>
					<ModalHeader>Edit Mover</ModalHeader>
					<ModalBody>
						<FieldArray
							name="movers"
							// @ts-expect-error not worrying about ts errors for old simulation code
							component={MoversEditor}
							props={{
								cardVideoUrl: card.mediaUrl,
							}}
						/>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.getToggleModalFunction('moverModalOpen')}>Close</Button>
					</ModalFooter>
				</Modal>
				{/* @ts-expect-error not worrying about ts errors for old simulation code */}
				<FieldArray
					name="effects"
					component={SpecialEffects}
					props={{
						type: 'effects',
						options: specialEffectsList,
						display: (value) => value.split('.').pop() || '',
					}}
				/>
				{/* @ts-expect-error not worrying about ts errors for old simulation code */}
				<FieldArray name="expositions" component={Expositions} rerenderOnEveryChange={true} />
				<h3>Launchpad Guides</h3>
				<FormGroup>
					<FieldArray
						// @ts-expect-error not worrying about ts errors for old simulation code
						component={Guides}
						props={{
							expositions: card.expositions,
							effects: card.effects,
							cardList: this.props.cardList.filter(
								(cardId) => cardId.cardId !== this.props.card._id
							),
						}}
						id="launchpadGuidesId"
						name="launchpadGuides"
						rerenderOnEveryChange={true}
					/>
				</FormGroup>
				<CardDetails
					cardList={this.props.cardList.filter((cardId) => cardId.cardId !== this.props.card._id)}
					simulationId={simulationId}
					card={card}
					prevMapId={prevMapId}
				/>
				<Modal isOpen={this.state.deleteConfirmationOpen}>
					<ModalHeader toggle={this.getToggleModalFunction('deleteConfirmationOpen')}>
						Delete {`"${card.name}"`}
					</ModalHeader>
					<ModalBody>Are you sure you want to delete {`"${card.name}"`}?</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								this.props.deleteCard({
									simulationId,
									cardId: card._id,
								})
								this.getToggleModalFunction('deleteConfirmationOpen')()
							}}
							color="danger">
							Delete
						</Button>
						<Button onClick={this.getToggleModalFunction('deleteConfirmationOpen')}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	getToggleModalFunction =
		(modalIsOpenKey: keyof State): (() => void) =>
		() => {
			// @ts-expect-error not worrying about ts errors for old simulation code
			this.setState((state: State) => ({
				[modalIsOpenKey]: !state[modalIsOpenKey],
			}))
		}
}

type CheckBoxProps = {
	fieldKey: string
	text: string
	onChange?: (arg0: boolean) => unknown
}

const CheckBox = ({ fieldKey, text, onChange }: CheckBoxProps) => {
	return (
		<div>
			<Label>
				<Field
					name={fieldKey}
					component="input"
					type="checkbox"
					onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.checked)}
				/>{' '}
				{text}
			</Label>
		</div>
	)
}

function isEmpty(currentValue: unknown) {
	return currentValue == null || currentValue === ''
}

function hasCustomLaunchpadGuides(launchpadGuides: Array<{ type: string }>): boolean {
	if (launchpadGuides.length < 1) return false
	let foundCustom = false
	launchpadGuides.forEach((guide) => {
		if (guide.type !== 'EXPOSITION' && guide.type !== 'AUTO_EFFECT') {
			foundCustom = true
		}
	})
	return foundCustom
}

function validateLaunchpadGuides(values: CardType): boolean {
	let hasErrors = false
	values.launchpadGuides.forEach((element) => {
		Object.values(element).forEach((value) => {
			if (isEmpty(value)) hasErrors = true
		})

		if (element.type === 'TRIGGER' && element.cardEffects.length < 1) {
			hasErrors = true
			toast.error('Please add Card Effects to Trigger in Launchpad Guides')
		}

		if ('subHeaders' in element) {
			element.subHeaders.forEach((subHeader) => {
				Object.values(subHeader).forEach((value) => {
					if (isEmpty(value)) hasErrors = true
				})
			})
		}
	})
	if (hasErrors) toast.error('Please fill in any empty fields in Launchpad Guides')
	const customLaunchpadGuides = hasCustomLaunchpadGuides(values.launchpadGuides)

	if (customLaunchpadGuides && isEmpty(values.guideSummary)) {
		hasErrors = true
		toast.error('Please enter a summary in Launchpad Guides')
	}

	if ((customLaunchpadGuides || !isEmpty(values.guideSummary)) && isEmpty(values.proceed)) {
		hasErrors = true
		toast.error('Please enter a message in the Proceed section of Launchpad Guides')
	}

	return hasErrors
}

const allFields = [...metaFields, ...otherFields]
const ReduxFormCard = reduxForm({
	form: REDUX_FORM_NAMES.CARD,
	// @ts-expect-error not worrying about ts errors for old simulation code
	onSubmit: function onSubmit(values, dispatch, props: Props & ReduxFormProps & ReduxProps) {
		// if field values haven't changed, don't run update
		// @ts-expect-error not worrying about ts errors for old simulation code
		if (allFields.every((field) => isEqual(values[field], props.card[field]))) {
			return
		}

		// check for empty fields in guides
		if (validateLaunchpadGuides(values)) return
		// create
		dispatch(
			actions.simulations.putCard({
				simulationId: props.simulationId,
				cardId: props.card._id,
				...values,
			})
		)
	},
	enableReinitialize: true,
	// @ts-expect-error not worrying about ts errors for old simulation code
})(Card)

const CardReduxFormWrapper = (props: Props) => {
	const card = props.card
	return (
		// @ts-expect-error not worrying about ts errors for old simulation code
		<ReduxFormCard
			{...props}
			initialValues={{
				name: card.name,
				summary: card.summary,
				mediaUrl: card.mediaUrl,
				audioUrl: card.audioUrl,
				directorInstructions: card.directorInstructions,
				cardObjectives: card.cardObjectives,
				guideSummary: card.guideSummary,
				proceed: card.proceed,
				launchpadGuides: card.launchpadGuides,
				mapId: card.mapId,
				jumpPrep: card.jumpPrep,
				jumpEnd: card.jumpEnd,
				looping: card.looping === undefined ? card.cycleCount === null : card.looping,
				playNext: card.playNext,
				continuePreviousAudio: card.continuePreviousAudio,
				noExtraAudio: card.noExtraAudio,
				movers: card.movers,
				expositions: card.expositions,
				effects: card.effects,
			}}
		/>
	)
}

export default connect(null, {
	deleteCard: actions.simulations.deleteCard,
})(CardReduxFormWrapper)
