import React from 'react'
import styled from 'styled-components'
import { Card } from './common/components'
import { IMAGE, VIDEO, VOCAL_TRACK, SONG_CHANGE, SPECIAL_EFFECT } from './constants'
import AudioCard from './Cards/AudioCard'
import MediaCard from './Cards/VideoImageCard'
import { FixedSizeGrid as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import type { ActionBankItem, Category } from '../actionDefinitions'

export type ActionContainerProps = {
	items: ReadonlyArray<ActionBankItem>
	category: Category
}

function ActionContainer({ items, category }: ActionContainerProps) {
	const ITEM_HEIGHT = category === 'MULTI_MEDIA' ? 100 : 80
	const ITEM_WIDTH = 200
	return (
		<AutoSizer>
			{({ height, width }) => {
				const itemsPerRow = Math.floor(width / ITEM_WIDTH)
				const rowCount = Math.ceil(items.length / itemsPerRow)
				return (
					<List
						height={height}
						width={width}
						columnCount={itemsPerRow}
						rowCount={rowCount}
						columnWidth={ITEM_WIDTH}
						rowHeight={ITEM_HEIGHT}>
						{({ columnIndex, rowIndex, style }) => {
							const index = rowIndex * itemsPerRow + columnIndex
							const item = items[index]
							if (!item) return null
							const audioSrc =
								item.type === VOCAL_TRACK || (item.type === SONG_CHANGE && 'url' in item)
									? item.url
									: item.type === SPECIAL_EFFECT
									? item.audioSrc
									: null
							if (audioSrc)
								return (
									<CellContainer style={style}>
										<AudioCard
											category={category}
											item={item}
											audioUrl={audioSrc}
											name={item.display}
										/>
									</CellContainer>
								)
							else if ((item.type === VIDEO || item.type === IMAGE) && item.url)
								return (
									<CellContainer style={style}>
										<MediaCard
											category={category}
											item={item}
											url={item.url}
											type={item.type}
											name={item.display}
										/>
									</CellContainer>
								)
							else
								return (
									<CellContainer style={style}>
										<Card category={category} item={item}>
											<div css="margin: auto;">{item.display}</div>
										</Card>
									</CellContainer>
								)
						}}
					</List>
				)
			}}
		</AutoSizer>
	)
}

export default React.memo<ActionContainerProps>(ActionContainer)

const CellContainer = styled.div`
	padding: 7px;
	overflow: hidden;
	.card {
		width: 100%;
		height: 100%;
	}
`
