import React, { useState } from 'react'
import { DataEditor, MediaTypePicker } from './DataEditor'
import { Button } from 'reactstrap'
import type { ObjectContentType, MapDataContentType, DataType } from '../../../../../types'
export default function MediaInformationContainer<
	T extends MapDataContentType | ObjectContentType
>({
	onChange,
	mediaList,
	includePrescannedField = true,
}: {
	onChange: (arg0: Array<T>) => void
	mediaList: T[]
	includePrescannedField?: boolean
}): JSX.Element {
	const [typeChosen, setTypeChosen] = useState<DataType | null | undefined>(null)
	return (
		<>
			<div css="display: flex; align-items: baseline; justify-content: space-between;">
				<h5>Media Information</h5>
				<div css="display: flex; gap: 16px;">
					<MediaTypePicker onSelect={setTypeChosen} mediaType={typeChosen} />
					<Button
						disabled={!typeChosen}
						onClick={() => {
							if (typeChosen) {
								const objectContent: Array<T> = [...mediaList]
								const data: ObjectContentType = {
									url: null,
									text: '',
									type: typeChosen,
									relevance: 0,
								}

								if (includePrescannedField) {
									data.prescanned = false
								}

								// @ts-expect-error - The generics need to be fixed here
								objectContent.push(data)
								onChange(objectContent)
							}
						}}
						color="primary">
						Add Media
					</Button>
				</div>
			</div>

			{mediaList.map((media, index) => (
				<div key={`media-${index}`}>
					<hr />
					<DataEditor
						onChange={(updater) => {
							const objectContent = [...mediaList]
							objectContent[index] = updater(objectContent[index])
							onChange(objectContent)
						}}
						onDelete={() => {
							const objectContent = [...mediaList]
							objectContent.splice(index, 1)
							onChange(objectContent)
						}}
						data={media}
						includePrescannedField={includePrescannedField}
					/>
				</div>
			))}
		</>
	)
}
