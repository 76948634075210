import React from 'react'
import { Row } from 'reactstrap'
import { BorderedDiv } from './Guides'
import { getIcon } from './icons'
import { Field } from 'redux-form'
import styled from 'styled-components'
type Props = {
	componentType: 'SUMMARY' | 'PROCEED'
}
export default function GuideSummaryProceed({ componentType }: Props): JSX.Element {
	return (
		<BorderedDiv className="p-3 mb-2">
			<Row className="w-100 ml-1">
				<CenteredVertical className="w-100">
					<div className="ml-4 pl-3">{getIcon(componentType)}</div>
					<h6 className="mr-3 ml-1 mb-0 d-inline">
						{componentType === 'SUMMARY' ? 'Summary' : 'Proceed'}
					</h6>
					<Field
						id="guideSummaryId"
						name={componentType === 'SUMMARY' ? 'guideSummary' : 'proceed'}
						component="input"
						className="form-control d-inline w-75"
					/>
				</CenteredVertical>
			</Row>
		</BorderedDiv>
	)
}
const CenteredVertical = styled.div`
	display: flex;
	align-items: center;
`
