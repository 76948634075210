import React, { useState } from 'react'
import styled from 'styled-components'
import { Card } from '../common/components'
import { FaExclamationTriangle } from 'react-icons/fa'
import type { Category, ActionBankItem } from '../../actionDefinitions'

type AudioCardProps = {
	category: Category
	audioUrl: string
	name: string
	item: ActionBankItem
}

function AudioCard({ category, audioUrl, name, item }: AudioCardProps): JSX.Element {
	const [error, setError] = useState<string | undefined>()
	return (
		<Card category={category} item={item}>
			<div css="padding: 4px;">
				{error ? (
					<div css="background-color: white; color: red; border-radius:16px; padding: 4px;">
						<FaExclamationTriangle css="margin: auto;" /> Failed to load audio
					</div>
				) : (
					<audio
						src={audioUrl}
						controls
						css="width: 100%; height: 20px;"
						title={name}
						controlsList="nodownload nofullscreen noremoteplayback"
						onError={() => setError('Failed to load')}
					/>
				)}
				<Name>{name}</Name>
			</div>
		</Card>
	)
}

const Name = styled.div`
	margin: auto;
`
export default React.memo(AudioCard)
