import React, { CSSProperties, useState } from 'react'
import { DraggableType } from './types'
import styled from 'styled-components'
import type { PlacementPayload, DraggableItem } from './types'
import { DropTargetHookSpec, DropTargetMonitor, XYCoord, useDrop } from 'react-dnd'
type Accepts = DraggableType | DraggableType[]
type Props = {
	onlyAccept?: Accepts
	customCanDrop?: (item: DraggableItem, monitor: unknown) => boolean
	onPlacement: (arg0: PlacementPayload) => void
	children?: JSX.Element
	allowDropWithinOffset?: (dragItem: DraggableItem, xyCoord: XYCoord | null) => boolean
	style?: CSSProperties
}
export default function Target({
	onlyAccept,
	children,
	customCanDrop,
	style,
	onPlacement,
	allowDropWithinOffset,
	...otherProps
}: Props): JSX.Element {
	/* 
  It is necessary to provide a stately canDropFromOffset rather than relying on canDrop because 
  the monitor in react-dnd collect functions only updates when items enter/leave the target space. (See issue https://github.com/react-dnd/react-dnd/issues/179)
  In some cases, when allowDropWithinOffset is provided, we need updates to occur when an item changes offset 
  within the target space.
  */
	const [canDropFromOffset, setCanDropFromOffset] = useState(true)

	const onDrop = (item: DraggableItem, monitor: DropTargetMonitor) => {
		const canDropSimple = monitor.canDrop()
		const canDrop = allowDropWithinOffset ? canDropFromOffset && canDropSimple : canDropSimple

		if (!monitor.didDrop() && canDrop) {
			onPlacement({
				item,
				clientOffset: monitor.getSourceClientOffset(),
			})
		}
	}

	const accept = onlyAccept || Object.keys(DraggableType)
	const dropConfig: DropTargetHookSpec<
		DraggableItem,
		unknown,
		{ canDrop: boolean; hasStarted: boolean; isOver: boolean }
	> = {
		accept,
		hover: (item: DraggableItem, monitor: DropTargetMonitor) => {
			if (allowDropWithinOffset) {
				const allowedToDrop = allowDropWithinOffset(item, monitor.getSourceClientOffset())
				setCanDropFromOffset(allowedToDrop)
			}
		},
		drop: onDrop,
		collect: (monitor: DropTargetMonitor) => ({
			canDrop: monitor.canDrop(),
			hasStarted: !!monitor.getClientOffset(),
			isOver: monitor.isOver(),
		}),
	}

	if (customCanDrop) {
		dropConfig.canDrop = (item, monitor) => {
			return customCanDrop(item, monitor)
		}
	}

	const [{ canDrop: canDropSimple, hasStarted, isOver }, dropRef] = useDrop(dropConfig)
	const canDrop = allowDropWithinOffset ? canDropFromOffset && canDropSimple : canDropSimple
	return (
		<Container style={{ ...style }} ref={dropRef} {...otherProps}>
			{hasStarted && (!canDrop ? <NegativeOverlay /> : canDrop && isOver && <PositiveOverlay />)}
			{children}
		</Container>
	)
}
const Container = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
`
const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	pointer-events: none;
`
const NegativeOverlay = styled(Overlay)`
	background: repeating-linear-gradient(
		45deg,
		rgba(0, 0, 0, 0.2),
		rgba(0, 0, 0, 0.2) 10px,
		rgba(0, 0, 0, 0.3) 10px,
		rgba(0, 0, 0, 0.3) 20px
	);
`
const PositiveOverlay = styled(Overlay)`
	opacity: 0.2;
	background-color: green;
`
