import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Label } from 'reactstrap'
import styled from 'styled-components'
import { debounceUpdateServerObject, updateMapObjectStore } from '../../../reducers/mapObjects'
import MapImage from './MapImage'
import MapForm from './MapForm'
import type { NormalizedMap as MapType } from '../../../types'
type Props = {
	map: MapType
	deleteMap: () => unknown
	onUpdate: (arg0: MapType) => void
	objectDelete?: (objectId: string) => void
	isAutomated?: boolean
}
export default function Map({
	map,
	deleteMap,
	onUpdate,
	objectDelete,
	isAutomated,
}: Props): JSX.Element {
	const dispatch = useDispatch()
	const [showHidden, setShowHidden] = useState(false)
	return (
		<StyledContainer>
			<h1 className="title" id={map._id}>
				{map.name}
			</h1>
			<div className="map-image">
				<MapImage
					objectIds={map.objects}
					color={map.color}
					backgroundImage={map.backgroundImage}
					allowDrag={true}
					onDrag={(key: string, id: string, [x, y]: [number, number]) => {
						dispatch(
							updateMapObjectStore({
								[key]: {
									[id]: {
										location: {
											$set: {
												x,
												y,
											},
										},
									},
								},
							})
						)
						dispatch(debounceUpdateServerObject(key, id))
					}}
					showHidden={showHidden}
				/>
				<StyledToggleWrapper>
					<Label>
						<input
							type="checkbox"
							checked={showHidden}
							onChange={(e) => setShowHidden(e.target.checked)}
						/>{' '}
						Show Hidden Objects
					</Label>
				</StyledToggleWrapper>
			</div>
			<MapForm
				map={map}
				deleteMap={deleteMap}
				onUpdate={onUpdate}
				objectDelete={objectDelete}
				isAutomated={isAutomated}
				mapDataContainerClassName="map-data-container"
				mapObjectContainerClassName="map-object-container"
			/>
		</StyledContainer>
	)
}
const StyledToggleWrapper = styled.div`
	margin-top: 4px;
`
const StyledContainer = styled.div`
	display: grid;
	grid-template:
		'title title' auto
		'image data' auto
		'objects objects' auto
		/ auto 1fr;
	grid-gap: 16px;

	.title {
		grid-area: title;
	}

	.map-image {
		grid-area: image;
	}

	.map-data-container {
		grid-area: data;
	}

	.map-object-container {
		grid-area: objects;
	}
`
