import { XYCoord } from 'react-dnd'
import type { EffectActionType, MediaActionType, OtherActionType } from '../actionTypes'
export enum DraggableType {
	SCREEN_TYPE = 'SCREEN_TYPE',
	ACTION_TYPE = 'ACTION_TYPE',
}
export type ScreenReferenceType = 'SCREEN_REFERENCE'
export type ActionType = 'ACTION_TYPE'
export type ActionMeta =
	| {
			type: MediaActionType
			url: string
	  }
	| {
			type: EffectActionType
			effectId?: string
			url?: string | null | undefined
	  }
	| {
			type: OtherActionType
	  }
	| {
			type: ScreenReferenceType
			referencedId: string
	  }
export type DraggableItem = {
	type: DraggableType
	title: string
	id?: string
	meta: ActionMeta
}
export type PlacementPayload = {
	item: DraggableItem
	clientOffset: XYCoord | null
}
