import React, { Component, Fragment, MouseEvent } from 'react'
import FontAwesome from 'react-fontawesome'
import classnames from 'classnames'
import styled from 'styled-components'
import styles from './FolderSelector.module.css'
import type { Collection } from './AssetManager'
export type SimpleFolder = {
	name: string
	children: SimpleFolder[]
}
type FolderProps = {
	folder: SimpleFolder
	depth: number
	previousCrumbs: string[]
	selectedCrumbs: string[]
	onFolderClick: (crumbs: string[]) => void
	onCreateFolder: (parentCrumbs: string[], name: string) => void
	collection: Collection
}
type FolderState = {
	open: boolean
}

class Folder extends Component<FolderProps, FolderState> {
	constructor(props: FolderProps) {
		super(props)
		this.state = {
			open: props.depth === 0,
		}
	}

	handleToggleOpen = (e: MouseEvent) => {
		const { open } = this.state
		e.stopPropagation()
		this.setState({
			open: !open,
		})
	}
	handleAddFolderClick = (e: MouseEvent) => {
		const {
			folder: { name },
			previousCrumbs,
			onCreateFolder,
		} = this.props
		e.stopPropagation()
		const result = prompt('New Folder Name')

		if (result) {
			onCreateFolder([...previousCrumbs, name], result)
		}
	}

	render(): JSX.Element {
		const {
			folder: { name, children },
			depth,
			previousCrumbs,
			selectedCrumbs,
			onFolderClick,
			onCreateFolder,
			collection,
		} = this.props
		const { open } = this.state
		const crumbs = [...previousCrumbs, name]
		const spacers = []

		for (let i = 0; i < depth; i++) {
			spacers.push(<span className={styles.indent} key={`spacers-${i}`} />)
		}

		const icon = open ? 'angle-down' : 'angle-right'
		let openContent = null

		if (open && children.length > 0) {
			openContent = children.map((child, index) => (
				<Folder
					key={`${child.name}-${index}`}
					folder={child}
					depth={depth + 1}
					previousCrumbs={crumbs}
					selectedCrumbs={selectedCrumbs}
					onFolderClick={onFolderClick}
					onCreateFolder={onCreateFolder}
					collection={collection}
				/>
			))
		}

		return (
			<Fragment>
				<li
					className={classnames('list-group-item', styles.folder, {
						active: crumbsEqual(crumbs, selectedCrumbs),
					})}
					onClick={() => onFolderClick(crumbs)}>
					{spacers}
					{(collection !== 'automatedSimulationMedia' ||
						(collection === 'automatedSimulationMedia' && depth !== 2)) && (
						<FontAwesome name={icon} title="Open/close folder" onClick={this.handleToggleOpen} />
					)}{' '}
					<span className={styles.folderName}>{name}</span>
				</li>

				{open && (
					<div>
						{openContent}
						{(collection !== 'automatedSimulationMedia' ||
							(collection === 'automatedSimulationMedia' && depth !== 0 && depth !== 2)) && (
							<PointerLi className="list-group-item" onClick={this.handleAddFolderClick}>
								{spacers}
								<span className={styles.indent} />
								<i>Add New Folder</i>
							</PointerLi>
						)}
					</div>
				)}
			</Fragment>
		)
	}
}

const PointerLi = styled.li`
	cursor: pointer;
`
type Props = {
	className?: string
	folders: SimpleFolder[]
	selectedCrumbs: string[]
	onFolderClick: (crumbs: string[]) => void
	onCreateFolder: (parentCrumbs: string[], name: string) => void
	collection: Collection
}
export default class FolderSelector extends Component<Props> {
	render(): JSX.Element {
		const { className, folders, selectedCrumbs, onFolderClick, onCreateFolder, collection } =
			this.props
		return (
			<div className={classnames(className)}>
				<ul className="list-group">
					{folders.map((folder, i) => (
						<Folder
							key={`${folder.name}-folder-${i}`}
							folder={folder}
							depth={0}
							previousCrumbs={[]}
							selectedCrumbs={selectedCrumbs}
							onFolderClick={onFolderClick}
							onCreateFolder={onCreateFolder}
							collection={collection}
						/>
					))}
				</ul>
			</div>
		)
	}
}

function crumbsEqual(a: string[], b: string[]): boolean {
	if (a.length !== b.length) {
		return false
	}

	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) {
			return false
		}
	}

	return true
}
