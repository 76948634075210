import React, { Component } from 'react'
import { Button, Input } from 'reactstrap'
import FontAwesome from 'react-fontawesome'
import { Modal as AssetModal } from '../../../common/AssetManager'
import { toast } from 'react-toastify'
import './Exposition.css'
export type Exposition = {
	_id: string
	delay: number
	effect: string
	accessibilityVideoUrl: string | null | undefined
	caption: string | null | undefined
	originalIndex: number
}
type State = {
	delay: string
	effect: string | null | undefined
	accessibilityVideoUrl: string | null | undefined
	caption: string
	editing: boolean
	modalMode: 'effect' | 'accessibility'
	showModal: boolean
}
type Props = {
	field:
		| (Exposition & {
				_id: string
		  })
		| null
		| undefined
	onRemove: (index: number) => void
	onSave: (index: number, newValues: Exposition) => void
	onCreate: (exposition: Exposition) => void
	creator: boolean
}
export default class ExpositionRow extends Component<Props, State> {
	state: State = {
		delay: '',
		effect: null,
		accessibilityVideoUrl: null,
		caption: '',
		editing: false,
		modalMode: 'effect',
		showModal: false,
	}
	render: () => JSX.Element = () => {
		const field = this.props.field
		return (
			<tr key={field ? field.delay + field.effect : 'newExposition'}>
				{this.props.field && !(this.props.creator || this.state.editing)
					? this.renderStatic(this.props.field)
					: this.renderEditable()}
				{this.renderAssetModal()}
			</tr>
		)
	}
	renderStatic = (field: Exposition) => {
		return [
			<td className="align-middle" key="delay">
				{field.delay}
			</td>,
			<td className="align-middle" key="effect">
				{formatUrl(field.effect)}
			</td>,
			<td className="align-middle" key="accessibility">
				{formatUrl(field.accessibilityVideoUrl)}
			</td>,
			<td
				className="align-middle"
				key="caption"
				style={{
					fontSize: 13,
					lineHeight: 1.5,
				}}>
				{field.caption}
			</td>,
			<td className="align-middle center-elements" key="actions">
				<FontAwesome
					name="edit"
					className="orange"
					title="Edit Exposition"
					onClick={() => this.setEditing(true)}
				/>
				<FontAwesome
					name="trash"
					className="red"
					title="Delete Exposition"
					onClick={() => this.remove()}
				/>
			</td>,
		]
	}
	renderEditable = () => {
		return [
			<td className="align-middle" key="delay">
				<Input
					type="number"
					bsSize="sm"
					value={this.state.delay}
					onChange={(e) =>
						this.setState({
							delay: e.target.value,
						})
					}
				/>
			</td>,
			<td className="align-middle" key="effect">
				<Button
					color="link"
					className="p-0"
					onClick={() => {
						this.showModal('effect')
					}}>
					{this.state.effect ? formatUrlText(this.state.effect) : 'Choose'}
				</Button>
			</td>,
			<td className="align-middle" key="accessibility">
				<Button
					color="link"
					className="p-0"
					onClick={() => {
						this.showModal('accessibility')
					}}>
					{this.state.accessibilityVideoUrl
						? formatUrlText(this.state.accessibilityVideoUrl)
						: 'Choose'}
				</Button>{' '}
				{this.state.accessibilityVideoUrl && (
					<Button
						color="danger"
						size="sm"
						onClick={() => {
							this.setState({
								accessibilityVideoUrl: null,
							})
						}}>
						Clear
					</Button>
				)}
			</td>,
			<td className="align-middle" key="caption">
				<Input
					type="textarea"
					// @ts-expect-error reactstrap types are wrong?
					size="sm"
					value={this.state.caption || ''}
					onChange={(e) =>
						this.setState({
							caption: e.target.value,
						})
					}
				/>
			</td>,
			this.props.creator ? (
				<td className="align-middle center-elements" key="actions">
					<FontAwesome
						name="plus-square"
						title="Create Exposition"
						className="blue"
						onClick={() => {
							const delay = parseInt(this.state.delay, 10)
							const { effect, accessibilityVideoUrl, caption } = this.state

							if ((!delay && delay !== 0) || !effect) {
								return toast.warn('Please provide a delay and an effect')
							}

							// @ts-expect-error This object should have `originalIndex`, but it does not
							this.props.onCreate({ delay, effect, accessibilityVideoUrl, caption })
							this.clearFields()
						}}
					/>
				</td>
			) : (
				<td className="center-elements" key="actions">
					{' '}
					<div>
						<FontAwesome
							name="check-square"
							title="Save Changes"
							className="green"
							onClick={() => {
								const delay = parseInt(this.state.delay, 10)

								if ((!delay && delay !== 0) || !this.state.effect) {
									return toast.warn('Please provide a delay and an effect')
								}

								this.saveAndQuitEditing()
							}}
						/>
					</div>
					<div>
						<FontAwesome
							name="recycle"
							title="Discard Changes"
							className="orange"
							onClick={() => {
								this.setEditing(false)
							}}
						/>
					</div>
					<div>
						<FontAwesome
							name="trash"
							className="red"
							title="Delete Exposition"
							onClick={() => this.remove()}
						/>
					</div>
				</td>
			),
		]
	}
	renderAssetModal: () => JSX.Element = () => {
		return (
			<AssetModal
				collection={this.state.modalMode === 'effect' ? 'expositions' : 'videos'}
				isOpen={this.state.showModal}
				onClose={this.closeModal}
				onFileClick={this.submitFile}
			/>
		)
	}
	setEditing: (value: boolean) => void = (value: boolean) => {
		if (value && this.props.field) {
			this.setState({
				delay: String(this.props.field.delay),
				effect: this.props.field?.effect,
				accessibilityVideoUrl: this.props.field?.accessibilityVideoUrl,
				caption: this.props.field?.caption || '',
				editing: true,
			})
		} else {
			this.setState({
				editing: false,
			})
		}
	}
	saveAndQuitEditing: () => void = () => {
		const delay = parseInt(this.state.delay, 10)
		const { effect, accessibilityVideoUrl, caption } = this.state
		const field = this.props.field

		if (
			field &&
			!(
				delay === field.delay &&
				effect === field.effect &&
				accessibilityVideoUrl === field.accessibilityVideoUrl &&
				caption === field.caption
			)
		) {
			this.setEditing(false)
			this.props.onSave(field.originalIndex, {
				delay,
				// @ts-expect-error effect should not be nullish here
				effect,
				accessibilityVideoUrl,
				caption,
			})
		}
	}
	clearFields: () => void = () => {
		this.setState({
			delay: '',
			effect: null,
			accessibilityVideoUrl: null,
			caption: '',
		})
	}
	remove: () => void = () => {
		if (this.props.field) {
			this.props.onRemove(this.props.field.originalIndex)
		}
	}
	handleModalReturn: (
		type: string,
		file: {
			url: string
		}
	) => void = (
		type: string,
		file: {
			url: string
		}
	) => {
		if (type === 'effect') {
			this.setState({
				effect: file.url,
			})
		} else if (type === 'accessibility') {
			this.setState({
				accessibilityVideoUrl: file.url,
			})
		}
	}
	showModal: (type: 'effect' | 'accessibility') => void = (type: 'effect' | 'accessibility') => {
		this.setState({
			showModal: true,
			modalMode: type,
		})
	}
	submitFile: (file: { url: string }) => void = (file: { url: string }) => {
		this.handleModalReturn(this.state.modalMode, file)
		this.closeModal()
	}
	closeModal: () => void = () => {
		this.setState({
			showModal: false,
		})
	}
}

function formatUrlText(videoUrl: string | null | undefined) {
	if (!videoUrl) {
		return null
	}

	return videoUrl.split('/').pop()
}

function formatUrl(videoUrl: string | null | undefined) {
	if (!videoUrl) {
		return null
	}

	return (
		<a href={videoUrl} target="_blank" rel="noopener noreferrer">
			{formatUrlText(videoUrl)}
		</a>
	)
}
