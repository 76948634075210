import { getSpecialEffect, getAmbianceEffect } from '@mission.io/mission-toolkit'
import type { DurationMap } from './PhaseCreator/types'
import { ONE_MINUTE, ONE_SECOND } from '../../helpers/constants'
import { prettifyTypeEnum, createTemporaryId } from '../../helpers/functions'
import { DraggableType, DraggableItem, ScreenReferenceType } from './DragDrop/types'
import { startCase, lowerCase } from 'lodash'
import { specialEffectsList } from '../../helpers/specialEffects'
import { IMAGE, VIDEO } from './ActionBank/constants'
import { SCREEN_REFERENCE } from './helpers/constants'
import { EffectActionType, MediaActionType, OtherActionType, ScreenActionId } from './actionTypes'
import type {
	Action,
	ActivateStationData,
	DeathMissionEffectData,
	DefensePlusTarget,
	PauseMissionEffectData,
	ScreenAction,
	TractorBeamPlusTarget,
	CollaborativeCulminatingMomentAction,
	ActionForType,
	ActionType as AnySimulationActionType,
} from '@mission.io/mission-toolkit/actions'
import {
	COLLABORATIVE_CULMINATING_MOMENT,
	DEFENSE_STATION,
	LITERACY_EVENT,
	TRACTOR_BEAM_STATION,
	VOCAL_TRACK_DISPLAY_IDS,
} from '@mission.io/mission-toolkit/constants'

export type { OtherActionType, MediaActionType, EffectActionType, ScreenActionId }

export type ActionId = string
export type PhaseId = string

export type ActionReference = {
	type: 'SCREEN_REFERENCE'
	reference: ActionId
}

type MissionEffect = 'PAUSE' | 'UNPAUSE' | 'DEATH' | 'MISSION_PAUSE'

export const missionEffectsList: Array<MissionEffect> = [
	'PAUSE',
	'UNPAUSE',
	'DEATH',
	'MISSION_PAUSE',
]
/*
This file is the source of all truth for defining everything to do with an action.

    Update types in this file:
        Add the action type under Action Types.
        Add the action type name under Action Editor Types.
        Add the action type to the `TypesToCategory` object.
	
	Add action definitions in this file:
		Add the action type to the `actionDefinitions` object.
		See the documentation for the `ActionDefinition` type for more information on what each property does.
   
    Update action editor values in this file:
        Add the action to the appropriate category in the action bank (i.e. `structureChangeActions`, `gameMapActions`, `objectiveGuideActions`, etc.)
        Include a check for the action type in one of the isAction functions (most likely the `isOtherAction` function)
        SCREEN ACTION ONLY - 
            Add action type to the `SCREEN_ACTION_TYPES` array 
            Add action type to the function `isScreenAction()` 
            Add action type to `getDraggableTypeFromActionType`
            Consider if any changes need to be made to the `createDraggableItem` function
    
    Update state management
        Action Modifiers: consider (not required) if any changes need to be made to `createModifiedAction`; this would only be necessary if 
            you want to reset an action in any way when certain properties are modified.
    
This file has almost everything you need to update for new actions EXCEPT for the following:
       If you are adding a new station data action - go to helpers/stations.js and add logic in `handleJrPlusStationChange` and `handleJrStationChange`
       If you are adding any _new_ EventResult[] field for an action, i.e. (something like `onComplete`, or `onSuccess`) you will need to add support for that field name in the
       'helpers/eventResults.js' file.
*/

// ################################### TYPES ################################## //

// Action Editor Types

export type PhaseChangeType = 'PHASE_CHANGE'
export type ActionType = MediaActionType | EffectActionType | OtherActionType | PhaseChangeType

export type PhaseChangeItem = {
	type: PhaseChangeType
	display: string
	actionId: string
}

export type ActionBankGeneralItem = {
	type: OtherActionType
	display: string
}

export type ActionBankMediaItem = {
	type: MediaActionType
	display: string
	url: string
}

export type ActionBankEffectItem =
	| {
			type: 'MISSION_EFFECT'
			display: string
			effectId: string
	  }
	| {
			type: 'SPECIAL_EFFECT'
			display: string
			effectId: string
			audioSrc: string | null | undefined
	  }
	| {
			type: 'SONG_CHANGE'
			display: string
			url: string
	  }
	| {
			type: 'SHIP_HEALTH_CHANGE'
			display: string
	  }

export type Category =
	| 'MULTI_MEDIA'
	| 'STRUCTURE'
	| 'GAME_MAP'
	| 'OBJECTIVE_GUIDE'
	| 'EFFECTS'
	| 'SCENARIOS'
	| 'FAVORITES'

export type ActionBankFavoriteItem =
	| {
			type: 'MISSION_EFFECT'
			display: string
			effectId: string
			category: Category
	  }
	| {
			type: 'SONG_CHANGE'
			display: string
			category: Category
	  }
	| {
			type: 'START_TIMER' | 'SET_MAIN_OBJECTIVE'
			display: string
			category: Category
	  }

export type ActionBankItem =
	| ActionBankGeneralItem
	| ActionBankMediaItem
	| ActionBankEffectItem
	| ActionBankFavoriteItem
	| PhaseChangeItem

type CategoriesValue = 'OBJECTIVE_GUIDE' | 'GAME_MAP' | 'EFFECTS' | 'STRUCTURE' | 'SCENARIOS'
type TypesToCategory = {
	VIDEO_OVERLAY: 'MULTI_MEDIA'
	IMAGE_OVERLAY: 'MULTI_MEDIA'
	VOCAL_TRACK: 'MULTI_MEDIA'
	VIDEO_SCREEN: 'MULTI_MEDIA'
	IMAGE_SCREEN: 'MULTI_MEDIA'
	NAVIGATION_SCREEN: CategoriesValue
	CULMINATING_MOMENT_SCREEN: CategoriesValue
	COLLABORATIVE_CULMINATING_MOMENT_SCREEN: CategoriesValue
	SCREEN_REFERENCE?: CategoriesValue
	SONG_CHANGE?: CategoriesValue
	MISSION_EFFECT?: CategoriesValue
	SHIP_HEALTH_CHANGE?: CategoriesValue
	SPECIAL_EFFECT?: CategoriesValue
} & {
	[ActionType in OtherActionType]?: CategoriesValue
}

export type ActionMeta =
	| { type: MediaActionType; url: string }
	| { type: EffectActionType; effectId?: string; url?: string | null | undefined }
	| { type: OtherActionType }
	| { type: ScreenReferenceType; referencedId: string }

// ################################### ACTIONS ################################## //

type CreateActionArg<ThisActionType extends AnySimulationActionType> = {
	simulationId: string
} & (ThisActionType extends
	| 'IMAGE_SCREEN'
	| 'VIDEO_SCREEN'
	| 'IMAGE_OVERLAY'
	| 'VIDEO_OVERLAY'
	| 'VOCAL_TRACK'
	? { url: string }
	: ThisActionType extends 'SONG_CHANGE'
	? { url: string | null }
	: ThisActionType extends 'MISSION_EFFECT'
	? { effectId: MissionEffect }
	: ThisActionType extends 'SPECIAL_EFFECT'
	? { effectId: string }
	: ThisActionType extends 'ACTIVATE_STATION'
	? { isJunior: boolean }
	: object)

type CreateAction<ThisActionType extends AnySimulationActionType> = (
	arg: CreateActionArg<ThisActionType>
) => ActionForType<ThisActionType>

type ActionDefinition<
	ThisActionType extends AnySimulationActionType,
	ThisAction = ActionForType<ThisActionType>
> = {
	// The string of the action type
	type: ThisActionType
	// Function to create a default action. Use the function `createAction` or `createScreen` to use this
	createAction: CreateAction<ThisActionType>
	// Function to get the duration of the action. If not provided, defaults to ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
	// Use `getActionTimeLength` to get the duration for any action.
	getDuration?: (action: ThisAction, durationMap: DurationMap) => number
	// Function to initialize the duration map for the action. Required if the duration of the action is
	// dependent on some data in the action, eg the duration of a video
	initializeDurationMap?: (action: ThisAction, durationMap: DurationMap) => void
	// Display name for the action. If not provided, defaults to the prettified version of the action type
	// Use `getTitle` to get the correct title for any action
	title?: string | ((action?: ThisAction) => string)
	// The order that the fields should be displayed. If not provided, defaults to the order of the keys in the action
	fieldOrder?: Array<keyof ThisAction>
}

export const actionDefinitions: { [T in Action<string>['type']]: ActionDefinition<T> } = {
	IMAGE_SCREEN: {
		type: 'IMAGE_SCREEN',
		createAction: ({ simulationId, url }) => ({
			type: 'IMAGE_SCREEN',
			url: url,
			timeLimit: DEFAULT_TIME_LENGTH_FOR_IMAGE_SCREEN,
			onStart: [],
			onEnd: [],
			atTime: [],
			...getSharedActionData(simulationId),
		}),
		getDuration: (action) => action.timeLimit,
		title: (action) => (action ? getActionTitleFromUrl(action.url) : 'Image Screen'),
	},
	VIDEO_SCREEN: {
		type: 'VIDEO_SCREEN',
		createAction: ({ simulationId, url }) => ({
			type: 'VIDEO_SCREEN',
			url: url,
			looping: false,
			finalFrameDuration: DEFAULT_FINAL_FRAME_DURATION,
			timeLimit: 0,
			onStart: [],
			onEnd: [],
			atTime: [],
			...getSharedActionData(simulationId),
		}),
		getDuration: (action, durationMap) => {
			if (action.looping) {
				return action.timeLimit
			}
			const mediaDuration =
				getDurationForUrl(action.url, durationMap) ?? ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
			return mediaDuration + action.finalFrameDuration
		},
		initializeDurationMap: (action, durationMap) => {
			initializeDurationMapForUrl(action.url, durationMap)
		},
		title: (action) => (action ? getActionTitleFromUrl(action.url) : 'Video Screen'),
	},
	NAVIGATION_SCREEN: {
		type: 'NAVIGATION_SCREEN',
		createAction: ({ simulationId }) => {
			return {
				type: 'NAVIGATION_SCREEN',
				map: '',
				timeLimit: DEFAULT_TIME_LENGTH_FOR_NAVIGATION_SCREEN,
				onComplete: [],
				onDeath: [],
				onCheatDeath: [],
				onPlayerCollisionWithSolid: [],
				onEnd: [], // when time runs out
				onStart: [],
				atTime: [],
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => action.timeLimit,
	},
	CULMINATING_MOMENT_SCREEN: {
		type: 'CULMINATING_MOMENT_SCREEN',
		createAction: ({ simulationId }) => {
			return {
				type: 'CULMINATING_MOMENT_SCREEN',
				teacherTips: [],
				questionInfo: {
					phrase: '',
					description: '',
					media: [],
					options: [],
				},
				timeLimit: DEFAULT_TIME_LENGTH_FOR_CULMINATING_MOMENT_SCREEN,
				onStart: [],
				onEnd: [],
				atTime: [],
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => action.timeLimit,
		title: (action) =>
			action?.questionInfo.phrase ? `CM: ${action.questionInfo.phrase}` : 'Culminating Moment',
	},
	COLLABORATIVE_CULMINATING_MOMENT_SCREEN: {
		type: 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN',
		createAction: ({ simulationId }) => {
			return {
				type: 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN',
				canvasActionId: '',
				editingTime: 5 * ONE_MINUTE,
				screenMedia: null,
				ending: COLLABORATIVE_CULMINATING_MOMENT.ENDING.ALL_RESPONSES,
				requiredPercentForSuccess:
					DEFAULT_COLLABORATIVE_CULMINATING_MOMENT_REQUIRED_PERCENT_FOR_SUCCESS,
				onFailure: [],
				onSuccess: [],
				onStart: [],
				onEnd: [],
				atTime: [],
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => Math.max(action.editingTime, 30_000),
		title: 'Collaborative Culminating Moment',
	},
	MAP_SCREEN: {
		type: 'MAP_SCREEN',
		createAction: ({ simulationId }) => {
			return {
				type: 'MAP_SCREEN',
				onStart: [],
				onEnd: [],
				atTime: [],
				timeLimit: DEFAULT_TIME_LENGTH_FOR_MAP_SCREEN,
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => action.timeLimit,
	},
	QUESTION_SLOT_SCREEN: {
		type: 'QUESTION_SLOT_SCREEN',
		createAction: ({ simulationId }) => {
			return {
				type: 'QUESTION_SLOT_SCREEN',
				onStart: [],
				onEnd: [],
				atTime: [],
				timeLimit: DEFAULT_TIME_LENGTH_FOR_QUESTION_SLOT_SCREEN,
				questionId: '',
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => action.timeLimit,
	},
	IMAGE_OVERLAY: {
		type: 'IMAGE_OVERLAY',
		createAction: ({ simulationId, url }) => {
			return {
				type: 'IMAGE_OVERLAY',
				url: url,
				duration: ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT,
				onStart: [],
				onEnd: [],
				atTime: [],
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => action.duration,
		title: (action) => (action ? getActionTitleFromUrl(action.url) : 'Image Overlay'),
	},
	VIDEO_OVERLAY: {
		type: 'VIDEO_OVERLAY',
		createAction: ({ simulationId, url }) => {
			return {
				type: 'VIDEO_OVERLAY',
				url: url,
				onStart: [],
				onEnd: [],
				atTime: [],
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action, durationMap) =>
			getDurationForUrl(action.url, durationMap) ?? ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT,
		initializeDurationMap: (action, durationMap) => {
			initializeDurationMapForUrl(action.url, durationMap)
		},
		title: (action) => (action ? getActionTitleFromUrl(action.url) : 'Video Overlay'),
	},
	VOCAL_TRACK: {
		type: 'VOCAL_TRACK',
		createAction: ({ simulationId, url }) => {
			return {
				type: 'VOCAL_TRACK',
				audioUrl: url,
				caption: '',
				accessibilityVideoUrl: '',
				characterId: '',
				display: VOCAL_TRACK_DISPLAY_IDS.ICON_ONLY,
				onStart: [],
				onEnd: [],
				atTime: [],
				attributes: [],
				softPause: false,
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action, durationMap) =>
			getDurationForUrl(action.audioUrl, durationMap) ?? ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT,
		initializeDurationMap: (action, durationMap) => {
			initializeDurationMapForUrl(action.audioUrl, durationMap)
		},
		title: (action) => (action ? getActionTitleFromUrl(action.audioUrl) : 'Vocal Track'),
	},
	MISSION_EFFECT: {
		type: 'MISSION_EFFECT',
		createAction: ({ simulationId, effectId }) => {
			return effectId === 'PAUSE' || effectId === 'UNPAUSE'
				? {
						type: 'MISSION_EFFECT',
						effectId: effectId,
						...getSharedActionData(simulationId),
				  }
				: effectId === 'DEATH'
				? {
						type: 'MISSION_EFFECT',
						...getDefaultDeathAction(),
						...getSharedActionData(simulationId),
				  }
				: {
						type: 'MISSION_EFFECT',
						...getDefaultMissionPause(),
						...getSharedActionData(simulationId),
				  }
		},
		title: (action) => (action ? prettifyTypeEnum(action.effectId) : 'Mission Effect'),
	},
	SHIP_HEALTH_CHANGE: {
		type: 'SHIP_HEALTH_CHANGE',
		createAction: ({ simulationId }) => {
			return {
				type: 'SHIP_HEALTH_CHANGE',
				shipHealth: {
					amount: 10,
					isPercentage: true,
				},
				...getSharedActionData(simulationId),
			}
		},
	},
	SPECIAL_EFFECT: {
		type: 'SPECIAL_EFFECT',
		createAction: ({ simulationId, effectId }) => {
			return {
				type: 'SPECIAL_EFFECT',
				specialEffectId: effectId,
				onStart: [],
				onEnd: [],
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action, durationMap) => {
			return (
				getDurationForSpecialEffectId(action.specialEffectId, durationMap) ??
				ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
			)
		},
		initializeDurationMap: (action, durationMap) => {
			initializeDurationMapForSpecialEffectId(action.specialEffectId, durationMap)
		},
		title: (action): string => {
			return action ? getSpecialEffectDisplay(action.specialEffectId) : 'Special Effect'
		},
	},
	ACTIVATE_STATION: {
		type: 'ACTIVATE_STATION',
		createAction: ({ simulationId, isJunior }) => {
			return {
				type: 'ACTIVATE_STATION',
				stationData: getDefaultStationData(isJunior),
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => {
			if ('duration' in action.stationData) {
				return action.stationData.duration
			}
			return ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
		},
		title: (action) => {
			if (!action) {
				return 'Activate Station'
			}
			return `Activate ${prettifyTypeEnum(action.stationData.stationId)} Station`
		},
	},
	UPDATE_HEALTH_TASK_STATUS: {
		type: 'UPDATE_HEALTH_TASK_STATUS',
		createAction: ({ simulationId }) => {
			return {
				type: 'UPDATE_HEALTH_TASK_STATUS',
				isActive: true,
				...getSharedActionData(simulationId),
			}
		},
		title: (action): string => {
			if (!action) {
				return 'Update Health Task Status'
			}

			return `${action.isActive ? 'Activate' : 'Deactivate'} Health Tasks`
		},
	},
	DEACTIVATE_STATION: {
		type: 'DEACTIVATE_STATION',
		createAction: ({ simulationId }) => {
			return {
				type: 'DEACTIVATE_STATION',
				stationId: 'DEFENSE',
				...getSharedActionData(simulationId),
			}
		},
		title: (action): string => {
			if (!action) {
				return 'Deactivate Station'
			}
			return `Deactivate ${prettifyTypeEnum(action.stationId)} Station`
		},
	},
	MAP_CHANGE: {
		type: 'MAP_CHANGE',
		createAction: ({ simulationId }) => {
			return {
				type: 'MAP_CHANGE',
				mapId: null,
				...getSharedActionData(simulationId),
			}
		},
		title: 'Change Map',
	},
	ADD_MAP_OBJECT: {
		type: 'ADD_MAP_OBJECT',
		createAction: ({ simulationId }) => {
			return {
				type: 'ADD_MAP_OBJECT',
				mapId: '',
				mapObjectId: '',
				...getSharedActionData(simulationId),
			}
		},
	},
	REMOVE_MAP_OBJECT: {
		type: 'REMOVE_MAP_OBJECT',
		createAction: ({ simulationId }) => {
			return {
				type: 'REMOVE_MAP_OBJECT',
				mapId: '',
				mapObjectId: '',
				...getSharedActionData(simulationId),
			}
		},
	},
	START_TIMER: {
		type: 'START_TIMER',
		createAction: ({ simulationId }) => {
			return {
				type: 'START_TIMER',
				duration: ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT,
				onStart: [],
				onEnd: [],
				atTime: [],
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action) => action.duration,
		title: 'Timer',
	},

	SET_MAIN_OBJECTIVE: {
		type: 'SET_MAIN_OBJECTIVE',
		createAction: ({ simulationId }) => {
			return {
				type: 'SET_MAIN_OBJECTIVE',
				objectiveText: '',
				teacherInfo: '',
				...getSharedActionData(simulationId),
			}
		},
	},
	REMOVE_MAIN_OBJECTIVE: {
		type: 'REMOVE_MAIN_OBJECTIVE',
		createAction: ({ simulationId }) => {
			return {
				type: 'REMOVE_MAIN_OBJECTIVE',
				...getSharedActionData(simulationId),
			}
		},
	},
	SONG_CHANGE: {
		type: 'SONG_CHANGE',
		createAction: ({ simulationId, url }) => {
			return {
				type: 'SONG_CHANGE',
				songUrl: url,
				startTime: 0,
				...getSharedActionData(simulationId),
			}
		},
		getDuration: (action, durationMap) => {
			if (!action.songUrl) {
				return ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
			}
			return (
				getDurationForUrl(action.songUrl, durationMap) ?? ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
			)
		},
		initializeDurationMap: (action, durationMap) => {
			action.songUrl && initializeDurationMapForUrl(action.songUrl, durationMap)
		},
		title: (action) => (action && action.songUrl ? getActionTitleFromUrl(action.songUrl) : 'Song'),
	},
	UPDATE_CREW_POINTS: {
		type: 'UPDATE_CREW_POINTS',
		createAction: ({ simulationId }) => {
			return {
				type: 'UPDATE_CREW_POINTS',
				difference: 10,
				...getSharedActionData(simulationId),
			}
		},
	},
	END_MISSION: {
		type: 'END_MISSION',
		createAction: ({ simulationId }) => {
			return {
				type: 'END_MISSION',
				...getSharedActionData(simulationId),
			}
		},
		title: 'End Mission',
	},

	DISPLAY_CURRENT_SCREEN_TIMER: {
		type: 'DISPLAY_CURRENT_SCREEN_TIMER',
		createAction: ({ simulationId }) => {
			return {
				type: 'DISPLAY_CURRENT_SCREEN_TIMER',
				...getSharedActionData(simulationId),
			}
		},
		title: 'Display Timer For Current Screen',
	},
	[LITERACY_EVENT.SIMULATION_ACTION_TYPE]: {
		type: LITERACY_EVENT.SIMULATION_ACTION_TYPE,
		createAction: ({ simulationId }) => {
			return {
				type: LITERACY_EVENT.SIMULATION_ACTION_TYPE,
				readingContexts: [],
				assignment: {
					type: LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL,
				},
				dataAppearance: { type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.NONE },
				...getSharedActionData(simulationId),
			}
		},
		title: 'Literacy Event',
		fieldOrder: ['assignment', 'readingContexts', 'dataAppearance'],
	},
	TOGGLE_INVESTIGATION: {
		type: 'TOGGLE_INVESTIGATION',
		createAction: ({ simulationId }) => {
			return {
				type: 'TOGGLE_INVESTIGATION',
				status: 'ACTIVE',
				culminatingMomentActionId: '',
				...getSharedActionData(simulationId),
			}
		},
		title: (action) => {
			if (!action) {
				// Only in rare cases will the TOGGLE_INVESTIGATION action be used to end an investigation.
				// From the user's perspective, we can call it "Start Investigation"
				return 'Start Investigation'
			}
			return action.status === 'ACTIVE' ? 'Start Investigation' : 'End Investigation'
		},
		fieldOrder: ['status', 'culminatingMomentActionId'],
	},
}

// Display Names

// get display for special effect id
export function getSpecialEffectDisplay(specialEffectId: string): string {
	return startCase(lowerCase(specialEffectId))
}

function getActionTitleFromUrl(url: string): string {
	const targetIndex = url.lastIndexOf('/')
	return decodeURIComponent(url.substring(targetIndex + 1))
}

/**
 * Get the action's title as defined in the actionDefinitions object. If no title is defined, return
 * the prettified version of the action type.
 * @param actionArg The action or action type to get the title for. If an action is provided, the title
 *                    may be more specific based on the action's data.
 */
export function getTitle(actionArg: Action<string> | AnySimulationActionType): string {
	const type = typeof actionArg === 'string' ? actionArg : actionArg.type
	const action = typeof actionArg === 'string' ? undefined : actionArg
	const titleDefinition = actionDefinitions[type].title
	if (titleDefinition) {
		if (typeof titleDefinition === 'string') {
			return titleDefinition
		}
		return titleDefinition(
			// @ts-expect-error We use the action type to get the `titleDefinition` function, so passing the action is correct
			action
		)
	}

	return prettifyTypeEnum(type)
}

// Action Bank Categories

const structureChangeActions: ActionBankGeneralItem[] = [
	{ type: 'END_MISSION', display: getTitle('END_MISSION') },
	{
		type: 'START_TIMER',
		display: getTitle('START_TIMER'),
	},
]

const objectiveGuideActions: ActionBankGeneralItem[] = [
	{
		type: 'START_TIMER',
		display: getTitle('START_TIMER'),
	},
	{
		type: 'SET_MAIN_OBJECTIVE',
		display: getTitle('SET_MAIN_OBJECTIVE'),
	},
	{
		type: 'REMOVE_MAIN_OBJECTIVE',
		display: getTitle('REMOVE_MAIN_OBJECTIVE'),
	},
	{
		type: 'DISPLAY_CURRENT_SCREEN_TIMER',
		display: getTitle('DISPLAY_CURRENT_SCREEN_TIMER'),
	},
	{
		type: 'TOGGLE_INVESTIGATION',
		display: getTitle('TOGGLE_INVESTIGATION'),
	},
	// {
	// 	type: 'SET_TRACKED_OBJECTIVES',
	// 	display: 'Set Tracked Objective',
	// },
]

const sharedGameMapActions = [
	{
		type: 'ACTIVATE_STATION' as const,
		display: getTitle('ACTIVATE_STATION'),
	},
	{
		type: 'DEACTIVATE_STATION' as const,
		display: getTitle('DEACTIVATE_STATION'),
	},
	{
		type: 'UPDATE_CREW_POINTS' as const,
		display: getTitle('UPDATE_CREW_POINTS'),
	},
	{
		type: 'NAVIGATION_SCREEN' as const,
		display: getTitle('NAVIGATION_SCREEN'),
	},
]

const jrGameMapActions: ActionBankGeneralItem[] = sharedGameMapActions

const jrPlusGameMapAction: ActionBankGeneralItem[] = [
	...sharedGameMapActions,
	{
		type: 'MAP_CHANGE',
		display: getTitle('MAP_CHANGE'),
	},
	{
		type: 'ADD_MAP_OBJECT',
		display: getTitle('ADD_MAP_OBJECT'),
	},
	{
		type: 'REMOVE_MAP_OBJECT',
		display: getTitle('REMOVE_MAP_OBJECT'),
	},
	{
		type: 'MAP_SCREEN',
		display: getTitle('MAP_SCREEN'),
	},
	{
		type: 'UPDATE_HEALTH_TASK_STATUS',
		display: getTitle('UPDATE_HEALTH_TASK_STATUS'),
	},
]

const favoriteActions: ActionBankFavoriteItem[] = [
	{
		type: 'MISSION_EFFECT',
		display: 'Pause',
		effectId: 'PAUSE',
		category: 'EFFECTS',
	},
	{
		type: 'SONG_CHANGE',
		display: getTitle('SONG_CHANGE'),
		category: 'EFFECTS',
	},
	{
		type: 'START_TIMER',
		display: getTitle('START_TIMER'),
		category: 'STRUCTURE',
	},
	{
		type: 'SET_MAIN_OBJECTIVE',
		display: getTitle('SET_MAIN_OBJECTIVE'),
		category: 'OBJECTIVE_GUIDE',
	},
]

const sharedScenarioActions: ActionBankGeneralItem[] = [
	{
		type: 'CULMINATING_MOMENT_SCREEN',
		display: getTitle('CULMINATING_MOMENT_SCREEN'),
	},
	{
		type: 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN',
		display: getTitle('COLLABORATIVE_CULMINATING_MOMENT_SCREEN'),
	},
	{
		type: LITERACY_EVENT.SIMULATION_ACTION_TYPE,
		display: getTitle(LITERACY_EVENT.SIMULATION_ACTION_TYPE),
	},
	// TODO add Thruster nav and pre built phases
]

const jrPlusScenariosActions: ActionBankGeneralItem[] = [...sharedScenarioActions]

const jrScenariosActions: ActionBankGeneralItem[] = [
	...sharedScenarioActions,
	{ type: 'QUESTION_SLOT_SCREEN', display: getTitle('QUESTION_SLOT_SCREEN') },
]

// Action Bank Category to Items Map

const CATEGORY_TO_ITEMS = {
	STRUCTURE: structureChangeActions,
	MULTI_MEDIA: [] as Array<ActionBankEffectItem>,
	JR_GAME_MAP: jrGameMapActions,
	JRPLUS_GAME_MAP: jrPlusGameMapAction,
	OBJECTIVE_GUIDE: objectiveGuideActions,
	EFFECTS: getEffectActionList(),
	JR_SCENARIOS: jrScenariosActions,
	JRPLUS_SCENARIOS: jrPlusScenariosActions,
	FAVORITES: favoriteActions,
}

function getEffectActionList(): ActionBankEffectItem[] {
	const effectList: ActionBankEffectItem[] = [
		{
			type: 'SHIP_HEALTH_CHANGE',
			display: getTitle('SHIP_HEALTH_CHANGE'),
		},
	]
	missionEffectsList.forEach((missionEffect) => {
		const effectItem: ActionBankEffectItem = {
			type: 'MISSION_EFFECT',
			display: prettifyTypeEnum(missionEffect),
			effectId: missionEffect,
		}
		effectList.push(effectItem)
	})
	specialEffectsList.forEach((specialEffectId) => {
		const specialEffectInfo =
			getSpecialEffect(specialEffectId) || getAmbianceEffect(specialEffectId)
		const effectAction: ActionBankEffectItem = {
			type: 'SPECIAL_EFFECT',
			display: getSpecialEffectDisplay(specialEffectId),
			effectId: specialEffectId,
			audioSrc:
				specialEffectInfo && 'audioSrc' in specialEffectInfo
					? specialEffectInfo.audioSrc
					: undefined,
		}
		effectList.push(effectAction)
	})
	return effectList
}

/**
 * Builds a map of action types to the correct category based on the category maps defined defined above.
 */
function buildActionTypesToCategory(): TypesToCategory {
	const map: TypesToCategory = {
		VIDEO_OVERLAY: 'MULTI_MEDIA',
		IMAGE_OVERLAY: 'MULTI_MEDIA',
		VOCAL_TRACK: 'MULTI_MEDIA',
		VIDEO_SCREEN: 'MULTI_MEDIA',
		IMAGE_SCREEN: 'MULTI_MEDIA',
		NAVIGATION_SCREEN: 'GAME_MAP',
		CULMINATING_MOMENT_SCREEN: 'SCENARIOS',
		COLLABORATIVE_CULMINATING_MOMENT_SCREEN: 'SCENARIOS',
	}
	const objectiveGuideKeys = CATEGORY_TO_ITEMS.OBJECTIVE_GUIDE.map((action) => action.type)
	objectiveGuideKeys.forEach((key) => (map[key] = 'OBJECTIVE_GUIDE'))

	const jrPlusGameMapKeys = CATEGORY_TO_ITEMS.JRPLUS_GAME_MAP.map((action) => action.type)
	jrPlusGameMapKeys.forEach((key) => (map[key] = 'GAME_MAP'))

	const jrGameMapKeys = CATEGORY_TO_ITEMS.JR_GAME_MAP.map((action) => action.type)
	jrGameMapKeys.forEach((key) => (map[key] = 'GAME_MAP'))

	const effectKeys = CATEGORY_TO_ITEMS.EFFECTS.map((action) => action.type)
	effectKeys.forEach((key) => (map[key] = 'EFFECTS'))
	map['SONG_CHANGE'] = 'EFFECTS'

	const structureKeys = CATEGORY_TO_ITEMS.STRUCTURE.map((action) => action.type)
	structureKeys.forEach((key) => (map[key] = 'STRUCTURE'))
	map['SCREEN_REFERENCE'] = 'STRUCTURE'

	const scenarioKeys = [
		...CATEGORY_TO_ITEMS.JR_SCENARIOS,
		...CATEGORY_TO_ITEMS.JRPLUS_SCENARIOS,
	].map((action) => action.type)
	scenarioKeys.forEach((key) => (map[key] = 'SCENARIOS'))

	return map
}
export const ACTION_TYPE_TO_CATEGORY: TypesToCategory = buildActionTypesToCategory()

export function getCategoryItems(
	category: Category,
	isJunior?: boolean
): Array<ActionBankGeneralItem> | Array<ActionBankEffectItem> | Array<ActionBankFavoriteItem> {
	if (category === 'GAME_MAP') {
		if (isJunior) {
			return CATEGORY_TO_ITEMS.JR_GAME_MAP
		}
		return CATEGORY_TO_ITEMS.JRPLUS_GAME_MAP
	} else if (category === 'SCENARIOS') {
		if (isJunior) {
			return CATEGORY_TO_ITEMS.JR_SCENARIOS
		}
		return CATEGORY_TO_ITEMS.JRPLUS_SCENARIOS
	}
	return CATEGORY_TO_ITEMS[category]
}

// Checks if type is action

export function isMediaAction(action: ActionBankItem): action is ActionBankMediaItem {
	return action.type === 'IMAGE' || action.type === 'VIDEO' || action.type === 'VOCAL_TRACK'
}
export function isOtherAction(action: ActionBankItem): action is ActionBankGeneralItem {
	return (
		action.type === 'UPDATE_CREW_POINTS' ||
		action.type === 'SET_MAIN_OBJECTIVE' ||
		action.type === 'REMOVE_MAIN_OBJECTIVE' ||
		action.type === 'START_TIMER' ||
		action.type === 'REMOVE_MAP_OBJECT' ||
		action.type === 'ADD_MAP_OBJECT' ||
		action.type === 'MAP_CHANGE' ||
		action.type === 'END_MISSION' ||
		action.type === 'DISPLAY_CURRENT_SCREEN_TIMER' ||
		action.type === 'ACTIVATE_STATION' ||
		action.type === 'UPDATE_HEALTH_TASK_STATUS' ||
		action.type === 'DEACTIVATE_STATION' ||
		action.type === 'NAVIGATION_SCREEN' ||
		action.type === 'CULMINATING_MOMENT_SCREEN' ||
		action.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN' ||
		action.type === 'MAP_SCREEN' ||
		action.type === 'QUESTION_SLOT_SCREEN' ||
		action.type === LITERACY_EVENT.SIMULATION_ACTION_TYPE ||
		action.type === 'TOGGLE_INVESTIGATION'
	)
}

export function isEffectAction(
	action: ActionBankItem
): action is ActionBankEffectItem | ActionBankFavoriteItem {
	return (
		action.type === 'MISSION_EFFECT' ||
		action.type === 'SPECIAL_EFFECT' ||
		action.type === 'SHIP_HEALTH_CHANGE' ||
		action.type === 'SONG_CHANGE'
	)
}

export function isScreenReferenceAction(action: ActionBankItem): action is PhaseChangeItem {
	return action.type === 'PHASE_CHANGE'
}

// Screen Action only

/**
 * An Array of all the action types that will be displayed as screens
 */
export const SCREEN_ACTION_TYPES = [
	'IMAGE_SCREEN',
	'VIDEO_SCREEN',
	'NAVIGATION_SCREEN',
	'CULMINATING_MOMENT_SCREEN',
	'COLLABORATIVE_CULMINATING_MOMENT_SCREEN',
	'MAP_SCREEN',
	'QUESTION_SLOT_SCREEN',
]

export function isScreenActionType(type: string): boolean {
	return Boolean(SCREEN_ACTION_TYPES.includes(type))
}

function getDraggableTypeFromActionType(type: ActionType): DraggableType {
	if (
		type === VIDEO ||
		type === IMAGE ||
		type === 'NAVIGATION_SCREEN' ||
		type === 'CULMINATING_MOMENT_SCREEN' ||
		type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN' ||
		type === 'MAP_SCREEN' ||
		type === 'QUESTION_SLOT_SCREEN'
	)
		return DraggableType.SCREEN_TYPE
	return DraggableType.ACTION_TYPE
}

// ################### ADDING META DATA TO DRAGGABLE ACTIONS ################### //

export function createDraggableItem(actionBankItem: ActionBankItem): DraggableItem {
	const { type, display } = actionBankItem
	let buildMeta: ActionMeta

	if (isMediaAction(actionBankItem)) {
		const item: ActionBankMediaItem = actionBankItem
		buildMeta = {
			type: item.type as MediaActionType,
			url: item.url,
		}
	} else if (isEffectAction(actionBankItem)) {
		const item: ActionBankEffectItem | ActionBankFavoriteItem = actionBankItem

		if (item.type === 'SONG_CHANGE') {
			buildMeta = {
				type: 'SONG_CHANGE',
				url: ('url' in item && item.url) || undefined,
			}
		} else if (item.type === 'MISSION_EFFECT') {
			buildMeta = {
				type: 'MISSION_EFFECT',
				effectId: item.effectId,
			}
		} else if (item.type === 'SPECIAL_EFFECT') {
			buildMeta = {
				type: 'SPECIAL_EFFECT',
				effectId: item.effectId,
			}
		} else if (item.type === 'SHIP_HEALTH_CHANGE') {
			buildMeta = {
				type: 'SHIP_HEALTH_CHANGE',
			}
		} else {
			throw new Error('Invalid Effect Action')
		}
	} else if (isOtherAction(actionBankItem)) {
		buildMeta = {
			type: actionBankItem.type as OtherActionType,
		}
	} else if (isScreenReferenceAction(actionBankItem)) {
		buildMeta = {
			type: SCREEN_REFERENCE,
			referencedId: actionBankItem.actionId,
		}
	} else {
		throw new Error(
			`Could not create draggable item from action bank item ${JSON.stringify(actionBankItem)}`
		)
	}

	return {
		type: getDraggableTypeFromActionType(type),
		title: display,
		meta: buildMeta,
	}
}

// ################################### STATE MANAGEMENT ################################## //
// Constants:
export const VIDEO_OVERLAY = 'VIDEO_OVERLAY'
export const VIDEO_SCREEN = 'VIDEO_SCREEN'
export const IMAGE_OVERLAY = 'IMAGE_OVERLAY'
export const IMAGE_SCREEN = 'IMAGE_SCREEN'
export const SCREEN_OVERLAY_TYPES = [VIDEO_OVERLAY, VIDEO_SCREEN, IMAGE_OVERLAY, IMAGE_SCREEN]

// Default Constants for Action Creators:

export const ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT = 3 * ONE_SECOND
export const TIME_LENGTH_FOR_UNKNOWN_SPECIAL_EFFECT = 4 * ONE_SECOND
export const TIME_LENGTH_FOR_REFERENCING_ACTION = 30 * ONE_SECOND
export const DEFAULT_TIME_LENGTH_FOR_IMAGE_SCREEN = 30 * ONE_SECOND
export const DEFAULT_TIME_LENGTH_FOR_LOOPING_VIDEO = 35 * ONE_SECOND
export const DEFAULT_TIME_LENGTH_FOR_NAVIGATION_SCREEN = 3 * ONE_MINUTE
export const DEFAULT_TIME_LENGTH_FOR_MAP_SCREEN = 3 * ONE_MINUTE
export const DEFAULT_TIME_LENGTH_FOR_CULMINATING_MOMENT_SCREEN = 2 * ONE_MINUTE
export const DEFAULT_TIME_LENGTH_FOR_QUESTION_SLOT_SCREEN = 2 * ONE_MINUTE
export const DEFAULT_FINAL_FRAME_DURATION = 0
// Tractor Beam Plus
export const DEFAULT_TRACTOR_BEAM_TARGET_SIZE = 100
export const DEFAULT_TRACTOR_BEAM_TARGET_POINT_REWARD = 150
export const DEFAULT_TRACTOR_BEAM_TARGET_COLLECTION_COUNT = 5
// Defense Plus
export const DEFAULT_DEFENSE_TARGET_SIZE = 20
export const DEFAULT_DEFENSE_TARGET_POINT_REWARD = 450
// upgrade 1 and upgrade 2 both do 20 and 25 damage respectively as defined in mission-toolkit.DEFENSE_STATION.UPGRADES.
// With a value of 60 here, upgrades 1 and 2 will both destroy a target in 2 hits
export const DEFAULT_DEFENSE_TARGET_HP = 60
export const DEFAULT_DEFENSE_TARGET_DESTRUCTION_COUNT = 2

const DEFAULT_COLLABORATIVE_CULMINATING_MOMENT_REQUIRED_PERCENT_FOR_SUCCESS = 50

/**
 * Gets all shared data for a new action, i.e. all fields that are required on all actions
 */
export function getSharedActionData(simulationId: string): {
	_id: string
	createdForSimulationId: string
} {
	return {
		_id: createTemporaryId(),
		createdForSimulationId: simulationId,
	}
}

/**
 * Creates an initial action given an object which could contain the action type, media url, or effectId (This meta data is also defined in PhaseCreator/types.js )
 * It is assumed that these actions will be added to the Actions map on an AutomatedSimulation
 * @param {{ actionType?: string, url?: string }} actionMeta
 */
export function createAction(
	actionMeta: ActionMeta,
	simulationId: string,
	isJunior: boolean
): Action<string> | null | undefined {
	switch (actionMeta.type) {
		case 'VIDEO':
			return actionDefinitions.VIDEO_OVERLAY.createAction({ simulationId, url: actionMeta.url })
		case 'IMAGE':
			return actionDefinitions.IMAGE_OVERLAY.createAction({ simulationId, url: actionMeta.url })
		case 'SPECIAL_EFFECT':
			if (!actionMeta.effectId) return null
			return actionDefinitions.SPECIAL_EFFECT.createAction({
				simulationId,
				effectId: actionMeta.effectId,
			})
		case 'MISSION_EFFECT':
			if (!actionMeta.effectId) return null
			return actionDefinitions.MISSION_EFFECT.createAction({
				simulationId,
				effectId: actionMeta.effectId as MissionEffect,
			})
		case 'VOCAL_TRACK':
			return actionDefinitions.VOCAL_TRACK.createAction({ simulationId, url: actionMeta.url })
		case 'ACTIVATE_STATION':
			return actionDefinitions.ACTIVATE_STATION.createAction({
				simulationId,
				isJunior,
			})
		case 'SONG_CHANGE':
			return actionDefinitions.SONG_CHANGE.createAction({
				simulationId,
				url: actionMeta.url || null,
			})
		// This is probably only necessary because of an inaccurate TS type
		case SCREEN_REFERENCE:
			return null
		default: {
			return actionDefinitions[actionMeta.type].createAction({ simulationId })
		}
	}
}

/**
 * Creates a screen action given an object which could contain the action type and media url. (This meta data is also defined in PhaseCreator/types.js )
 * It is assumed that these actions will be added to the Actions map on an AutomatedSimulation
 * @param {ActionMeta} actionMeta
 */
export function createScreen(
	actionMeta: ActionMeta,
	simulationId: string
): ScreenAction<string> | undefined | null {
	switch (actionMeta.type) {
		case 'VIDEO':
			return actionDefinitions.VIDEO_SCREEN.createAction({
				simulationId,
				url: actionMeta.url,
			}) as ScreenAction<string>
		case 'IMAGE': {
			return actionDefinitions.IMAGE_SCREEN.createAction({
				simulationId,
				url: actionMeta.url,
			}) as ScreenAction<string>
		}
		case 'NAVIGATION_SCREEN':
			return actionDefinitions.NAVIGATION_SCREEN.createAction({
				simulationId,
			}) as ScreenAction<string>
		case 'CULMINATING_MOMENT_SCREEN':
			return actionDefinitions.CULMINATING_MOMENT_SCREEN.createAction({
				simulationId,
			}) as ScreenAction<string>
		case 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN':
			return actionDefinitions.COLLABORATIVE_CULMINATING_MOMENT_SCREEN.createAction({
				simulationId,
			}) as ScreenAction<string>
		case 'MAP_SCREEN':
			return actionDefinitions.MAP_SCREEN.createAction({ simulationId }) as ScreenAction<string>
		case 'QUESTION_SLOT_SCREEN':
			return actionDefinitions.QUESTION_SLOT_SCREEN.createAction({
				simulationId,
			}) as ScreenAction<string>
		default:
			throw new Error('action type was not found')
	}
}

/**
 * @returns A default target for the DEFENSE_PLUS station
 */
export function getDefaultDefenseTarget(): DefensePlusTarget<string> {
	return {
		_id: createTemporaryId(),
		initialHp: DEFAULT_DEFENSE_TARGET_HP,
		countPerStudent: 1,
		pointReward: DEFAULT_DEFENSE_TARGET_POINT_REWARD,
		requiredDestroyCountPerStudent: DEFAULT_DEFENSE_TARGET_DESTRUCTION_COUNT,
		size: { width: DEFAULT_DEFENSE_TARGET_SIZE, height: DEFAULT_DEFENSE_TARGET_SIZE },
		actionObjectInfo: {
			objectDescription: '',
			objectName: '',
		},
		media: null,
		movement: { type: DEFENSE_STATION.MOVEMENT.RANDOM },
		objectSharing: { type: DEFENSE_STATION.OBJECT_SHARING.SEPARATE },
		exitAnimation: { type: DEFENSE_STATION.EXIT_ANIMATION.FADE_OUT },
		respawn: { type: DEFENSE_STATION.RESPAWN.ALWAYS },
	}
}

/**
 * @returns A default target for the TRACTOR_BEAM_PLUS station
 */
export function getDefaultTractorBeamTarget(): TractorBeamPlusTarget<string> {
	return {
		_id: createTemporaryId(),
		countPerStudent: 1,
		pointReward: DEFAULT_TRACTOR_BEAM_TARGET_POINT_REWARD,
		requiredCollectionCountPerStudent: DEFAULT_TRACTOR_BEAM_TARGET_COLLECTION_COUNT,
		size: { width: DEFAULT_TRACTOR_BEAM_TARGET_SIZE, height: DEFAULT_TRACTOR_BEAM_TARGET_SIZE },
		actionObjectInfo: {
			objectDescription: '',
			objectName: '',
		},
		media: null,
		movement: { type: TRACTOR_BEAM_STATION.MOVEMENT.RANDOM },
		respawn: { type: TRACTOR_BEAM_STATION.RESPAWN.ALWAYS },
	}
}

/**
 * Required to appease flow for making an ACTIVATE STATION action
 * Because ActivateStation cannot start out empty, we just default to creating a
 * defense activate station action.
 */
const getDefaultStationData = (isJunior: boolean): ActivateStationData<string> => {
	if (isJunior) {
		return {
			stationId: 'DEFENSE',
			hitsPerStudent: 5,
			onComplete: [],
			objectDescription: null,
			objectName: null,
			targetType: 'TARGET',
		}
	}
	return {
		stationId: 'DEFENSE_PLUS',
		onComplete: [],
		targets: [getDefaultDefenseTarget()],
	}
}

export function getDefaultMissionPause(): PauseMissionEffectData<string> {
	return {
		effectId: 'MISSION_PAUSE',
		prompt: '',
		allowGoBack: false,
		goBackButtonText: '',
		continueButtonText: '',
		onContinue: [],
	}
}

export function getDefaultDeathAction(): DeathMissionEffectData<string> {
	return {
		effectId: 'DEATH',
		mainScreenText: 'Looks like your crew has failed the mission',
		prompt: 'Mission Failed',
		isShipDeath: false,
		goBackButtonText: 'Revive',
		continueButtonText: 'Skip Ahead',
		onCheatDeath: [],
	}
}

// ################### ACTION MODIFIERS #################### //

/**
 * Some actions need to be converted from a screen to an overlay or an overlay to a screen.
 * This function checks the action and its type, and then returns the converted action correctly.
 * @param {$ReadOnly<Action>} simAction
 * @param {boolean} convertToScreen
 */
export function convertActionIfNecessary(
	simAction: Action<string>,
	convertToScreen: boolean
): Action<string> | undefined {
	const actionId = simAction._id
	if (simAction.type === 'VIDEO_SCREEN' && !convertToScreen) {
		return {
			type: 'VIDEO_OVERLAY',
			onStart: simAction.onStart,
			onEnd: simAction.onEnd,
			atTime: simAction.atTime,
			url: simAction.url,
			_id: actionId,
			createdForSimulationId: simAction.createdForSimulationId,
		}
	} else if (simAction.type === 'IMAGE_SCREEN' && !convertToScreen) {
		return {
			type: 'IMAGE_OVERLAY',
			duration: simAction.timeLimit,
			onStart: simAction.onStart,
			onEnd: simAction.onEnd,
			atTime: simAction.atTime,
			url: simAction.url,
			_id: actionId,
			createdForSimulationId: simAction.createdForSimulationId,
		}
	} else if (simAction.type === 'VIDEO_OVERLAY' && convertToScreen) {
		return {
			type: 'VIDEO_SCREEN',
			looping: false,
			finalFrameDuration: DEFAULT_FINAL_FRAME_DURATION,
			onStart: simAction.onStart,
			onEnd: simAction.onEnd,
			atTime: simAction.atTime,
			url: simAction.url,
			_id: actionId,
			createdForSimulationId: simAction.createdForSimulationId,
		}
	} else if (simAction.type === 'IMAGE_OVERLAY' && convertToScreen) {
		return {
			type: 'IMAGE_SCREEN',
			timeLimit: simAction.duration,
			onStart: simAction.onStart,
			onEnd: simAction.onEnd,
			atTime: simAction.atTime,
			url: simAction.url,
			_id: actionId,
			createdForSimulationId: simAction.createdForSimulationId,
		}
	}
}

/**
 * When certain properties are changed on an action, that sometimes requires adding additonal properties
 * or removing some properties. This function checks the property being changed and/or the newValue
 * and returns a new action with all of the necessary fields.
 */

export function createModifiedAction(
	newValue: unknown,
	property: string,
	action: Action<string>
): Action<string> {
	if (
		property === 'type' &&
		(action.type === 'VIDEO_OVERLAY' ||
			action.type === 'IMAGE_OVERLAY' ||
			action.type === 'IMAGE_SCREEN' ||
			action.type === 'VIDEO_SCREEN')
	) {
		const convertToScreen = newValue === 'IMAGE_SCREEN' || newValue === 'VIDEO_SCREEN'
		const convertedAction = convertActionIfNecessary(action, convertToScreen)
		if (convertedAction) return convertedAction
	}

	// Remove onCheatDeath, prompt, allowGoBack, continueButtonText, goBackButtonText, onContinue
	// isShipDeath and mainScreenText from the action, because we know the new effectId is
	// not `DEATH` or 'MISSION_PAUSE, so the new action does not need the `onCheatDeath` handlers.
	if (property === 'effectId' && action.type === 'MISSION_EFFECT') {
		const {
			// @ts-expect-error - potential property needs to be removed
			onCheatDeath,
			// @ts-expect-error - potential property needs to be removed
			prompt,
			// @ts-expect-error - potential property needs to be removed
			allowGoBack,
			// @ts-expect-error - potential property needs to be removed
			continueButtonText,
			// @ts-expect-error - potential property needs to be removed
			goBackButtonText,
			// @ts-expect-error - potential property needs to be removed
			onContinue,
			// @ts-expect-error - potential property needs to be removed
			isShipDeath,
			// @ts-expect-error - potential property needs to be removed
			mainScreenText,
			...newAction
		} = action

		if (newValue === 'DEATH')
			return { ...newAction, ...getDefaultDeathAction(), [property]: newValue }
		else if (newValue === 'MISSION_PAUSE')
			return { ...newAction, ...getDefaultMissionPause(), [property]: newValue }
		else if (newValue === 'PAUSE' || newValue === 'UNPAUSE') {
			return { ...newAction, [property]: newValue }
		} else {
			throw new Error(`newValue should only be an expected value. Got ${newValue}`)
		}
	}

	// If we switch the value of looping on a VIDEO_SCREEN, we need to modified the properties on the action
	if (action.type === VIDEO_SCREEN && property === 'looping') {
		if (!action.looping && newValue) {
			// set to looping
			// eslint-disable-next-line no-unused-vars
			const { finalFrameDuration, ...videoScreen } = action
			return { ...videoScreen, looping: true, timeLimit: DEFAULT_TIME_LENGTH_FOR_LOOPING_VIDEO }
		} else if (action.looping && !newValue) {
			// set to *not* looping
			// eslint-disable-next-line no-unused-vars
			const { timeLimit, ...videoScreen } = action
			return { ...videoScreen, looping: false, finalFrameDuration: DEFAULT_FINAL_FRAME_DURATION }
		}
	}

	if (action.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN' && property === 'ending') {
		const newAction: CollaborativeCulminatingMomentAction<string> = {
			...action,
			// @ts-expect-error We expect `newValue` to be valid
			[property]: newValue,
		}
		if (
			newAction.ending === COLLABORATIVE_CULMINATING_MOMENT.ENDING.ALL_RESPONSES ||
			newAction.ending === COLLABORATIVE_CULMINATING_MOMENT.ENDING.EACH_RESPONSE
		) {
			newAction.requiredPercentForSuccess ??=
				DEFAULT_COLLABORATIVE_CULMINATING_MOMENT_REQUIRED_PERCENT_FOR_SUCCESS
			if ('onVoteStart' in newAction) {
				delete newAction.onVoteStart
			}
		} else if (newAction.ending === COLLABORATIVE_CULMINATING_MOMENT.ENDING.CLASS_VOTE) {
			newAction.onVoteStart ??= []
			if ('requiredPercentForSuccess' in newAction) {
				delete newAction.requiredPercentForSuccess
			}
		}
		return newAction
	}

	return {
		...action,
		[property]: newValue,
	}
}

// ################### ACTION DURATION MANAGEMENT #################### //

/**
 * Gets the duration for the given url from the `durationMap`
 * @param url The url to get the duration for
 * @param durationMap The duration map to get the duration from
 * @returns The duration in milliseconds for the given url, or null if the url is not in the durationMap
 */
function getDurationForUrl(url: string, durationMap: DurationMap): number | null {
	return durationMap.url[url] ?? null
}

/**
 * The `durationMap` needs to be populated with the urls that need the duration fetched.
 * This function adds `null` to the `durationMap` for the given url if it does not already exist.
 * @param url The url to add to the `durationMap`
 * @param durationMap
 */
function initializeDurationMapForUrl(url: string, durationMap: DurationMap): void {
	if (!durationMap.url[url]) {
		durationMap.url[url] = null
	}
}

/**
 * Gets the duration for the given `specialEffectId` from the `durationMap`
 * @param specialEffectId The special effect id to get the duration for
 * @param durationMap The duration map to get the duration from
 * @returns The duration in milliseconds for the given special effect id, or null if
 * the special effect id is not in the durationMap
 */
function getDurationForSpecialEffectId(
	specialEffectId: string,
	durationMap: DurationMap
): number | null {
	return durationMap.specialEffectId[specialEffectId] ?? null
}

/**
 * The `durationMap` needs to be populated with the special effect ids that need the duration fetched.
 * This function adds `null` to the `durationMap` for the given special effect id if it does not already exist.
 * @param specialEffectId  The special effect id to add to the `durationMap`
 * @param durationMap
 */
function initializeDurationMapForSpecialEffectId(
	specialEffectId: string,
	durationMap: DurationMap
): void {
	if (!durationMap.specialEffectId[specialEffectId]) {
		durationMap.specialEffectId[specialEffectId] = null
	}
}

/**
 * Given an action and a durationMap of urls to timeLengths in milliseconds,
 * determine the length in milliseconds of the given action. If there is no time limit set on the action, this function returns
 * an arbitrary time length of 3 seconds
 * @param {*} action
 * @param {*} durationMap
 */
export function getActionTimeLength(action: Action<string>, durationMap: DurationMap): number {
	return (
		actionDefinitions[action.type].getDuration?.(
			// @ts-expect-error - we used `action.type` to get the `getDuration` function, so passing `action` is correct
			action,
			durationMap
		) ?? ARBITRARY_TIME_LENGTH_FOR_INSTANT_EVENT
	)
}
