import React, { Component } from 'react'
import { Progress } from 'reactstrap'
import { humanFileSize } from '../../helpers/functions'
import styles from './Uploads.module.css'
import { RcFile } from 'rc-upload/lib/interface'
export type Upload = {
	file: RcFile
	status: 'start' | 'progress' | 'finish' | 'fail' | 'cancel'
	percent: number | undefined
}
type Props = {
	uploads: Upload[]
	onCloseClick: () => void
	onClearUpload: (file: RcFile) => void
	onCancelUpload: (file: RcFile) => void
}
export default class FolderSelector extends Component<Props> {
	render(): JSX.Element {
		const { uploads, onCloseClick, onClearUpload, onCancelUpload } = this.props

		const renderProgress = (status: string, percent: number | undefined) => {
			if (status === 'finish') {
				return <Progress value={100} color="success" />
			}

			if (status === 'fail') {
				return <Progress value={percent} color="danger" />
			}

			if (status === 'cancel') {
				return <Progress value={percent} color="warning" />
			}

			return <Progress value={percent} animated />
		}

		const renderClearOrCancel = (upload: Upload) => {
			if (upload.status === 'start' || upload.status === 'progress') {
				return (
					<button
						type="button"
						className="btn btn-primary btn-sm"
						onClick={() => onCancelUpload(upload.file)}>
						Cancel
					</button>
				)
			}

			return (
				<button
					type="button"
					className="btn btn-primary btn-sm"
					onClick={() => onClearUpload(upload.file)}>
					Clear
				</button>
			)
		}

		return (
			<div className={styles.container}>
				<div className={styles.content}>
					<div className={styles.close} onClick={onCloseClick}>
						&times;
					</div>
					<h4 className={styles.title}>Uploads</h4>
					<table className="table">
						<thead>
							<tr>
								<th scope="col">File</th>
								<th scope="col">Progress</th>
								<th scope="col">Size</th>
								<th scope="col">Clear/Cancel</th>
							</tr>
						</thead>
						<tbody>
							{uploads.map((upload, index: number) => (
								<tr key={String(index)}>
									<td>{upload.file.name}</td>
									<td>{renderProgress(upload.status, upload.percent)}</td>
									<td>{humanFileSize(upload.file.size)}</td>
									<td>{renderClearOrCancel(upload)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}
