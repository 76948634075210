import type {
	LiteracyEventReadingContext,
	LiteracyEventTaskList,
	LiteracyEventTask,
	LiteracyEventMultipleChoiceTask,
	LiteracyEventSpeakingTask,
} from '@mission.io/mission-toolkit/actions'
import { RELEVANCE_MAX } from '../../../../maps/Map/MapObjects/MapObjectForm/RelevanceSelector'
import { createTemporaryId } from '../../../../../helpers/functions'
import { ONE_SECOND } from '../../../../../helpers/constants'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'

// Creates a new reading context for a literacy event.
export function getNewReadingContext(): LiteracyEventReadingContext<string> {
	const defaultTaskList = getNewTaskList()
	defaultTaskList.list.push(getNewTask(LITERACY_EVENT.TASK.TYPE.SPEAKING))
	return {
		id: createTemporaryId(),
		text: '',
		title: '',
		relevance: RELEVANCE_MAX,
		media: [],
		taskLists: [defaultTaskList],
	}
}

// Creates a new task list
export function getNewTaskList(): LiteracyEventTaskList<string> {
	return {
		list: [],
	}
}

/**
 * Creates a new multiple choice option.
 */
export const createMultipleChoiceOption: (
	text: string,
	correct?: boolean
) => {
	id: string
	text: string
	correct: boolean
	// An explanation to the user of why this option is correct/incorrect
	explanation?: string
} = (text, correct = false) => ({
	id: createTemporaryId(),
	text,
	correct,
})

// Defines the tasks that are currently supported. Provides a function 'getDefault' that returns a default task.
const SUPPORTED_TASK_TYPES = {
	// TODO: Support literacy event close reading task
	[LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE]: {
		type: LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE,
		getDefault: () =>
			({
				id: createTemporaryId(),
				prompt: 'Make a decision',
				type: LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE,
				options: [
					createMultipleChoiceOption('Decision A'),
					createMultipleChoiceOption('Decision B'),
				],
			} as LiteracyEventMultipleChoiceTask<string>),
	},
	[LITERACY_EVENT.TASK.TYPE.SPEAKING]: {
		type: LITERACY_EVENT.TASK.TYPE.SPEAKING,
		getDefault: () =>
			({
				id: createTemporaryId(),
				prompt: 'Discuss the reading with your team.',
				type: LITERACY_EVENT.TASK.TYPE.SPEAKING,
				timeBeforeAllowingCompletion: 30 * ONE_SECOND,
				target: {
					type: LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.NONE,
				},
			} as LiteracyEventSpeakingTask<string>),
	},
}

// Checks if the provided task type is currently supported in sim creator
export function isTaskTypeSupported(type: string): type is keyof typeof SUPPORTED_TASK_TYPES {
	return type in SUPPORTED_TASK_TYPES
}

// Creates a default task.
export function getNewTask(type: string): LiteracyEventTask<string> {
	if (isTaskTypeSupported(type)) {
		return SUPPORTED_TASK_TYPES[type].getDefault()
	} else {
		throw new Error('Unsupported literacy event task type ' + type)
	}
}
