import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { split, capitalize } from 'lodash'
import { Label, Input } from 'reactstrap'
import {
	ALL,
	NONE,
	AUTOMATED_SIMULATION_MEDIA,
	COLLECTIONS_URL,
	IMAGE,
	VIDEO,
	VOCAL_TRACK,
} from './constants'
import { searchActionBankItems } from './common/functions'
import ActionContainer from './ActionContainer'
import Spinner from './common/Spinner'
import type { Category, ActionBankMediaItem, MediaActionType } from '../actionDefinitions'
import type { Directory } from '../../../common/AssetManager/AssetManager'
import type { File } from '../../../common/AssetManager/FileDetails'
type ActionContainerProps = {
	category: Category
}
const VIDEO_TYPES = ['mp4', 'm4v']
const VOCAL_TRACK_TYPES = ['mp3', 'wav']
export default function MediaActionContainer({ category }: ActionContainerProps): JSX.Element {
	const [allFiles, setAllFiles] = useState<ActionBankMediaItem[] | null>(null)
	const [gradeToFile, setGradeToFile] = useState<Record<string, ActionBankMediaItem[]> | null>(null)
	const [gradeToMissionToFile, setGradeToMissionToFile] = useState<Record<
		string,
		Record<string, ActionBankMediaItem[]>
	> | null>(null)
	const [mediaDirectory, setMediaDirectory] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [grade, setGrade] = useState(ALL)
	const [mission, setMission] = useState(NONE)
	const [search, setSearch] = useState('')
	const [type, setType] = useState(ALL)

	/**
	 * Loads all media
	 */
	useEffect(() => {
		const loadCollectionData = async () => {
			setLoading(true)

			try {
				setError(null)
				const res = await axios.get(`${COLLECTIONS_URL}/${AUTOMATED_SIMULATION_MEDIA}`)
				setMediaDirectory(res.data.root)
				const data: {
					gradeToFile: Record<string, ActionBankMediaItem[]>
					gradeToMissionToFile: Record<string, Record<string, ActionBankMediaItem[]>>
					allFiles: ActionBankMediaItem[]
				} = parseData(res.data.root)
				setGradeToFile(data.gradeToFile)
				data.gradeToMissionToFile[ALL] = {}
				setGradeToMissionToFile(data.gradeToMissionToFile)
				setAllFiles(data.allFiles)
			} catch (err) {
				setError(`Error loading media: ${err}`)
			} finally {
				setLoading(false)
			}
		}

		if (!mediaDirectory && !error && !loading) loadCollectionData()
	}, [mediaDirectory, loading, error])

	/** Unset the mission when the grade is changed and mission is no longer in the grade */
	useEffect(() => {
		if (
			gradeToMissionToFile &&
			grade !== ALL &&
			!Object.keys(gradeToMissionToFile[grade]).includes(mission)
		) {
			setMission(NONE)
		}
	}, [gradeToMissionToFile, grade, mission])
	const viewItems = useMemo(() => {
		if (gradeToFile && gradeToMissionToFile && allFiles && mission) {
			let filteredItems

			if (grade === ALL) {
				filteredItems = filterByType(type, allFiles)
			} else {
				if (Object.keys(gradeToMissionToFile[grade]).includes(mission)) {
					filteredItems = filterByType(type, gradeToMissionToFile[grade][mission])
				} else {
					filteredItems = filterByType(type, gradeToFile[grade])
				}
			}

			if (search.length > 0) {
				filteredItems = searchActionBankItems(search, filteredItems)
			}

			return filteredItems
		}

		return []
	}, [gradeToFile, gradeToMissionToFile, allFiles, grade, mission, search, type])
	return (
		<div css="display: flex; flex-direction: column; height: 100%;">
			{loading ? (
				<Spinner />
			) : (
				<div className="w-100">
					{gradeToMissionToFile && (
						<HeaderRow>
							<div>
								<Label className="mb-0">Grade</Label>
								<Input
									type="select"
									value={grade}
									onChange={(e) => setGrade(e.currentTarget.value)}>
									{Object.keys(gradeToMissionToFile).map((gradeName) => (
										<option value={gradeName} key={gradeName}>
											{capitalize(gradeName)}
										</option>
									))}
								</Input>
							</div>
							<div>
								<Label className="mb-0">Mission</Label>
								<Input
									type="select"
									value={mission}
									onChange={(e) => setMission(e.currentTarget.value)}>
									<option value={NONE}>None</option>
									{Object.keys(gradeToMissionToFile[grade]).map((missionName) => (
										<option value={missionName} key={missionName}>
											{missionName}
										</option>
									))}
									)
								</Input>
							</div>
							<div>
								<Label className="mb-0">Type</Label>
								<Input type="select" value={type} onChange={(e) => setType(e.currentTarget.value)}>
									<option value={ALL}>All</option>
									<option value={VIDEO}>Video</option>
									<option value={VOCAL_TRACK}>Vocal Track</option>
									<option value={IMAGE}>Image</option>)
								</Input>
							</div>
							<div>
								<Label className="mb-0">Search</Label>
								<Input
									value={search}
									type="text"
									placeholder="Search..."
									onChange={(e) => setSearch(e.currentTarget.value)}
								/>
							</div>
						</HeaderRow>
					)}
					{error && <div>{error}</div>}
				</div>
			)}
			<div css="flex: 1">
				<ActionContainer category={category} items={viewItems} />
			</div>
		</div>
	)
}
/**
 * This function should only be called on the automatedSimulationMedia file
 * because we are expecting a certain file structure that the AssetManager modal requires when the collection type is automatedSimulationMedia
 *
 */

function parseData(item: Directory): {
	gradeToFile: Record<string, ActionBankMediaItem[]>
	gradeToMissionToFile: Record<string, Record<string, ActionBankMediaItem[]>>
	allFiles: ActionBankMediaItem[]
} {
	const gradeToFile: Record<string, ActionBankMediaItem[]> = {}
	const gradeToMissionToFile: Record<string, Record<string, ActionBankMediaItem[]>> = {}
	const allFiles: ActionBankMediaItem[] = []
	item.contents.forEach((grade) => {
		if (grade.type === 'file') {
			addFile(grade, allFiles)
		} else {
			const gradeName = grade.name
			gradeToFile[gradeName] = []
			gradeToMissionToFile[gradeName] = {}
			grade.contents.forEach((mission) => {
				if (mission.type === 'file') {
					addFile(mission, gradeToFile[gradeName], allFiles)
				} else {
					const missionFiles: ActionBankMediaItem[] = []
					mission.contents.forEach((missionFile) => {
						if (missionFile.type === 'file') {
							addFile(missionFile, missionFiles, allFiles)
						}
					})
					gradeToMissionToFile[gradeName][mission.name] = missionFiles
				}
			})
		}
	})
	return {
		gradeToFile: gradeToFile,
		gradeToMissionToFile: gradeToMissionToFile,
		allFiles: allFiles,
	}
}

function addFile(file: File, itemList: ActionBankMediaItem[], allFiles?: ActionBankMediaItem[]) {
	if (!file.name) return
	const fileType = split(file.name, '.').slice(-1)[0]
	let type: MediaActionType
	if (VIDEO_TYPES.includes(fileType)) type = VIDEO
	else if (VOCAL_TRACK_TYPES.includes(fileType)) type = VOCAL_TRACK
	else type = IMAGE
	const newItem: ActionBankMediaItem = {
		type: type,
		display: file.name,
		url: file.url,
	}
	itemList.push(newItem)
	if (allFiles) allFiles.push(newItem)
}

function filterByType(type: string, items: ActionBankMediaItem[]) {
	if (type === ALL) return items
	else return items.filter((item) => item.type === type)
}

const HeaderRow = styled.div`
	display: grid;
	grid-template-columns: 15% 15% 15% 55%;
	grid-column-gap: 5px;
	padding: 0 12px 10px 7px;
	font-size: 0.85rem;
`
