import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { Input, Button } from 'reactstrap'
import Markdown from './Markdown'
import type { CustomComponent, CustomComponentsMap } from './Markdown'

export type CustomComponentDescriptor = {
	component: CustomComponent
	token?: string
	description: string
}

export type CustomComponentDescriptors = {
	[componentMarker: string]: CustomComponentDescriptor
}

/**
 * MarkDownInput - A component used to input and preview markdown
 *
 * @param {Object} props - the react props
 * @param {string} props.value - the current value of the markdown input
 * @param {(newValue: string) => void} props.onChange - a callback for when the value has changed
 * @param {?string} props.placeholder? - what should be displayed when there is no value
 * @param {?className} props.className? - used to style this component
 * @param {?boolean} props.previewVertical? - used to put the input and preview on top of each other
 * @param {?Array<string>} props.disabledComponents? - a list of components which should not be rendered by the markdown renderer
 * @param {?string} props.id? - the id the text input should have
 * @param {?CustomComponentDescriptors} props.customComponents? - a mapping of the componentName to what the component should render
 * @param {?(value: string) => string} props.preparser - a preparser used to convert custom syntax into a syntax that the markdown renderer can understand
 *
 * @return {React$Node}
 */

export default function MarkDownInput({
	value,
	onChange,
	placeholder,
	className,
	previewVertical,
	disabledComponents,
	id,
	customComponents,
	preparser,
}: {
	value: string
	onChange: (newValue: string) => void
	placeholder?: string | undefined
	className?: string
	previewVertical?: boolean
	disabledComponents?: string[]
	id?: string
	customComponents?: CustomComponentDescriptors
	preparser?: ((value: string) => string) | null
}): JSX.Element {
	const [isEditing, setIsEditing] = useState(false)
	const [showPreview, setShowPreview] = useState(false)

	const togglePreview = () => setShowPreview((state) => !state)

	const allowEditing = () => {
		if (!isEditing) {
			setIsEditing(true)
		}
	}

	const transformedValue = preparser ? preparser(value) : value
	const customComponentsDescription: Array<{ token: string; description: string }> = []
	const customComponentMap: CustomComponentsMap = {}
	if (customComponents) {
		Object.keys(customComponents).forEach((componentMarker) => {
			const customComponentData = customComponents[componentMarker]
			customComponentMap[componentMarker] = customComponentData.component
			customComponentsDescription.push({
				token: customComponentData.token ?? `<${componentMarker} />`,
				description: customComponentData.description,
			})
		})
	}

	return (
		<MarkdownForm tabIndex={0} onFocus={allowEditing} onClick={allowEditing} className={className}>
			{isEditing ? (
				<>
					<MarkDownHeader>
						<div>
							This field supports{' '}
							<a
								href="https://www.markdownguide.org/basic-syntax/"
								target="_blank"
								rel="noreferrer">
								markdown
							</a>
							. To use{' '}
							<a href="https://www.overleaf.com/learn" target="_blank" rel="noreferrer">
								latex
							</a>
							, use an inline code block (<code>`lang-math ...`</code>).
						</div>
						<Button onClick={() => setIsEditing(false)} color="primary">
							Close
						</Button>
					</MarkDownHeader>
					<Legend customComponentsDescription={customComponentsDescription} />
					<MarkDownSectionHeaders $previewVertical={previewVertical}>
						<Section $sectionName="input">
							<MarkDownSectionHeader>
								Input
								{previewVertical && (
									<Button color="link" onClick={togglePreview} css="padding: 0; border: 0;">
										{showPreview ? 'Hide' : 'Show'} Preview
									</Button>
								)}
							</MarkDownSectionHeader>

							<StyledInput
								type="textarea"
								name="markdown-text"
								rows={previewVertical ? 4 : undefined}
								$horizontal={!previewVertical}
								id={id}
								value={value}
								onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
								autoFocus={true}
								placeholder={placeholder}
							/>
						</Section>
						{(showPreview || !previewVertical) && (
							<Section $sectionName="preview">
								<MarkDownSectionHeader>Preview</MarkDownSectionHeader>
								<PreviewPadding>
									<Markdown
										disabledComponents={disabledComponents}
										customComponents={customComponentMap}>
										{transformedValue}
									</Markdown>
								</PreviewPadding>
							</Section>
						)}
					</MarkDownSectionHeaders>
				</>
			) : (
				<MarkDownPlaceholder>
					<Markdown disabledComponents={disabledComponents} customComponents={customComponentMap}>
						{transformedValue || placeholder || ' '}
					</Markdown>
				</MarkDownPlaceholder>
			)}
		</MarkdownForm>
	)
}

/**
 * Legend - provides a legend explaining how to use custom syntax
 *
 * @param {Object} props - the react props
 * @param {Array<{ token: string, description: string }>} props.customComponentsDescription - a list of the custom syntax `token` and the description of what that syntax will do.
 *
 * @return {React$Node}
 */
function Legend({
	customComponentsDescription,
}: {
	customComponentsDescription: Array<{ token: string; description: string }>
}): JSX.Element | null {
	if (!customComponentsDescription.length) {
		return null
	}
	return (
		<div>
			<LegendText>This field supports the following custom syntax:</LegendText>
			<LegendList>
				{customComponentsDescription.map(({ token, description }, index) => (
					<li key={index}>
						<code>{token}</code> - <span>{description}</span>
					</li>
				))}
			</LegendList>
		</div>
	)
}

const reactStrapMinInputHeight = `min-height: calc(2.25rem + 2px); padding: 0.375rem 0.75rem;`
const MarkdownForm = styled.div`
	border: 1px solid #ced4da;
	background-color: white;
	border-radius: 4px;
	height: min-content;
`
const MarkDownHeader = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
`
const Section = styled.div<{ $sectionName: string }>`
	display: flex;
	flex-direction: column;
	grid-area: ${({ $sectionName }) => $sectionName};
`
const MarkDownSectionHeaders = styled.div<{ $previewVertical: unknown }>`
	display: grid;
	${({ $previewVertical }) =>
		$previewVertical
			? `
	grid-template-columns: 1fr;
	grid-template-areas: "input" "preview";
	`
			: `
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: "input preview";`}
`
const MarkDownSectionHeader = styled.div`
	margin-left: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
const MarkDownPlaceholder = styled.div`
	${reactStrapMinInputHeight}

	&:hover {
		cursor: text;
	}
`
const StyledInput = styled(Input)<{ $horizontal: boolean }>`
	${({ $horizontal }) =>
		$horizontal &&
		`
		flex: 1 1 0;
		resize: none;
		`}
	margin: 4px;
	width: auto;
`
const PreviewPadding = styled.div`
	padding: 8px;
	margin: 4px;
	border-radius: 4px;
	border: 1px solid #ced4da;
	${reactStrapMinInputHeight}
`

const LegendText = styled.div`
	margin-left: var(--spacing);
`

const LegendList = styled.ul`
	list-style-type: none;
`
