import React from 'react'
import _Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
// Order matters here. Our styles need to be loaded after the rc-slider styles so they can override properly.
import './RelevanceSelector.css'

const Slider = _Slider.createSliderWithTooltip(_Slider)

// The minimum and maximum for relevance
const RELEVANCE_MIN = -1
export const RELEVANCE_MAX = 2

// The marks to show on the relevance slider
const MARKS = (() => {
	const result: Record<number, number> = {}

	for (let i = RELEVANCE_MIN; i <= RELEVANCE_MAX; i++) {
		result[i] = i
	}

	return result
})()

// The tooltips to show for each value in the relevance range
const relevanceTooltips = {
	'-1': "-1: Unimportant and a distraction. Student's should downvote this data",
	'0': '0: Unimportant to the mission. This data will not count towards the critical thinking score',
	'1': '1: Somewhat important',
	'2': '2: Very important',
}
export default function RelevanceSelector({
	value,
	onChange,
	...rest
}: {
	value: number
	onChange: (arg0: number) => void
	name?: string
}): JSX.Element {
	return (
		<Slider
			{...rest}
			min={RELEVANCE_MIN}
			max={RELEVANCE_MAX}
			value={value}
			onChange={onChange}
			tipProps={{
				placement: 'bottom',
			}}
			tipFormatter={(value) =>
				relevanceTooltips[String(value) as keyof typeof relevanceTooltips] || value
			}
			marks={MARKS}
		/>
	)
}
