import React from 'react'
import { Field, FieldArrayFieldsProps } from 'redux-form'
import { Col, Button, FormGroup, Label } from 'reactstrap'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components'
import './StandardList.css'
import { AutomatedSimulation } from '../types/AutomatedSimulation'

/**
 * VocabularyList - a redux form component used to edit the vocabulary list for a simulation, should be used with
 * the FieldArray component from `redux-form`
 *
 * @return {React$Node}
 */

export default function VocabularyList({
	fields: { length, push, map },
}: {
	fields: FieldArrayFieldsProps<AutomatedSimulation['suggestedVocabulary'][number]>
}): JSX.Element {
	return (
		<div className="StandardList">
			{length === 0 && (
				<p className="my-1">
					<i>No Vocabulary found.</i>
				</p>
			)}

			{length > 0 &&
				map((fieldName, index, { remove }) => {
					const word = fieldName + '.word'
					const definition = fieldName + '.definition'
					return (
						<div key={fieldName} className="bg-light rounded shadow p-3 mb-3">
							<FormGroup row>
								<Col sm={11}>
									<FormGroup row>
										<Label for={word} sm={2}>
											Word
										</Label>
										<Col sm={10}>
											<Field id={word} name={word} component="input" className="form-control" />
										</Col>
									</FormGroup>

									<FormGroup row>
										<Label for={definition} sm={2}>
											Definition
										</Label>
										<Col sm={10}>
											<Field
												id={definition}
												name={definition}
												component="input"
												className="form-control"
											/>
										</Col>
									</FormGroup>
								</Col>
								<ColumnCenter sm={1}>
									<Button
										className="float-right"
										color="danger"
										size="sm"
										onClick={() => remove(index)}>
										<FontAwesome name="trash" />
									</Button>
								</ColumnCenter>
							</FormGroup>
						</div>
					)
				})}

			<button
				className="add-choice"
				onClick={() =>
					push({
						word: '',
						definition: '',
					})
				}>
				Add
			</button>
		</div>
	)
}
const ColumnCenter = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
`
