export default class KeyManager {
	keysPressed: Record<string, boolean>
	mountedOnPress: Record<string, Array<() => void>>

	constructor() {
		this.keysPressed = {}
		this.mountedOnPress = {}
	}

	handleKeyPress: (event: KeyboardEvent) => void = (event) => {
		this.keysPressed[event.key] = true
		const mountedFunctions = this.mountedOnPress[event.key]

		if (mountedFunctions) {
			mountedFunctions.forEach((method) => method())
		}
	}
	handleKeyRelease: (event: KeyboardEvent) => void = (event) => {
		delete this.keysPressed[event.key]
	}
	isKeyPressed: (key: string) => boolean = (key: string) => {
		return Boolean(this.keysPressed[key])
	}

	mountOnPress(key: string, method: () => void) {
		if (this.mountedOnPress[key]) {
			this.mountedOnPress[key].push(method)
		} else {
			this.mountedOnPress[key] = [method]
		}
	}

	/**
	 * startListening - start listening for key presses
	 */
	startListening() {
		window.addEventListener('keydown', this.handleKeyPress)
		window.addEventListener('keyup', this.handleKeyRelease)
	}

	/**
	 * stopListening - stop listening for key presses
	 */
	stopListening() {
		window.removeEventListener('keydown', this.handleKeyPress)
		window.removeEventListener('keyup', this.handleKeyRelease)
	}

	/**
	 * clearCallbacks - clear all callbacks for key stroke events
	 */
	clearCallbacks() {
		this.mountedOnPress = {}
	}
}
