import React from 'react'
import { config } from '../config'
import axios from 'axios'
import type { TileParams } from '../types/tiles'
import { IoIosFlask, IoIosCalculator, IoIosPulse, IoIosPlanet } from 'react-icons/io'
import { GoGlobe } from 'react-icons/go'
import { FaRegGem, FaBug } from 'react-icons/fa'

export const BADGE_ICON_MAP = {
	SCIENCE: <IoIosFlask title="Science" />,
	STRAND: <IoIosPlanet title="Science" />,
	BIOLOGY: <FaBug title="Biology" />,
	EARTH_SCIENCE: <FaRegGem title="Earth Science" />,
	SOCIAL_STUDIES: <GoGlobe title="Social Studies" />,
	MATH: <IoIosCalculator title="Math" />,
	HEALTH: <IoIosPulse title="Health" />,
	NONE: null,
}

export type BadgeIconType = keyof typeof BADGE_ICON_MAP

export type Category = {
	_id: string
	name: string
	badge?: BadgeIconType | null | undefined
	order?: number | null | undefined
	availableForSimulations?: boolean | null
	availableForQuestions?: boolean | null
}

export type CategoryStore = {
	fetching: boolean
	error: string | null | undefined
	categories: Array<Category> | null
	badges: string[] | null
}
enum ActionType {
	SET_FETCHING_CATEGORIES = 'SET_FETCHING_CATEGORIES',
	SET_CATEGORIES = 'SET_CATEGORIES',
}

function setFetching(fetching: boolean) {
	return {
		type: ActionType.SET_FETCHING_CATEGORIES as ActionType.SET_FETCHING_CATEGORIES,
		payload: fetching,
	}
}

function setCategories(data: {
	categories: Array<Category> | null
	error: string | null | undefined
	badges?: string[] | null | undefined
}) {
	return {
		type: ActionType.SET_CATEGORIES as ActionType.SET_CATEGORIES,
		payload: data,
	}
}

export function fetchCategories(): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		const state = getState()

		if (state.categories.fetching) {
			return
		}

		dispatch(setFetching(true))
		axios
			.get(`${config.simulationsApiUrl}/api/categories`)
			.then((res) => {
				dispatch(setCategories(res.data))
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
					return dispatch(
						setCategories({
							categories: null,
							error: errData.message,
						})
					)
				}

				console.error(errData.error)
				dispatch(setCategories(errData))
			})
	}
}
export function createCategory(data: {
	name: string
	badge: string | null | undefined
	availableForSimulations: boolean
	availableForQuestions: boolean
}): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		if (!data.name) {
			return
		}

		dispatch(setFetching(true))
		axios
			.post(`${config.simulationsApiUrl}/api/categories`, data)
			.then((res) => {
				dispatch(setCategories(res.data))
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
					return dispatch(
						setCategories({
							categories: null,
							error: errData.message,
						})
					)
				}

				console.error(errData.error)
				dispatch(setCategories(errData))
			})
	}
}
export function updateCategory(
	id: string,
	data: Partial<Category>
): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		if (!id) {
			return
		}

		dispatch(setFetching(true))
		axios
			.patch(`${config.simulationsApiUrl}/api/categories/${id}`, data)
			.then((res) => {
				dispatch(setCategories(res.data))
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
					return dispatch(
						setCategories({
							categories: null,
							error: errData.message,
						})
					)
				}

				console.error(errData.error)
				dispatch(setCategories(errData))
			})
	}
}
export function deleteCategory(id: string): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		if (!id) {
			return
		}

		dispatch(setFetching(true))
		axios
			.delete(`${config.simulationsApiUrl}/api/categories/${id}`)
			.then((res) => {
				dispatch(setCategories(res.data))
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
					return dispatch(
						setCategories({
							categories: null,
							error: errData.message,
						})
					)
				}

				console.error(errData.error)
				dispatch(setCategories(errData))
			})
	}
}
const initialState: CategoryStore = {
	fetching: false,
	categories: null,
	error: null,
	badges: null,
}
export default function categoriesReducer(
	state: CategoryStore = initialState,
	action: ReturnType<typeof setCategories> | ReturnType<typeof setFetching>
): CategoryStore {
	switch (action.type) {
		case ActionType.SET_CATEGORIES:
			return { ...state, ...action.payload, fetching: false }

		case ActionType.SET_FETCHING_CATEGORIES:
			return { ...state, fetching: action.payload }

		default:
			return state
	}
} // selectors

export function getCategories(state: { categories: CategoryStore }): Array<Category> | null {
	return state.categories.categories
}
export function getBadges(state: { categories: CategoryStore }): string[] | null | undefined {
	return state.categories.badges
}
