import React from 'react'
import { createRoot } from 'react-dom/client'

import './setup/lodashMixins'
import store from './setup/store'
import App from './App'
import { setupAxios } from './setup/axios'

import './index.css'

setupAxios()

const container = document.getElementById('root')

if (!container) {
	throw new Error('No root element found')
}

const root = createRoot(container)

root.render(<App store={store} />)
