import React, { ReactNode } from 'react'
import type {
	LiteracyEventDataAppearance,
	SingleLiteracyEventDataAppearance,
} from '@mission.io/mission-toolkit/actions'
import { MISSION_TYPES, LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import {
	useControlSet,
	useCreativeCanvasStations,
	useMaps,
} from '../../../../../reducers/simulationEditor'
import Select, { OptionProps, components } from 'react-select'
import {
	CreativeCanvasLabel,
	CreativeCanvasSelectorOption,
	CreativeCanvasSelectorOptionType,
} from '../BasicFormComponents'

type SelectOption =
	| {
			type: typeof LITERACY_EVENT.DATA_APPEARANCE.LOCATION.MAP
			label: ReactNode
			value: string
	  }
	| ({
			type: typeof LITERACY_EVENT.DATA_APPEARANCE.LOCATION.CREATIVE_CANVAS_STATION
	  } & CreativeCanvasSelectorOptionType)

/**
 * Component for updating the `dataAppearance` property of a literacy event. Allows selecting any maps and/or creative canvases from the simulation.
 */
export function DataAppearance({
	value,
	onChange,
}: {
	value: LiteracyEventDataAppearance
	onChange: (newValue: LiteracyEventDataAppearance) => unknown
}): ReactNode {
	const arrayValue = Array.isArray(value) ? value : []
	const controlSet = useControlSet()
	const maps = useMaps()
	const creativeCanvases = useCreativeCanvasStations()

	if (controlSet !== MISSION_TYPES.FOUR_PLUS) {
		return 'Data Appearance is only supported in Junior Plus Missions'
	}

	return controlSet === MISSION_TYPES.FOUR_PLUS ? (
		<>
			<p>Select the maps and creative canvases where data from this Literacy Event will appear</p>
			<Select<SelectOption, true>
				{...{
					options: [
						{
							label: 'Maps',
							options: maps.map((map) => ({
								type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.MAP,
								label: map.name,
								value: map._id,
							})),
						},
						{
							label: 'Creative Canvases',
							options: creativeCanvases.map((canvasAction) => ({
								type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.CREATIVE_CANVAS_STATION,
								value: canvasAction._id,
								creativeCanvasAction: canvasAction,
							})),
						},
					],
					value: arrayValue.map((appearance): SelectOption => {
						if (appearance.type === LITERACY_EVENT.DATA_APPEARANCE.LOCATION.MAP) {
							const map = maps.find((m) => m._id === appearance.mapId)
							if (!map) {
								throw new Error('Could not find map that is assigned to data appearance')
							}
							return {
								type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.MAP,
								value: appearance.mapId,
								label: (
									<>
										<b>{map.name}</b> (Map)
									</>
								),
							}
						}
						const creativeCanvasAction = creativeCanvases.find(
							(cc) => cc._id === appearance.stationActionId
						)
						if (!creativeCanvasAction) {
							throw new Error(
								'Could not find creative canvas action that is assigned to data appearance'
							)
						}
						return {
							type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.CREATIVE_CANVAS_STATION,
							value: appearance.stationActionId,
							label: <CreativeCanvasLabel creativeCanvasAction={creativeCanvasAction} />,

							creativeCanvasAction,
						}
					}),
					onChange: (selectedValues) => {
						if (!selectedValues) {
							onChange({ type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.NONE })
							return
						}

						onChange(
							selectedValues.map((selectedValue): SingleLiteracyEventDataAppearance => {
								return selectedValue.type ===
									LITERACY_EVENT.DATA_APPEARANCE.LOCATION.CREATIVE_CANVAS_STATION
									? {
											type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.CREATIVE_CANVAS_STATION,
											stationActionId: selectedValue.value,
									  }
									: {
											type: LITERACY_EVENT.DATA_APPEARANCE.LOCATION.MAP,
											mapId: selectedValue.value,
									  }
							})
						)
					},
					isMulti: true,
					components: {
						Option: DataAppearanceSelectorOption,
					},
					styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
					menuPortalTarget: document.body,
				}}
			/>
		</>
	) : (
		'Data Appearance is only supported in 4+ Missions'
	)
}

/**
 * The option that will be displayed in the select dropdown for a possible data appearance. Displays the default Option for maps,
 * and `CreativeCanvasSelectorOption` for creative canvases.
 */
function DataAppearanceSelectorOption<IsMulti extends boolean>(
	props: OptionProps<SelectOption, IsMulti>
) {
	if (props.data.type === LITERACY_EVENT.DATA_APPEARANCE.LOCATION.CREATIVE_CANVAS_STATION) {
		return (
			<CreativeCanvasSelectorOption
				// Cast to make the local Option type compatible with the option type used by CreativeCanvasSelectorOption
				{...(props as OptionProps<CreativeCanvasSelectorOptionType & { type?: string }, IsMulti>)}
			/>
		)
	}
	return <components.Option {...props} />
}
