import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { OverlayStyled } from '../Screen'
import type { OverlayType } from '../ActionPlayer'
import type { ActionPlayerAction } from '../sharedTypes'
import type { EventResult } from '@mission.io/mission-toolkit/actions'
type VideoImageOverlayProps = {
	overlay?: OverlayType
	dispatch: (arg0: ActionPlayerAction) => unknown
	playActions: (arg0: EventResult<string>) => void
}
/**
 * This component display a Video or Image Overlay when a mission has a overlay in its list.
 * It plays all the onStar actions that the overlay has.
 * @param {VideoImageOverlayProps} props
 * @returns {React$Node} react node
 */

export default function VideoImageOverlay({
	overlay,
	dispatch,
	playActions,
}: VideoImageOverlayProps): JSX.Element | null {
	useEffect(() => {
		if (overlay) {
			playActions(overlay.onStart)
		}
	}, [overlay, playActions])
	return overlay ? (
		<OverlayStyled $noBackground>
			<div>
				{overlay.type === 'VIDEO' ? (
					<VideoOverlay dispatch={dispatch} playActions={playActions} overlay={overlay} />
				) : overlay.type === 'IMAGE' ? (
					<ImageOverlay dispatch={dispatch} overlay={overlay} />
				) : null}
			</div>
		</OverlayStyled>
	) : null
}
/**
 * Video is displayed with an overlay in the center of the screen.
 * It has an overlay as item to displayed. When the video ended
 * it play all the actions that it has with the onEndAction
 * @param { {overlay, dispatch, playActions} } props
 * @returns {React$Node} react node
 */

function VideoOverlay({
	overlay,
	dispatch,
	playActions,
}: {
	overlay: OverlayType
	dispatch: (arg0: ActionPlayerAction) => unknown
	playActions: (arg0: EventResult<string>) => void
}): JSX.Element {
	const videoRef = useRef(null)
	return (
		<OverlayVideo
			ref={videoRef}
			src={overlay.url}
			onEnded={() => {
				dispatch({
					type: 'END_OVERLAY',
					payload: overlay?.actionId,
				})
				playActions(overlay.onEnd)
			}}
			autoPlay
		/>
	)
}

/**
 * Image is displayed with an overlay in the center of the screen.
 * It has an overlay as item to displayed.
 * @param { {overlay, dispatch} } props
 * @returns {React$Node} react node
 */
function ImageOverlay({
	overlay,
	dispatch,
}: {
	overlay: OverlayType
	dispatch: (arg0: ActionPlayerAction) => unknown
}): JSX.Element {
	const timerIdRef = useRef<NodeJS.Timeout | null>(null)
	timerIdRef.current = setTimeout(() => {
		if (overlay && overlay.type === 'IMAGE') {
			dispatch({
				type: 'END_OVERLAY',
				payload: overlay?.actionId,
			})
		}
	}, overlay?.duration)
	useEffect(
		() => () => {
			if (timerIdRef.current) {
				clearTimeout(timerIdRef.current)
			}
		},
		[]
	)
	return <StyledImage src={overlay.url} alt="Image Overlay" />
}

const OverlayVideo = styled.video`
	max-width: 100%;
	max-height: 100%;
	display: block;
	border: 1px solid black;
	border-radius: 4px;
`
const StyledImage = styled.img`
	max-width: 100%;
	max-height: 100%;
`
