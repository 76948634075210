import React from 'react'
import Media from './Media'
import Entity from './Entity'
import Rectangle from './Rectangle'
import Ellipse from './Ellipse'
import type { WorldComponent, ParentData } from '../types'
import { PRIMITIVE_TYPES } from '../constants'
export function renderObject(object: WorldComponent, parentData: ParentData): JSX.Element {
	const ComponentType = COMPONENT_MAP[object.type]

	if (ComponentType) {
		return (
			// @ts-expect-error - We are assuming the props are correct for the component
			<ComponentType {...object} {...parentData} key={object._id} />
		)
	}

	throw new Error(`No component for ${object.type}`)
}
const COMPONENT_MAP = {
	[PRIMITIVE_TYPES.MEDIA]: Media,
	[PRIMITIVE_TYPES.ENTITY]: Entity,
	[PRIMITIVE_TYPES.RECTANGLE]: Rectangle,
	[PRIMITIVE_TYPES.ELLIPSE]: Ellipse,
	[PRIMITIVE_TYPES.CAMERA]: Rectangle,
}
