import React, { ChangeEvent, Component, ReactNode } from 'react'
import { connect } from 'react-redux'
import { Modal, InputGroup, Input, Button } from 'reactstrap'
import classNames from 'classnames'
import { actions, selectors } from '../../../../../setup/tiles'
import type { ReduxStore } from '../../../../../types/ReduxStore'
import { values } from '../../../../../helpers/functions'
const mapObjectInfo = {
	celestialBodies: {
		label: 'Celestial Bodies',
		serverType: 'CELESTIAL_BODY' as const,
	},
	ships: {
		label: 'Ships',
		serverType: 'SHIP' as const,
	},
	other: {
		label: 'Uncategorized Objects',
		serverType: 'OTHER' as const,
	},
	contacts: {
		label: 'Contacts',
		serverType: 'CONTACT' as const,
	},
}
export type Category = 'ships' | 'celestialBodies' | 'other' | 'contacts'
type Props = {
	category: Category
	mapId: string
	className?: string
	isOpen: boolean
	onClose: () => void
}
type ListObject = {
	name: string
	_id: string
}
type ReduxProps = {
	list: ListObject[]
	addPreexistingObject: (arg0: {
		mapId: string
		objectId: string
		type: 'SHIP' | 'CELESTIAL_BODY' | 'OTHER' | 'CONTACT'
	}) => void
}
type State = {
	filter: string
}

class MapObjectCopyComponent extends Component<Props & ReduxProps, State> {
	searchRef

	constructor(props: Props & ReduxProps) {
		super(props)
		this.state = {
			filter: '',
		}
		this.searchRef = React.createRef<HTMLInputElement>()
	}

	onOpen = () => {
		if (this.searchRef.current) {
			this.searchRef.current.focus()
		}
	}
	onClose = () => {
		this.props.onClose()
	}

	render(): JSX.Element {
		return (
			<Modal
				className={classNames(this.props.className, 'MapCopyModal')}
				isOpen={this.props.isOpen}
				centered={true}
				size="lg"
				onOpened={this.onOpen}>
				<div className="input-container">
					<InputGroup className="search">
						<Input
							type="text"
							className="map-search"
							name="map-copy-input"
							value={this.state.filter}
							onChange={this.handleFilterChange}
							placeholder={`Search ${mapObjectInfo[this.props.category].label}`}
							innerRef={this.searchRef}
						/>
					</InputGroup>
					<Button onClick={this.onClose} color="danger">
						X
					</Button>
				</div>
				<div
					className="object-copy-results"
					style={{
						padding: '8px',
					}}>
					<h3>{mapObjectInfo[this.props.category].label} from the current simulation</h3>
					{this.renderResults()}
				</div>
			</Modal>
		)
	}

	handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			filter: e.target.value,
		})
	}
	renderResults = (): ReactNode => {
		if (this.props.list) {
			return this.props.list
				.filter(
					(obj) => obj.name && obj.name.toLowerCase().includes(this.state.filter.toLowerCase())
				)
				.map((obj) => {
					return (
						<div
							key={obj._id}
							className="copy-object-result clickable"
							onClick={() => {
								this.handleSelect(obj._id)
							}}>
							{obj.name}
						</div>
					)
				})
		}

		return <div className="copy-object-result" />
	}
	handleSelect = (id: string) => {
		this.props.addPreexistingObject({
			mapId: this.props.mapId,
			objectId: id,
			type: mapObjectInfo[this.props.category].serverType,
		})
		this.onClose()
	}
}

const mapDispatchToProps = {
	addPreexistingObject: actions.maps.addPreexistingObject,
}

function mapStateToProps(state: ReduxStore, props: Props) {
	const map = selectors.maps.store(state)[props.mapId]
	const list = values<ListObject>(state.mapObjects[props.category]).filter(
		(object) => !map.objects[props.category].includes(object._id)
	)
	return {
		list,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MapObjectCopyComponent)
