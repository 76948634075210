import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateEntity } from '../../../reducers/entities'
import { updateNavigationMap } from '../../../reducers/navigationMap'
import { EditorType, PRIMITIVE_TYPES } from '../constants'
import type { SaveData } from '../types'
import { MenuItem } from './styles'
import { FaSave } from 'react-icons/fa'
import styled from 'styled-components'
import type { NavigationMap, Entity } from '@mission.io/navigation-map-server'
import { toast } from '../../../helpers/uiFunctions'
type Props = {
	getSaveData: () => SaveData
	afterSave: (savedEntityOrMap: NavigationMap | Entity) => void
	editorType: EditorType
}
export default function Save({ getSaveData, afterSave, editorType }: Props): JSX.Element {
	const dispatch = useDispatch()
	const [currentlySaving, setCurrentlySaving] = useState(false)
	return (
		<MenuItem>
			<StyledSave
				$isSaving={currentlySaving}
				onClick={() => {
					if (currentlySaving) {
						return
					}

					setCurrentlySaving(true)
					const { data, _id, name, description } = getSaveData()
					const sharedUpdateFields = {
						_id: _id || '',
						name,
						x: 0,
						y: 0,
						width: 1,
						height: 1,
						components: data,
					}

					const doCatch = (err: Error) => {
						console.error(err)
						toast.error(`failed to save: ${err.message}`)
					}

					const doFinally = () => {
						setCurrentlySaving(false)
					}

					const doThen = (entityOrMap: Entity | NavigationMap) => {
						afterSave(entityOrMap)
					}

					if (editorType === EditorType.ENTITY) {
						dispatch(
							updateEntity({
								type: PRIMITIVE_TYPES.ENTITY,
								...sharedUpdateFields,
							})
						)
							// @ts-expect-error - we need to use redux-toolkit in order for redux to understand thunks in ts
							.then(doThen)
							.catch(doCatch)
							.finally(doFinally)
					} else {
						dispatch(
							updateNavigationMap({
								type: PRIMITIVE_TYPES.WORLD,
								...sharedUpdateFields,
								description: description || '',
							})
						)
							// @ts-expect-error - we need to use redux-toolkit in order for redux to understand thunks in ts
							.then(doThen)
							.catch(doCatch)
							.finally(doFinally)
					}
				}}
			/>
		</MenuItem>
	)
}
const StyledSave = styled(FaSave)<{ $isSaving: boolean }>`
	color: ${({ $isSaving }: { $isSaving: boolean }) => ($isSaving ? 'lightblue' : 'orange')};
`
