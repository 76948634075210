import React from 'react'
import { FormGroup } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import type {
	Activity as MissionGuideActivity,
	SimulationMissionGuideType,
} from '@mission.io/mission-guide'
import MarkdownInput from '../../../common/MarkdownInput'
import { Label } from 'reactstrap'
import { formValueSelector, change, Field, FieldArray } from 'redux-form'
import { AUTOMATED_REDUX_FORM_NAMES } from '../../../helpers/constants'
import ActivityList from './ActivityList'
import { useActivitiesWithDroppableIds } from './hooks'
import MapList from './MapList'
import VocabularyList from '../../../common/VocabularyList'
import MarkdownField from '../../../common/MarkdownField'
import type { Simulation } from '../../../types/Simulation'
import type { AutomatedSimulation } from '../../../types/AutomatedSimulation'
const PREP_LIST = 'PREP_ACTIVITIES'
const DEBRIEF_LIST = 'DEBRIEF_ACTIVITIES'
/**
 * MissionGuide - A form used to edit mission guide fields
 *
 * @param {{simulation: Simulation | AutomatedSimulation}} simulation - the current simulation data
 */

export default function MissionGuideForm({
	simulation,
}: {
	simulation: Simulation | AutomatedSimulation
}): JSX.Element | null {
	const getFormData = formValueSelector(AUTOMATED_REDUX_FORM_NAMES.DETAILS)
	const missionGuide: SimulationMissionGuideType = useSelector((state) =>
		getFormData(state, 'missionGuide')
	)
	const prepActivities = useActivitiesWithDroppableIds(missionGuide?.prepActivities)
	const debriefingActivities = useActivitiesWithDroppableIds(missionGuide?.debriefingActivities)
	const dispatch = useDispatch()

	if (!('missionGuide' in simulation) || !missionGuide) {
		return null
	}

	/**
	 * reorderActivities - move an activity within or between the activity lists,
	 *     called by DragDropContext from react-beautiful-dnd
	 *
	 * @param {{index: number, droppableId: string}} {source} - the starting location of the activity
	 * @param {{index: number, droppableId: string}} {destination} - the ending location of the activity
	 */
	const reorderActivities = ({ source, destination }: DropResult) => {
		let item: MissionGuideActivity | null | undefined = null

		if (!source || !destination) {
			return
		}

		const prepActivities = [...missionGuide.prepActivities]
		const debriefingActivities = [...missionGuide.debriefingActivities]

		if (source.droppableId === PREP_LIST) {
			item = prepActivities.splice(source.index, 1)[0]
		} else if (source.droppableId === DEBRIEF_LIST) {
			item = debriefingActivities.splice(source.index, 1)[0]
		}

		if (!item) {
			return
		}

		if (destination.droppableId === PREP_LIST) {
			prepActivities.splice(destination.index, 0, item)
		} else if (destination.droppableId === DEBRIEF_LIST) {
			debriefingActivities.splice(destination.index, 0, item)
		}

		dispatch(
			change(AUTOMATED_REDUX_FORM_NAMES.DETAILS, 'missionGuide', {
				...missionGuide,
				prepActivities,
				debriefingActivities,
			})
		)
	}

	/**
	 * onEditList - edit/add/delete an activity from a list
	 *
	 * @param {string} listId - the id of the list to edit
	 * @return {(updatedActivity: ?MissionGuideActivity, index: number) => void}
	 *           {?MissionGuideActivity} updatedActivity - null/undefined if deleting, an activity if updating or adding
	 *           {number} index - the index in the list to modify, if `updatedActivity` is null then the activity at this
	 *                            location will be deleted, if the index is within the list, the activity will be replaced,
	 *                            if equal to the number of elements in the list the activity will be added to the end of the list.
	 */
	const onEditList =
		(listId: 'prepActivities' | 'debriefingActivities') =>
		(updatedActivity: MissionGuideActivity | null | undefined, index: number) => {
			const newActivities = [...missionGuide[listId]]

			if (updatedActivity) {
				newActivities.splice(index, 1, updatedActivity)
			} else {
				newActivities.splice(index, 1)
			}

			dispatch(
				change(AUTOMATED_REDUX_FORM_NAMES.DETAILS, 'missionGuide', {
					...missionGuide,
					[listId]: newActivities,
				})
			)
		}

	return (
		<div>
			<h4>Mission Guide Fields</h4>
			<FormGroup>
				<Label>Suggested Vocabulary</Label>
				<FieldArray name="suggestedVocabulary" component={VocabularyList} />
			</FormGroup>

			<FormGroup>
				<Label>Prerequisite Knowledge</Label>
				<Field name="prerequisiteKnowledge" component={MarkdownField} />
			</FormGroup>
			<FormGroup>
				<Label>Investigation Message</Label>
				<MarkdownInput
					value={missionGuide.investigationMessage}
					onChange={(newMessage) => {
						dispatch(
							change(AUTOMATED_REDUX_FORM_NAMES.DETAILS, 'missionGuide', {
								...missionGuide,
								investigationMessage: newMessage,
							})
						)
					}}
				/>
			</FormGroup>
			<FormGroup>
				<Label>Debriefing Message</Label>
				<MarkdownInput
					value={missionGuide.debriefingMessage}
					onChange={(newMessage) => {
						dispatch(
							change(AUTOMATED_REDUX_FORM_NAMES.DETAILS, 'missionGuide', {
								...missionGuide,
								debriefingMessage: newMessage,
							})
						)
					}}
				/>
			</FormGroup>
			<DragDropContext onDragEnd={reorderActivities}>
				<FormGroup>
					Prep and/or Intervention Ideas:
					<ActivityList
						items={prepActivities}
						id={PREP_LIST}
						onEdit={onEditList('prepActivities')}
					/>
				</FormGroup>
				<FormGroup>
					Extension Ideas:
					<ActivityList
						items={debriefingActivities}
						id={DEBRIEF_LIST}
						onEdit={onEditList('debriefingActivities')}
					/>
				</FormGroup>
				<MapList />
			</DragDropContext>
		</div>
	)
}
