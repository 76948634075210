import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { FaCheckCircle } from 'react-icons/fa'
import { usePhaseContext } from '../PhaseContext'
import { useFormContext } from '../FormContext'
import { useSelector, useDispatch } from 'react-redux'
import type { PlacementPayload } from '../DragDrop/types'
import styled, { css } from 'styled-components'
import { ScreensContainer } from './shared'
import { Target, Draggable } from '../DragDrop'
import { DraggableType } from '../DragDrop/types'
import { actionHelpers, stateHelpers, canvasHelpers } from './helpers'
import { selectors } from '../../../reducers/simulationEditor'
import { useSaveContextUpdate } from '../EditorSimulationSaveContext'
import { prettifyTypeEnum } from '../../../helpers/functions'
import { toast } from 'react-toastify'
import { runWithConfirmationMessage } from '../../../helpers/uiFunctions'
import type { Screen, DurationMap, ScreensList } from './types'
import type { ScreenActionId } from '../actionDefinitions'
import type { ThemeType } from './theme'
import { ReduxStore } from '../../../types/ReduxStore'
type Props = {
	screensList: ScreensList
	screens: Screen[]
	setScreenFocus: (index: number, id: string) => void
	durations: DurationMap
	screenRefs: {
		current: Record<string, HTMLDivElement | null | undefined>
	}
}
const HelperMessage = styled.div`
	color: white;
	margin: auto 0;
	display: block;
	text-align: center;
	width: 100%;
`
export default function ScreenList({
	screensList,
	screens,
	setScreenFocus,
	durations,
	screenRefs,
}: Props): JSX.Element {
	const dispatch = useDispatch()
	const { currentPhase } = usePhaseContext()
	const onUpdate = useSaveContextUpdate()
	const { id: simulationId } = useParams<{
		id: string
	}>()

	const placeScreen = ({ item, clientOffset }: PlacementPayload) => {
		const clientOffsetX = clientOffset?.x
		if (typeof clientOffsetX !== 'number') {
			return
		}
		const result = canvasHelpers.deriveScreenAndRelativePositionBasedOnX(
			clientOffsetX,
			screenRefs.current
		)
		let placeAfterScreen: Screen | null | undefined = screens[screens.length - 1]

		if (result) {
			const [screenId, xPositionRelativeToScreen, screenWidth] = result
			const index = screens.findIndex((screen) => screen.id === screenId)

			// If the screen was dropped more than halfway across another screen, we will add to the next index
			if (xPositionRelativeToScreen > screenWidth / 2 && index > -1) {
				placeAfterScreen = screens[index]
			} else if (index > -1) {
				placeAfterScreen = screens[index - 1]
			}
		} else {
			const firstScreen = screenRefs.current[screens[0]?.id]

			if (firstScreen) {
				const { left: refX } = firstScreen.getBoundingClientRect()

				// The screen was dropped before the first screen!
				if (clientOffsetX < refX) {
					placeAfterScreen = null
				}
			}
		}

		const callCreate = (shiftScreens = false) => {
			stateHelpers.createScreenAfterScreenId({
				item,
				screenId: placeAfterScreen?.id,
				simulationId,
				dispatch,
				onUpdate,
				shiftScreens,
				currentPhase,
			})
		}

		if (placeAfterScreen !== screens[screens.length - 1]) {
			runWithConfirmationMessage(
				`Would you like to shift all subsequent screens to be after this screen?`,
				() => {
					callCreate(true)
				},
				'Shift screens',
				'INFO'
			)
		} else if (
			placeAfterScreen &&
			placeAfterScreen.meta.newPhase &&
			placeAfterScreen.id !== currentPhase
		) {
			toast.error(
				`You must navigate to Phase: ${placeAfterScreen.meta.newPhase} in order to add screens to it.`
			)
		} else {
			callCreate()
		}
	}

	return (
		<ScreenListStyle>
			<Target onlyAccept={DraggableType.SCREEN_TYPE} onPlacement={placeScreen}>
				<ScreensView>
					{screensList.length === 0 ? (
						<HelperMessage> Drag a new screen into box to begin.</HelperMessage>
					) : (
						screensList.map((screenIdOrIds, screenListIndex) => {
							const screen = screens[screenListIndex]

							if (screen) {
								if (typeof screenIdOrIds === 'string') {
									return <ScreenView key={`${screen.id}-${screenListIndex}`} value={screen} />
								} else if (Array.isArray(screenIdOrIds)) {
									return (
										<MultipleScreens
											key={`screenlist-${screenListIndex}`}
											screenIds={screenIdOrIds}
											selectedScreen={screen}
											durations={durations}
											setScreenFocus={(screenId) => setScreenFocus(screenListIndex, screenId)}
										/>
									)
								}
							}

							return null
						})
					)}
				</ScreensView>
			</Target>
		</ScreenListStyle>
	)
}

function ScreenView({ value }: { value: Screen }) {
	const isPhaseReference = useIsPhaseReference(value)
	return (
		<>
			<StyledDraggable
				item={value}
				nocopy
				$screenMs={actionHelpers.getActionTimeLength(value, isPhaseReference)}>
				<Value value={value} />
			</StyledDraggable>
		</>
	)
}

function MultipleScreens({
	screenIds,
	selectedScreen,
	durations,
	setScreenFocus,
}: {
	screenIds: ScreenActionId[]
	selectedScreen: Screen
	durations: DurationMap
	setScreenFocus: (arg0: ScreenActionId) => void
}) {
	const isPhaseReference = useIsPhaseReference(selectedScreen)
	return (
		<MultipleScreensStyle
			$screenMs={actionHelpers.getActionTimeLength(selectedScreen, isPhaseReference)}>
			{screenIds.map((screenId) => {
				if (screenId === selectedScreen.id) {
					return <ActiveFlexScreen key={selectedScreen.id} screen={selectedScreen} />
				}

				return (
					<InActiveFlexScreen
						onClick={() => setScreenFocus(screenId)}
						key={screenId}
						screenId={screenId}
						durations={durations}
					/>
				)
			})}
		</MultipleScreensStyle>
	)
}

function InActiveFlexScreen({
	screenId,
	durations,
	onClick,
}: {
	screenId: string
	durations: DurationMap
	onClick: () => void
}) {
	const screen = useSelector((state: ReduxStore) => selectors.getScreenAction(state, screenId))
	const item = screen ? actionHelpers.fromScreenToScreenType(screen, screenId, durations) : null
	const isPhaseReference = useIsPhaseReference(item)
	if (!item) return null
	return (
		<>
			<FlexDraggable
				item={item}
				nocopy
				$screenMs={actionHelpers.getActionTimeLength(item, isPhaseReference)}>
				<Value value={item} onClick={onClick} />
			</FlexDraggable>
		</>
	)
}

function ActiveFlexScreen({ screen }: { screen: Screen }) {
	const isPhaseReference = useIsPhaseReference(screen)
	return (
		<>
			<FlexDraggable
				// @ts-expect-error $active is a valid prop for FlexDraggable
				$active
				item={screen}
				nocopy
				$screenMs={actionHelpers.getActionTimeLength(screen, isPhaseReference)}>
				<Value value={screen} />
			</FlexDraggable>
		</>
	)
}

const Checkpoint = () => {
	return (
		<StyledCheckpoint>
			<FaCheckCircle size={25} />
		</StyledCheckpoint>
	)
}

const StyledCheckpoint = styled.div`
	position: absolute;
	top: 0;
	color: #ffc107;
	height: 25px;
	margin: -4px;
	left: 0;
`
const ValueStyle = styled.div<{
	color: string
	$blackOverride: unknown
}>`
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	cursor: pointer;
	color: white;
	background-color: ${({ color, $blackOverride, theme }) =>
		$blackOverride ? theme.colors.black : color};
	img,
	video {
		height: 100%;
		max-width: 100%;
	}
`
const ScreenLabel = styled.div`
	align-self: center;
	width: 100%;
	text-align: center;
`

const useIsPhaseReference = (value: Screen | null | undefined): boolean => {
	const { currentPhase } = usePhaseContext()
	if (!value) return false
	return value.meta.newPhase ? value.id !== currentPhase : false
}

function Value({ value, onClick: parentOnClick }: { value: Screen; onClick?: () => void }) {
	const isPhaseReference = useIsPhaseReference(value)
	const { setCurrentPhase } = usePhaseContext()
	const { setCurrentActionId } = useFormContext()
	const color = actionHelpers.getColor(value, isPhaseReference)
	const mediaRef = useRef(null)
	const onClick =
		parentOnClick ||
		(isPhaseReference ? () => setCurrentPhase(value.id) : () => setCurrentActionId(value.id))
	return (
		<ValueStyle
			color={color}
			$blackOverride={!isPhaseReference && value.meta.type === 'VIDEO_SCREEN'}
			onClick={onClick}>
			{value.meta.checkpoint && <Checkpoint />}
			{isPhaseReference ? (
				<div
					css={`
						display: flex;
						align-items: center;
						overflow: hidden;
						text-align: center;
					`}>
					Go to {value.meta.newPhase}
				</div>
			) : value.meta.type === 'VIDEO_SCREEN' ? (
				<video ref={mediaRef} src={value.meta.url || undefined} preload="metadata" />
			) : value.meta.type === 'IMAGE_SCREEN' ? (
				<img src={value.meta.url || undefined} alt="Screen" />
			) : (
				<ScreenLabel>{prettifyTypeEnum(value.meta.type)}</ScreenLabel>
			)}
		</ValueStyle>
	)
}

const ScreensView = styled(ScreensContainer)`
	padding: 0px ${({ theme }: { theme: ThemeType }) => theme.pageSideMargin};
	display: flex;
	height: 100%;
`
const ScreenListStyle = styled.div`
	background-color: ${({ theme }: { theme: ThemeType }) => theme.colors.darkGrey};
	min-width: fit-content;
	width: 100%;
	border: 1px solid ${({ theme }: { theme: ThemeType }) => theme.colors.darkGreyBorder};
	min-height: ${({ theme }: { theme: ThemeType }) => theme.screenContainerHeight};
	height: 0;
`

type StyledDraggableProps = {
	$screenMs: number
}
const StyledDraggable = styled(Draggable)<StyledDraggableProps>`
	${({ theme, $screenMs }) => `
		width: ${$screenMs * theme.pixelsPerMs}px;
		background-color: ${theme.colors.white};
		margin-top: ${theme.screenCardMargin};
		cursor: pointer;
		max-height: ${theme.screenCardHeight};
	`}
`
const MultipleScreensStyle = styled.div<{ $screenMs: number }>`
	display: flex;
	max-height: 100%;
	flex-direction: column;
	${({ theme }) => `
		margin-top: ${theme.screenCardMargin};`}
	*:not(:last-child) {
		border-bottom: 2px dotted #707070;
	}
	${({ theme, $screenMs }: { theme: ThemeType; $screenMs: number }) =>
		`width: ${$screenMs * theme.pixelsPerMs}px;`}
`
const FlexDraggable = styled(StyledDraggable)<{
	$active: boolean
}>`
	margin: 0;
	${({ $active }) =>
		$active
			? css`
					height: 50px;
					box-shadow: 2px 2px 6px 2px rgba(202, 202, 202, 1);
					z-index: 11;
			  `
			: css`
					height: 20px;
					max-width: 100%;
					cursor: pointer;
					font-size: 0.5em;
					white-space: nowrap;
			  `}
`
