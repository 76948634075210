import React, { useState, useMemo } from 'react'
import { Table, Button, ButtonGroup } from 'reactstrap'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import type { Simulation } from '../../types/Simulation'
import type { AutomatedSimulation } from '../../types/AutomatedSimulation'
import { config } from '../../config'

export type SimulationGroup = {
	_id: string
	name: string
	canRun: string[]
	canView: string[]
	defaultPublish: {
		canView: boolean
		canRun: boolean
	}
	isMajorGroup: boolean
	unlockMessageType?: 'PAID_LICENSE'
}

type UpdateDefaultPublishPayload = Record<string, Partial<SimulationGroup['defaultPublish']>>

/**
 * GroupPermissionForm - A form used to edit the permissions of simulation groups for the given simulation
 *
 * @param {{simulation: Simulation | AutomatedSimulation}} simulation - the current simulation data
 */

export default function GroupPermissionForm({
	simulation,
}: {
	simulation: Simulation | AutomatedSimulation
}): JSX.Element | null {
	const { data: availableGroups, isLoading: isSimulationsLoading } = useQuery(
		'simulation-groups',
		() =>
			axios
				.get(`${config.simulationsApiUrl}/api/groups`)
				.then(({ data }: { data: { groups: SimulationGroup[] } }) => data.groups)
	)
	const [showAll, setShowAll] = useState(false)
	const queryClient = useQueryClient()
	const { mutate, isLoading: isMutating } = useMutation(
		(payload: UpdateDefaultPublishPayload) =>
			axios.post(`${config.simulationsApiUrl}/api/groups/simulation/${simulation._id}`, payload),
		{
			onSettled: () => {
				queryClient.invalidateQueries('simulation-groups')
			},
			onError: () => {
				toast.error(`Failed to update simulation preferences`)
			},
		}
	)
	const isLoading = isSimulationsLoading || isMutating
	const { canRun, canView, majorGroups, minorGroups } = useMemo(() => {
		const canRunGroups: Set<string> = new Set()
		const canViewGroups: Set<string> = new Set()
		const majorGroups: SimulationGroup[] = []
		const minorGroups: SimulationGroup[] = []

		if (availableGroups) {
			availableGroups.forEach((group) => {
				if (group.canView.includes(simulation._id)) {
					canViewGroups.add(group._id)
				}

				if (group.canRun.includes(simulation._id)) {
					canRunGroups.add(group._id)
				}

				if (group.isMajorGroup) {
					majorGroups.push(group)
				} else {
					minorGroups.push(group)
				}
			})
		}

		return {
			canRun: canRunGroups,
			canView: canViewGroups,
			majorGroups: majorGroups.sort((a, b) => a.name.localeCompare(b.name)),
			minorGroups: minorGroups.sort((a, b) => a.name.localeCompare(b.name)),
		}
	}, [availableGroups, simulation._id])

	if (!availableGroups) {
		return null
	}

	return (
		<div>
			<Header>
				<h4>Group Permissions (Saves Instantly){isLoading ? ' - Loading' : ''}</h4>
				<ButtonGroup>
					<Button
						color="success"
						onClick={() => {
							const payload: UpdateDefaultPublishPayload = {}
							availableGroups.forEach(({ _id, defaultPublish }) => (payload[_id] = defaultPublish))
							mutate(payload)
						}}>
						Default Publish
					</Button>
					<Button
						color="danger"
						onClick={() => {
							const payload: UpdateDefaultPublishPayload = {}
							availableGroups.forEach(
								({ _id }) =>
									(payload[_id] = {
										canView: false,
										canRun: false,
									})
							)
							mutate(payload)
						}}>
						Revoke All Access
					</Button>
				</ButtonGroup>
			</Header>
			<Table>
				<thead>
					<tr>
						<th>Group Name</th>
						<th>Can View</th>
						<th>Can Run</th>
					</tr>
				</thead>
				<tbody>
					{majorGroups.map((group: SimulationGroup) => (
						<SimulationGroupRow
							group={group}
							mutate={mutate}
							canRun={canRun}
							canView={canView}
							key={group._id}
							disabled={isLoading}
						/>
					))}
					<tr>
						<ToggleMinorGroupRow colSpan={3} onClick={() => setShowAll(!showAll)}>
							{showAll ? 'Hide' : 'Show'} minor groups
						</ToggleMinorGroupRow>
					</tr>
					{showAll
						? minorGroups.map((group: SimulationGroup) => (
								<SimulationGroupRow
									group={group}
									mutate={mutate}
									canRun={canRun}
									canView={canView}
									key={group._id}
									disabled={isLoading}
								/>
						  ))
						: null}
				</tbody>
			</Table>
		</div>
	)
}
/**
 * SimulationGroupRow - a table row representing the groups permissions for the current simulation
 *
 * @param {SimulationGroup} group - the group this row is representing
 * @param {Set<string>} canRun - the set of groups which can run the simulation
 * @param {Set<string>} canView - the set of groups which can view the simulation
 * @param {(update: { [groupId: string]: { canView?: boolean, canRun?: boolean } }) => void} mutate - the mutation call back to update the group permissions
 * @param {boolean} disabled - true if mutation is not allowed, false if mutation is allowed
 */

function SimulationGroupRow({
	group,
	canRun,
	canView,
	mutate,
	disabled,
}: {
	group: SimulationGroup
	canRun: Set<string>
	canView: Set<string>
	mutate: (
		update: Record<
			string,
			{
				canView?: boolean
				canRun?: boolean
			}
		>
	) => void
	disabled: boolean
}): JSX.Element {
	return (
		<tr>
			<td>{group.name}</td>
			<td>
				<input
					type="checkbox"
					disabled={disabled}
					checked={canView.has(group._id)}
					onChange={(event) =>
						mutate({
							[group._id]: {
								canView: event.target.checked,
							},
						})
					}
				/>
			</td>
			<td>
				<input
					type="checkbox"
					checked={canRun.has(group._id)}
					disabled={disabled}
					onChange={(event) => {
						if (event.target.checked) {
							mutate({
								[group._id]: {
									canRun: true,
									canView: true,
								},
							})
						} else {
							mutate({
								[group._id]: {
									canRun: false,
								},
							})
						}
					}}
				/>
			</td>
		</tr>
	)
}

const ToggleMinorGroupRow = styled.td`
	text-align: center;
	background-color: white;
	padding: 0.25em !important;

	&:hover {
		filter: brightness(0.95);
		cursor: pointer;
	}
`
const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5em;
`
