import React, { useState } from 'react'
import { Button, FormGroup, Col, Label, Input, Row } from 'reactstrap'
import { MediaName as Name } from './MediaName'
import { Modal } from '../../../../../common/AssetManager'
export const ALLOWED_ICON_FILE_EXTENSIONS = ['svg']

export default function SelectIcon({
	onChange,
	icon,
}: {
	onChange: (arg0: 'url' | 'width' | 'height', arg1: string | number) => void
	icon:
		| {
				url: string
				width: number
				height: number
		  }
		| null
		| undefined
}): JSX.Element {
	const [isOpen, setIsOpen] = useState(false)
	const [autoSize, setAutoSize] = useState(true)

	const toggle = () => {
		setIsOpen((state) => !state)
	}

	const alterSize = (type: 'width' | 'height', value: string) => {
		if (autoSize) {
			onChange('width', value)
			onChange('height', value)
		} else {
			onChange(type, value)
		}
	}

	return (
		<Row form css="align-items: center">
			<Modal
				restrictions={{
					allowedFileExtensions: ALLOWED_ICON_FILE_EXTENSIONS,
					mime: 'image/svg+xml',
				}}
				collection="mapIcons"
				isOpen={isOpen}
				onClose={toggle}
				onFileClick={(file) => {
					onChange('url', file.url)
					toggle()
				}}
			/>
			<Col md={4}>
				<Button onClick={toggle} color="primary" size="sm">
					Choose Icon
				</Button>
				<span css="margin-left: 8px;" />
				{icon && (
					<>
						<SvgInline src={icon.url} />
						<span css="margin-left: 8px;" />
						<Name mediaUrl={icon.url} />
					</>
				)}
			</Col>
			{icon && (
				<>
					<Col md={4}>
						<FormGroup>
							<Label for="width">Width</Label>
							<Input
								id="width"
								name="width"
								value={icon.width}
								type="number"
								onChange={(e) => alterSize('width', e.target.value)}
							/>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Label
								for="height"
								css="display: flex; justify-content: space-between; align-items: center;">
								Height
								<FormGroup check inline>
									<Label check>
										<Input
											type="checkbox"
											checked={autoSize}
											onChange={() => setAutoSize((size) => !size)}
										/>{' '}
										Auto-size
									</Label>
								</FormGroup>
							</Label>
							<Input
								id="height"
								name="height"
								value={icon.height}
								type="number"
								onChange={(e) => alterSize('height', e.target.value)}
							/>
						</FormGroup>
					</Col>
				</>
			)}
		</Row>
	)
}

/**
 * SvgInline - a component used to show an svg in a single line
 *
 * @param {{src:string}} {src} - the url for the svg
 *
 * @return {React$Node}
 */
export function SvgInline({ src }: { src: string }) {
	return <img src={src} width="25" height="25" alt="The icon" />
}
