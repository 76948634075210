import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import { isNil } from 'lodash'
import type { DraggableItem, PlacementPayload } from '../DragDrop/types'
import type { DurationMap, Action as ActionType, Screen as ScreenType } from './types'
import type { ScreenActionId } from '../actionDefinitions'
import styled, { css } from 'styled-components'
import { Target, Draggable } from '../DragDrop'
import { ScreensContainer } from './shared'
import { actionHelpers, canvasHelpers, stateHelpers } from './helpers'
import { forEachEntry } from '../../../helpers/functions'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { usePhaseContext } from '../PhaseContext'
import { useFormContext } from '../FormContext'
import { useSaveContextUpdate } from '../EditorSimulationSaveContext'
import { selectors } from '../../../reducers/simulationEditor'
import type { ThemeType } from './theme'
import { XYCoord } from 'react-dnd'
type Props = {
	screens: ScreenType[]
	actions: ActionType[]
	durations: DurationMap
	screenRefs: {
		current: Record<string, HTMLDivElement | null | undefined>
	}
}
export default function ActionCanvas({ screens, actions, screenRefs }: Props): JSX.Element | null {
	const dispatch = useDispatch()
	const { currentPhase } = usePhaseContext()
	const onUpdate = useSaveContextUpdate()
	const isJunior = useSelector(selectors.isJunior)
	const { id } = useParams<{
		id: string
	}>()
	const theme = useContext(ThemeContext)
	const pixelsPerMs = theme?.pixelsPerMs ?? 0.004

	const placeItemInTarget = ({ item, clientOffset }: PlacementPayload) => {
		const clientOffsetX = clientOffset?.x
		if (typeof clientOffsetX !== 'number') return

		const result = canvasHelpers.deriveScreenAndRelativePositionBasedOnX(
			clientOffsetX,
			screenRefs.current
		)

		if (result) {
			const [screenId, xPositionRelativeToScreen] = result
			const timestamp = Math.round(xPositionRelativeToScreen / pixelsPerMs)
			const dropFeature = canvasHelpers.getDropFeature(
				xPositionRelativeToScreen,
				screenRefs.current[screenId]
			)
			const placement = dropFeature
				? {
						type: dropFeature,
				  }
				: {
						type: 'atTime' as const,
						timestamp,
				  }
			stateHelpers.createOrMoveAction(item, placement, screenId, id, dispatch, onUpdate, isJunior)
		}
	}

	const allowDropWithinOffset = (item: DraggableItem, clientOffset: XYCoord | null) => {
		const itemId = item.id
		const clientOffsetX = clientOffset?.x
		if (typeof clientOffsetX !== 'number') return false

		const result = canvasHelpers.deriveScreenAndRelativePositionBasedOnX(
			clientOffsetX,
			screenRefs.current
		)
		if (!result) return true
		const [screenIdOfItem] = result
		if (screenIdOfItem === itemId) return false // The item is the same as the screen which it is being dropped into.

		if (screenIdOfItem !== currentPhase) {
			const screen = screens.find((screen) => screen.id === screenIdOfItem)
			if (screen?.meta.newPhase) return false
		}

		return true
	}

	const screenRows = sortScreensIntoActionRows(screens, actions, currentPhase)
	if (screens.length === 0) return null
	return (
		<ActionCanvasStyle>
			<Target
				onPlacement={(payload) => placeItemInTarget(payload)}
				allowDropWithinOffset={allowDropWithinOffset}>
				<ScreensView>
					{screens.map((screen, index) => {
						const showEmpty = screen.meta.newPhase ? screen.id !== currentPhase : false
						const numberOfScreenDuplicates = screen.meta.duplicate
						const isDuplicate = Boolean(screen.meta.duplicate)
						const key = `actions-for-screen-${screen.id}${
							numberOfScreenDuplicates ? `-${numberOfScreenDuplicates}` : ''
						}`
						const screenMs = actionHelpers.getActionTimeLength(screen, showEmpty)
						return (
							<StyledContainer key={key} $screenMs={screenMs} $empty={showEmpty}>
								<EditorToolOverlay
									$cover={isDuplicate}
									ref={(ref) => {
										if (isDuplicate) return
										else return (screenRefs.current[screen.id] = ref)
									}}
									$screenMs={screenMs}
								/>
								{!showEmpty && (
									<ScreenEditor
										disabled={isDuplicate}
										screenRefs={screenRefs}
										placeItemInTarget={placeItemInTarget}
										screen={screen}
										rows={screenRows[screen.id]}
									/>
								)}
							</StyledContainer>
						)
					})}
				</ScreensView>
			</Target>
		</ActionCanvasStyle>
	)
}
/**
 *
 */

function ScreenEditor({
	screen,
	rows,
	placeItemInTarget,
	screenRefs,
	disabled,
}: {
	screen: ScreenType
	rows: Array<ActionType[]>
	placeItemInTarget: (arg0: PlacementPayload, arg1: string) => void
	screenRefs: {
		current: Record<string, HTMLDivElement | null | undefined>
	}
	disabled: boolean
}) {
	return (
		<ValuesContainer disabled={disabled}>
			{rows.map((row, index) => (
				<Row key={`row-${index}`}>
					{row.map((value) => (
						<ActionView key={`${value.id}`} value={value} />
					))}
				</Row>
			))}
		</ValuesContainer>
	)
}

function ActionView({ value }: { value: ActionType }) {
	const screenMs = actionHelpers.getActionTimeLength(value)
	const relativeTime = actionHelpers.getActionStartTime(value)
	const color = actionHelpers.getColor(value)
	const domId = `View${value.id}`
	const [draggingStyle, setDraggingStyle] = useState({})
	const { setCurrentActionId } = useFormContext()
	return (
		<>
			<StyledValue
				$screenMs={screenMs}
				style={draggingStyle}
				start={relativeTime}
				color={color}
				onClick={() => setCurrentActionId(value.id)}
				id={domId}>
				<Draggable
					style={{
						height: '100%',
					}}
					setParentDragStyle={setDraggingStyle}
					item={value}
					nocopy
				/>
			</StyledValue>
			<UncontrolledTooltip target={domId} placement="top">
				<span>{value.title}</span>
			</UncontrolledTooltip>
		</>
	)
}

const Row = styled.div`
	border-top: 1px solid ${({ theme }: { theme: ThemeType }) => theme.colors.lightGreyBorder};
	height: ${({ theme }: { theme: ThemeType }) => theme.editorRowHeight};
	margin-top: 1px;
	position: relative;
`
const StyledValue = styled.div<{
	$screenMs: number
	color: string
	start: number
}>`
	position: absolute;
	cursor: pointer;
	margin-bottom: 1px;
	${({ $screenMs, color, start, theme }) => css`
		height: ${theme.editorRowHeight};
		left: ${start * theme.pixelsPerMs}px;
		background-color: ${color};
		width: ${$screenMs * theme.pixelsPerMs}px;
	`}
	z-index: 1;
	border-radius: 4px;
`
const EditorToolOverlay = styled.div<{ $screenMs: number; $cover: boolean }>`
	width: ${({ theme, $screenMs }) => `${$screenMs * theme.pixelsPerMs}px;`}
	position: absolute;
	margin-top: ${({ theme }: { theme: ThemeType }) => `-${theme.screenContainerHeight}`};
	height: ${({ theme }: { theme: ThemeType }) => `calc(100% + ${theme.screenContainerHeight})`};
	overflow: visible;
	pointer-events: none;
	${({ $cover }) =>
		$cover &&
		css`
			z-index: 101;
			background-color: rgba(215, 215, 215, 0.5);
		`}
			
`
const ActionCanvasStyle = styled.div`
	margin: 0px ${({ theme }: { theme: ThemeType }) => theme.pageSideMargin};
	width: fit-content;
	flex: 1;
`
const ScreensView = styled(ScreensContainer)`
	background-color: ${({ theme }: { theme: ThemeType }) => theme.colors.grey};
	border: 1px dashed ${({ theme }: { theme: ThemeType }) => theme.colors.darkGreyBorder};
	border-top: none;
`
const StyledContainer = styled.div<{ $screenMs: number; $empty: boolean }>`
	${({ theme, $screenMs }: { theme: ThemeType; $screenMs: number }) => `
	width: ${$screenMs * theme.pixelsPerMs}px;	
	min-width:  ${$screenMs * theme.pixelsPerMs}px;
`}
	background: ${({ theme, $empty }: { theme: ThemeType; $empty: boolean }) =>
		$empty
			? `repeating-linear-gradient(
	125deg,
	rgba(0, 0, 0, 0),
	rgba(0, 0, 0, 0) 10px,
	rgba(0, 0, 0, 0.1) 10px,
	rgba(0, 0, 0, 0.1) 20px
)`
			: theme.colors.lightGrey};
`
const ValuesContainer = styled.div<{ disabled: boolean }>`
	height: 100%;
	width: 100%;
	${({ disabled }: { disabled: boolean }) =>
		disabled &&
		css`
			pointer-events: none;
		`}
`
const MIN_ROWS = 3

/**
 * Sorts actions into the rows which they will displayed on in the action canvas.
 * Actions are sorted based on which screen they are located on, their position on the screen, and their time length.
 * Rows are added if enough actions overlap. There will always be at least <MIN_ROWS> rows
 * and there will always be one more row than the total necessary amount of rows
 *
 * @param {ActionType[]} actions
 * @param { { [ScreenActionId]: number }} screenMap
 */
function sortActionsInRows(
	actions: ActionType[],
	screenMap: Record<ScreenActionId, number>
): Array<ActionType[]> {
	const { getActionStartTime: getRelativeStartTime, getActionTimeLength } = actionHelpers

	const getActionStartTime = (action: ActionType) => {
		const screenTime = screenMap[action.position.screenId]
		return getRelativeStartTime(action) + screenTime
	}

	const rows: Array<Array<ActionType>> = []
	actions.sort((a, b) => {
		const aStartTime = getActionStartTime(a)
		const bStartTime = getActionStartTime(b)
		return aStartTime - bStartTime
	})
	actions.forEach((action) => {
		if (isNil(screenMap[action.position.screenId])) return
		const curActionStart = getActionStartTime(action)
		const rowToAddActionIndex = rows.findIndex((row) => {
			const lastAction = row[row.length - 1]

			if (lastAction) {
				const lastActionEnd = getActionTimeLength(lastAction) + getActionStartTime(lastAction)
				if (curActionStart > lastActionEnd) return true
			}

			return false
		})
		if (rowToAddActionIndex > -1) rows[rowToAddActionIndex].push(action)
		else {
			rows.push([action])
		}
	})

	while (rows.length < MIN_ROWS) rows.push([])

	if (rows[rows.length - 1].length > 0) rows.push([]) // Always ensure an empty row

	return rows
}

/**
   Given all screens and all actions, we sort the actions into the rows which they should go on so there is no overlap. 
   Also only adds the screens and actions pertaining to the given phase.
 * @param {ScreenType[]} allScreens 
 * @param {ActionType[]} allActions 
 * @param {ScreenActionId} currentPhase 
 */
function sortScreensIntoActionRows(
	allScreens: ScreenType[],
	allActions: ActionType[],
	currentPhase: ScreenActionId | null | undefined
): Record<string, Array<ActionType[]>> {
	let totalTime = 0
	const allScreensActionRows: Record<string, Array<ActionType[]>> = {}
	const screenTimeMap: Record<string, number> = {}
	allScreens.forEach((screen, index) => {
		if (!screen.meta.newPhase || screen.id === currentPhase) {
			screenTimeMap[screen.id] = totalTime
			allScreensActionRows[screen.id] = []
		}

		totalTime += actionHelpers.getActionTimeLength(screen)
	})
	const rows = sortActionsInRows(allActions, screenTimeMap)
	forEachEntry(allScreensActionRows, (_, screenId) => {
		allScreensActionRows[screenId] = getArrayOfArrays(rows.length)
	})
	rows.forEach((row: ActionType[], rowIndex: number) => {
		row.forEach((action: ActionType) => {
			const screenId = action.position.screenId

			if (allScreensActionRows[screenId]?.[rowIndex]) {
				allScreensActionRows[screenId][rowIndex].push(action)
			}
		})
	})
	return allScreensActionRows
}

function getArrayOfArrays(length: number) {
	const arr = []

	for (let i = 0; i < length; i++) {
		arr.push([])
	}

	return arr
}
