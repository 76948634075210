import React, { useMemo } from 'react'
import { Modal as BootstrapModal, ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'
import type { ValidationResult, BadMapData } from '@mission.io/simulation-validation'
import type { FormConfig } from '../FormContext'
import { useEditorSimulation } from '../../../reducers/simulationEditor'
import * as constants from './constants'
import { useFullMapsFromSimulation } from '../helpers/validationHelper'
type MapNameLookup = {
	maps: Record<string, string>
	mapObjects: Record<string, string>
}
/**
 * ValidationModal - a modal used to show the results of the simple simulation validation
 *
 * @param  {{
 *	 validationResult: ValidationResult, - the result of the simple simulation validation from the `simulation-validation` module
 *   closeModal: () => void, - callback used to close this modal
 *   showForm: (actionId: string) => void, - callback used to display the action editor for the given actionId
 * }} params
 */

export default function ValidationModal({
	validationResult,
	closeModal,
	showForm,
}: {
	validationResult: ValidationResult
	closeModal: () => void
	showForm: (formConfig: FormConfig) => void
}): JSX.Element {
	const editorSimulation = useEditorSimulation()
	const {
		errors,
		warnings,
		problems: { wasTested, wasNotTested },
		badData,
	} = validationResult.simpleValidation
	const fullMaps = useFullMapsFromSimulation(editorSimulation)
	const mapDataLookup = useMemo(() => {
		const maps: Record<string, string> = {}
		const mapObjects: Record<string, string> = {}

		if (fullMaps && Array.isArray(fullMaps)) {
			fullMaps.forEach((map) => {
				maps[String(map._id)] = map.name
				Object.keys(map.objects).forEach((type) => {
					const objects = map.objects[type as keyof typeof map.objects]

					if (!Array.isArray(objects)) {
						return
					}

					objects.forEach((object) => {
						if (!object) {
							return
						}

						mapObjects[object._id] = object.name
					})
				})
			})
		}

		return {
			maps,
			mapObjects,
		}
	}, [fullMaps])
	const hasProblems =
		warnings.length ||
		errors.length ||
		wasTested.length ||
		wasNotTested.length ||
		Object.keys(badData).length
	const {
		badActionReference,
		badMapData,
		badNavigationMapReference,
		badMapObjectReference,
		badMapReference,
	} = badData
	return (
		<BootstrapModal isOpen={true} centered={true} size="lg">
			<ModalHeader toggle={closeModal}>Simulation Validation</ModalHeader>
			<ModalBody>
				{errors.length > 0 ? (
					<>
						<h4>
							<StyledError>
								The following errors occurred during validation (validation results may be incorrect
								due to errors)
							</StyledError>
						</h4>
						<ul>
							{errors.map((error: Error, index: number) => (
								<li key={error.message}>
									<StyledError>{error.message}</StyledError>
								</li>
							))}
						</ul>
					</>
				) : null}
				{warnings.length > 0 ? (
					<>
						<h4>
							<Warn>The following warnings occurred during validation</Warn>
						</h4>
						<ul>
							{warnings.map((warning: string) => (
								<li key={warning}>
									<Warn>{warning}</Warn>
								</li>
							))}
						</ul>
					</>
				) : null}
				{wasTested.length > 0 ? (
					<>
						<h4>{constants.WAS_TESTED_PROBLEMS_FOUND}</h4>
						<ul>
							{wasTested.map(
								(possibleProblem: { id: string; edges: Array<string> }, index: number) => {
									const action = editorSimulation?.actions[possibleProblem.id]
									let actionName = possibleProblem.id

									if (action) {
										actionName = action.type
									}

									return (
										<li key={possibleProblem.id}>
											{possibleProblem.edges.map((edgeName: string) => (
												<span key={edgeName}>
													<Warn>{edgeName}</Warn>
													{index !== possibleProblem.edges.length - 1 ? ', ' : null}
												</span>
											))}{' '}
											in{' '}
											<ActionLink
												onClick={() =>
													showForm({
														id: possibleProblem.id,
													})
												}>
												{actionName}
											</ActionLink>{' '}
											action
										</li>
									)
								}
							)}
						</ul>
					</>
				) : null}
				<SimpleActionReport
					actions={wasNotTested?.map((action: { id: string }) => action.id)}
					title={constants.WAS_NOT_TESTED_PROBLEMS_FOUND}
					showForm={showForm}
				/>
				<SimpleActionReport
					actions={badMapReference}
					title="The following actions reference maps which do not exist"
					showForm={showForm}
				/>
				<SimpleActionReport
					actions={badMapObjectReference}
					title="The following actions reference map objects which do not exist"
					showForm={showForm}
				/>
				{badActionReference && (
					<>
						<h4>The following actions reference other actions which do not exist</h4>
						<ul>
							{Object.keys(badActionReference).map((actionId) => {
								const badEdges = Object.keys(badActionReference[actionId])
								const action = editorSimulation?.actions[actionId]
								let actionName = actionId

								if (action) {
									actionName = action.type
								}

								return (
									<li key={actionId}>
										{badEdges.map((edgeName: string, index: number) => (
											<span key={edgeName}>
												<Warn>{edgeName}</Warn>
												{index !== badEdges.length - 1 ? ', ' : null}
											</span>
										))}{' '}
										in{' '}
										<ActionLink
											onClick={() =>
												showForm({
													id: actionId,
												})
											}>
											{actionName}
										</ActionLink>{' '}
										action
									</li>
								)
							})}
						</ul>
					</>
				)}
				{badMapData && (
					<>
						<h4>The following map and/or map objects reference actions which do not exist</h4>
						<ul>
							{badMapData.map((badMapData: BadMapData) => (
								<MapError
									badMapData={badMapData}
									showForm={showForm}
									lookup={mapDataLookup}
									key={badMapData.mapId}
								/>
							))}
						</ul>
					</>
				)}
				<SimpleActionReport
					actions={badNavigationMapReference}
					title="The following actions reference navigation maps which do not exist"
					showForm={showForm}
				/>
				{!hasProblems ? <Good>{constants.NO_PROBLEMS_FOUND}</Good> : null}
			</ModalBody>
		</BootstrapModal>
	)
}
/**
 * SimpleActionReport - show error reporting for an array of actions
 *
 * @param  {{
 *	actions: ?(string[]) - the list of actions the error ocurred in
 *	title: string - the title of the error
 *	showForm: (formConfig: FormConfig) => void - the callback to show a form to the user
 * }}
 */

function SimpleActionReport({
	actions,
	title,
	showForm,
}: {
	actions: string[] | null | undefined
	title: string
	showForm: (formConfig: FormConfig) => void
}) {
	const editorSimulation = useEditorSimulation()

	if (!actions || !actions.length) {
		return null
	}

	return (
		<>
			<h4>{title}</h4>
			<ul>
				{actions.map((actionId) => {
					const action = editorSimulation?.actions[actionId]
					let actionName = actionId

					if (action) {
						actionName = action.type
					}

					return (
						<li key={actionId}>
							<ActionLink
								onClick={() =>
									showForm({
										id: actionId,
									})
								}>
								{actionName}
							</ActionLink>{' '}
							action
						</li>
					)
				})}
			</ul>
		</>
	)
}

/**
 * MapError - setup the list item for the map error
 *
 * @param  {{
 *	 badMapData: BadMapData - the error that ocurred
 *	 showFrom: (formConfig: FormConfig) => void - the callback to show a form to the user
 *	 lookup: MapNameLookup - a lookup used to determine the names of maps and map objects
 *	 }}
 */
function MapError({
	badMapData,
	showForm,
	lookup,
}: {
	badMapData: BadMapData
	showForm: (formConfig: FormConfig) => void
	lookup: MapNameLookup
}) {
	const { mapId, objectId, edges } = badMapData
	const badEdges = edges.map((edgeName: string, index: number) => (
		<span key={edgeName}>
			<Warn>{edgeName}</Warn>
			{index !== edges.length - 1 ? ', ' : null}
		</span>
	))

	if (mapId && objectId) {
		return (
			<li>
				{badEdges} in map object{' '}
				<ActionLink
					onClick={() =>
						showForm({
							mapId,
							objectId,
							id: '',
						})
					}>
					{lookup.mapObjects[objectId] || objectId}
				</ActionLink>
			</li>
		)
	}

	return (
		<li>
			{badEdges} in map{' '}
			<ActionLink
				onClick={() =>
					showForm({
						mapId,
						id: '',
					})
				}>
				{lookup.maps[mapId] || mapId}
			</ActionLink>
		</li>
	)
}

const ActionLink = styled.span`
	color: blue;

	&:hover {
		color: red;
		cursor: pointer;
	}
`
const StyledError = styled.span`
	color: darkred;
`
const Warn = styled.span`
	color: darkorange;
`
const Good = styled.span`
	color: darkgreen;
`
