import React, { useReducer, useEffect, useRef, useCallback, HTMLProps } from 'react'
import produce from 'immer'
import TriggeredActions from './TriggeredActions'
import StatusBar from './StatusBar'
import Screen from './Screen'
import { isScreenAction } from '../../../reducers/simulationEditor'

import {
	getSpecialEffect,
	FullStateLiteracyEvent,
	FullStateReadingContext,
} from '@mission.io/mission-toolkit'
import { startPause, endPause } from './helperFunctions'
import styled from 'styled-components'

import type {
	ActionMap,
	Action,
	ScreenAction,
	EventResult,
	LiteracyEventTask,
	LiteracyEventDataAppearance,
	LiteracyEventTaskList,
} from '@mission.io/mission-toolkit/actions'
import type { TriggeredActionType, ActionPlayerAction, ActiveStations } from './sharedTypes'
import { ActionId } from '../actionDefinitions'
import { IdMap } from '../../../types/util'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
// Values copied from Launchpad
const MUSIC_VOLUME = 0.25
const VOCAL_TRACK_VOLUME = 0.8
const SPECIAL_EFFECTS_VOLUME = 0.7 // const AMBIANCE_VOLUME = 0.35

export default function ActionPlayerWrapper({
	actionId,
	actions,
	onClose,
}: {
	actionId: ActionId
	actions: ActionMap<string>
	onClose: () => unknown
}): JSX.Element {
	const action = actions[actionId]
	return (
		<ActionPlayerContainer>
			<ActionPlayer action={action} actions={actions} onClose={onClose} />
		</ActionPlayerContainer>
	)
}

export enum PAUSE_STATE {
	PLAYING = 'PLAYING',
	SOFT_PAUSE = 'SOFT_PAUSE',
	PAUSED = 'PAUSED',
}

export type MissionPause = {
	onContinue: EventResult<string>
	prompt: string
	allowGoBack: boolean
	continueButtonText: string
	goBackButtonText: string
}
export type PausedState =
	| {
			state: 'PLAYING'
			missionPause?: MissionPause
	  }
	| {
			state: 'PAUSED' | 'SOFT_PAUSE'
			missionPause?: MissionPause
			pendingState: PausedState
	  }
type VocalTrackAction = {
	actionId: ActionId
	url: string
	onEnd: EventResult<string>
	softPause: boolean
}
export type ReachedPhasesAndCheckpoints = {
	id: ActionId
	type: 'PHASE' | 'CHECKPOINT'
	// Recursive type requires use before definition
	// eslint-disable-next-line no-use-before-define
	previousState: ActionPlayerState
}
export type DeathState = {
	status: boolean
	mainScreenText: string
	prompt: string
	isShipDeath: boolean
	// this will cause the ship to explode for the class and will be logged in analytics as a death
	goBackButtonText: string
	continueButtonText: string
	onCheatDeath: EventResult<string>
}
export type OverlayType = {
	actionId: ActionId
	url: string
	type: 'IMAGE' | 'VIDEO'
	onStart: EventResult<string>
	onEnd: EventResult<string>
	duration?: number
}

export interface FullStateReadingContextForActionPlayer extends FullStateReadingContext {
	assignment:
		| {
				// Will use the first readingContext in the readingContexts array for all teams
				type: typeof LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL
		  }
		| {
				type: typeof LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM
				teams: Array<string>
		  }
	relevance: number
	taskLists: Array<LiteracyEventTaskList<string>>
}

export interface FullStateLiteracyEventForActionPlayer extends FullStateLiteracyEvent {
	dataAppearance: LiteracyEventDataAppearance
	readingContext: IdMap<FullStateReadingContextForActionPlayer>
}

export type ActionPlayerState = {
	currentScreen: ScreenAction<string> | null
	triggeredActions: TriggeredActionType[]
	vocalTracks: Array<VocalTrackAction>
	specialEffects: Array<{
		actionId: ActionId
		effectId: string
		onEnd: EventResult<string>
	}>
	overlays: Array<OverlayType>
	currentSong:
		| {
				url: string
				startTime: number
		  }
		| null
		| undefined
	activeStations: ActiveStations
	pausedState: PausedState
	deathState: DeathState | null
	reachedPhasesAndCheckpoints: Array<ReachedPhasesAndCheckpoints>
	investigations: Array<{
		culminatingMomentActionId: string
	}>
	literacyEvents: {
		seenEvents: IdMap<FullStateLiteracyEventForActionPlayer>
	}
}

/**
 * The reducer that maintains the state of the ActionPlayer
 */
function reducer(state: ActionPlayerState, action: ActionPlayerAction) {
	switch (action.type) {
		case 'PLAY_ACTION':
			return produce(state, (draft: ActionPlayerState) => {
				const gameAction = action.payload.action
				// Add to list of triggered actions
				draft.triggeredActions.push({
					id: gameAction?._id,
					timestamp: Date.now(),
				})

				if (!gameAction) {
					return
				}

				// Update the current screen
				if (isScreenAction(gameAction)) {
					if (gameAction.newPhase || gameAction.checkpoint) {
						const previousState = {
							...draft,
							reachedPhasesAndCheckpoints: [],
							triggeredActions: state.triggeredActions,
						}
						draft.reachedPhasesAndCheckpoints.push({
							id: gameAction._id,
							type: gameAction.newPhase ? 'PHASE' : 'CHECKPOINT',
							previousState,
						})
					}
					// screen is changing away from a CM
					if (
						draft.currentScreen?.type === 'CULMINATING_MOMENT_SCREEN' &&
						draft.currentScreen._id !== gameAction._id
					) {
						draft.investigations = draft.investigations.filter(
							(investigation) =>
								investigation.culminatingMomentActionId !== draft.currentScreen?._id
						)
					}

					draft.currentScreen = gameAction
				}

				if (gameAction.type === 'VOCAL_TRACK') {
					// Only set the state to soft pause if there is not another vocal track currently playing
					if (gameAction.softPause && draft.vocalTracks.length < 1) {
						startPause(draft, PAUSE_STATE.SOFT_PAUSE)
					}

					draft.vocalTracks.push({
						actionId: gameAction._id,
						url: gameAction.audioUrl,
						onEnd: gameAction.onEnd,
						softPause: gameAction.softPause,
					})
				}

				if (gameAction.type === 'SPECIAL_EFFECT') {
					draft.specialEffects.push({
						actionId: gameAction._id,
						effectId: gameAction.specialEffectId,
						onEnd: gameAction.onEnd,
					})
				}

				if (gameAction.type === 'IMAGE_OVERLAY') {
					draft.overlays.push({
						actionId: gameAction._id,
						url: gameAction.url,
						duration: gameAction.duration,
						type: 'IMAGE',
						onStart: gameAction.onStart,
						onEnd: gameAction.onEnd,
					})
				}

				if (gameAction.type === 'VIDEO_OVERLAY') {
					draft.overlays.push({
						actionId: gameAction._id,
						url: gameAction.url,
						type: 'VIDEO',
						onStart: gameAction.onStart,
						onEnd: gameAction.onEnd,
					})
				}

				if (gameAction.type === 'SONG_CHANGE' && gameAction.songUrl) {
					draft.currentSong = {
						url: gameAction.songUrl,
						startTime: gameAction.startTime,
					}
				}

				if (gameAction.type === 'ACTIVATE_STATION') {
					const stationId = gameAction.stationData.stationId

					if (!('onComplete' in gameAction.stationData)) {
						if (!draft.activeStations[stationId]) {
							draft.activeStations[stationId] = {}
						}

						return
					}

					if (stationId in draft.activeStations) {
						draft.activeStations[stationId]?.onComplete?.push(...gameAction.stationData.onComplete)
					} else {
						draft.activeStations[stationId] = {
							onComplete: gameAction.stationData.onComplete,
						}
					}
				}

				if (gameAction.type === 'DEACTIVATE_STATION') {
					delete draft.activeStations[gameAction.stationId]
				}

				if (gameAction.type === 'MISSION_EFFECT') {
					if (gameAction.effectId === 'PAUSE') startPause(draft, PAUSE_STATE.SOFT_PAUSE)
					else if (gameAction.effectId === 'UNPAUSE') endPause(draft, PAUSE_STATE.SOFT_PAUSE)
					else if (gameAction.effectId === 'MISSION_PAUSE') {
						draft.pausedState.missionPause = {
							onContinue: gameAction.onContinue,
							prompt: gameAction.prompt,
							allowGoBack: gameAction.allowGoBack,
							continueButtonText: gameAction.continueButtonText,
							goBackButtonText: gameAction.goBackButtonText,
						}
					} else if (gameAction.effectId === 'DEATH') {
						draft.deathState = {
							status: true,
							mainScreenText: gameAction.mainScreenText,
							prompt: gameAction.prompt,
							isShipDeath: gameAction.isShipDeath,
							goBackButtonText: gameAction.goBackButtonText,
							onCheatDeath: gameAction.onCheatDeath,
							continueButtonText: gameAction.continueButtonText,
						}
					}
				}

				if (gameAction.type === 'TOGGLE_INVESTIGATION') {
					if (gameAction.status === 'INACTIVE') {
						draft.investigations = draft.investigations.filter(
							(investigation) =>
								investigation.culminatingMomentActionId !== gameAction.culminatingMomentActionId
						)
					} else {
						if (
							!draft.investigations.some(
								(investigation) =>
									investigation.culminatingMomentActionId === gameAction.culminatingMomentActionId
							)
						) {
							draft.investigations.push({
								culminatingMomentActionId: gameAction.culminatingMomentActionId,
							})
						}
					}
				}

				if (gameAction.type === LITERACY_EVENT.SIMULATION_ACTION_TYPE) {
					// If a general assignment, only use the first reading context
					const validReadingContexts =
						gameAction.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL
							? gameAction.readingContexts.slice(0, 1)
							: [...gameAction.readingContexts]

					const readingContexts: IdMap<FullStateReadingContextForActionPlayer> = {}

					validReadingContexts.forEach((readingContext) => {
						const tasks: IdMap<LiteracyEventTask<string>> = {}

						readingContext.taskLists.forEach(({ list: taskList }) => {
							taskList.forEach((task) => {
								tasks[task.id] = task
							})
						})
						const assignment =
							gameAction.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL
								? { ...gameAction.assignment }
								: {
										type: LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM,
										teams: gameAction.assignment.teams
											.filter((assignmentTeam) =>
												assignmentTeam.readingContexts.find(
													(readingContextId) => readingContextId === readingContext.id
												)
											)
											.map((assignmentTeam) => assignmentTeam.teamId),
								  }

						readingContexts[readingContext.id] = {
							title: readingContext.title,
							id: readingContext.id,
							media: readingContext.media,
							text: readingContext.text,
							tasks,
							assignment,
							relevance: readingContext.relevance,
							taskLists: readingContext.taskLists,
						}
					})
					draft.literacyEvents.seenEvents[gameAction._id] = {
						id: gameAction._id,
						actionId: gameAction._id,
						readingContext: readingContexts,
						dataAppearance: gameAction.dataAppearance,
					}
				}
			})

		case 'END_VOCAL_TRACK': {
			return produce(state, (draft: ActionPlayerState) => {
				const vocalTrackIndexToRemove = draft.vocalTracks.findIndex(
					({ actionId }) => actionId === action.payload
				)

				if (draft.vocalTracks[vocalTrackIndexToRemove]?.softPause) {
					endPause(draft, PAUSE_STATE.SOFT_PAUSE)
				}

				if (vocalTrackIndexToRemove !== -1) {
					draft.vocalTracks.splice(vocalTrackIndexToRemove, 1)
				}

				// Check if the newly starting vocal track has softPause
				if (draft.vocalTracks.length > vocalTrackIndexToRemove) {
					if (draft.vocalTracks[vocalTrackIndexToRemove]?.softPause) {
						startPause(draft, PAUSE_STATE.SOFT_PAUSE)
					}
				}
			})
		}

		case 'END_SPECIAL_EFFECT': {
			return produce(state, (draft: ActionPlayerState) => {
				const index = draft.specialEffects.findIndex(({ actionId }) => actionId === action.payload)

				if (index !== -1) {
					draft.specialEffects.splice(index, 1)
				}
			})
		}

		case 'STATION_COMPLETED': {
			return produce(state, (draft: ActionPlayerState) => {
				delete draft.activeStations[action.payload.stationId]
			})
		}

		case 'SET_PAUSE': {
			return produce(state, (draft: ActionPlayerState) => {
				const nextPauseState: PAUSE_STATE = action.payload.nextPauseState
				if (nextPauseState === PAUSE_STATE.PAUSED) startPause(draft, PAUSE_STATE.PAUSED)
				else endPause(draft, PAUSE_STATE.PAUSED)
			})
		}

		case 'FINISH_MISSION_PAUSE': {
			return produce(state, (draft: ActionPlayerState) => {
				draft.pausedState.missionPause = undefined
			})
		}

		case 'REVIVE': {
			const previousState = action.payload.previousState
			return {
				...previousState,
				deathState: null,
				reachedPhasesAndCheckpoints: state.reachedPhasesAndCheckpoints.slice(0, -1),
			}
		}

		case 'CHEAT_DEATH': {
			return produce(state, (draft: ActionPlayerState) => {
				if (draft.deathState) draft.deathState.status = false
			})
		}

		case 'END_OVERLAY': {
			return produce(state, (draft: ActionPlayerState) => {
				const index = draft.overlays.findIndex(({ actionId }) => actionId === action.payload)

				if (index !== -1) {
					draft.overlays.splice(index, 1)
				}
			})
		}

		case 'DISMISS_LITERACY_EVENT': {
			return produce(state, (draft: ActionPlayerState) => {
				delete draft.literacyEvents.seenEvents[action.payload]
			})
		}

		default:
			return state
	}
}

const STATUS_BAR_HEIGHT = '70px'

function ActionPlayer({
	action,
	actions,
	onClose,
}: {
	action: Action<string>
	actions: ActionMap<string>
	onClose: () => unknown
}) {
	const [state, dispatch] = useReducer(reducer, {
		currentScreen: null,
		triggeredActions: [],
		vocalTracks: [],
		specialEffects: [],
		currentSong: null,
		activeStations: {},
		pausedState: {
			state: PAUSE_STATE.PLAYING,
		},
		deathState: null,
		reachedPhasesAndCheckpoints: [],
		overlays: [],
		investigations: [],
		literacyEvents: { seenEvents: {} },
	})
	const {
		currentScreen,
		triggeredActions,
		vocalTracks,
		specialEffects,
		currentSong,
		activeStations,
		pausedState,
		deathState,
		overlays,
		reachedPhasesAndCheckpoints,
		literacyEvents,
	} = state

	/**
	 * Plays all actions represented by the given list of actionIds (the eventResult)
	 */
	const playActions = useCallback(
		function (eventResult: EventResult<string>) {
			/**
			 * Starts timers for a given action.
			 * TODO: Pause timers
			 */
			const setupTimers = function (action: Action<string>) {
				if ('atTime' in action) {
					action.atTime.forEach(({ timestamp, actions }) => {
						setTimeout(() => {
							playActions(actions)
						}, timestamp)
					})
				}

				if (action.type === 'START_TIMER') {
					setTimeout(() => {
						playActions(action.onEnd)
					}, action.duration)
				}
			}

			eventResult.forEach((actionId) => {
				const currentAction = actions[actionId]
				dispatch({
					type: 'PLAY_ACTION',
					payload: {
						action: currentAction,
					},
				})

				if (!currentAction) {
					return
				}

				if ('onStart' in currentAction) {
					playActions(currentAction.onStart)
				}

				setupTimers(currentAction)
			})
		},
		[actions]
	)
	// Play the initial action
	useEffect(() => {
		playActions([action._id])
	}, [playActions, action])
	return (
		<>
			<div css="width: 100%; height: 100%; display: flex; flex-direction: row;">
				<TriggeredActions triggeredActions={triggeredActions} actions={actions} />
				<div css="flex: 1;">
					<StatusBar
						dispatch={dispatch}
						onClose={onClose}
						pausedState={pausedState}
						activeStations={activeStations}
						playActions={playActions}
						deathState={deathState}
						overlays={overlays}
						actionPlayerState={state}
						actions={actions}
						css={`
							height: ${STATUS_BAR_HEIGHT};
							border-bottom: 1px solid gray;
						`}
						seenLiteracyEvents={literacyEvents.seenEvents}
					/>
					<Screen
						dispatch={dispatch}
						currentScreen={currentScreen}
						playActions={playActions}
						pausedState={pausedState}
						deathState={deathState}
						overlays={overlays}
						reachedPhasesAndCheckpoints={reachedPhasesAndCheckpoints}
						actions={actions}
						css={`
							height: calc(100% - ${STATUS_BAR_HEIGHT});
						`}
					/>
				</div>
			</div>
			{vocalTracks[0] && (
				<Audio
					src={vocalTracks[0].url}
					volume={VOCAL_TRACK_VOLUME}
					pausedState={pausedState}
					deathState={deathState}
					autoPlay
					key={vocalTracks[0].actionId}
					onEnded={() => {
						playActions(vocalTracks[0].onEnd)
						dispatch({
							type: 'END_VOCAL_TRACK',
							payload: vocalTracks[0].actionId,
						})
					}}
				/>
			)}
			{specialEffects.map(({ actionId, effectId, onEnd }) => (
				<Audio
					src={getSpecialEffect(effectId)?.audioSrc}
					volume={SPECIAL_EFFECTS_VOLUME}
					pausedState={pausedState}
					deathState={deathState}
					autoPlay
					key={actionId}
					onEnded={() => {
						playActions(onEnd)
						dispatch({
							type: 'END_SPECIAL_EFFECT',
							payload: actionId,
						})
					}}
				/>
			))}
			{currentSong?.url && (
				<Audio
					startTime={currentSong.startTime}
					volume={MUSIC_VOLUME}
					src={currentSong.url}
					pausedState={pausedState}
					deathState={deathState}
					autoPlay
					loop
				/>
			)}
		</>
	)
}

/**
 * An audio component that manages setting the volume on the audio element, and can start the audio at a specific time
 */
function Audio({
	volume,
	startTime,
	pausedState,
	deathState,
	...props
}: HTMLProps<HTMLAudioElement> & {
	volume: number
	startTime?: number
	pausedState?: PausedState
	deathState: DeathState | null | undefined
}) {
	const ref = useRef<HTMLAudioElement>(null)
	// Set the volume
	useEffect(() => {
		if (ref.current) {
			ref.current.volume = volume
		}
	})
	// Make the song play from the desired time
	useEffect(() => {
		if (startTime && ref.current) {
			ref.current.currentTime = startTime / 1000
		}
	}, [startTime])
	useEffect(() => {
		if (ref.current && pausedState) {
			if (pausedState.state === PAUSE_STATE.PAUSED || pausedState.missionPause) ref.current.pause()
			else ref.current.play()
		}
	}, [pausedState])
	useEffect(() => {
		if (ref.current) {
			if (deathState?.status) ref.current.pause()
			else ref.current.load()
		}
	}, [deathState])
	return <audio {...props} ref={ref} />
}

const ActionPlayerContainer = styled.div`
	background-color: black;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1050;
	color: white;
`
