import type { TileParams } from '../types/tiles'
import axios from 'axios'
import { toast } from 'react-toastify'
import type { NavigationMap } from '@mission.io/navigation-map-server'
import { config } from '../config'

const NAVIGATION_MAPS_URL = `${config.simulationsApiUrl}/api/navigation/maps`

type NavigationMapMeta = {
	name: string
	_id: string
}
type NavigationMapWithType = NavigationMap & {
	type: 'WORLD'
}
type NavigationMapMetaLookup = Record<string, NavigationMapMeta>
type NavigationMapLookup = Record<string, NavigationMap>
export type NavigationMapStore = {
	fetching: Record<string, boolean>
	mapMeta: NavigationMapMetaLookup | null | undefined
	maps: NavigationMapLookup
}
const types: {
	SET_NAVIGATION_META: 'SET_NAVIGATION_META'
	SET_NAVIGATION_MAPS: 'SET_NAVIGATION_MAPS'
	SET_FETCHING_NAVIGATION_DATA: 'SET_FETCHING_NAVIGATION_DATA'
	REFRESH_NAVIGATION_STORE: 'REFRESH_NAVIGATION_STORE'
	ADD_NAVIGATION_ERROR: 'ADD_NAVIGATION_ERROR'
} = {
	SET_NAVIGATION_META: 'SET_NAVIGATION_META',
	SET_NAVIGATION_MAPS: 'SET_NAVIGATION_MAPS',
	SET_FETCHING_NAVIGATION_DATA: 'SET_FETCHING_NAVIGATION_DATA',
	REFRESH_NAVIGATION_STORE: 'REFRESH_NAVIGATION_STORE',
	ADD_NAVIGATION_ERROR: 'ADD_NAVIGATION_ERROR',
}
type SetFetchingAction = {
	type: 'SET_FETCHING_NAVIGATION_DATA'
	payload: Record<string, boolean>
}
type SetNavigationMetaAction = {
	type: 'SET_NAVIGATION_META'
	payload: NavigationMapMetaLookup
}
type SetNavigationMapAction = {
	type: 'SET_NAVIGATION_MAPS'
	payload: NavigationMap[]
}
type RefreshNavigationStoreAction = {
	type: 'REFRESH_NAVIGATION_STORE'
}

function setFetching(fetching: Record<string, boolean>): SetFetchingAction {
	return {
		type: types.SET_FETCHING_NAVIGATION_DATA,
		payload: fetching,
	}
}

function setNavigationMeta(meta: NavigationMapMetaLookup): SetNavigationMetaAction {
	return {
		type: types.SET_NAVIGATION_META,
		payload: meta,
	}
}

function setNavigationMaps(entities: NavigationMap[]): SetNavigationMapAction {
	return {
		type: types.SET_NAVIGATION_MAPS,
		payload: entities,
	}
}

export function refreshStore(): RefreshNavigationStoreAction {
	return {
		type: types.REFRESH_NAVIGATION_STORE,
	}
}
export function fetchNavigationMeta(): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		const state = getState()

		if (state.navigation.fetching.meta) {
			return
		}

		dispatch(
			setFetching({
				meta: true,
			})
		)
		axios
			.get(`${NAVIGATION_MAPS_URL}/meta`)
			.then(({ data }) => {
				if (data.meta) {
					const formattedMeta: NavigationMapMetaLookup = {}
					data.meta.forEach((item: NavigationMapMeta) => (formattedMeta[item._id] = item))
					dispatch(setNavigationMeta(formattedMeta))
				}

				if (data.error) {
					console.error(`Error while fetching navigationMap Meta Data: ${data.error}`)
				}
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
				} else {
					console.error(errData.error)
				}
			})
			.finally(() =>
				dispatch(
					setFetching({
						meta: false,
					})
				)
			)
	}
}
export function fetchNavigationMaps(ids: string[]): (arg0: TileParams<void>) => void {
	return ({ dispatch, getState }: TileParams<void>) => {
		const state = getState()
		ids = ids.filter((id) => !state.navigation.fetching[id])

		if (ids.length === 0) {
			return
		}

		const fetchingData: Record<string, boolean> = {}
		ids.forEach((id) => (fetchingData[id] = true))
		dispatch(setFetching(fetchingData))
		axios
			.get(NAVIGATION_MAPS_URL, {
				params: {
					ids: ids.join(' '),
				},
			})
			.then(({ data }) => {
				dispatch(setNavigationMaps(data.maps))
			})
			.catch((errData) => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
				} else {
					console.error(errData.message)
				}
			})
			.finally(() => {
				const fetching: Record<string, boolean> = {}
				ids.forEach((id) => {
					fetching[id] = false
				})
				dispatch(setFetching(fetching))
			})
	}
}
export function updateNavigationMap(
	map: NavigationMapWithType
): (arg0: TileParams<void>) => Promise<NavigationMap> {
	return ({ dispatch, getState }: TileParams<void>) => {
		return axios
			.post(NAVIGATION_MAPS_URL, {
				map,
			})
			.then(({ data }) => {
				if (data.error) {
					console.error(data.error)
				}

				if (data.map) {
					dispatch(setNavigationMaps([data.map]))
					return data.map
				} else {
					console.error(data)
					throw new Error(`Server did not return a map: ${data.error}`)
				}
			})
	}
}
export function copyNavigationMap(mapId: string): (arg0: TileParams<void>) => Promise<unknown> {
	return ({ dispatch }: TileParams<void>) => {
		return axios
			.post(`${NAVIGATION_MAPS_URL}/copy/${mapId}`)
			.catch((error) => {
				let message = ''

				if (typeof error === 'object') {
					message = error.message || ''
				} else if (typeof error === 'string') {
					message = error
				}

				toast.error(`The following error occurred while copying map "${mapId}": ${message}`)
			})
			.finally(() => {
				dispatch(refreshStore())
			})
	}
}
const initialState: NavigationMapStore = {
	fetching: {},
	mapMeta: null,
	maps: {},
}
type Actions =
	| SetNavigationMetaAction
	| SetFetchingAction
	| SetNavigationMapAction
	| RefreshNavigationStoreAction
export default function navigationMapReducer(
	state: NavigationMapStore = initialState,
	action: Actions
): NavigationMapStore {
	switch (action.type) {
		case types.SET_NAVIGATION_META:
			return { ...state, mapMeta: { ...state.mapMeta, ...action.payload } }

		case types.SET_FETCHING_NAVIGATION_DATA:
			return { ...state, fetching: { ...state.fetching, ...action.payload } }

		case types.SET_NAVIGATION_MAPS:
			const newMaps: Record<string, NavigationMap> = {}
			action.payload.forEach((map) => (newMaps[String(map._id)] = map))
			return { ...state, maps: { ...state.maps, ...newMaps } }

		case types.REFRESH_NAVIGATION_STORE:
			return initialState

		default:
			return state
	}
} // selectors

type NavigationMapResponse = {
	map: NavigationMap | null | undefined
	fetching: boolean | null | undefined
}
type NavigationMapMetaResponse = {
	meta: NavigationMapMeta[] | null | undefined
	fetching: boolean | null | undefined
}
export function getNavigationMap(
	id: string | null | undefined
): (state: { navigation: NavigationMapStore }) => NavigationMapResponse {
	return (state: { navigation: NavigationMapStore }) => {
		const navigationStore = state.navigation
		return {
			map: id ? navigationStore.maps[id] : null,
			fetching: id ? navigationStore.fetching[id] : null,
		}
	}
}
export function getNavigationMeta(state: {
	navigation: NavigationMapStore
}): NavigationMapMetaResponse {
	const navigationStore = state.navigation
	let meta = null

	if (navigationStore.mapMeta) {
		meta = Object.values(navigationStore.mapMeta) as NavigationMapMeta[]
	}

	return {
		meta,
		fetching: navigationStore.fetching.meta,
	}
}
