import React, { Component } from 'react'
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte'
const TOOLBAR_CONFIG: ToolbarConfig = {
	display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
	INLINE_STYLE_BUTTONS: [
		{
			label: 'Bold',
			style: 'BOLD',
		},
		{
			label: 'Italic',
			style: 'ITALIC',
		},
	],
	BLOCK_TYPE_BUTTONS: [
		{
			label: 'Bullet List',
			style: 'unordered-list-item',
		},
		{
			label: 'Number List',
			style: 'ordered-list-item',
		},
	],
	BLOCK_ALIGNMENT_BUTTONS: [],
	BLOCK_TYPE_DROPDOWN: [],
}
type Props = {
	input: {
		value: string | null | undefined
		onChange: (arg0: string) => void
	}
	reset?: boolean
}
type State = {
	value: EditorValue | null
}
export default class FormTextEditor extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			value: null,
		}
	}

	componentDidMount() {
		const {
			input: { value },
		} = this.props
		this.setState({
			value: value
				? RichTextEditor.createValueFromString(value, 'html')
				: RichTextEditor.createEmptyValue(),
		})
	}

	componentDidUpdate(prevProps: Props) {
		if (typeof prevProps.reset !== 'undefined' && typeof this.props.reset !== 'undefined') {
			if (!prevProps.reset && this.props.reset) {
				const {
					input: { value },
				} = this.props
				this.setState({
					value: value
						? RichTextEditor.createValueFromString(value, 'html')
						: RichTextEditor.createEmptyValue(),
				})
			}
		}
	}

	handleChange = (value: EditorValue) => {
		const {
			input: { onChange },
		} = this.props
		this.setState({
			value,
		})
		onChange(value.toString('html'))
	}

	render(): JSX.Element {
		const { value } = this.state

		if (value) {
			return (
				<RichTextEditor value={value} onChange={this.handleChange} toolbarConfig={TOOLBAR_CONFIG} />
			)
		} else {
			return <div />
		}
	}
}
