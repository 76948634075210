import React from 'react'
import MarkDownInput from './MarkdownInput'
import type { CustomComponentDescriptors } from './MarkdownInput'

type Props = {
	input: {
		value: string
		onChange: (newValue: string) => void
	}
	className?: string
	customComponents?: CustomComponentDescriptors
	preparser?: (value: string) => string
}
/**
 * MarkdownField - A wrapper of MarkdownInput which allows it to be used with Redux-Form
 *
 * @return {React$Node}
 */
export default function MarkdownField({
	input: { value, onChange },
	...props
}: Props): JSX.Element {
	return (
		<MarkDownInput
			value={value}
			onChange={onChange}
			className={props.className}
			customComponents={props.customComponents}
			preparser={props.preparser}
		/>
	)
}
/**
 * Same as above, but with `previewVertical` set to true
 */

export function VerticalMarkdownField({
	input: { value, onChange },
	...props
}: Props): JSX.Element {
	return (
		<MarkDownInput
			value={value}
			onChange={onChange}
			previewVertical
			className={props.className}
			customComponents={props.customComponents}
			preparser={props.preparser}
		/>
	)
}
