import React from 'react'
import styled, { css } from 'styled-components'
import { CATEGORY_TO_COLOR } from '../../helpers/styles'
import { Draggable } from '../../DragDrop'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import { createDraggableItem, type ActionBankItem, type Category } from '../../actionDefinitions'

const styledCardStyle = css<{ $category: Category }>`
	padding: 10px 0 10px 0;
	color: white;
	width: 18%;
	text-align: center;
	border-radius: 5px;
	font-size: 0.85rem;
	cursor: pointer;
	background-color: ${({ $category }) => CATEGORY_TO_COLOR[$category]};
`
const JustStyle = styled.div<{ $category: Category }>`
	${styledCardStyle}
`

const UnDraggableCard = (props: { $category: Category; className?: string }) => {
	return (
		<JustStyle
			{...props}
			onMouseDown={() => {
				toast.warn('Item cannot be dragged. Contact the Dev team if problem persists')
			}}
		/>
	)
}

const DraggableCard = styled(Draggable)<{ $category: Category }>`
	${styledCardStyle}
`
export function Card({
	item,
	category,
	allowDrag = true,
	className,
	...rest
}: {
	item: ActionBankItem
	allowDrag?: boolean
	category: Category
	className?: string
	children?: React.ReactNode
}): JSX.Element {
	const draggable = createDraggableItem(item)
	const StyledCard = draggable ? DraggableCard : UnDraggableCard
	return (
		<StyledCard
			{...rest}
			item={draggable}
			$category={category}
			className={classnames(className, 'card')}
		/>
	)
}
