import React, { useState } from 'react'
import styled from 'styled-components'
import { FormGroup, CardBody, Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { List, StyledCard } from './sharedComponents'
import Map from '../../maps/Map/Map'
import { actions } from '../../../setup/tiles'
import {
	selectors as simulationSelectors,
	deleteMap as deleteMapFromSimulation,
	deleteObject as deleteObjectFromSimulation,
	setMapOrdering,
} from '../../../reducers/simulationEditor'
import { selectors } from '../../../setup/tiles'
import Markdown from '../../../common/Markdown'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Label } from 'reactstrap'
import type { NormalizedMap as MapType } from '../../../types'
/**
 * MapList - a react component which allows the editing of the map ordering on the mission-guide
 */

export default function MapList(): JSX.Element {
	const dispatch = useDispatch()
	const mapIds = useSelector(simulationSelectors.getMapIds)
	const maps = useSelector(selectors.maps.store)

	/**
	 * reorderMap - move a map to a new position within the map list. Called by DragDropContext from react-beautiful-dnd.
	 *
	 * @param {{index: number, droppableId: string}} {source} - the starting location of the map
	 * @param {{index: number, droppableId: string}} {destination} - the ending location of the map
	 */
	const reorderMap = ({ source, destination }: DropResult) => {
		if (!destination || source.index === destination.index) {
			return
		}

		const newOrdering = [...mapIds]
		const item = newOrdering.splice(source.index, 1)[0]
		newOrdering.splice(destination.index, 0, item)
		dispatch(setMapOrdering(newOrdering))
	}

	return (
		<FormGroup>
			<Label>Maps:</Label>
			<DragDropContext onDragEnd={reorderMap}>
				<Droppable droppableId="map-ordering">
					{(provided) => (
						<List ref={provided.innerRef}>
							{mapIds.map((mapId, index) => (
								<Draggable key={mapId} draggableId={mapId} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}>
											<MapDisplay map={maps[mapId]} />
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</List>
					)}
				</Droppable>
			</DragDropContext>
		</FormGroup>
	)
}
/**
 * MapDisplay - a react component which allows the displaying and editing of a map within the MapList
 *
 * @param {{map}} map - the map to display and edit
 */

function MapDisplay({ map }: { map: MapType }): JSX.Element {
	const [isEditing, setIsEditing] = useState(false)
	const dispatch = useDispatch()
	return (
		<StyledCard>
			<CardBody>
				<RelativeWrapper>
					{!isEditing ? (
						<MapData>
							<MapImageColumn>
								{map.backgroundImage ? (
									<MapImage src={map.backgroundImage} />
								) : (
									<MapColorImage
										style={{
											backgroundColor: map.color || undefined,
										}}
									/>
								)}
							</MapImageColumn>

							<MapColumn>
								<h2>{map.name}</h2>
								<Markdown>{map.description}</Markdown>
							</MapColumn>
						</MapData>
					) : (
						<Map
							map={map}
							deleteMap={() => dispatch(deleteMapFromSimulation(map._id))}
							objectDelete={(objectId: string) => {
								dispatch(deleteObjectFromSimulation(objectId))
							}}
							onUpdate={(newMap) => {
								dispatch(actions.maps.store.updateMap(newMap))
								dispatch(
									actions.maps.patch({
										id: newMap._id,
										...newMap,
									})
								)
							}}
							isAutomated={true}
						/>
					)}
					<MapButtonWrapper>
						<Button onClick={() => setIsEditing(!isEditing)} color="primary">
							{isEditing ? 'Stop Editing' : `Edit Map`}
						</Button>
					</MapButtonWrapper>
				</RelativeWrapper>
			</CardBody>
		</StyledCard>
	)
}

const RelativeWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`
const MapData = styled.div`
	display: flex;
`
const MapImageColumn = styled.div`
	flex: 0.1 0.1 0;
	align-self: center;
`
const MapImage = styled.img`
	max-height: 100%;
	max-width: 100%;
`
const MapColorImage = styled.div`
	float: left;
	width: 100%;
	height: 100%;
	shape-outside: circle();
	clip-path: circle();

	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
`
const MapColumn = styled.div`
	flex: 0.9 0.9 0;
	padding: 4px;
`
const MapButtonWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
`
