import React from 'react'
import Tree, { CustomNodeElementProps, RawNodeDatum } from 'react-d3-tree'
import styled from 'styled-components'
import { actionHelpers } from '../PhaseCreator/helpers'
import { isScreenActionType, ActionId, getTitle } from '../actionDefinitions'
import { getNextActionIdsFromAction } from '../helpers/algorithms'
import type { Action } from '@mission.io/mission-toolkit/actions'
import { useFormContext } from '../FormContext'
import { IdMap } from '../../../types/util'
import { useDimensions } from '../../../helpers/hooks'
type ActionGraphProps = {
	topActionId: ActionId
	actions: IdMap<Action<string>>
}
interface DataItem extends RawNodeDatum {
	attributes: {
		actionId: string
	}
}

export default function ActionGraph({ topActionId, actions }: ActionGraphProps): JSX.Element {
	const [treeContainerRef, dimensions] = useDimensions<HTMLDivElement>()
	const translate = {
		x: 50,
		y: dimensions.height == null ? 0 : dimensions.height / 2,
	}
	const { push } = useFormContext()
	const separation = {
		siblings: 1.1,
		nonSiblings: 1.1,
	}
	const data = createGraphData(
		topActionId,
		actions,
		createDataItem(actions[topActionId], topActionId)
	)
	return (
		<TreeContainer ref={treeContainerRef} id="treeWrapper">
			<Tree
				translate={translate}
				separation={separation}
				collapsible={false}
				pathFunc="elbow"
				zoomable={true}
				data={data}
				onNodeClick={(node) => {
					if (!node.data.attributes) {
						return
					}
					if (typeof node.data.attributes.actionId !== 'string') {
						throw new Error('Expected actionId on clicked node to be a string')
					}
					push({
						id: node.data.attributes.actionId,
						graph: false,
					})
				}}
				renderCustomNodeElement={({ nodeDatum, onNodeClick }: CustomNodeElementProps) => {
					const actionId = nodeDatum.attributes?.actionId as string | undefined
					const action: Action<string> | undefined = actionId ? actions[actionId] : undefined
					const color = actionHelpers.getColor(action)
					return (
						<>
							<rect
								{...{
									width: '85',
									height: '45',
									x: '-20',
									y: '-20',
									fill: color,
									stroke: action && isScreenActionType(action.type) ? 'gold' : color,
									rx: '5',
									onClick: onNodeClick,
								}}
							/>
							<foreignObject width="80" height="40" x="-18" y="-18" pointerEvents="none">
								<Label>{nodeDatum.name}</Label>
							</foreignObject>
						</>
					)
				}}
			/>
		</TreeContainer>
	)
}
/*
 * Creates data for react-de-tree in the form that react-d3-requires.
 * Performs a depth first search to get all children actions of the given topActionId.
 * The depth first search does not look at children of screen actions (or the tree could go on forever)
 */

function createGraphData(
	topActionId: ActionId,
	actions: IdMap<Action<string>>,
	dataItem: DataItem
): DataItem {
	const nextActionIds = getNextActionIdsFromAction(topActionId, actions)

	if (nextActionIds.length > 0) {
		const children: Array<DataItem> = []
		nextActionIds.forEach((actionId) => {
			const action: Action<string> | null | undefined = actions[actionId]
			let newDataItem: DataItem = createDataItem(action, actionId)

			if (action && !isScreenActionType(action.type)) {
				const result = createGraphData(actionId, actions, newDataItem)

				if (result.children) {
					newDataItem = { ...newDataItem, children: result.children }
				}
			}

			children.push(newDataItem)
		})
		dataItem.children = children
	}

	return dataItem
}

function createDataItem(action: Action<string> | null | undefined, actionId: ActionId): DataItem {
	return {
		name: action ? getTitle(action) : `Unknown Action!!`,
		attributes: {
			actionId,
		},
	}
}

const Label = styled.div`
	font-size: 0.8rem;
	text-align: center;
	color: white;
	height: 100%;
`
const TreeContainer = styled.div`
	flex: 1;
	width: 100%;
	height: 100%;
	overflow-x: scroll;
`
