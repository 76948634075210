import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Input, Button, InputGroup, ListGroup, ListGroupItem } from 'reactstrap'
import styled from 'styled-components'
import { FaSync, FaClone } from 'react-icons/fa'
import SimulationTabs from '../simulations/SimulationTabs'
import {
	fetchEntityMeta,
	refreshStore as refreshEntityStore,
	copyEntity,
} from '../../reducers/entities'
import {
	fetchNavigationMeta,
	refreshStore as refreshMapStore,
	copyNavigationMap,
} from '../../reducers/navigationMap'
import { Spinner } from '../../common/Spinner'
import { getNewEntityUrl, getNewMapUrl } from './utilities'
import { useNavigationMapMeta, useEntityMeta } from './hooks'
/**
 * An application page that allows a user to edit/create navigation maps and entities.
 */

export default function NavigationMapBank(): JSX.Element {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchNavigationMeta())
		dispatch(fetchEntityMeta())
	})
	return (
		<Container>
			<SimulationTabs
				tabs={[
					{
						title: 'Navigation Maps',
						component: (
							<ListOfMeta
								useMeta={useNavigationMapMeta}
								keyword="map"
								url={getNewMapUrl()}
								refresh={() => dispatch(refreshMapStore())}
								copy={(id: string) => dispatch(copyNavigationMap(id))}
							/>
						),
					},
					{
						title: 'Entities',
						component: (
							<ListOfMeta
								useMeta={useEntityMeta}
								keyword="entity"
								url={getNewEntityUrl()}
								refresh={() => dispatch(refreshEntityStore())}
								copy={(id: string) => dispatch(copyEntity(id))}
							/>
						),
					},
				]}
			/>
		</Container>
	)
}

function ListOfMeta({
	useMeta,
	keyword,
	url,
	refresh,
	copy,
}: {
	useMeta: () => {
		fetching: boolean | null | undefined
		meta:
			| Array<{
					name: string
					_id: string
			  }>
			| null
			| undefined
	}
	keyword: string
	url: string
	refresh: () => unknown
	copy: (id: string) => unknown
}) {
	const { meta: items, fetching } = useMeta()
	const [filterString, setFilterString] = useState('')
	return (
		<div>
			<InputGroup>
				<SearchBar applySearch={setFilterString} />
				<Button tag={Link} to={url}>
					Create {keyword}
				</Button>
				<Button>
					<FaSync onClick={refresh} />
				</Button>
			</InputGroup>
			{fetching && <Spinner />}
			{items && (
				<StyledListGroup>
					{items.length === 0 ? (
						<div>No {keyword} exists</div>
					) : (
						items
							.filter((entityMeta) =>
								entityMeta.name.toLowerCase().includes(filterString.toLowerCase())
							)
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map((value) => {
								return (
									<StyledListGroupItem key={value._id}>
										<StyledLink href={`${url}/${value._id}`}>{value.name}</StyledLink>
										<FaClone onClick={() => copy(value._id)} title="clone" />
									</StyledListGroupItem>
								)
							})
					)}
				</StyledListGroup>
			)}
		</div>
	)
}

function SearchBar({ applySearch }: { applySearch: (arg0: string) => void }) {
	const [search, setSearch] = useState('')

	const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value)
		applySearch(e.currentTarget.value)
	}

	return <Input value={search} onChange={onChange} placeholder="Search..." />
}

const StyledListGroupItem = styled(ListGroupItem)`
	display: flex !important;
	justify-content: space-between;

	> * {
		color: #007bff;

		&:hover {
			color: #004794;
			text-decoration: none;
			cursor: pointer;
		}
	}
`
const StyledListGroup = styled(ListGroup)`
	margin-top: 8px;
	height: 60vh;
	overflow: auto;
`
const StyledLink = styled.a`
	flex: 1 0 0;
`
