import React, { Component } from 'react'
import { AutoSizer, Table, Column } from 'react-virtualized'
import styled from 'styled-components'
import { humanFileSize } from '../../helpers/functions'
export type File = {
	type: 'file'
	name: string
	key: string
	size: number
	lastModified: number
	url: string
}
type Props = {
	className?: string
	files: File[]
	onFileClick: (file: File) => void
	searchTerm: string
}
export default class FileDetails extends Component<Props> {
	render(): JSX.Element {
		const { className, files, onFileClick, searchTerm } = this.props
		let parsedFiles = files

		if (searchTerm.length > 0) {
			parsedFiles = files.filter(
				(file) => file.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
			)
		}

		return (
			<StyledFileDetails className={className}>
				<AutoSizer>
					{({ width, height }) => (
						<Table
							onRowClick={({ index }) => onFileClick(parsedFiles[index])}
							width={width}
							height={height}
							headerHeight={49}
							rowHeight={49}
							rowCount={parsedFiles.length}
							rowGetter={({ index }) => parsedFiles[index]}>
							<Column label="Name" dataKey="name" width={width / 2} flexGrow={1} />
							<Column
								label="Size"
								dataKey="size"
								width={width / 6}
								flexGrow={1}
								cellDataGetter={({ dataKey, rowData }) => humanFileSize(rowData[dataKey])}
							/>
							<Column
								label="Last Modified"
								dataKey="lastModified"
								width={width / 3}
								flexGrow={1}
								cellDataGetter={({ dataKey, rowData }) => humanDate(rowData[dataKey])}
							/>
						</Table>
					)}
				</AutoSizer>
			</StyledFileDetails>
		)
	}
}

function humanDate(milliseconds: number): string {
	return new Date(milliseconds).toLocaleString()
}

const StyledFileDetails = styled.div`
	.row {
		cursor: pointer;
	}
`
