import React from 'react'
import { Button, ButtonProps } from 'reactstrap'

/**
 * OptionsMessage
 *
 * Props:
 * 	message: The main message to show.
 *  buttons: An array of button tuples of the form [string, ButtonProps]. Data from each tuple
 *              will be used to make a button with the provided text and props.
 */

export default function OptionMessage({
	message,
	buttons,
}: {
	message: string
	buttons: Array<[string, ButtonProps]>
}): JSX.Element {
	return (
		<div>
			<div css="margin-bottom: 8px;">{message}</div>
			<div css="display: inline-flex; justify-content: space-between;">
				{buttons.map(([text, props]) => (
					<Button {...props} key={text} css="margin-left: 8px;">
						{text}
					</Button>
				))}
			</div>
		</div>
	)
}
