import React, { Component } from 'react'
import { FormText, Label } from 'reactstrap'
import ExpositionRow from './ExpositionRow'
import type { Exposition } from './ExpositionRow'
import { FieldArrayFieldsProps } from 'redux-form'
export default class Expositions extends Component<{
	fields: FieldArrayFieldsProps<Exposition>
}> {
	renderTable: () => JSX.Element = () => {
		return (
			<table className="table table-sm mb-1 exposition-table">
				<thead>
					<tr>
						<th
							style={{
								width: '15%',
							}}>
							Delay (ms)
						</th>
						<th
							style={{
								width: '20%',
							}}>
							Effect
						</th>
						<th
							style={{
								width: '20%',
							}}>
							Accessibility Video
						</th>
						<th
							style={{
								width: '35%',
							}}>
							Caption
						</th>
						<th
							style={{
								width: '10%',
							}}
						/>
					</tr>
				</thead>
				<tbody>
					{this.props.fields
						.getAll()
						.map((value, index) => ({ ...value, originalIndex: index }))
						.sort((a, b) => (a.delay > b.delay ? 1 : -1))
						.map((value) => {
							return (
								<ExpositionRow
									field={value}
									onRemove={this.remove}
									onSave={this.save}
									onCreate={this.create}
									creator={false}
									key={value._id}
								/>
							)
						})}
					<ExpositionRow
						field={null}
						onRemove={this.remove}
						onSave={this.save}
						onCreate={this.create}
						creator={true}
					/>
				</tbody>
			</table>
		)
	}

	render(): JSX.Element {
		return (
			<div className="Expositions">
				<Label>Expositions</Label>
				<FormText>Settings related to expositions.</FormText>

				{this.renderTable()}
			</div>
		)
	}

	remove: (index: number) => void = (index: number) => {
		this.props.fields.remove(index)
	}
	save: (index: number, newValue: Exposition) => void = (index: number, newValue: Exposition) => {
		this.props.fields.splice(index, 1, newValue)
	}
	create: (exposition: Exposition) => void = (exposition: Exposition) => {
		this.props.fields.push(exposition)
	}
}
