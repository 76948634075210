import React from 'react'
import { Button, Row, Col } from 'reactstrap'
import GuideHeader from './GuideHeader'
import type { Guide, Effect, Card, ExpositionGuide, AutoEffectGuide } from '../../../types'
import { FieldArray, FieldArrayFieldsProps } from 'redux-form'
import GuideSubHeader from './GuideSubHeader'
import type { Exposition } from '../Expositions/ExpositionRow'
import {
	SortableContainer,
	SortableContainerProps,
	SortableElement,
	SortableElementProps,
	SortableHandle,
} from 'react-sortable-hoc'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components'
import ExpositionEffectView from './ExpositionEffect'
import GuideSummaryProceed from './GuideSummaryProceed'
export const DragHandle = SortableHandle(() => <FontAwesome name="bars" title="Sort" />)
type Props = {
	fields: FieldArrayFieldsProps<Guide>
	expositions: Exposition[]
	effects: Effect[]
	cardList: Card[]
}

type GuideCardProps = {
	name: string
	removeCard: () => unknown
	currentGuide: Guide
	getContent: (currentGuide: ExpositionGuide | AutoEffectGuide) => Exposition | Effect | undefined
	cardList: Card[]
	changeSelection: (guide: Guide) => void
}

const GuideCardSortable: React.ComponentClass<SortableElementProps & GuideCardProps> =
	SortableElement(
		({ name, removeCard, currentGuide, getContent, cardList, changeSelection }: GuideCardProps) => {
			const getCardClass = (): string => {
				if (currentGuide.type === 'EXPOSITION' || currentGuide.type === 'AUTO_EFFECT') {
					return 'p-2 pl-3 mb-2 bg-light'
				}

				return 'p-3 mb-2'
			}

			return (
				<BorderedDiv className={getCardClass()}>
					<Row>
						<Col xs={1} className="pr-0">
							<DragAndDrop className="mt-1">
								<DragHandle />
							</DragAndDrop>
						</Col>
						<Col xs={11} className="pl-0">
							{currentGuide.type !== 'EXPOSITION' && currentGuide.type !== 'AUTO_EFFECT' && (
								<div>
									<GuideHeader
										name={name}
										changeSelection={changeSelection}
										remove={removeCard}
										guide={currentGuide}
										cardList={cardList}
									/>
									{currentGuide.type !== 'TRIGGER' && (
										<FieldArray
											name={`${name}.subHeaders`}
											component={GuideSubHeader}
											rerenderOnEveryChange={true}
										/>
									)}
								</div>
							)}
							{(currentGuide.type === 'EXPOSITION' || currentGuide.type === 'AUTO_EFFECT') && (
								<div>
									<ExpositionEffectView
										name={name}
										content={getContent(currentGuide)}
										guide={currentGuide}
									/>
								</div>
							)}
						</Col>
					</Row>
				</BorderedDiv>
			)
		}
	)

type GuidesListProps = {
	fields: FieldArrayFieldsProps<Guide>
	expositions: Exposition[]
	effects: Effect[]
	cardList: Card[]
}
const GuidesList: React.ComponentClass<SortableContainerProps & GuidesListProps> =
	SortableContainer(({ fields, expositions, effects, cardList }: GuidesListProps) => {
		return (
			<div>
				<GuideSummaryProceed componentType={'SUMMARY'} />
				{fields.map((field, index: number) => (
					<GuideCardSortable
						key={index}
						name={field}
						index={index}
						changeSelection={(newGuide: Guide) => {
							fields.remove(index)
							fields.insert(index, newGuide)
						}}
						removeCard={() => {
							fields.remove(index)
						}}
						cardList={cardList}
						getContent={(
							current: AutoEffectGuide | ExpositionGuide
						): Effect | Exposition | undefined => {
							if (current.type === 'AUTO_EFFECT') {
								const val = effects.find((effect) => {
									return effect._id === current.effectId
								})
								return val
							} else {
								return expositions.find((exposition) => {
									return exposition._id === current.expositionId
								})
							}
						}}
						currentGuide={fields.get(index)}
					/>
				))}
				<GuideSummaryProceed componentType={'PROCEED'} />
				<Button
					onClick={() => {
						fields.push({
							type: 'CHARACTER',
							header: '',
							subHeaders: [],
						})
					}}
					color="primary">
					Add Guide
				</Button>
			</div>
		)
	})
export default function Guides({ fields, expositions, effects, cardList }: Props): JSX.Element {
	return (
		<GuidesList
			fields={fields}
			expositions={expositions}
			effects={effects}
			cardList={cardList}
			useDragHandle
			onSortEnd={(data, e) => {
				fields.move(data.oldIndex, data.newIndex)
			}}
		/>
	)
}
export const BorderedDiv = styled.div`
	border: 2px solid #dcdcdc;
	border-radius: 5px;
	width: 100%;
	height: fit-content;
`
const DragAndDrop = styled.div`
	cursor: grab;
`
