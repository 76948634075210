import { toast } from 'react-toastify'
import { keys } from 'lodash'
import { SCREEN_REFERENCE } from '../../helpers/constants'
import {
	addActionToSimulation,
	moveActionOnSimulation,
	addEventResultToAction,
} from '../../../../reducers/simulationEditor'
import { EVENT_RESULT_DETERMINERS } from '../../helpers/eventResults'
import { createAction, createScreen, type ScreenActionId } from '../../actionDefinitions'

import type { DraggableItem } from '../../DragDrop/types'
import type { EventType, SimpleEventType } from '../../helpers/eventResults'
import type { Dispatch } from 'redux'

/**
 * Handles an error by creating a toast with the error's message attached
 * @param {BaseError} error
 */
export function handleError(error: unknown): void {
	if (!(error instanceof Error)) {
		toast.error('An unknown error occurred')
	} else if (error.name === 'ReducerWarningError') {
		toast.warn(error.message)
	} else if (error.name === 'ReducerFailedError') {
		toast.error(error.message)
	} else {
		toast.error(error.message)
	}
}

/**
 * Given a Draggable Item, Checks to make sure that Draggable Item represents a ScreenReference item.
 * If so, it dispatches an event which then attaches the draggable item's action reference id to the given
 * action without creating or moving any actions.
 * @param {DraggableItem} item
 * @param {{ type: EventType, timestamp?: number },} placement
 * @param {string} actionId
 * @param {Dispatch<*>} dispatch
 */
export function setReferenceToAction(
	item: DraggableItem,
	placement: Readonly<{
		type: EventType
		timestamp?: number
	}>,
	actionId: string,
	dispatch: Dispatch
) {
	if (item.meta.type !== SCREEN_REFERENCE) return
	const eventResultId: string = item.meta.referencedId
	const config: {
		event: EventType
		timestamp?: number
	} = {
		event: placement.type,
	}

	if (placement.type === 'atTime') {
		config.timestamp = placement.timestamp
	}

	try {
		dispatch(
			addEventResultToAction({
				actionId,
				eventResultId,
				config,
			})
		)
	} catch (error) {
		handleError(error)
	}
}

/**
 * Dispatches an event which either moves or adds an item to an editable simulation.
 * Also figures out the correct config to send to the dispatcher based on the placement value passed into the function.
 * @param {any} item
 * @param {{| type: 'AT_TIME', timestamp: number |} | {| type: 'ON_START' | 'ON_END' |}} placement
 * @param {ScreenActionId} screenId
 * @param {Dispatch<*>} dispatch
 */
export function createOrMoveAction(
	item: DraggableItem,
	placement:
		| {
				type: 'atTime'
				timestamp: number
		  }
		| {
				type: SimpleEventType
		  }
		| {
				type: 'questionInfo'
				optionIndex: number
		  },
	screenId: ScreenActionId,
	simulationId: string,
	dispatch: Dispatch,
	onUpdate: () => unknown,
	isJunior: boolean
) {
	if (item.meta.type === SCREEN_REFERENCE) {
		setReferenceToAction(item, placement, screenId, dispatch)
		return
	}

	const config: {
		to: string
		timestamp?: number
		optionIndex?: number
	} = {
		to: screenId,
	}
	const event: EventType = placement.type

	if (!keys(EVENT_RESULT_DETERMINERS).includes(placement.type)) {
		console.error('No placement value provided for action creation')
		toast.error('Unable to create action because no placement value was provided.')
		return
	}

	if (placement.type === 'atTime') {
		config.timestamp = placement.timestamp
	} else if (placement.type === 'questionInfo') {
		config.optionIndex = placement.optionIndex
	}

	try {
		if (item.id) {
			const id = item.id
			dispatch(moveActionOnSimulation(id, { ...config, event }))
			onUpdate()
		} else {
			const action = createAction(item.meta, simulationId, isJunior)

			if (!action) {
				toast.error('Unable to create action')
				return
			}

			if (!event) return
			dispatch(addActionToSimulation(action, { ...config, event }))
			onUpdate()
		}
	} catch (error) {
		handleError(error)
	}
}

/**
 * Creates a screen at the end of a given screenId by dispatching an event which either
 * changes a preexisting action to become a screen action or adds a new screen action
 * @param {any} item
 * @param {ScreenActionId} screenId
 * @param {Dispatch<*>} dispatch
 */
export function createScreenAfterScreenId({
	item,
	screenId,
	currentPhase,
	simulationId,
	dispatch,
	onUpdate,
	shiftScreens,
}: {
	item: DraggableItem
	screenId: ScreenActionId | null | undefined
	simulationId: string
	dispatch: Dispatch
	onUpdate: () => unknown
	shiftScreens: boolean
	currentPhase: ScreenActionId | null | undefined
}) {
	if (!item) {
		toast.error('Unable to create action')
		return
	}

	try {
		if (item.id) {
			if (item.id === screenId) {
				// Cannot add a screen to itself
				return
			}

			dispatch(
				moveActionOnSimulation(item.id, {
					event: 'onEnd',
					to: screenId,
					convertToScreen: true,
					shiftScreens,
					currentPhase,
				})
			)
			onUpdate()
		} else {
			const screen = createScreen(item.meta, simulationId)

			if (!screen) {
				toast.error('Unable to create screen')
				return
			}

			dispatch(
				addActionToSimulation(screen, {
					event: 'onEnd',
					to: screenId,
					shiftScreens,
					currentPhase,
				})
			)
			onUpdate()
		}
	} catch (error) {
		handleError(error)
	}
}
