import React from 'react'
import { useMap } from './hooks'
import QuickPreview from './QuickPreview'
type Props = {
	match: {
		params: {
			id: string
		}
	}
}
export default function PreviewRoute({
	match: {
		params: { id },
	},
}: Props): JSX.Element {
	const navigationMap = useMap(id)
	return <QuickPreview map={navigationMap} allowDebugging={true} />
}
