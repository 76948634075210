import React, { ChangeEvent } from 'react'
import { Button, FormGroup, Label, Row, Col } from 'reactstrap'
import { Field } from 'redux-form'
import styled from 'styled-components'
import StationSelector from './StationSelection'
import { getIcon } from './icons'
import Trigger from './Trigger'
import { specialEffectsList } from '../specialEffects/index'
import EffectSelector from './EffectSelector'
import type { Guide, Card } from '../../../types'
type Props = {
	guide: Guide
	remove: () => void
	name: string
	cardList: Card[]
	changeSelection: (newGuide: Guide) => unknown
}
export default function GuideHeader({
	guide,
	remove,
	name,
	cardList,
	changeSelection,
}: Props): JSX.Element {
	const createGuide = (selection: string): Guide | null | undefined => {
		let newGuide: Guide | null

		if (selection === 'STATION') {
			newGuide = {
				type: 'STATION',
				header: 'header' in guide ? guide.header : '',
				jrStation: 'POWER',
				jrPlusStation: 'POWER',
				subHeaders: 'subHeaders' in guide ? guide.subHeaders : [],
			}
		} else if (selection === 'TRIGGER') {
			newGuide = {
				type: 'TRIGGER',
				cardEffects: [],
			}
		} else if (selection === 'EFFECT') {
			newGuide = {
				type: 'EFFECT',
				effect: specialEffectsList[0],
				header: 'header' in guide ? guide.header : '',
				subHeaders: 'subHeaders' in guide ? guide.subHeaders : [],
			}
		} else if (selection === 'QUESTION' || selection === 'TEXT' || selection === 'CHARACTER') {
			newGuide = {
				type: selection,
				header: 'header' in guide ? guide.header : '',
				subHeaders: 'subHeaders' in guide ? guide.subHeaders : [],
			}
		} else {
			newGuide = null
		}

		return newGuide
	}

	const getHeaderColl = () => {
		if (guide.type === 'EFFECT') return 4
		if (guide.type === 'STATION') return 3
		return 7
	}

	const getTypeCol = () => {
		if (guide.type === 'STATION') return 2
		return 3
	}

	return (
		<div>
			<Row>
				<Col xs={getTypeCol()}>
					<Row>
						<Icon className="m-1">{getIcon(guide.type)}</Icon>
						<FormGroup className="w-75">
							<Label>Type</Label>
							<Field
								type="select"
								name={name + '.type'}
								component="select"
								onChange={(e: ChangeEvent<HTMLSelectElement>) => {
									const selection = e.currentTarget.value
									const newGuide: Guide | null | undefined = createGuide(selection)
									if (newGuide) {
										changeSelection(newGuide)
									}
								}}
								className="form-control">
								<option value={'CHARACTER'}>Character</option>
								<option value={'QUESTION'}>Question</option>
								<option value={'STATION'}>Station</option>
								<option value={'TRIGGER'}>Trigger</option>
								<option value={'TEXT'}>Text</option>
								<option value={'EFFECT'}>Effect</option>
							</Field>
						</FormGroup>
					</Row>
				</Col>
				{guide.type === 'EFFECT' && (
					<Col xs={3}>
						<EffectSelector
							effect={guide.effect}
							showLabel={true}
							display={(value) => value.split('.').pop() as string}
							customEffectList={true}
							onChange={(effect: string) => {
								const newGuide: Guide = { ...guide, effect: effect }
								changeSelection(newGuide)
							}}
						/>
					</Col>
				)}
				{guide.type === 'STATION' && (
					<Col xs={5}>
						<StationSelector name={name} guide={guide} />
					</Col>
				)}
				<Col xs={getHeaderColl()}>
					{guide.type !== 'TRIGGER' && (
						<FormGroup>
							<Label>Header</Label>
							<Field
								name={name + '.header'}
								component="input"
								className="form-control"
								type="text"
								placeholder="Header"
							/>
						</FormGroup>
					)}
				</Col>
				<Col xs={2}>
					<Button color="danger" size="sm" className="mt-4 w-100" onClick={() => remove()}>
						Delete Guide
					</Button>
				</Col>
			</Row>

			{guide.type === 'TRIGGER' && <Trigger name={name} cardList={cardList} />}
		</div>
	)
}
const Icon = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
