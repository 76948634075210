import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { usePhaseContext } from '../PhaseContext'
import classnames from 'classnames'
import styled from 'styled-components'
import type { ActionContainerProps } from './ActionContainer'
import ActionContainer from './ActionContainer'
import MediaActionContainer from './MediaActionContainer'
import EffectsActionContainer from './EffectsActionContainer'
import { CATEGORY_TO_COLOR } from '../helpers/styles'
import { Card } from './common/components'
import { selectors } from '../../../reducers/simulationEditor'
import { MULTI_MEDIA, FAVORITES, EFFECTS, STRUCTURE } from './constants'
import {
	type ActionBankFavoriteItem,
	getCategoryItems,
	ActionBankGeneralItem,
	PhaseChangeItem,
} from '../actionDefinitions'
import type { ThemeType } from '../PhaseCreator/theme'

const CATEGORY_TO_DISPLAY = {
	MULTI_MEDIA: 'Media',
	OBJECTIVE_GUIDE: 'Obj/Guide',
	GAME_MAP: 'Game/Map',
	EFFECTS: 'Effects',
	STRUCTURE: 'Structure',
	SCENARIOS: 'Scenarios',
}
type Category = keyof typeof CATEGORY_TO_DISPLAY

function ActionBank() {
	const [activeTab, setActiveTab] = useState(MULTI_MEDIA)
	const categories = Object.keys(CATEGORY_TO_DISPLAY) as Array<keyof typeof CATEGORY_TO_DISPLAY>
	const { phases, currentPhase } = usePhaseContext()
	const isJunior = useSelector(selectors.isJunior)
	return (
		<Wrapper>
			<Favorites>
				<Header className="p-4 w-full rounded-t text-center text-white">Favorites</Header>
				<Values>
					{(getCategoryItems(FAVORITES) as Array<ActionBankFavoriteItem>).map(
						(item: ActionBankFavoriteItem) => {
							return (
								<StyledCard item={item} key={`${FAVORITES}_${item.type}`} category={item.category}>
									{item.display}
								</StyledCard>
							)
						}
					)}
				</Values>
			</Favorites>
			<div>
				<Nav>
					{categories.map((category) => {
						return (
							<StyledNavItem key={`${category}_tab`}>
								<Tab
									type={category}
									className={classnames({
										active: activeTab === category,
									})}
									onClick={() => setActiveTab(category)}>
									{CATEGORY_TO_DISPLAY[category]}
								</Tab>
							</StyledNavItem>
						)
					})}
				</Nav>
				<TabContent activeTab={activeTab}>
					{categories.map((category) => {
						let Container

						switch (category) {
							case MULTI_MEDIA:
								Container = <MediaActionContainer category={category} />
								break

							case EFFECTS:
								Container = <EffectsActionContainer />
								break

							case STRUCTURE: {
								const items = getCategoryItems(category) as Array<
									ActionBankGeneralItem | PhaseChangeItem
								>

								if (phases) {
									phases.forEach((phase) => {
										items.push({
											type: 'PHASE_CHANGE',
											actionId: phase.screenId,
											display:
												currentPhase === phase.screenId
													? `Restart Current Phase`
													: `Start phase "${phase.display}"`,
										})
									})
								}

								Container = (
									<WrappedActionContainer items={Array.from(items)} category={STRUCTURE} />
								)
								break
							}

							default:
								Container = (
									<WrappedActionContainer
										items={getCategoryItems(category, isJunior)}
										category={category}
									/>
								)
						}

						return (
							<StyledTabPane key={`${category}_content`} tabId={category}>
								{Container}
							</StyledTabPane>
						)
					})}
				</TabContent>
			</div>
		</Wrapper>
	)
}

/**
 * A wrapper for the action container to make sure the auto sizer for react-virtualization in the ActionContainer works as expected.
 * @param {ActionContainerProps} props
 * @returns {React$Node}
 */
const WrappedActionContainer = (props: ActionContainerProps) => {
	return (
		<div css="height: 100%;">
			<ActionContainer {...props} />
		</div>
	)
}

export default React.memo(ActionBank)

const Favorites = styled.div`
	height: 100%;
	width: 100%;
`
const HEADER_TEXT_SIZE = 0.85
const Header = styled.div`
	background-color: ${CATEGORY_TO_COLOR[FAVORITES]};
	font-size: ${HEADER_TEXT_SIZE}rem;
`
const Values = styled.div`
	border: 4px solid ${CATEGORY_TO_COLOR[FAVORITES]};
	border-radius: 0 0px 3px 3px;
	padding: 5px;
`
const StyledCard = styled(Card)`
	width: 93% !important;
	margin: 7px;
`
const Wrapper = styled.div`
	margin: ${({ theme }: { theme: ThemeType }) => `20px ${theme.pageSideMargin}`}
	font-family: open-sans, sans-serif;
	font-style: normal;
	font-weight: 300;
	display: grid;
	grid-template-columns: 17% 1fr;
	grid-column-gap: 10px;
`
const StyledNavItem = styled(NavItem)`
	width: 16.66%;
	text-align: center;
`
const Tab = styled(NavLink)<{ type: Category }>`
	background-color: ${(props) => CATEGORY_TO_COLOR[props.type]} !important;
	color: white !important;
	cursor: pointer;
	border-radius: 4px 4px 0 0;
	padding: 15px;
	font-size: ${HEADER_TEXT_SIZE}rem;
	overflow: hidden;
`
const StyledTabPane = styled(TabPane)<{ tabId: Category }>`
	border: 4px solid ${(props) => CATEGORY_TO_COLOR[props.tabId]};
	border-radius: 0 3px 3px 3px;
	padding: 15px;
	height: 500px;
	overflow-y: scroll;
`
