import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { specialEffectsList, customSpecialEffectsList } from '../specialEffects/index'
type Props = {
	onChange: (effect: string) => void
	effect: string
	showLabel?: boolean
	display?: ((arg0: string) => string) | null | undefined
	customEffectList?: boolean
}
export default function EffectSelector({
	onChange,
	effect,
	showLabel,
	display,
	customEffectList,
}: Props): JSX.Element {
	const getEffectDisplay = (effect: string) => {
		return display ? parseCamelCase(display(effect)) : effect
	}

	const effectsList = customEffectList ? customSpecialEffectsList : specialEffectsList
	return (
		<FormGroup className="mb-0">
			{showLabel && <Label>Effect</Label>}
			<Input
				type="select"
				id="effect"
				value={effect}
				onChange={(e) => onChange(e.currentTarget.value)}>
				{effectsList
					.sort((a, b) => (getEffectDisplay(a) > getEffectDisplay(b) ? 1 : -1))
					.map((option) => (
						<option value={option} key={option}>
							{getEffectDisplay(option)}
						</option>
					))}
			</Input>
		</FormGroup>
	)
}
/**
 * Gets a string with a space before each uppercase letter in a given string
 *
 * @param {string} string The original string
 * @returns {string}
 */

export function parseCamelCase(string: string): string {
	const parts = string.match(/([A-Z]?[^A-Z]*)/g)

	if (parts) {
		return parts.slice(0, -1).join(' ')
	}

	return string
}
