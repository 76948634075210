import styled from 'styled-components'
const BACKGROUND_COLOR = '#14163d'
export type Props = {
	iconOnly?: boolean
}

const getFilledStyle = ({ iconOnly }: Props) => `
	${iconOnly ? 'fill: white;' : ''};
	pointer-events: none;
`

export const FilledPath = styled.path<Props>`
	${getFilledStyle}
`
export const FilledCircle = styled.circle<Props>`
	${getFilledStyle}
`
export const FilledEllipse = styled.ellipse<Props>`
	${({ iconOnly }: Props) => (iconOnly ? 'stroke: white;' : '')};
	pointer-events: none;
`
export const FilledPolygon = styled.polygon<Props>`
	${getFilledStyle}
`
export const BackgroundCircle = styled.circle`
	fill: ${BACKGROUND_COLOR};
`
