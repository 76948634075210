import React from 'react'
import FontAwesome from 'react-fontawesome'
import { useSelector } from 'react-redux'
import { Container, Button } from 'reactstrap'
import { isDirty } from 'redux-form'
import { useQuestionReducer } from '../questions'
import MapsContainer from '../maps/MapsContainer/MapsContainer'
import SimulationDetails from './SimulationDetails'
import SimulationTabs from './SimulationTabs'
import { selectors } from '../../setup/tiles'
import { QuestionsForm } from '../questions'
import { CardsContainer } from '../cards'
import { REDUX_FORM_NAMES } from '../../helpers/constants'
import { actions } from '../../setup/tiles'
import styled from 'styled-components'
import type { Simulation } from '../../types'
import { ReduxStore } from '../../types/ReduxStore'
import { Dispatch } from 'redux'
import { AutomatedSimulation } from '../../types/AutomatedSimulation'

function useSimulationStatus(simulation: Simulation): {
	formIsDirty: boolean
	isSaving: boolean
} {
	const id = simulation._id
	const formIsDirty = useSelector((state) =>
		Object.values(REDUX_FORM_NAMES).some((form) => isDirty(form)(state))
	)
	const isSaving = useSelector((state: ReduxStore) => {
		const putQuestionsApiData = selectors.simulations.putQuestions(state, {
			id,
		})
		const putMetaApiData = selectors.simulations.putMeta(state, {
			id,
		})
		const putCardApiData = selectors.simulations.putCard(state, {
			simulationId: id,
		})
		const putImageData = selectors.simulations.putImage(state, {
			simulationId: id,
		})
		const deleteImageData = selectors.simulations.deleteImage(state, {
			simulationId: id,
		})
		const moveCardsData = selectors.simulations.moveCard(state, {
			simulationId: id,
		})
		const mapDataIsPending = !simulation
			? false
			: simulation.mapIds.some((mapId) => {
					return selectors.maps.patch(state, {
						id: mapId,
					}).isPending
			  })
		return (
			putQuestionsApiData.isPending ||
			putMetaApiData.isPending ||
			putCardApiData.isPending ||
			putImageData.isPending ||
			deleteImageData.isPending ||
			mapDataIsPending ||
			moveCardsData.isPending
		)
	})
	return {
		isSaving,
		formIsDirty,
	}
}
/**
 * The "Old Simulation" screen. It displays the tabs available for automated simulations.
 */

export default function OldSimulation({ simulation }: { simulation: Simulation }): JSX.Element {
	const { formIsDirty, isSaving } = useSimulationStatus(simulation)
	const {
		submit: submitQuestions,
		isDirty: isQuestionFormDirty,
		...questionProps
	} = useQuestionReducer(simulation.questions, simulation._id)
	return (
		<Container>
			<StyledHeader>
				<h3>{simulation.name}</h3>

				<StyledSync>
					{isSaving ? (
						<>
							Saving... <StyledIcon name="circle-o-notch" spin />
						</>
					) : formIsDirty || isQuestionFormDirty ? (
						<>
							You have unsaved changes{' '}
							<StyledIcon name="exclamation-circle" className="text-warning" />
						</>
					) : (
						<>
							All changes saved <StyledIcon name="check-circle" />
						</>
					)}
				</StyledSync>
			</StyledHeader>

			<SimulationTabs
				tabs={[
					{
						title: 'Details',
						component: (
							<SimulationDetails
								form={REDUX_FORM_NAMES.DETAILS}
								simulation={simulation}
								initialValues={{
									name: simulation.name,
									public: simulation.public,
									isQuest: simulation.isQuest,
									summary: simulation.summary,
									grades: simulation.grades,
									duration: simulation.duration,
									narration: simulation.narration,
									briefing: simulation.briefing,
									checkpoints: simulation.checkpoints,
									standards: simulation.standards,
									prerequisiteKnowledge: simulation.prerequisiteKnowledge,
									appliedKnowledge: simulation.appliedKnowledge,
									suggestedVocabulary: simulation.suggestedVocabulary,
									debriefing: simulation.debriefing,
									walkthroughVideoUrl: simulation.walkthroughVideoUrl,
									briefingVideoUrl: simulation.briefingVideoUrl,
									categories: simulation.categories || [],
								}}
								onSubmit={(
									values: Simulation | AutomatedSimulation,
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									dispatch: Dispatch<any>,
									props: { simulation: Simulation | AutomatedSimulation }
								) => {
									dispatch(
										actions.simulations.putMeta({
											id: props.simulation._id,
											...values,
											categories: values.categories || [],
										})
									)

									if (isQuestionFormDirty) {
										submitQuestions()
									}
								}}
							/>
						),
					},
					{
						title: 'Questions',
						component: (
							<>
								<SaveQuestionsContainer>
									<Button
										color="primary"
										disabled={!isQuestionFormDirty}
										onClick={() => submitQuestions()}>
										Save Questions
									</Button>
								</SaveQuestionsContainer>
								<QuestionsForm {...questionProps} id={simulation._id} />
							</>
						),
					},
					{
						title: 'Cards',
						component: (
							<CardsContainer
								simulationId={simulation._id}
								cards={simulation.deck.cards}
								quests={simulation.deck.quests}
								classQuestions={simulation.deck.classQuestions}
							/>
						),
					},
					{
						title: 'Maps',
						component: <MapsContainer simulationId={simulation._id} mapIds={simulation.mapIds} />,
					},
				]}
			/>
		</Container>
	)
}
const SaveQuestionsContainer = styled.div`
	margin-left: auto;
	margin-right: 18px;
	width: fit-content;
	z-index: 15;
	top: 60px;
	position: sticky;
`
const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 100;
	padding-top: 8px;
`
const StyledSync = styled.div`
	display: flex;
	align-items: center;

	color: #bdbdbd;
`
const StyledIcon = styled(FontAwesome)`
	font-size: 1.3rem;
	margin-left: 4px;
`
