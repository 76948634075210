import React, { ChangeEvent, Component, RefObject } from 'react'
import { Modal, ModalBody, ModalFooter, Input, Button, Label, Col, FormGroup } from 'reactstrap'
import update from 'immutability-helper'
import { toast } from 'react-toastify'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import { Modal as AssetModal } from '../../../common/AssetManager'
import codesToKeyCodes from './keyCodesMap'
import type { Mover, Shortcut } from '../../../types/Simulation'
import type { File } from '../../../common/AssetManager'
export const BASE_WIDTH = 1920
export const BASE_HEIGHT = 1080
const SCALE = 1 / 6
type Props = {
	isOpen: boolean
	mover: Mover | null | undefined
	onSave: (mover: Mover) => void
	onCancel: () => unknown
	cardVideoUrl: string | undefined
}
type State = {
	// Mover fields
	width: string
	height: string
	label: string
	layoutX: string
	layoutY: string
	mediaUrl: string
	name: string
	rotation: string
	shortcuts: ReadonlyArray<Shortcut>
	// Bonus state
	keyBinding: string | null | undefined
	imageChooserOpen: boolean
}

// Gets all keys of T that have a value of type V
type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T]

const defaultState: State = {
	width: '400',
	height: '400',
	label: '',
	layoutX: '760',
	layoutY: '340',
	mediaUrl: 'https://resources-cdn.mission.io/simulations/mover/ShipTop0.png',
	name: 'Ship',
	rotation: '270',
	shortcuts: [
		{
			keys: ['W'],
			action: 'FIRE_AFT',
		},
		{
			keys: ['A'],
			action: 'FIRE_STBD',
		},
		{
			keys: ['S'],
			action: 'FIRE_FORE',
		},
		{
			keys: ['D'],
			action: 'FIRE_PORT',
		},
		{
			keys: ['Q'],
			action: 'YAW_MINUS',
		},
		{
			keys: ['E'],
			action: 'YAW_PLUS',
		},
		{
			keys: ['SPACE'],
			action: 'ALL_STOP',
		},
	],
	keyBinding: null,
	imageChooserOpen: false,
}
const keyBindings = [
	'FIRE_AFT',
	'FIRE_STBD',
	'FIRE_FORE',
	'FIRE_PORT',
	'YAW_MINUS',
	'YAW_PLUS',
	'ALL_STOP',
]
export default class MoverEditor extends Component<Props, State> {
	parentRef: RefObject<HTMLDivElement>

	constructor(props: Props) {
		super(props)
		this.state = this.getStateForMover(props.mover)
		this.parentRef = React.createRef()
	}

	getStateForMover(mover: Mover | null | undefined): State {
		if (mover) {
			return {
				name: mover.name,
				width: String(mover.width),
				height: String(mover.height),
				label: mover.label,
				layoutX: String(mover.layoutX),
				layoutY: String(mover.layoutY),
				mediaUrl: mover.mediaUrl,
				rotation: String(mover.rotation),
				shortcuts: mover.shortcuts,
				keyBinding: null,
				imageChooserOpen: false,
			}
		} else {
			return { ...defaultState }
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.mover !== this.props.mover) {
			this.setState(this.getStateForMover(this.props.mover))
		}
	}

	componentWillUnmount() {
		this.removeEventListener()
	}

	addEventListener: () => void = () => {
		if (this.parentRef.current) {
			this.parentRef.current.addEventListener('keydown', this.handleKeyPress)
		}
	}
	removeEventListener: () => void = () => {
		if (this.parentRef.current) {
			this.parentRef.current.removeEventListener('keydown', this.handleKeyPress)
		}
	}
	handleKeyPress = (e: KeyboardEvent) => {
		const { keyBinding } = this.state
		const shortcutIndex = this.state.shortcuts.findIndex(
			(shortcut) => shortcut.action === keyBinding
		)

		if (!keyBinding || shortcutIndex === -1) {
			return
		}

		e.preventDefault()

		if (e.code === 'Escape') {
			this.setState({
				keyBinding: null,
			})
			return
		}

		if (codesToKeyCodes[e.code]) {
			this.setState((state) => ({
				keyBinding: null,
				shortcuts: update(state.shortcuts, {
					[shortcutIndex]: {
						keys: {
							$set: [codesToKeyCodes[e.code]],
						},
					},
				}),
			}))
		} else {
			toast.warn('Invalid Key Binding')
		}
	}

	render(): JSX.Element {
		return (
			<Modal isOpen={this.props.isOpen} onOpened={this.onModalOpened} onClosed={this.onModalClosed}>
				<ModalBody>
					<div ref={this.parentRef}>
						<StyledPreviewContainer>
							<StyledPreview
								style={{
									width: `${BASE_WIDTH * SCALE}px`,
									height: `${BASE_HEIGHT * SCALE}px`,
								}}>
								<video
									src={this.props.cardVideoUrl}
									width={BASE_WIDTH * SCALE}
									height={BASE_HEIGHT * SCALE}
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									autoPlay
								/>
								<img src={this.state.mediaUrl} style={this.getMoverStyle()} alt="Mover Icon" />
							</StyledPreview>
						</StyledPreviewContainer>
						{this.renderTextInput({
							field: 'name',
							label: 'Name:',
						})}
						{this.renderTextInput({
							field: 'width',
							label: 'Width:',
							type: 'number',
						})}
						{this.renderTextInput({
							field: 'height',
							label: 'Height:',
							type: 'number',
						})}
						{this.renderTextInput({
							field: 'layoutX',
							label: 'X :',
							type: 'number',
						})}
						{this.renderTextInput({
							field: 'layoutY',
							label: 'Y :',
							type: 'number',
						})}
						{this.renderTextInput({
							field: 'rotation',
							label: 'Rotation:',
							type: 'number',
						})}
						<FormGroup>
							<Button
								className="mr-3"
								onClick={() =>
									this.setState({
										imageChooserOpen: true,
									})
								}>
								Update Image
							</Button>
							<StyledImagePreview src={this.state.mediaUrl} alt="preview" />
						</FormGroup>
						<h3>Key Bindings</h3>
						<StyledKeyBinding>
							{keyBindings.map((keyBinding, i) => {
								const shortcut = this.state.shortcuts.find(
									(shortcut) => shortcut.action === keyBinding
								)
								return (
									<div key={keyBinding}>
										<Button
											onClick={() =>
												this.setState({
													keyBinding,
												})
											}>
											{shortcut
												? `${shortcut.action}: ${shortcut.keys[0]}`
												: 'Strange things are afoot - Please refresh the page'}
										</Button>
									</div>
								)
							})}
							{this.state.keyBinding &&
								`Press a key to set the new key binding for '${this.state.keyBinding}'`}
						</StyledKeyBinding>
					</div>
					<AssetModal
						collection="movers"
						isOpen={this.state.imageChooserOpen}
						onClose={() =>
							this.setState({
								imageChooserOpen: false,
							})
						}
						onFileClick={(file: File) => {
							this.setState({
								mediaUrl: file.url,
								imageChooserOpen: false,
							})
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={this.onClickSave}>
						Save
					</Button>
					<Button onClick={this.onClickCancel}>Cancel</Button>
				</ModalFooter>
			</Modal>
		)
	}

	/**
	 * Gets the style for how the mover will appear in launchpad. These styles are meant to match the styles that are present on the mover in launchpad.
	 *
	 * @returns A styles object
	 * @memberof MoverEditor
	 */
	getMoverStyle() {
		return {
			display: 'block',
			width: 'auto',
			height: 'auto',
			maxWidth: Math.floor(parseInt(this.state.width, 10) * SCALE),
			maxHeight: Math.floor(parseInt(this.state.height, 10) * SCALE),
			position: 'absolute' as const,
			top: Math.floor(parseInt(this.state.layoutY, 10) * SCALE),
			left: Math.floor(parseInt(this.state.layoutX, 10) * SCALE),
			transform: `rotate(${parseInt(this.state.rotation, 10)}deg)`,
		}
	}

	onModalOpened: () => void = () => {
		this.setState(this.getStateForMover(this.props.mover))
		this.addEventListener()
	}
	onModalClosed: () => void = () => {
		this.removeEventListener()
	}
	onClickCancel: () => void = () => {
		this.removeEventListener()
		this.props.onCancel()
	}

	renderTextInput({
		field,
		label,
		type = 'text',
	}: {
		field: KeysMatching<State, string>
		label: string
		type?: 'text' | 'number'
	}): JSX.Element {
		return (
			<FormGroup row>
				<Label sm={2} for={field}>
					{label}
				</Label>
				<Col sm={10}>
					<Input
						id={field}
						value={this.state[field]}
						type={type}
						onChange={this.onUpdateField(field)}
					/>
				</Col>
			</FormGroup>
		)
	}

	onUpdateField = (field: KeysMatching<State, string>) => (e: ChangeEvent<HTMLInputElement>) => {
		// @ts-expect-error This should be correct
		this.setState({
			[field]: e.currentTarget.value,
		})
	}
	onClickSave: () => void = () => {
		const { width, height, layoutX, layoutY, rotation, shortcuts, ...basicFields } = this.state
		const initialMover = this.props.mover || {
			transition: null,
			xmlId: uuid(),
		}
		const updatedMover = {
			...initialMover,
			...basicFields,
			width: parseInt(width, 10),
			height: parseInt(height, 10),
			layoutX: parseInt(layoutX, 10),
			layoutY: parseInt(layoutY, 10),
			rotation: parseInt(rotation, 10),
			shortcuts: [...shortcuts],
		}
		// @ts-expect-error updatedMover doesn't have the _id here
		this.props.onSave(updatedMover)
	}
}
const StyledPreviewContainer = styled.div`
	display: flex;
	justify-content: center;
`
const StyledPreview = styled.div`
	margin-bottom: 12px;
	position: relative;
`
const StyledImagePreview = styled.img`
	max-height: 50px;
`
const StyledKeyBinding = styled.div`
	display: flex;
	flex-wrap: wrap;
`
