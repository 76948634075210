import { createOption } from '../../../helpers/functions'
import type {
	AtTime,
	QuestionInfo,
	ActivateStationData,
	EventResult,
} from '@mission.io/mission-toolkit/actions'
import { SHARED_STATION_IDS } from '@mission.io/mission-toolkit/constants'
import type { ActionId } from '../actionDefinitions'

type EventResultMap<T> = {
	onStart: T
	onEnd: T
	onComplete: T
	atTime: T
	questionInfo: T
	stationData: T
	onCheatDeath: T
	onDeath: T
	onPlayerCollisionWithSolid: T
	onContinue: T
	onFailure: T
	onSuccess: T
	onVoteStart: T
	// * WARNING: If we add any other fields to actions that hold an EventResult, we must include that here as well.
}

export type EventResultKey = keyof EventResultMap<unknown>

/**
 * These are event result types that can be added to without requiring extra parameters such as optionIndex or timeStamp
 */
export const SimpleEventResultTypes = {
	onStart: 'onStart',
	onEnd: 'onEnd',
	onComplete: 'onComplete',
	stationData: 'stationData',
	onCheatDeath: 'onCheatDeath',
	onDeath: 'onDeath',
	onContinue: 'onContinue',
	onPlayerCollisionWithSolid: 'onPlayerCollisionWithSolid',
	onFailure: 'onFailure',
	onSuccess: 'onSuccess',
	onVoteStart: 'onVoteStart',
}
export type SimpleEventType = keyof typeof SimpleEventResultTypes
export type EventType = keyof EventResultMap<unknown>

/**
 * A map with keys of all the possible fields on actions that yield EventResults.
 * Values of map consist of a function that returns an EventResult object given an action.
 * Note: the fields are marked as optional in the case that the field was removed from an action.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- the more specific types for each property are defined in the property
export const EVENT_RESULT_DETERMINERS: EventResultMap<(a: any) => EventResult<string> | undefined> =
	{
		onStart: (a: { onStart?: EventResult<string> }): EventResult<string> | undefined => a.onStart,
		onEnd: (a: { onEnd?: EventResult<string> }): EventResult<string> | undefined => a.onEnd,
		onComplete: (a: { onComplete?: EventResult<string> }): EventResult<string> | undefined =>
			a.onComplete,
		onFailure: (a: { onFailure?: EventResult<string> }): EventResult<string> | undefined =>
			a.onFailure,
		onSuccess: (a: { onSuccess?: EventResult<string> }): EventResult<string> | undefined =>
			a.onSuccess,
		onVoteStart: (a: { onVoteStart?: EventResult<string> }): EventResult<string> | undefined =>
			a.onVoteStart,
		atTime: (a: { atTime?: AtTime<string> }): EventResult<string> | undefined => {
			if (!a.atTime) {
				return
			}
			return [...a.atTime]
				.sort((a, b) => a.timestamp - b.timestamp)
				.reduce(
					(allTimeActions, time) => [...allTimeActions, ...time.actions],
					[] as EventResult<string>
				)
		},
		questionInfo: (
			a: { questionInfo?: QuestionInfo<string> },
			optionIndex?: number
		): EventResult<string> | undefined => {
			if (!a.questionInfo) {
				return
			}
			if (optionIndex != null) {
				return a.questionInfo.options[optionIndex]?.result || []
			}

			return a.questionInfo.options.reduce(
				(allOptionResults, option) => [...allOptionResults, ...option.result],
				[] as EventResult<string>
			)
		},
		onContinue: (a: { onContinue?: EventResult<string> }) => a.onContinue,
		stationData: (a: {
			stationData?: ActivateStationData<string>
		}): EventResult<string> | undefined =>
			// Don't get EventResults from the Creative Canvas station because those will be resolved during the collaborative culminating moment
			a.stationData && 'onComplete' in a.stationData ? a.stationData.onComplete : undefined,
		onCheatDeath: (a: { onCheatDeath?: EventResult<string> }) => a.onCheatDeath,
		onDeath: (a: { onDeath?: EventResult<string> }) => a.onDeath,
		onPlayerCollisionWithSolid: (a: { onPlayerCollisionWithSolid?: EventResult<string> }) =>
			a.onPlayerCollisionWithSolid,
	}
export type BranchEventResult = {
	branchId: string
	eventResult: EventResult<string>
}
export const DEFAULT_BRANCH_ID = 'default'

/**
 * The same thing as EVENT_RESULT_DETERMINERS, but the event results are grouped by the "branch" that they are on.
 * For example a culminating moment will have a unique branch for each option.
 */
export const EVENT_RESULT_DETERMINERS_WITH_BRANCHES: EventResultMap<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- the more specific types for each property are defined in the property
	(a: any) => Array<BranchEventResult>
> = {
	onStart: (a: { onStart: EventResult<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onStart,
		},
	],
	onVoteStart: (a: { onStart: EventResult<string> }): Array<BranchEventResult> => [
		{ branchId: DEFAULT_BRANCH_ID, eventResult: a.onStart },
	],
	onEnd: (a: { onEnd: EventResult<string>; type: string }): Array<BranchEventResult> => [
		{
			// If this is a culminating moment, consider the "onEnd" event to be a unique branch
			branchId: a.type === 'CULMINATING_MOMENTS_SCREEN' ? 'onEnd' : DEFAULT_BRANCH_ID,
			eventResult: a.onEnd,
		},
	],
	onComplete: (a: { onComplete: EventResult<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onComplete,
		},
	],
	onFailure: (a: { onFailure: EventResult<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onFailure,
		},
	],
	onSuccess: (a: { onSuccess: EventResult<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onSuccess,
		},
	],
	atTime: (a: { atTime: AtTime<string> }): Array<BranchEventResult> => {
		return [
			{
				branchId: DEFAULT_BRANCH_ID,
				eventResult: [...a.atTime]
					.sort((a, b) => a.timestamp - b.timestamp)
					.reduce(
						(allTimeActions, time) => [...allTimeActions, ...time.actions],
						[] as EventResult<string>
					),
			},
		]
	},
	questionInfo: (a: { questionInfo: QuestionInfo<string> }): Array<BranchEventResult> => {
		return a.questionInfo.options.map((option, index) => ({
			branchId: option._id,
			eventResult: option.result,
		}))
	},
	onContinue: (a: { onContinue?: EventResult<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onContinue || [],
		},
	],
	stationData: (a: { stationData: ActivateStationData<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult:
				'onComplete' in a.stationData
					? a.stationData.onComplete // Don't get EventResults from the Creative Canvas station because those will be resolved during the collaborative culminating moment
					: [],
		},
	],
	onCheatDeath: (a: { onCheatDeath?: EventResult<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onCheatDeath || [],
		},
	],
	onDeath: (a: { onDeath: EventResult<string> }): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onDeath || [],
		},
	],
	onPlayerCollisionWithSolid: (a: {
		onPlayerCollisionWithSolid: EventResult<string>
	}): Array<BranchEventResult> => [
		{
			branchId: DEFAULT_BRANCH_ID,
			eventResult: a.onPlayerCollisionWithSolid || [],
		},
	],
}

/**
 * A map with keys of all the possible fields on actions that yield EventResults.
 * Values of map consist of a function which removes a given ActionId from the EventResult.
 * Returns true if able to remove action. Otherwise returns false
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- the more specific types for each property are defined in the property
export const EVENT_RESULT_REMOVERS: EventResultMap<(action: any, idToRemove: ActionId) => boolean> =
	{
		onStart: (
			action: {
				onStart?: EventResult<string>
			},
			idToRemove: ActionId
		): boolean => {
			return findAndRemoveFromArray(action.onStart, idToRemove)
		},
		onEnd: (
			action: {
				onEnd?: EventResult<string>
			},
			idToRemove: ActionId
		): boolean => {
			return findAndRemoveFromArray(action.onEnd, idToRemove)
		},
		onComplete: (
			action: {
				onComplete?: EventResult<string>
			},
			idToRemove: ActionId
		): boolean => {
			return findAndRemoveFromArray(action.onComplete, idToRemove)
		},
		onFailure: (
			action: {
				onFailure?: EventResult<string>
			},
			idToRemove: ActionId
		): boolean => {
			return findAndRemoveFromArray(action.onFailure, idToRemove)
		},
		onSuccess: (
			action: {
				onSuccess?: EventResult<string>
			},
			idToRemove: ActionId
		): boolean => {
			return findAndRemoveFromArray(action.onSuccess, idToRemove)
		},
		onVoteStart: (action: { onVoteStart?: EventResult<string> }, idToRemove: ActionId): boolean => {
			return findAndRemoveFromArray(action.onVoteStart, idToRemove)
		},
		onPlayerCollisionWithSolid: (
			action: {
				onPlayerCollisionWithSolid?: EventResult<string>
			},
			idToRemove: ActionId
		): boolean => {
			return findAndRemoveFromArray(action.onPlayerCollisionWithSolid, idToRemove)
		},
		stationData: (
			action: {
				stationData?: ActivateStationData<string>
			},
			idToRemove: string
		): boolean => {
			if (!action.stationData) {
				return false
			}

			if ('onComplete' in action.stationData) {
				return findAndRemoveFromArray(action.stationData.onComplete, idToRemove)
			}

			// Handle Creative Canvas station
			if ('issue' in action.stationData) {
				const rubric = action.stationData.issue.rubric
				return (
					findAndRemoveFromArray(rubric.onSuccess, idToRemove) ||
					findAndRemoveFromArray(rubric.onFail, idToRemove)
				)
			}

			return action.stationData.issues.some((issue) => {
				return (
					findAndRemoveFromArray(issue.rubric.onSuccess, idToRemove) ||
					findAndRemoveFromArray(issue.rubric.onFail, idToRemove)
				)
			})
		},
		onContinue: (
			action: {
				onContinue?: EventResult<string>
			},
			idToRemove: ActionId
		): boolean => {
			return findAndRemoveFromArray(action.onContinue || null, idToRemove)
		},
		onCheatDeath: (
			a: {
				onCheatDeath?: EventResult<string>
			},
			idToRemove: string
		): boolean => findAndRemoveFromArray(a.onCheatDeath || null, idToRemove),
		onDeath: (
			action: {
				onDeath?: EventResult<string>
			},
			idToRemove: string
		) => {
			return findAndRemoveFromArray(action.onDeath, idToRemove)
		},
		atTime: (
			action: {
				atTime?: AtTime<string>
			},
			idToRemove: ActionId
		): boolean => {
			if (!('atTime' in action && action.atTime)) return false

			let eventIndex = -1
			const atTimeIndex = action.atTime.findIndex((time) =>
				time.actions.find((id, index) => {
					if (id === idToRemove) {
						eventIndex = index
						return true
					}

					return false
				})
			)

			if (eventIndex > -1 && atTimeIndex > -1) {
				action.atTime[atTimeIndex].actions.splice(eventIndex, 1)

				if (action.atTime[atTimeIndex].actions.length === 0) {
					action.atTime.splice(atTimeIndex, 1)
				}

				return true
			}

			return false
		},
		questionInfo: (
			action: {
				questionInfo?: QuestionInfo<string>
			},
			idToRemove: string
		): boolean => {
			if (!('questionInfo' in action && action.questionInfo)) return false

			let eventIndex = -1
			const optionsIndex = action.questionInfo.options.findIndex((option) =>
				option.result.find((id, index) => {
					if (id === idToRemove) {
						eventIndex = index
						return true
					}

					return false
				})
			)

			if (eventIndex > -1 && optionsIndex > -1) {
				action.questionInfo.options[optionsIndex].result.splice(eventIndex, 1)
				return true
			}

			return false
		},
	}

/**
 * A map with keys of all the possible fields on actions that yield EventResults.
 * Values of map consist of a function which adds a given ActionId to an EventResult array.
 * Returns true if able to add action. Otherwise returns false
 */
export const EVENT_RESULT_ADDERS: EventResultMap<
	(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- the more specific types for each property are defined in the property
		a: any,
		idToAdd: ActionId,
		config?: {
			timestamp?: number
			optionIndex?: number
		}
	) => boolean
> = {
	onStart: (
		a: {
			onStart?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onStart && a.onStart.push(idToAdd))
	},
	onEnd: (
		a: {
			onEnd?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onEnd && a.onEnd.push(idToAdd))
	},
	onComplete: (
		a: {
			onComplete?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onComplete && a.onComplete.push(idToAdd))
	},
	onFailure: (
		a: {
			onFailure?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onFailure && a.onFailure.push(idToAdd))
	},
	onSuccess: (
		a: {
			onSuccess?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onSuccess && a.onSuccess.push(idToAdd))
	},
	onVoteStart: (a: { onVoteStart?: EventResult<string> }, idToAdd: ActionId): boolean => {
		return Boolean(a.onVoteStart && a.onVoteStart.push(idToAdd))
	},
	stationData: (a: { stationData?: ActivateStationData<string> }, idToAdd: ActionId): boolean => {
		if (!a.stationData) {
			return false
		}

		if (a.stationData.stationId === SHARED_STATION_IDS.CREATIVE_CANVAS) {
			throw new Error(
				'Cannot add an action to an arbitrary location on the Creative Canvas station'
			)
		}

		return Boolean(a.stationData.onComplete.push(idToAdd))
	},
	onContinue: (
		a: {
			onContinue?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onContinue && a.onContinue.push(idToAdd))
	},
	onCheatDeath: (
		a: {
			onCheatDeath?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		if (a.onCheatDeath) return Boolean(a.onCheatDeath.push(idToAdd))
		else {
			a.onCheatDeath = [idToAdd]
			return true
		}
	},
	onDeath: (
		a: {
			onDeath?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onDeath && a.onDeath.push(idToAdd))
	},
	onPlayerCollisionWithSolid: (
		a: {
			onPlayerCollisionWithSolid?: EventResult<string>
		},
		idToAdd: ActionId
	): boolean => {
		return Boolean(a.onPlayerCollisionWithSolid && a.onPlayerCollisionWithSolid.push(idToAdd))
	},
	atTime: (
		action: {
			atTime?: AtTime<string>
		},
		idToAdd: ActionId,
		config?: {
			timestamp?: number
		}
	): boolean => {
		if (!config?.timestamp || !('atTime' in action && action.atTime)) return false
		const timestamp = config.timestamp
		const atTimeIndex = action.atTime.findIndex((time) => time.timestamp === timestamp)

		if (atTimeIndex > -1) {
			action.atTime[atTimeIndex].actions.push(idToAdd)
		} else {
			action.atTime.push({
				actions: [idToAdd],
				timestamp,
			})
		}

		return true
	},
	questionInfo: (
		action: {
			questionInfo?: QuestionInfo<string>
		},
		idToAdd: ActionId,
		config?: {
			optionIndex?: number
		}
	): boolean => {
		const optionIndex = config?.optionIndex
		if (optionIndex == null || !('questionInfo' in action && action.questionInfo)) return false

		if (
			!action.questionInfo.options[optionIndex] &&
			optionIndex === action.questionInfo.options.length
		) {
			action.questionInfo.options.push(createOption())
			return true
		} else if (!action.questionInfo.options[optionIndex]) return false

		action.questionInfo.options[optionIndex].result.push(idToAdd)
		return true
	},
}
type GetUpdatedId = (arg0: string) => string

/**
 * A map with keys of all the possible fields on actions that yield EventResults. The values are functions that
 * will get a new value for that key in the provided action with all temporary action ids replaced with the updated
 * ids.
 *
 * For example, for simple EventResult keys like onStart, onEnd, and onComplete, the function will return EventResult. For more complex entries in Actions
 * like `stationData` and `questionInfo`, the functions will return `ActivateStationData` and `QuestionInfo` respectively.
 *
 */
export const EVENT_RESULT_ID_UPDATERS: EventResultMap<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- the more specific types for each property are defined in the property
	(action: any, getUpdatedId: GetUpdatedId) => any
> = {
	// Simple entries, where types of the corresponding values in Actions are simply `EventResult`
	onStart: (a: { onStart: EventResult<string> }, getUpdatedId: GetUpdatedId): EventResult<string> =>
		a.onStart.map(getUpdatedId),
	onVoteStart: (
		a: { onVoteStart: EventResult<string> },
		getUpdatedId: GetUpdatedId
	): EventResult<string> => a.onVoteStart.map(getUpdatedId),
	onEnd: (a: { onEnd: EventResult<string> }, getUpdatedId: GetUpdatedId): EventResult<string> =>
		a.onEnd.map(getUpdatedId),
	onComplete: (
		a: { onComplete: EventResult<string> },
		getUpdatedId: GetUpdatedId
	): EventResult<string> => a.onComplete.map(getUpdatedId),
	onCheatDeath: (
		a: { onCheatDeath: EventResult<string> },
		getUpdatedId: GetUpdatedId
	): EventResult<string> => a.onCheatDeath.map(getUpdatedId),
	onContinue: (
		a: { onContinue: EventResult<string> },
		getUpdatedId: GetUpdatedId
	): EventResult<string> => a.onContinue.map(getUpdatedId),
	onDeath: (a: { onDeath: EventResult<string> }, getUpdatedId: GetUpdatedId): EventResult<string> =>
		a.onDeath.map(getUpdatedId),
	onFailure: (
		a: { onFailure: EventResult<string> },
		getUpdatedId: GetUpdatedId
	): EventResult<string> => a.onFailure.map(getUpdatedId),
	onSuccess: (
		a: { onSuccess: EventResult<string> },
		getUpdatedId: GetUpdatedId
	): EventResult<string> => a.onSuccess.map(getUpdatedId),
	onPlayerCollisionWithSolid: (
		a: { onPlayerCollisionWithSolid: EventResult<string> },
		getUpdatedId: GetUpdatedId
	): EventResult<string> => {
		return a.onPlayerCollisionWithSolid.map(getUpdatedId)
	},
	// Complex entries, where types of the corresponding values in Actions are *not* simply `EventResult`
	atTime: (
		a: {
			atTime: AtTime<string>
		},
		getUpdatedId: GetUpdatedId
	): AtTime<string> =>
		a.atTime.map((atTimeItem) => {
			return { ...atTimeItem, actions: atTimeItem.actions.map(getUpdatedId) }
		}),
	questionInfo: (
		a: {
			questionInfo: QuestionInfo<string>
		},
		getUpdatedId: GetUpdatedId
	): QuestionInfo<string> => ({
		...a.questionInfo,
		options: a.questionInfo.options.map((option) => ({
			...option,
			result: option.result.map(getUpdatedId),
		})),
	}),
	stationData: (
		a: {
			stationData: ActivateStationData<string>
		},
		getUpdatedId: GetUpdatedId
	): ActivateStationData<string> => {
		if ('onComplete' in a.stationData) {
			return {
				...a.stationData,
				onComplete: a.stationData.onComplete.map(getUpdatedId),
			} as ActivateStationData<string>
		}

		if ('issue' in a.stationData) {
			const issue = a.stationData.issue
			return {
				...a.stationData,
				issue: {
					...issue,
					rubric: {
						...a.stationData.issue.rubric,
						onSuccess: issue.rubric.onSuccess.map(getUpdatedId),
						onFail: issue.rubric.onFail.map(getUpdatedId),
					},
				},
			}
		}

		return {
			...a.stationData,
			issues: a.stationData.issues.map((issue) => ({
				...issue,
				rubric: {
					...issue.rubric,
					onSuccess: issue.rubric.onSuccess.map(getUpdatedId),
					onFail: issue.rubric.onFail.map(getUpdatedId),
				},
			})),
		}
	},
}

/**
 * A map with keys of all the possible fields on actions that yield EventResults.
 * Values of map consist of a function which sets the EventResult.
 * Returns true if able to add action. Otherwise returns false
 */
export const EVENT_RESULT_SETTERS: EventResultMap<
	(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- the more specific types for each property are defined in the property
		a: any,
		eventResult: EventResult<string>,
		config?: {
			timestamp?: number
			optionIndex?: number
			issueIndex?: number
			eventType?: 'success' | 'fail'
		}
	) => boolean
> = {
	onStart: (
		a: {
			onStart?: EventResult<string>
		},
		eventResult: EventResult<string>
	): boolean => {
		if (a.onStart) a.onStart = eventResult
		return Boolean(a.onStart)
	},
	onVoteStart: (
		a: { onVoteStart?: EventResult<string> },
		eventResult: EventResult<string>
	): boolean => {
		if (a.onVoteStart) {
			a.onVoteStart = eventResult
		}
		return Boolean(a.onVoteStart)
	},
	onEnd: (a: { onEnd?: EventResult<string> }, eventResult: EventResult<string>): boolean => {
		if (a.onEnd) a.onEnd = eventResult
		return Boolean(a.onEnd)
	},
	onComplete: (
		a: {
			onComplete?: EventResult<string>
		},
		eventResult: EventResult<string>
	): boolean => {
		if (a.onComplete) a.onComplete = eventResult
		return Boolean(a.onComplete)
	},
	onFailure: (
		a: {
			onFailure?: EventResult<string>
		},
		eventResult: EventResult<string>
	): boolean => {
		if (a.onFailure) a.onFailure = eventResult
		return Boolean(a.onFailure)
	},
	onSuccess: (
		a: {
			onSuccess?: EventResult<string>
		},
		eventResult: EventResult<string>
	): boolean => {
		if (a.onSuccess) a.onSuccess = eventResult
		return Boolean(a.onSuccess)
	},
	stationData: (
		a: {
			stationData?: ActivateStationData<string>
		},
		eventResult: EventResult<string>,
		config: {
			issueIndex?: number
			eventType?: 'success' | 'fail'
		} = {}
	): boolean => {
		if (!a.stationData) {
			return false
		}

		if ('onComplete' in a.stationData) {
			a.stationData.onComplete = eventResult
			return true
		}

		if (!config.eventType) {
			throw new Error('Must provide eventType when setting stationData on creative canvas station')
		}

		let issue

		if ('issue' in a.stationData) {
			issue = a.stationData.issue
		} else {
			const issues = a.stationData.issues

			if (config.issueIndex == null || isNaN(config.issueIndex)) {
				throw new Error(
					'Must provide issueIndex when setting stationData on creative canvas station'
				)
			}

			issue = issues[config.issueIndex]
		}

		if (!issue) {
			throw new Error('Could not find issue when setting stationData on creative canvas station')
		}

		if (config.eventType === 'success') {
			issue.rubric.onSuccess = eventResult
		} else if (config.eventType === 'fail') {
			issue.rubric.onFail = eventResult
		} else {
			throw new Error('Invalid eventType when setting stationData on creative canvas station')
		}

		return true
	},
	onContinue: (
		a: {
			onContinue?: EventResult<string>
		},
		eventResult: EventResult<string>
	): boolean => {
		if (a.onContinue) a.onContinue = eventResult
		return Boolean(a.onContinue)
	},
	onCheatDeath: (
		a: {
			onCheatDeath?: EventResult<string>
		},
		eventResult: EventResult<string>
	): boolean => {
		if (a.onCheatDeath) a.onCheatDeath = eventResult
		return Boolean(a.onCheatDeath)
	},
	onDeath: (
		a: {
			onDeath?: EventResult<string>
		},
		eventResult: EventResult<string>
	): boolean => {
		if (a.onDeath) a.onDeath = eventResult
		return Boolean(a.onDeath)
	},
	onPlayerCollisionWithSolid: (
		a: {
			onPlayerCollisionWithSolid?: EventResult<string>
		},
		eventResult
	): boolean => {
		if (a.onPlayerCollisionWithSolid) {
			a.onPlayerCollisionWithSolid = eventResult
		}

		return Boolean(a.onPlayerCollisionWithSolid)
	},
	atTime: (
		action: {
			atTime?: AtTime<string>
		},
		eventResult: EventResult<string>,
		config?: {
			timestamp?: number
		}
	): boolean => {
		if (!config?.timestamp || !('atTime' in action && action.atTime)) return false
		const timestamp = config.timestamp
		const atTimeIndex = action.atTime.findIndex((time) => time.timestamp === timestamp)

		if (atTimeIndex > -1) {
			action.atTime[atTimeIndex].actions = eventResult
		} else {
			action.atTime.push({
				actions: eventResult,
				timestamp,
			})
		}

		return true
	},
	questionInfo: (
		a: {
			questionInfo?: QuestionInfo<string>
		},
		eventResult: EventResult<string>,
		config?: {
			optionIndex?: number
		}
	): boolean => {
		const optionIndex = config?.optionIndex

		if (optionIndex == null || !a.questionInfo) {
			return false
		}

		if (!a.questionInfo?.options[optionIndex]) {
			return false
		}

		a.questionInfo.options[optionIndex].result = eventResult
		return true
	},
}

/**
 * Finds the first object in an array matches the given value and removes it.
 * Returns true if an item was removed, and false if not.
 * @param {T[]} arr
 * @param {T} value the value we want to remove
 */
function findAndRemoveFromArray<T>(arr: T[] | null | undefined, value: T) {
	if (!arr) return false
	const index = arr.findIndex((v) => v === value)

	if (index > -1) {
		arr.splice(index, 1)
		return true
	}

	return false
}
