import { config } from '../../../config'

export const VIDEO = 'VIDEO'
export const IMAGE = 'IMAGE'
export const VOCAL_TRACK = 'VOCAL_TRACK' as const
export const NONE = 'NONE'
export const ALL = 'ALL'
export const COLLECTIONS_URL = `${config.simulationsApiUrl}/api/collections`
export const SONG_CHANGE = 'SONG_CHANGE'
export const SPECIAL_EFFECT = 'SPECIAL_EFFECT'
export const AUTOMATED_SIMULATION_MEDIA = 'automatedSimulationMedia'
export const MULTI_MEDIA = 'MULTI_MEDIA'
export const EFFECTS = 'EFFECTS'
export const FAVORITES = 'FAVORITES'
export const STRUCTURE = 'STRUCTURE'
