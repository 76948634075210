import React from 'react'
import OptionMessage from './OptionMessage'
import { ButtonProps } from 'reactstrap'
type ConfirmationProps = {
	message: string
	confirmText?: string
	onConfirm: (...args: Array<unknown>) => unknown
	secondButton?: boolean
} & (
	| {
			secondButton: true
			onCancel: (...args: Array<unknown>) => unknown
			cancelText?: string
	  }
	| { secondButton?: false }
)
/*
Simple Confirmation

Props:
	message: a string message, such as "Are you sure you want to delete this action?",
	confirmText: the text to be displayed on the confirm button,
	cancelText: the text to be displayed on the cancel button,
	onConfirm: the confirmation callback,
	onCancel: the cancel callback

Functionality:
	a simple confirmation component, ideally used in a toast. Alternatively, use the `runWithConfirmationMessage` function to a create a toast with this component.
	Can/should be used to get simple yes/no submit/cancel feedback from a user on an action.
*/

export default function SimpleConfirmation(props: ConfirmationProps): JSX.Element {
	const buttons: Array<[string, ButtonProps]> = [
		[
			props.confirmText || 'Confirm',
			{
				onClick: props.onConfirm,
				color: 'primary',
			},
		],
	]

	if (props.secondButton) {
		buttons.push([
			props.cancelText || 'Cancel',
			{
				onClick: props.onCancel,
				color: 'secondary',
			},
		])
	}

	return <OptionMessage message={props.message} buttons={buttons} />
}
