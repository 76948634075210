import MarkdownField, { VerticalMarkdownField } from '../../common/MarkdownField'

import React from 'react'
import { FormGroup, Button, Label } from 'reactstrap'
import { FieldArray, Field, FieldArrayFieldsProps } from 'redux-form'
import styled from 'styled-components'
import FontAwesome from 'react-fontawesome'

/**
 * Allows editing the 3D Science fields on a simulation.
 */
export function Edit3DScience(): JSX.Element {
	return (
		<FormGroup>
			<div>3D Science</div>
			<FormGroup>
				<Label>Phenomenon</Label>
				<Field name="science3D.phenomenon" component={MarkdownField} />
			</FormGroup>
			<Science3DContainer>
				<div>
					<FieldArray
						name="science3D.scienceAndEngineeringPractices"
						component={get3DScienceComponent({
							group: 'Science and Engineering Practices',
							textFieldName: 'practiceText',
							options: science3dOptions.scienceAndEngineeringPractices,
						})}
					/>
				</div>
				<div>
					<FieldArray
						name="science3D.crosscuttingConcepts"
						component={get3DScienceComponent({
							group: 'Crosscutting Concepts',
							textFieldName: 'conceptText',
							options: science3dOptions.crosscuttingConcepts,
						})}
					/>
				</div>
				<div>
					<FieldArray
						name="science3D.disciplinaryCoreIdeas"
						component={get3DScienceComponent({
							group: 'Disciplinary Core Ideas',
							textFieldName: 'ideaText',
							options: science3dOptions.disciplinaryCoreIdeas,
						})}
					/>
				</div>
			</Science3DContainer>
		</FormGroup>
	)
}
const Science3DContainer = styled.div`
	--3dScienceBorder: 1px solid var(--bs-gray-400);
	border: var(--3dScienceBorder);
	border-radius: 4px;
	display: flex;

	@media (max-width: 1050px) {
		flex-direction: column;
	}

	> div {
		padding: var(--spacing);
		flex: 1;
		border: var(--3dScienceBorder);
	}
`

/**
 * Creates a component that renders a list of fields for one of the 3D Science groups.
 * @param {string} group The name of the group, e.g. "Science and Engineering Practices"
 * @param {string} textFieldName The name of the field that contains the text for each entry in the group, e.g. "practiceText"
 * @param {Array<string>} options The list of predefined options for this group
 */
function get3DScienceComponent({
	group,
	textFieldName,
	options,
}: {
	group: string
	textFieldName: string
	options: Array<string>
}) {
	const optionsListId = group.replace(/\s/g, '') + 'options'
	return function Science3DComponent({
		fields,
	}: {
		fields: FieldArrayFieldsProps<{
			// this object also has a field with the key that is stored in `textFieldName`, but we can't type it here because it's a variable
			description: string
		}>
	}) {
		return (
			<div css="display: flex; flex-direction: column; align-items: flex-start;">
				<datalist id={optionsListId}>
					{options.map((option) => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</datalist>
				<Label>{group}</Label>
				{fields.map((field, index) => (
					<div
						key={field}
						css="width: 100%; display: flex; gap: var(--spacing); align-items: flex-start;"
						className="mb-3">
						<div css="flex-grow: 1;">
							<Label>Name</Label>
							<Field
								name={`${field}.${textFieldName}`}
								{...{
									className: 'form-control form-control-sm mb-1',
									component: 'input',
									type: 'text',
									list: optionsListId,
								}}
								css="max-width: 100%;"
							/>
							<Label>Description</Label>
							<Field
								name={`${field}.description`}
								component={VerticalMarkdownField}
								type="text"
								className="mb-1"
							/>
						</div>
						<Button
							color="danger"
							size="sm"
							css="flex-grow: 0;"
							onClick={() => {
								fields.remove(index)
							}}>
							<FontAwesome name="trash" />
						</Button>
					</div>
				))}
				<Button
					color="primary"
					size="sm"
					css="justify-self: flex-end;"
					onClick={() => {
						fields.push({
							[textFieldName]: '',
							description: '',
						})
					}}>
					Add
				</Button>
			</div>
		)
	}
}

const science3dOptions = {
	scienceAndEngineeringPractices: [
		'Asking Questions and Defining Problems',
		'Developing and Using Models',
		'Planning and Carrying Out Investigations',
		'Analyzing and Interpreting Data',
		'Using Mathematics and Computational Thinking',
		'Constructing Explanations and Designing Solutions',
		'Engaging in Argument from Evidence',
		'Obtaining, Evaluating, and Communicating Information',
	],
	crosscuttingConcepts: [
		'Patterns',
		'Cause and Effect',
		'Scale, Proportion, and Quantity',
		'Systems and System Models',
		'Energy and Matter',
		'Structure and Function',
		'Stability and Change',
	],
	disciplinaryCoreIdeas: [
		'LS1: From Molecules to Organisms',
		'LS1.A: Structure and Function',
		'LS1.B: Growth and Development of Organisms',
		'LS1.C: Organization for Matter and Energy Flow in Organisms',
		'LS1.D: Information Processing',
		'LS2: Ecosystems',
		'LS2.A: Interdependent Relationships in Ecosystems',
		'LS2.B: Cycles of Matter and Energy Transfer in Ecosystems',
		'LS2.C: Ecosystem Dynamics, Functioning, and Resilience',
		'LS2.D: Social Interactions and Group Behavior',
		'LS3: Heredity',
		'LS3.A: Inheritance of Traits',
		'LS3.B: Variation of Traits',
		'LS4: Biological Evolution',
		'LS4.A: Evidence of Common Ancestry and Diversity',
		'LS4.B: Natural Selection',
		'LS4.C: Adaptation',
		'LS4.D: Biodiversity and Humans',
		'ESS1: Earth’s Place in the Universe',
		'ESS1.A: The Universe and Its Stars',
		'ESS1.B: Earth and the Solar System',
		'ESS1.C: The History of Planet Earth',
		'ESS2: Earth’s Systems',
		'ESS2.A: Earth Materials and Systems',
		'ESS2.B: Plate Tectonics and Large-Scale System Interactions',
		'ESS2.C: The Roles of Water in Earth’s Surface Processes',
		'ESS2.D: Weather and Climate',
		'ESS2.E: Biogeology',
		'ESS3: Human Impacts on the Earth',
		'ESS3.A: Natural Resources',
		'ESS3.B: Natural Hazards',
		'ESS3.C: Human Impacts on Earth Systems',
		'ESS3.D: Global Climate Change',
		'PS1: Matter and Its Interactions',
		'PS1.A: Structure and Properties of Matter',
		'PS1.B: Chemical Reactions',
		'PS1.C: Nuclear Processes',
		'PS2: Motion and Stability: Forces and Interactions',
		'PS2.A: Forces and Motion',
		'PS2.B: Types of Interactions',
		'PS2.C: Stability and Instability in Physical Systems',
		'PS3: Energy',
		'PS3.A: Definitions of Energy',
		'PS3.B: Conservation of Energy and Energy Transfer',
		'PS3.C: Relationship Between Energy and Forces',
		'PS3.D: Energy in Chemical Processes and Everyday Life',
		'PS4: Waves and Their Applications in Technology',
		'PS4.A: Wave Properties',
		'PS4.B: Electromagnetic Radiation',
		'PS4.C: Information Technologies and Instrumentation',
		'ETS1: Engineering Design',
		'ETS1.A: Defining and Delimiting Engineering Problems',
		'ETS1.B: Developing Possible Solutions',
		'ETS1.C: Optimizing the Design Solution',
	],
}
