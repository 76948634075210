import React, { Component } from 'react'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { DragDropContext, Droppable, Draggable, DraggableLocation } from 'react-beautiful-dnd'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { QuestThumbnail, ClassQuestionThumbnail } from './specialThumbnails'
import ClassQuestionModal from './ClassQuestionModal'
import EmptyThumbnail from './empty-thumbnail.png'
import { actions } from '../../setup/tiles'
import styled from 'styled-components'
import type {
	Card as CardType,
	Quest as QuestType,
	ClassQuestion,
	ClassQuestionWithoutId,
	Option,
} from '../../types'
type Props = {
	simulationId: string
	cards: CardType[]
	quests: QuestType[]
	classQuestions: ClassQuestion[] | null | undefined
	selectedCardId: string | null | undefined
	onCardClick: (id: string) => void
}
type ReduxProps = {
	onMoveCard: (arg0: {
		simulationId: string
		cardId: string
		sourceIndex: number
		destinationIndex: number
	}) => void
	addCard: (arg0: { simulationId: string; cardIndex: number }) => unknown
	addQuest: (arg0: { simulationId: string; locationIndex: number }) => unknown
	deleteQuest: (arg0: { simulationId: string; questIndex: number }) => unknown
	addClassQuestion: (arg0: { simulationId: string; classQuestion: ClassQuestionWithoutId }) => void
	editClassQuestion: (arg0: { simulationId: string; classQuestion: ClassQuestion }) => void
	deleteClassQuestion: (arg0: { simulationId: string; classQuestionId: string }) => void
}
type State = {
	classQuestionModalOpen: boolean
	classQuestionToEdit: ClassQuestion | null | undefined
	previousCardId: string | null | undefined
}

class CardsSidebar extends Component<Props & ReduxProps, State> {
	state: State = {
		classQuestionModalOpen: false,
		previousCardId: null,
		classQuestionToEdit: null,
	}
	handleDragEnd = (result: {
		destination?: DraggableLocation | null | undefined
		source: {
			index: number
		}
	}) => {
		const { simulationId, cards, onMoveCard } = this.props

		if (!result.destination) {
			return
		}

		const cardId = cards[result.source.index]._id
		onMoveCard({
			simulationId,
			cardId,
			sourceIndex: result.source.index,
			destinationIndex: result.destination.index,
		})
	}
	addCard = (
		e: unknown,
		{
			index,
		}: {
			index: number
		}
	) => {
		this.props.addCard({
			simulationId: this.props.simulationId,
			cardIndex: index,
		})
	}
	addQuest = (
		e: unknown,
		{
			index,
		}: {
			index: number
		}
	) => {
		this.props.addQuest({
			simulationId: this.props.simulationId,
			locationIndex: index,
		})
	}
	deleteQuest = (
		_: unknown,
		{
			index,
		}: {
			index: number
		}
	) => {
		this.props.deleteQuest({
			simulationId: this.props.simulationId,
			questIndex: index,
		})
	}
	onClickAddClassQuestion = (
		e: unknown,
		{
			cardId,
		}: {
			cardId: string
		}
	) => {
		this.setState({
			classQuestionModalOpen: true,
			previousCardId: cardId,
		})
	}
	closeClassQuestionModal = () => {
		this.setState({
			classQuestionModalOpen: false,
			previousCardId: null,
			classQuestionToEdit: null,
		})
	}

	render(): JSX.Element {
		const { cards } = this.props
		const preClassQuestionCardIndex: number = this.state.previousCardId
			? cards.findIndex((card: CardType) => card._id === this.state.previousCardId)
			: -1
		return (
			<>
				<DragDropContext onDragEnd={this.handleDragEnd}>
					<Droppable droppableId="cards">
						{(provided, snapshot) => (
							<StyledSidebar ref={provided.innerRef}>
								{cards.map((card: CardType, index: number) => {
									const questIndex: number | null | undefined = this.hasQuest(index)
									const classQuestion: ClassQuestion | null | undefined =
										this.getClassQuestionAfterIndex(index)
									return (
										<React.Fragment key={card._id}>
											{questIndex != null && (
												<QuestThumbnail index={questIndex} deleteQuest={this.deleteQuest} />
											)}
											{this.renderMenuComponent(card, index)}
											{classQuestion && (
												<ClassQuestionThumbnail
													classQuestion={classQuestion}
													onDelete={() =>
														this.props.deleteClassQuestion({
															simulationId: this.props.simulationId,
															classQuestionId: classQuestion._id,
														})
													}
													onEdit={() =>
														this.setState({
															classQuestionModalOpen: true,
															classQuestionToEdit: classQuestion,
														})
													}
												/>
											)}
										</React.Fragment>
									)
								})}
								<Button
									onClick={(e) =>
										this.addCard(e, {
											index: cards.length,
										})
									}>
									Add Card
								</Button>
								{provided.placeholder}
							</StyledSidebar>
						)}
					</Droppable>
				</DragDropContext>
				{this.state.classQuestionModalOpen &&
					((preClassQuestionCardIndex >= 0 && cards[preClassQuestionCardIndex]) ||
						this.state.classQuestionToEdit) && (
						<ClassQuestionModal
							closeModal={this.closeClassQuestionModal}
							cards={cards}
							preCardIndex={preClassQuestionCardIndex}
							initialValues={this.state.classQuestionToEdit}
							onSave={({ phrase, options }: { phrase: string; options: Option[] }) => {
								const { previousCardId } = this.state

								if (!phrase) {
									toast.warn('Please enter a question')
									return
								}

								if (!options.every((option) => !!option.text)) {
									toast.warn('Please enter text for every option')
									return
								}

								if (this.state.classQuestionToEdit) {
									this.props.editClassQuestion({
										simulationId: this.props.simulationId,
										classQuestion: { ...this.state.classQuestionToEdit, phrase, options },
									})
									this.closeClassQuestionModal()
									return
								}

								if (!previousCardId) {
									toast.warn('Card was not selected. Please try again')
									this.closeClassQuestionModal()
									return
								}

								this.props.addClassQuestion({
									simulationId: this.props.simulationId,
									classQuestion: {
										phrase,
										previousCardId,
										options,
									},
								})
								this.closeClassQuestionModal()
							}}
						/>
					)}
			</>
		)
	}

	/**
	 * If there should be a quest slot at the given card index, returns the index in the quests prop where that quest slot is defined
	 * @return {?number} If there is no quest slot for the given index, null. Otherwise the index of the quest slot in the `quests` prop
	 */
	hasQuest(cardIndex: number): number | null | undefined {
		const { quests } = this.props
		if (!quests) return null
		const questIndex: number = quests.findIndex(
			(quest: QuestType) => quest.locationIndex === cardIndex
		)
		if (questIndex >= 0) return questIndex
		return null
	}

	/**
	 *  Gets the class question immediately after the card at index `cardIndex`. If there is no class question there, returns null
	 */
	getClassQuestionAfterIndex(cardIndex: number): ClassQuestion | null | undefined {
		const card = this.props.cards[cardIndex]
		return (
			this.props.classQuestions &&
			this.props.classQuestions.find((classQuestion) => classQuestion.previousCardId === card._id)
		)
	}

	renderMenuComponent(card: CardType, index: number): JSX.Element {
		const { selectedCardId, onCardClick } = this.props
		return (
			<Styles>
				<Draggable key={card._id} draggableId={card._id} index={index}>
					{(provided, snapshot) => (
						<React.Fragment>
							{/* @ts-expect-error TS doesn't think ContextMenuTrigger should have children */}
							<ContextMenuTrigger
								id={card._id}
								attributes={{
									className: 'context-menu-wrapper',
								}}>
								<StyledListItem
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									className={classnames({
										active: card._id === selectedCardId,
									})}
									onClick={() => onCardClick(card._id)}>
									<StyledCardName>{card.name}</StyledCardName>
									<StyledCardNumber>{index + 1}</StyledCardNumber>

									{card.thumbnailUrl && <StyledImage src={card.thumbnailUrl} alt="" />}
									{!card.thumbnailUrl && <StyledImage src={EmptyThumbnail} alt="" />}
								</StyledListItem>
							</ContextMenuTrigger>
							{/* @ts-expect-error TS doesn't think ContextMenu should have children */}
							<StyledContextMenu id={card._id} className="context-menu" key={card._id}>
								{/* @ts-expect-error TS doesn't think MenuItem should have children */}
								<MenuItem
									data={{
										index,
									}}
									onClick={this.addCard}
									attributes={{
										className: 'context-menu-item',
									}}>
									Insert Card Before
								</MenuItem>
								{/* @ts-expect-error TS doesn't think MenuItem should have children */}
								<MenuItem
									data={{
										index: index + 1,
									}}
									onClick={this.addCard}
									attributes={{
										className: 'context-menu-item',
									}}>
									Insert Card After
								</MenuItem>
								{/* @ts-expect-error TS doesn't think MenuItem should have children */}
								<MenuItem
									data={{
										index: index + 1,
									}}
									onClick={this.addQuest}
									attributes={{
										className: 'context-menu-item',
									}}>
									Insert Quest After
								</MenuItem>
								{/* @ts-expect-error TS doesn't think MenuItem should have children */}
								<MenuItem
									data={{
										cardId: card._id,
									}}
									onClick={this.onClickAddClassQuestion}
									attributes={{
										className: 'context-menu-item',
									}}>
									Insert Class Question After
								</MenuItem>
							</StyledContextMenu>
						</React.Fragment>
					)}
				</Draggable>
			</Styles>
		)
	}
}

const Styles = styled.div`
	& .context-menu-wrapper {
		cursor: pointer;
		position: relative;
		margin-bottom: 10px;

		&:last-child {
			margin: 0;
		}
	}

	& .context-menu-item {
		cursor: pointer;
	}
`
const StyledContextMenu = styled(ContextMenu)`
	background-color: white;
	border-radius: 3px;
	padding: 4px;
	border: 1px solid gray;
	z-index: 1;

	& .context-menu-item {
		cursor: pointer;
	}
`
const StyledSpan = styled.span`
	color: #fff;
	background: #000000a3;
	padding: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	line-height: 1;
	position: absolute;
`
const StyledCardName = styled(StyledSpan)`
	bottom: 0;
	left: 0;
`
const StyledCardNumber = styled(StyledSpan)`
	bottom: 0;
	right: 0;
`
const StyledImage = styled.img`
	width: 100%;
`
const StyledListItem = styled.li`
	&.active {
		border: 5px solid aqua;
		span {
			font-weight: 700;
		}
	}

	&.special-thumbnail {
		padding: 8px;
		color: white;
		text-align: center;
		margin-bottom: 10px;
	}

	&.quest {
		background-color: green;
	}

	&.class-question {
		background-color: steelblue;
	}
`
const StyledSidebar = styled.ul`
	list-style: none;
	margin: 0;
	padding: 10px;
	background: #d3d3d3;
	z-index: 2;
`
const mapDispatchToProps = {
	onMoveCard: actions.simulations.moveCard,
	addCard: actions.simulations.addCard,
	addQuest: actions.simulations.addQuest,
	deleteQuest: actions.simulations.deleteQuest,
	addClassQuestion: actions.simulations.addClassQuestion,
	editClassQuestion: actions.simulations.editClassQuestion,
	deleteClassQuestion: actions.simulations.deleteClassQuestion,
}
export default connect(null, mapDispatchToProps)(CardsSidebar)
