import React from 'react'
import { startCase } from 'lodash'
import { componentMap } from './ComponentMap'
import { EVENT_RESULT_SETTERS } from '../helpers/eventResults'
import FormComponentWrapper from './FormComponentWrapper'
import {
	MapSelector,
	AddMapObject,
	ScreenOverlaySelector,
	UrlInput,
	CharacterAttributesInput,
	MapObjectSelector,
	StationIdSelector,
} from './FormPieces/FormComponents'
import { getAppropriateFileRestrictions } from '../helpers/algorithms'

import type {
	Action,
	StationId,
	ActivateStationData,
	ActivateStationAction,
	LiteracyEventAction,
	VocalTrackAction,
	EventResult,
} from '@mission.io/mission-toolkit/actions'
import { STATION_IDS } from '@mission.io/mission-toolkit/constants'
import { KeysOfUnion } from '../../../helpers/types'
import type { ReadingContextOnChangeEvent } from './FormPieces/LiteracyEvent/ReadingContextEditor'
import { ActionId } from '../actionDefinitions'

type ConstantProps<LocalAction extends Action<string>, Label extends KeysOfUnion<LocalAction>> = {
	label: Label
	value: LocalAction[Label]
	actionId: ActionId
	action: LocalAction
	createdActions?: Action<string>[]
	onLeave: (cb: (arg0: boolean) => void) => () => void
}

type OnChange<T> = (newValue: T) => unknown

type StringOnChange = OnChange<string | null | undefined>

type FormContainerProps<
	LocalAction extends Action<string>,
	Label extends KeysOfUnion<LocalAction>
> =
	| (ConstantProps<LocalAction, Label> & {
			onChange: LocalAction[Label] extends string
				? StringOnChange
				: (newValue: LocalAction[Label]) => void
	  })
	| (ConstantProps<LocalAction, Label> & {
			value: EventResult<string>
			onChange: (e: {
				type: 'EVENT_RESULT'
				newAction?: Action<string>
				deletedActionId?: ActionId
				modifiedEventResult: EventResult<string>
			}) => void
	  })
	| (ConstantProps<ActivateStationAction<string>, 'stationData'> & {
			createdActions?: Action<string>[]
			onChange: (
				e:
					| {
							type: 'STATION_DATA'
							stationData: ActivateStationData<string>
					  }
					| {
							type: 'EVENT_RESULT'
							newAction?: Action<string>
							modifiedEventResult: EventResult<string>
					  }
			) => void
	  })
	| (ConstantProps<LiteracyEventAction<string>, 'readingContexts'> & {
			onChange: (event: ReadingContextOnChangeEvent) => void
	  })

const REMOVE_MAP_OBJECT = 'REMOVE_MAP_OBJECT'
const ADD_MAP_OBJECT = 'ADD_MAP_OBJECT'
export default function FormContainer<
	LocalAction extends Action<string>,
	Label extends KeysOfUnion<LocalAction>
>({
	label,
	value,
	onChange,
	onLeave,
	actionId,
	action,
	createdActions,
}: FormContainerProps<LocalAction, Label>): JSX.Element | null {
	const inputLabel = (): string => {
		if (action.type === 'MISSION_EFFECT' && action.effectId === 'DEATH') {
			if (action.isShipDeath) {
				if (label === 'continueButtonText') {
					return 'cheatDeathButtonText'
				}
			} else {
				if (label === 'onCheatDeath') {
					return 'onContinue'
				} else return label
			}
		}

		return label
	}

	if (!shouldDisplayComponent({ action, label })) {
		return null
	}

	// @ts-expect-error we want to use `label` to index into `componentMap`
	const Component = label in componentMap ? componentMap[label] : undefined
	const formComponent = Component ? (
		Object.keys(EVENT_RESULT_SETTERS).includes(label) ? (
			<Component
				rootAction={action}
				value={value}
				onChange={onChange}
				actions={createdActions}
				label={label}
				actionId={actionId}
				onLeave={onLeave}
			/>
		) : label === 'mapId' && (typeof value === 'string' || value === null) ? (
			<MapSelector
				// @ts-expect-error We expect value to be the right type
				value={value}
				// @ts-expect-error We expect onChange to be the right type
				onChange={onChange}
				onSelectObject={onLeave}
				actionType={action.type}
				showHidden={Boolean(
					action.type && (action.type === ADD_MAP_OBJECT || action.type === REMOVE_MAP_OBJECT)
				)}
			/>
		) : label === 'mapObjectId' ? (
			action.type === ADD_MAP_OBJECT ? ( // @ts-expect-error if type is ADD_MAP_OBJECT, then value is string, and onChange parameter is string
				<AddMapObject value={value} onChange={onChange} mapId={action.mapId} />
			) : action.type === REMOVE_MAP_OBJECT ? ( // @ts-expect-error if type is REMOVE_MAP_OBJECT, then value is string, and onChange parameter is string
				<MapObjectSelector value={value} onChange={onChange} mapId={action.mapId} />
			) : null
		) : label === 'type' && typeof value === 'string' ? ( // @ts-expect-error if value type is string, then onChange param will be string
			<ScreenOverlaySelector value={value} onChange={onChange} actionId={actionId} />
		) : Component === UrlInput ? (
			<UrlInput
				value={value as string | null | undefined}
				onChange={onChange as StringOnChange}
				restrictions={getAppropriateFileRestrictions(action.type, label)}
			/>
		) : label === 'attributes' && action.type === 'VOCAL_TRACK' ? (
			<CharacterAttributesInput
				value={value as VocalTrackAction<string>['attributes']}
				onChange={onChange as OnChange<string[]>}
				characterId={action.characterId}
			/>
		) : label === 'stationId' && action.type === 'DEACTIVATE_STATION' ? (
			<StationIdSelector
				value={value as StationId}
				onChange={onChange as (value: StationId) => unknown}
				filter={(id) => id !== STATION_IDS.REPAIRS}
			/>
		) : (
			<Component value={value} onChange={onChange} onLeave={onLeave} rootAction={action} />
		)
	) : (
		<div>Error finding {label} Component</div>
	)

	// 'stationData' is special because it is essentially the entire ACTIVATE_STATION action.
	// We don't want to show the label and have it taking up the extra space
	if (label === 'stationData') {
		return formComponent
	}

	return <FormComponentWrapper label={startCase(inputLabel())} component={formComponent} />
}

/**
 * Tells whether the component for the given action and label should be displayed. If there is no
 * reason to hide the component, then it should be displayed.
 */
function shouldDisplayComponent({ action, label }: { action: Action<string>; label: string }) {
	// atTime is edited in the timeline
	if (label === 'atTime') {
		return false
	}

	return true
}
