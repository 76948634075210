import type { ComponentType } from 'react'
import { Component } from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps & {
	children: JSX.Element
}

class ScrollToTop extends Component<Props> {
	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render(): JSX.Element {
		return this.props.children
	}
}

export default withRouter(ScrollToTop) as ComponentType<{
	children?: JSX.Element
}>
