import React from 'react'
import { Modal as BootstrapModal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
type Props = {
	title?: string | null | undefined
	prompt: string
	callback: (result: boolean) => void
	isOpen: boolean
}
export default function ConfirmModal({ title, prompt, callback, isOpen }: Props): JSX.Element {
	return (
		<BootstrapModal isOpen={isOpen}>
			<ModalHeader>{title || 'Are you sure?'}</ModalHeader>
			<ModalBody>
				<span>{prompt}</span>
			</ModalBody>
			<ModalFooter>
				<Button
					color="warning"
					onClick={() => {
						callback(false)
					}}>
					Go back
				</Button>
				<Button
					color="danger"
					onClick={() => {
						callback(true)
					}}>
					Continue
				</Button>
			</ModalFooter>
		</BootstrapModal>
	)
}
