import type { DurationMap } from '../PhaseCreator/types'

export const INITIAL_DURATION_MAP: DurationMap = {
	url: {},
	specialEffectId: {},
}
export const SCREEN_REFERENCE = 'SCREEN_REFERENCE'

export const DELETE_CONFIRMATION_MESSAGE =
	'Are you sure? Deleting this action will cause a cascade, deleting all of its children actions.'
export const PLAYER_COLLISION_WITH_SOLID_DESCRIPTION =
	'Triggers whenever a `Player` collision type collides with a `SOLID` or `SOLID_DAMAGE` collision type'
export const AUTOMATED_SIMULATION_STATUSES = {
	UNAVAILABLE: {
		value: 'UNAVAILABLE',
		text: 'Unavailable',
	},
	COMING_SOON: {
		value: 'COMING_SOON',
		text: 'Coming Soon',
	},
	BETA: {
		value: 'BETA',
		text: 'Beta',
	},
	NEW: {
		value: 'NEW',
		text: 'New',
	},
	ACTIVE: {
		value: 'ACTIVE',
		text: 'Active',
	},
	UNDER_CONSTRUCTION: {
		value: 'UNDER_CONSTRUCTION',
		text: 'Under Construction',
	},
}
