import { createGlobalStyle } from 'styled-components'
import { theme } from '@mission.io/styles'

const GlobalStyles = createGlobalStyle`
	:root {
		--spacingHalf: 4px;
		--spacing: 8px;
		--spacing2x: 16px;
		--spacing3x: 24px;
		--spacing4x: 32px;
		--primary: ${theme.primary};
		--secondary: ${theme.secondary};
	}

	body {
		margin: 0;
		padding: 0;
	}

	* {
		font-family: proxima-nova, sans-serif;
	}

    /****************
    *  App Styles  *
    ****************/

    .nav-tabs .nav-item a.nav-link {
        cursor: pointer;
    }

    .clickable:hover {
        cursor: pointer;
    }

    /********************
    *  Utility Styles  *
    ********************/

    .shadow {
        box-shadow: 1px 1px 4px #cecece !important;
    }

    button.add-choice {
        border: 1px solid #999;
        border-radius: 2px;
        background: none;
        cursor: pointer;
        color: #636363;
        font-style: italic;
        padding: 2px 5px;
        margin-top: 5px;
    }

    /**********************
    *  Plugin Overrides  *
    **********************/

    .RichTextEditor__root___2QXK- {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }

    input.h1, input.h2, input.h3, input.h4, input.h5, input.h6 {
        border: none;
        border-bottom-width: 1px;
        border-bottom-style: inset;
        border-bottom-color: lightgray;
        outline: none;
        width: 50%;
        transition: border-bottom-color 0.7s ease-in;
    }

    input.h1:focus, input.h2:focus, input.h3:focus, input.h4:focus, input.h5:focus, input.h6:focus {
        border-bottom-color: darkgray;
    }

    .save-button-container {
        float: right;
        z-index: 15;
        position: sticky;
        top: 49px;
        background: white;
        padding: 0.5rem;
    }


    /**
        File details table
     */


    .ReactVirtualized__Table__headerRow {
		font-weight: bold;
		display: flex;
		flex-direction: row;

		padding: 0.75rem;
		border-top: 1px solid #dee2e6;
		border-bottom: 1px solid #dee2e6;
	}
	.ReactVirtualized__Table__row {
		display: flex;
		flex-direction: row;

		padding: 0.75rem;
		border-top: 1px solid #dee2e6;

		&:hover {
			background: rgba(0, 0, 0, 0.075);
			cursor: pointer;
		}
	}

	.ReactVirtualized__Table__headerTruncatedText {
		display: inline-block;
		max-width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.ReactVirtualized__Table__headerColumn,
	.ReactVirtualized__Table__rowColumn {
		margin-right: 10px;
		min-width: 0px;
	}
	.ReactVirtualized__Table__rowColumn {
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.ReactVirtualized__Table__headerColumn:first-of-type,
	.ReactVirtualized__Table__rowColumn:first-of-type {
		margin-left: 10px;
	}

	.ReactVirtualized__Table__sortableHeaderColumn {
		cursor: pointer;
	}

	.ReactVirtualized__Table__sortableHeaderIconContainer {
		display: flex;
		align-items: center;
	}

	.ReactVirtualized__Table__sortableHeaderIcon {
		flex: 0 0 24px;
		height: 1em;
		width: 1em;
		fill: currentColor;
	}

`
export default GlobalStyles
