import { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getEntity,
	getEntities,
	fetchEntities,
	fetchEntityMeta,
	getEntityMeta,
} from '../../reducers/entities'
import {
	getNavigationMap,
	fetchNavigationMaps,
	fetchNavigationMeta,
	getNavigationMeta,
} from '../../reducers/navigationMap'
import EditorContext from './context'
import { getBoundingBox } from './utilities'
import type { EntityResponse, EntityMetaResponse } from '../../reducers/entities'
import type { NavigationMap, Entity } from '@mission.io/navigation-map-server'
import type { ParentData, Box } from './types'
export function useEntity(entityId: string | null | undefined): Entity | null | undefined {
	const entityData: EntityResponse = useSelector(getEntity(entityId))
	const dispatch = useDispatch()
	useEffect(() => {
		if (entityId && entityData.entity == null && entityData.fetching == null) {
			dispatch(fetchEntities([entityId]))
		}
	}, [entityId, entityData.entity, entityData.fetching, dispatch])
	return entityData.entity
}
export function useEntities(entityIds: string[] | null | undefined): Entity[] | null | undefined {
	const entityData: EntityResponse[] | null | undefined = useSelector(getEntities(entityIds))
	const dispatch = useDispatch()
	useEffect(() => {
		if (!entityData) {
			return
		}

		const toFetch: string[] = []
		entityData.forEach((data, index) => {
			if (data.id && data.entity == null && data.fetching == null) {
				toFetch.push(data.id)
			}
		})
		dispatch(fetchEntities(toFetch))
	}, [entityData, dispatch])

	if (!entityData) {
		return null
	}

	const toReturn: Entity[] = []

	for (const data of entityData) {
		if (!data.entity) {
			return null
		}

		toReturn.push(data.entity)
	}

	return toReturn
}
export function useEntityMeta(): EntityMetaResponse {
	const entityMetaData: EntityMetaResponse = useSelector(getEntityMeta)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!entityMetaData.meta && !entityMetaData.fetching) {
			dispatch(fetchEntityMeta())
		}
	}, [dispatch, entityMetaData.meta, entityMetaData.fetching])
	return entityMetaData
}
export function useMap(mapId: string | null | undefined): NavigationMap | null | undefined {
	const mapData = useSelector(getNavigationMap(mapId))
	const dispatch = useDispatch()
	useEffect(() => {
		if (mapId && mapData.map == null && mapData.fetching == null) {
			dispatch(fetchNavigationMaps([mapId]))
		}
	}, [mapId, dispatch, mapData.map, mapData.fetching])
	return mapData.map
}
export function useNavigationMapMeta(): ReturnType<typeof getNavigationMeta> {
	const mapMeta = useSelector(getNavigationMeta)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!mapMeta.meta && !mapMeta.fetching) {
			dispatch(fetchNavigationMeta())
		}
	}, [dispatch, mapMeta.meta, mapMeta.fetching])
	return mapMeta
}

/**
 * @param  {{
 *   ...parentData: the global positioning data of the parent,
 *   ...Box: the local (within the parent's coordinate system) positioning data of the child
 * }} props
 * @returns {{
 *	 x: the x coordinate of the object in global space (top left),
 *	 y: the y coordinate of the object in global space (top left),
 *	 width: the x-span of the object in global space,
 *	 height: the y-span of the object in global space,
 *	 rotation: the rotation of the object (around the center of the object) in global space,
 *	 transform: the svg transform string which will rotate the object correctly in global space
 *}}
 */
export function usePositioningData(props: ParentData & Box): {
	x: number
	y: number
	width: number
	height: number
	rotation: number
	transform: string
} {
	const { rotation: parentRotation, rotateAround: parentOrigin } = useContext(EditorContext)
	const boundingBox = getBoundingBox(props)
	let transform = ''

	if (parentRotation != null && parentOrigin) {
		transform = `rotate(${parentRotation} ${parentOrigin.x} ${parentOrigin.y})`
	}

	if (boundingBox.rotation) {
		transform += `rotate(${boundingBox.rotation} ${boundingBox.x + boundingBox.width / 2} ${
			boundingBox.y + boundingBox.height / 2
		})`
	}

	return { ...boundingBox, transform }
}
