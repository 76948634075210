import React from 'react'
import { Field, FieldArrayFieldsProps } from 'redux-form'
import { Button, InputGroup } from 'reactstrap'
import FontAwesome from 'react-fontawesome'

function TextList(
	pluralEntity: string
): (props: { fields: FieldArrayFieldsProps<string> }) => JSX.Element {
	return function TextListComponent({
		fields: { length, push, map },
	}: {
		fields: FieldArrayFieldsProps<string>
	}): JSX.Element {
		return (
			<div>
				{length === 0 && (
					<p className="my-1">
						<i>No {pluralEntity} found</i>
					</p>
				)}

				{length > 0 &&
					map((name, index, { remove }) => (
						<InputGroup key={name} className="mb-1">
							<Field name={name} component="input" className="form-control" />
							<Button color="danger" onClick={() => remove(index)}>
								<FontAwesome name="trash" />
							</Button>
						</InputGroup>
					))}

				<button className="add-choice" onClick={() => push('')}>
					Add
				</button>
			</div>
		)
	}
}

export default TextList
