import _ from 'lodash'

_.mixin({
	memoizeDebounce: function (func, wait = 3000, options = {}) {
		const mem = _.memoize(function () {
			return _.debounce(func, wait, options)
		}, options.resolver)

		return function () {
			// @ts-expect-error This code is copied from the internet
			// eslint-disable-next-line prefer-rest-params
			mem.apply(this, arguments).apply(this, arguments)
		}
	},
})
