import { createStore, combineReducers, applyMiddleware } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension'
import mapObjectReducer from '../reducers/mapObjects'
import categoryReducer from '../reducers/categories'
import charactersReducer, { CharacterStore } from '../reducers/characters'
import entityReducer from '../reducers/entities'
import navigationMapReducer from '../reducers/navigationMap'
import simulationEditorReducer from '../reducers/simulationEditor'
import { topLevel, reducer as tileReducer, middleware as tileMiddleware } from './tiles'
import type { ReduxStore } from '../types/ReduxStore'
import type { AnyAction, Reducer, Store } from 'redux'
const rootReducer = combineReducers<ReduxStore>({
	[topLevel]: tileReducer,
	form: formReducer,
	mapObjects: mapObjectReducer,
	categories: categoryReducer,
	characters: charactersReducer as Reducer<CharacterStore>,
	entities: entityReducer,
	navigation: navigationMapReducer,
	simulationEditor: simulationEditorReducer,
})
export function createReduxStore(initialState?: Partial<ReduxStore>): Store<ReduxStore> {
	const composedEnhancers = composeWithDevTools(applyMiddleware(tileMiddleware))

	if (initialState) {
		return createStore<ReduxStore, AnyAction, unknown, unknown>(
			rootReducer,
			initialState as ReduxStore,
			composedEnhancers
		)
	}

	return createStore(rootReducer, composedEnhancers)
}
const store: Store<ReduxStore> = createReduxStore()
export default store
