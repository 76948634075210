import React from 'react'
import { sortBy } from 'lodash'

import { Card, CardBody, CardHeader, DuplicateButton, CardFooter } from './SimulationCard'
import { Spinner } from '../../common/Spinner'
import { useAutomatedSimulations } from '../automatedSimulations/queries'
/**
 * Displays a list of all automated simulations. If the list has not been fetched yet, displays
 * a spinner.
 */

export default function AutomatedSimulationList({
	filter,
}: {
	filter: (simulation: { name: string }) => boolean
}): JSX.Element {
	const { data: simulations_, isLoading, error } = useAutomatedSimulations()
	const simulations = sortBy(simulations_?.filter(filter) ?? [], 'name')
	return (
		<div>
			<h2 className="text-2xl font-bold my-2">Automated Simulations</h2>
			{error ? (
				'Error Loading Automated Simulations'
			) : isLoading ? (
				<Spinner />
			) : (
				simulations.map((simulation) => (
					<Card key={simulation._id}>
						<CardHeader text={simulation.name}>
							<DuplicateButton simulation={simulation} />
						</CardHeader>
						<CardBody data={[['Control Set', simulation.controlSet]]} />
						<CardFooter id={simulation._id} />
					</Card>
				))
			)}
		</div>
	)
}
