import type { ComponentType } from 'react'
import React, { useState } from 'react'
import { Button, FormGroup, Label, FormText } from 'reactstrap'
import { startCase } from 'lodash'
import Select from 'react-select'

import {
	MapObjectSelector,
	MapSelector,
	NumberInput,
	StringInput,
	MediaInput,
} from './BasicFormComponents'
import { getDefaultDefenseTarget, getDefaultTractorBeamTarget } from '../../actionDefinitions'
import FormComponentWrapper from '../FormComponentWrapper'
import { useId } from '../../../../helpers/hooks'
import type {
	Image,
	ActionObjectInfo,
	DefensePlusTarget,
	MapObject,
	TargetSize,
	TractorBeamPlusTarget,
} from '@mission.io/mission-toolkit/actions'
import { DEFENSE_STATION, TRACTOR_BEAM_STATION } from '@mission.io/mission-toolkit/constants'
import { useSelector } from 'react-redux'
import { getMapObject } from '../../../../reducers/mapObjects'
import { FaAngleDown, FaAngleUp, FaTrash } from 'react-icons/fa'
import { ReduxStore } from '../../../../types/ReduxStore'
type StationTarget = DefensePlusTarget<string> | TractorBeamPlusTarget<string>
type GenericInputProps<T> = {
	value: T
	onChange: (arg0: T) => void
	id: string
}
type StationConstants = {
	constants: typeof DEFENSE_STATION | typeof TRACTOR_BEAM_STATION
	canvas: {
		width: number
		height: number
	}
}
type GenericInputPropsWithTargetAndStationConstants<
	T,
	TargetType extends StationTarget = StationTarget
> = GenericInputProps<T> & {
	target: TargetType extends DefensePlusTarget<string>
		? DefensePlusTarget<string>
		: TractorBeamPlusTarget<string>
	stationConstants: StationConstants
}
export const DEFENSE_CANVAS = {
	width: 200,
	height: 100,
}
export const TRACTOR_BEAM_CANVAS = {
	width: 1920,
	height: 1080,
}

/**
 * A map of properties on `TargetType` to a component used to edit that property
 */
type ComponentsForTarget<TargetType extends StationTarget> = {
	[K in keyof TargetType]: ComponentType<
		GenericInputPropsWithTargetAndStationConstants<TargetType[K], TargetType>
	>
}

function SmallNumberInput(props: GenericInputProps<number>) {
	return <NumberInput {...props} bsSize="sm" />
}

function SmallStringInput(props: GenericInputProps<string>) {
	return <StringInput {...props} bsSize="sm" />
}

/**
 * A form group where the label and component are inline
 */
function InlineFormGroup<T>({
	value,
	onChange,
	label,
	component: Component,
}: {
	value: T
	onChange: (arg0: T) => void
	label: string
	component: ComponentType<GenericInputProps<T>>
}) {
	const id = useId()
	return (
		<FormGroup className="form-inline" css="justify-content: space-between;">
			<Label htmlFor={id} css="margin-right: var(--spacing);">
				{label}
			</Label>
			<Component id={id} value={value} onChange={onChange} />
		</FormGroup>
	)
}

/**
 * An input that can modify the `size` property on a target
 */
function SizeInput({
	value: size,
	onChange,
	target,
	stationConstants,
}: GenericInputPropsWithTargetAndStationConstants<TargetSize>) {
	const imageUrl = useTargetImageUrl(target)
	const xy =
		target.movement.type === 'STATIC' && target.movement.location.type === 'DEFINED'
			? {
					x: target.movement.location.x,
					y: target.movement.location.y,
			  }
			: {
					x: stationConstants.canvas.width / 2,
					y: stationConstants.canvas.height / 2,
			  }
	xy.x -= size.width / 2
	xy.y -= size.height / 2
	const imageProps = { ...xy, width: size.width, height: size.height }
	return (
		<div css="display:flex; gap: calc(var(--spacing) * 8);">
			<div>
				<InlineFormGroup
					label="Width"
					component={SmallNumberInput}
					value={size.width}
					onChange={(newWidth) => onChange({ ...size, width: newWidth })}
				/>
				<InlineFormGroup
					label="Height"
					component={SmallNumberInput}
					value={size.height}
					onChange={(newHeight) => onChange({ ...size, height: newHeight })}
				/>
			</div>

			<div css="overflow: scroll; resize: both;">
				<svg
					viewBox={`0 0 ${stationConstants.canvas.width} ${stationConstants.canvas.height}`}
					width="100%"
					height="100%"
					css="background-color: lightgray;">
					{imageUrl ? (
						<image xlinkHref={imageUrl} {...imageProps} preserveAspectRatio="none" />
					) : (
						<rect {...imageProps} />
					)}
				</svg>
			</div>
		</div>
	)
}

/**
 * An input that can modify the `actionObjectInfo` property on a target
 */
function ActionObjectInfoInput({
	value: actionObjectInfo,
	onChange,
}: GenericInputProps<ActionObjectInfo | undefined>) {
	if (!actionObjectInfo) {
		return null
	}

	return (
		<div>
			<InlineFormGroup
				label="Name"
				component={SmallStringInput}
				value={actionObjectInfo.objectName}
				onChange={(newName) => onChange({ ...actionObjectInfo, objectName: newName })}
			/>
			<InlineFormGroup
				label="Description"
				component={SmallStringInput}
				value={actionObjectInfo.objectDescription}
				onChange={(newDescription) =>
					onChange({ ...actionObjectInfo, objectDescription: newDescription })
				}
			/>
		</div>
	)
}

/**
 * An input that can modify the `mapObject` property on a target
 */
function MapObjectInput({
	value: mapObject,
	onChange,
}: GenericInputProps<MapObject | null | undefined>) {
	return (
		<div>
			<MapSelector
				value={mapObject?.mapId}
				onChange={(newMapId) => {
					if (newMapId !== mapObject?.mapId) {
						onChange({
							mapId: newMapId || '',
							mapObjectId: '',
						})
					}
				}}
			/>
			<MapObjectSelector
				mapId={mapObject?.mapId || ''}
				value={mapObject?.mapObjectId || ''}
				onChange={(newMapObjectId) => {
					if (!newMapObjectId) {
						onChange(null)
						return
					}

					if (!mapObject) {
						return
					}

					onChange({ ...mapObject, mapObjectId: newMapObjectId })
				}}
			/>
		</div>
	)
}

/**
 * An input that can modify the `media` property on a target
 */
function ImageMediaInput({ value: media, onChange }: GenericInputProps<Image | null>) {
	return (
		<MediaInput
			collection="stationImages"
			value={media || null}
			onChange={(newMedia) => {
				if (newMedia) onChange(newMedia)
			}}
			mediaType="IMAGE"
		/>
	)
}

type ExtraData = Record<
	string,
	{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		defaultValue: any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		component: ComponentType<GenericInputProps<any>>
	}
>

// A config to pass to `getTypeObjectEditor`
type TypeObjectEditorConfig<TypeString extends string> = Array<
	| TypeString
	| {
			type: TypeString
			extraData: ExtraData
	  }
>

/**
 * Gets a component that is used to edit a property field whose value is an object of the form `{ type: <ENUM>, ...extraData }`.
 * The produced component will be a select component that allows any of the options provided in `config`. If any of the options in `config`
 * include `extraData`, then the properties in extraData will also be configurable when that option is selected.
 */
function getTypeObjectEditor<TypeString extends string>(
	config: TypeObjectEditorConfig<TypeString>,
	isDisabled = false
): ComponentType<
	GenericInputProps<{
		type: TypeString
	}>
> {
	return function TypeEditor({
		value,
		onChange,
		id,
	}: GenericInputProps<
		{
			type: TypeString
		} & Record<string, unknown>
	>) {
		const options = config.map((configEntry) => {
			if (typeof configEntry === 'string') {
				return {
					value: configEntry,
					label: startCase(configEntry),
				}
			}

			return {
				value: configEntry.type,
				extraData: configEntry.extraData,
				label: startCase(configEntry.type),
			}
		})
		const selectedOption = options.find((option) => option.value === value.type)
		return (
			<>
				<Select<{ value: TypeString; label: string; extraData?: ExtraData }>
					inputId={id}
					{...{
						value: selectedOption,
						onChange: (newlySelectedOption) => {
							if (!newlySelectedOption) {
								return
							}
							const newValue: { type: TypeString } & Record<string, unknown> = {
								type: newlySelectedOption.value,
							}

							if ('extraData' in newlySelectedOption) {
								for (const key in newlySelectedOption.extraData) {
									newValue[key] = newlySelectedOption.extraData[key].defaultValue
								}
							}

							onChange(newValue)
						},
						options,
						isDisabled,
					}}
				/>
				{selectedOption?.extraData &&
					Object.keys(selectedOption.extraData).map((key) => {
						const { component: Component } = selectedOption.extraData[key]
						const nestedId = id + '-' + key
						return (
							<div key={key} css="margin-left: var(--spacing2x);">
								<label htmlFor={nestedId}>{startCase(key)}</label>
								<Component
									id={nestedId}
									value={value[key]}
									onChange={(newValueForKey) => onChange({ ...value, [key]: newValueForKey })}
								/>
							</div>
						)
					})}
			</>
		)
	}
}

type TargetsEditorProps<TargetType> = {
	targets: Array<TargetType>
	onChange: (targets: Array<TargetType>) => unknown
}

/**
 * Gets a `TargetsEditor` which can be used to edit the `targets` field on a station that supports multiple targets.
 * The resulting component accepts a `targets` prop with the current targets, and will call the `onChange` prop with
 * the new array of targets whenever the targets change.
 *
 * @param componentMap A mapping of valid target properties to components used to edit those properties
 * @param getDefaultTarget A function that will get a default target for the station
 */
function getTargetsEditor<TargetType extends StationTarget>(
	componentMap: ComponentsForTarget<TargetType>,
	getDefaultTarget: () => TargetType,
	stationConstants: StationConstants
): ComponentType<TargetsEditorProps<TargetType>> {
	const TargetEditor = getTargetEditor(componentMap)
	return function TargetsEditor({ targets, onChange }: TargetsEditorProps<TargetType>) {
		return (
			<div>
				{targets.map((target, i) => (
					<TargetEditor
						key={target._id}
						target={target}
						onChange={(updatedTarget) => {
							const updatedTargets = [...targets]
							updatedTargets[i] = updatedTarget
							onChange(updatedTargets)
						}}
						onDelete={() => {
							const newTargets = [...targets]
							newTargets.splice(i, 1)
							onChange(newTargets)
						}}
						header={`Target ${i + 1}`}
						stationConstants={stationConstants}
					/>
				))}
				<Button
					onClick={() => {
						onChange([...targets, getDefaultTarget()])
					}}>
					Add Target
				</Button>
			</div>
		)
	}
}

type TargetEditorProps<TargetType> = {
	target: TargetType
	onChange: (target: TargetType) => unknown
	header: string
	onDelete: () => unknown
	stationConstants: StationConstants
}

/**
 * Gets a `TargetEditor` capable of editing a single target. The resulting component accepts a `target` prop with the current target,
 * and will call the `onChange` prop with the new target whenever it changes.
 *
 * @param componentMap A mapping of valid target properties to components used to edit those properties
 */
function getTargetEditor<TargetType extends StationTarget>(
	componentMap: ComponentsForTarget<TargetType>
): ComponentType<TargetEditorProps<TargetType>> {
	return function TargetEditor({
		target,
		onChange,
		header,
		onDelete,
		stationConstants,
	}: TargetEditorProps<TargetType>) {
		const [isExpanded, setIsExpanded] = useState(true)
		return (
			<div css="border-radius: 4px; border: 1px solid black; margin-bottom: var(--spacing); padding: var(--spacing);">
				<h5
					css={`
						display: flex;
						justify-content: space-between;
						align-items: center;
						${!isExpanded ? 'margin-bottom: 0;' : ''}
					`}>
					<span css="display: flex; align-items: center; gap: var(--spacing);">
						{header}
						{!isExpanded && <TargetImage target={target} />}
					</span>
					<span css="display: flex; gap: var(--spacing);">
						<Button title={'Delete ' + header} onClick={() => onDelete()} color="danger">
							<FaTrash />
						</Button>
						<Button
							onClick={() => setIsExpanded((expanded) => !expanded)}
							outline
							title={isExpanded ? 'Collapse' : 'Expand'}>
							{isExpanded ? <FaAngleUp /> : <FaAngleDown />}
						</Button>
					</span>
				</h5>
				{isExpanded &&
					Object.keys(componentMap).map((key, i) => {
						const Component = componentMap[key as keyof typeof componentMap]

						if (!Component) {
							throw new Error(`No component defined for ${key}. This should be impossible.`)
						}

						if (
							key === 'actionObjectInfo' &&
							target.mapObject?.mapObjectId &&
							target.mapObject.mapId
						) {
							return null
						}

						if (key === '_id') {
							return null
						}

						const id = `Target-Editor-${target._id}-input-${key}`
						return (
							<FormComponentWrapper
								key={key}
								id={id}
								label={startCase(key)}
								component={
									// @ts-expect-error We expect this to be the right type
									<Component
										// @ts-expect-error `key` is a key of `target`
										value={target[key]}
										onChange={(newValue) => {
											const newTarget: TargetType = { ...target, [key]: newValue }

											if (key === 'mapObject') {
												if (newValue) {
													delete newTarget.actionObjectInfo
												} else {
													delete newTarget.mapObject
													newTarget.actionObjectInfo = {
														objectName: '',
														objectDescription: '',
													}
												}
											}

											onChange(newTarget)
										}}
										id={id}
										target={target}
										stationConstants={stationConstants}
									/>
								}
							/>
						)
					})}
			</div>
		)
	}
}

/**
 * Gets the url for the image of a target.
 * @param target The target to get the image for
 * @returns The url of the image, or null
 */
function useTargetImageUrl(target: StationTarget): string | null | undefined {
	const mapObject = useSelector((state: ReduxStore) =>
		target.mapObject ? getMapObject(target.mapObject.mapObjectId, state) : null
	)
	return target.media?.url || mapObject?.icon.url
}

/**
 * Displays the image for the given target
 */
function TargetImage<TargetType extends StationTarget>({ target }: { target: TargetType }) {
	const imageUrl = useTargetImageUrl(target)

	if (!imageUrl) {
		return null
	}

	return <img src={imageUrl} alt="" css="max-height: 50px;" />
}

// All components for editing a single Defense Target
const DefenseTargetComponents: ComponentsForTarget<DefensePlusTarget<string>> = {
	_id: ({ value }) => null,
	media: ImageMediaInput,
	countPerStudent: stripNonGenericProps(SmallNumberInput),
	initialHp: stripNonGenericProps((props) => {
		return (
			<>
				<SmallNumberInput {...props} />
				<FormText>
					Weapon damage:{' '}
					{DEFENSE_STATION.UPGRADES.map(
						({ damage, index }) =>
							damage + (index === DEFENSE_STATION.DEFAULT_UPGRADE ? ' (default)' : '')
					).join(', ')}
				</FormText>
			</>
		)
	}),
	pointReward: stripNonGenericProps(SmallNumberInput),
	requiredDestroyCountPerStudent: stripNonGenericProps(SmallNumberInput),
	actionObjectInfo: ActionObjectInfoInput,
	mapObject: MapObjectInput,
	// @ts-expect-error TS is very hard to get right here, so suppress the errors
	movement: getTypeObjectEditor(
		keys(DEFENSE_STATION.MOVEMENT).map((key) =>
			key === DEFENSE_STATION.MOVEMENT.STATIC
				? {
						type: key,
						extraData: {
							location: {
								defaultValue: {
									type: DEFENSE_STATION.LOCATION.RANDOM,
								},
								component: getTypeObjectEditor(
									Object.keys(DEFENSE_STATION.LOCATION).map((locationKey) =>
										locationKey === DEFENSE_STATION.LOCATION.DEFINED
											? {
													type: locationKey,
													extraData: {
														x: {
															defaultValue: DEFENSE_CANVAS.width / 2,
															component: SmallNumberInput,
														},
														y: {
															defaultValue: DEFENSE_CANVAS.height / 2,
															component: SmallNumberInput,
														},
													},
											  }
											: locationKey
									)
								),
							},
						},
				  }
				: key
		)
	) as ComponentType<
		GenericInputPropsWithTargetAndStationConstants<DefensePlusTarget<string>['movement']>
	>,
	size: SizeInput,
	objectSharing: stripNonGenericProps(
		getTypeObjectEditor(keys(DEFENSE_STATION.OBJECT_SHARING), true)
	),
	exitAnimation: stripNonGenericProps(
		getTypeObjectEditor(keys(DEFENSE_STATION.EXIT_ANIMATION), true)
	),
	respawn: stripNonGenericProps(getTypeObjectEditor(keys(DEFENSE_STATION.RESPAWN))),
}
// All components for editing a single Tractor Beam Target
const TractorBeamTargetComponents: ComponentsForTarget<TractorBeamPlusTarget<string>> = {
	_id: () => null,
	media: ImageMediaInput,
	countPerStudent: stripNonGenericProps(SmallNumberInput),
	pointReward: stripNonGenericProps(SmallNumberInput),
	requiredCollectionCountPerStudent: stripNonGenericProps(SmallNumberInput),
	actionObjectInfo: ActionObjectInfoInput,
	mapObject: MapObjectInput,
	// @ts-expect-error TS is very hard to get right here, so suppress the errors
	movement: getTypeObjectEditor(
		keys(TRACTOR_BEAM_STATION.MOVEMENT).map((key) =>
			key === TRACTOR_BEAM_STATION.MOVEMENT.STATIC
				? {
						type: key,
						extraData: {
							location: {
								defaultValue: {
									type: TRACTOR_BEAM_STATION.LOCATION.RANDOM,
								},
								component: getTypeObjectEditor(
									Object.keys(TRACTOR_BEAM_STATION.LOCATION).map((locationKey) =>
										locationKey === TRACTOR_BEAM_STATION.LOCATION.DEFINED
											? {
													type: locationKey,
													extraData: {
														x: {
															defaultValue: TRACTOR_BEAM_CANVAS.width / 2,
															component: SmallNumberInput,
														},
														y: {
															defaultValue: TRACTOR_BEAM_CANVAS.height / 2,
															component: SmallNumberInput,
														},
													},
											  }
											: locationKey
									)
								),
							},
						},
				  }
				: key
		)
	),
	size: SizeInput,
	respawn: stripNonGenericProps(getTypeObjectEditor(keys(TRACTOR_BEAM_STATION.RESPAWN))),
}
export const TractorBeamTargetsEditor: ComponentType<
	TargetsEditorProps<TractorBeamPlusTarget<string>>
> = getTargetsEditor(TractorBeamTargetComponents, getDefaultTractorBeamTarget, {
	constants: TRACTOR_BEAM_STATION,
	canvas: TRACTOR_BEAM_CANVAS,
})
export const DefenseTargetsEditor: ComponentType<TargetsEditorProps<DefensePlusTarget<string>>> =
	getTargetsEditor(DefenseTargetComponents, getDefaultDefenseTarget, {
		constants: DEFENSE_STATION,
		canvas: DEFENSE_CANVAS,
	})

/**
 * Uses the given `Component` to create a new component that accepts `GenericInputPropsWithTargetAndStationConstants` but strips out the
 * `target` and `stationConstants` props before rendering `Component`.
 */
function stripNonGenericProps<TargetType extends StationTarget, T>(
	Component: ComponentType<GenericInputProps<T>>
): ComponentType<GenericInputPropsWithTargetAndStationConstants<T, TargetType>> {
	return function Name({
		target,
		stationConstants,
		...props
	}: GenericInputPropsWithTargetAndStationConstants<T>) {
		return <Component {...props} />
	}
}

function keys<T extends Record<string, unknown>>(obj: T): Array<keyof T> {
	return Object.keys(obj)
}
