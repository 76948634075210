/**
 * Given an svg object, the x, y coordinates will be at its top left corner,
 * this changes the coordinates so that the center of the object is the coordinate base.
 * @param  {[number, number]} [x, y]   coordinates
 * @param  {[number, number]} sizes [width, height] of the object being placed
 * @return {[number, number]}      new centered coordinates
 */
export function centerCoordinates(
	[x, y]: [number, number],
	size: [number, number]
): [number, number] {
	return [x - size[0] / 2, y - size[1] / 2]
}

/**
 * Given an svg object, the x, y coordinates have been altered to be at its center,
 * this changes the coordinates so that the center of the object is the top left.
 * @param  {[number, number]} [x, y]   coordinates
 * @param  {[number, number]} sizes [width, height] of the object being placed
 * @return {[number, number]}      new un-centered coordinates
 */
export function unCenterCoordinates(
	[x, y]: [number, number],
	size: [number, number]
): [number, number] {
	return [x + size[0] / 2, y + size[1] / 2]
}
