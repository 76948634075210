import React, { useEffect } from 'react'
import { DragSourceMonitor, useDrag } from 'react-dnd'
import type { DraggableType } from './types'
type Props<
	T extends {
		type: DraggableType
	}
> = {
	children?: React.ReactNode
	item: T
	nocopy?: boolean
	style?: React.CSSProperties
	setParentDragStyle?: (style: React.CSSProperties) => void
}
export default function Draggable<
	T extends {
		type: DraggableType
	}
>({ children, item, nocopy, style, setParentDragStyle, ...otherProps }: Props<T>): JSX.Element {
	const onEnd = (item: unknown, monitor: DragSourceMonitor) => {
		if (!monitor.didDrop()) {
			// The item did not drop into a target: perhaps the user missed a target or perhaps the target was unable to accept the item
		}
	}

	const [{ isDragging }, drag] = useDrag(
		() => ({
			item: item,
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			type: item.type,
			end: onEnd,
		}),
		[item]
	)
	useEffect(() => {
		if (setParentDragStyle) {
			setParentDragStyle(getStyles(isDragging, nocopy))
		}
	}, [isDragging, setParentDragStyle, nocopy])
	return (
		<div ref={drag} style={getStyles(isDragging, nocopy, style)} css="z-index: 10" {...otherProps}>
			{children}
		</div>
	)
}

function getStyles(isDragging: boolean, nocopy?: boolean, style?: React.CSSProperties) {
	const styles: React.CSSProperties = {
		...(style || {}),
		opacity: isDragging ? (nocopy ? 0 : 0.5) : 1,
	}
	if (isDragging && nocopy) styles.height = 0
	return styles
}
