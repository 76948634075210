import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { toast } from '../../helpers/uiFunctions'
import { MISSION_TYPES } from '../../helpers/constants'
import { useCreateSimulation } from '../automatedSimulations/queries'
import { ControlSet } from '../../types/AutomatedSimulation'
/**
 * Component to allow creating new simulations. Receives two props:
 * `isOpen` - whether it should be open
 * `onClose` - a function to call when the modal is closed
 */

export default function NewSimulationModal({
	isOpen,
	onClose,
}: {
	isOpen: boolean
	onClose: () => unknown
}): JSX.Element {
	const [simulationName, setSimulationName] = useState('')
	const [controlSet, setControlSet] = useState<ControlSet>(MISSION_TYPES.JUNIOR_PLUS)
	const history = useHistory()
	const { mutateAsync: createAutomatedSimulation, isLoading: isCreating } = useCreateSimulation()
	return (
		<Modal isOpen={isOpen}>
			<ModalHeader toggle={onClose}>New Simulation</ModalHeader>
			<ModalBody>
				Name
				<Input value={simulationName} onChange={(e) => setSimulationName(e.currentTarget.value)} />
				Controls
				<Input
					type="select"
					value={controlSet}
					onChange={(e) => setControlSet(e.currentTarget.value as ControlSet)}>
					<option>{MISSION_TYPES.JUNIOR}</option>
					<option>{MISSION_TYPES.JUNIOR_PLUS}</option>
				</Input>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => {
						createAutomatedSimulation(
							{
								name: simulationName,
								controlSet,
							},
							{
								onError: (error) => {
									toast.error(
										'Failed to create simulation.',
										error instanceof Error ? error.message : undefined
									)
								},
							}
						).then((simulationId) => {
							onClose()
							history.push(`/${simulationId}`)
						})
					}}
					color="primary"
					disabled={isCreating}>
					Create
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</ModalFooter>
		</Modal>
	)
}
