import React, { ReactNode, useState } from 'react'
import {
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
	Button,
	Label,
	FormText,
	Input,
	FormGroup,
} from 'reactstrap'
import styled from 'styled-components'
import type { DataType, ObjectContentType, MapDataContentType } from '../../../../../types/MapTypes'
import { FaTrash } from 'react-icons/fa'
import type { CreativeCanvasDataWithoutMap } from '@mission.io/mission-toolkit/actions'

import { Modal as AssetModal } from '../../../../../common/AssetManager'
import RelevanceSelector from './RelevanceSelector'
import MarkdownInput from '../../../../../common/MarkdownInput'

import type { Collection, File } from '../../../../../common/AssetManager'
import { prettifyTypeEnum } from '../../../../../helpers/functions'
import { fileRestrictionsByType } from '../../../../automatedSimulations/helpers/algorithms'

type ContentEditorProps<T> =
	| {
			type?: never
			data: T
			onChange: (cb: (oldState: T) => T) => unknown
			includePrescannedField: boolean
	  }
	| {
			type: 'CREATIVE_CANVAS_DATA'
			onChange: (newData: CreativeCanvasDataWithoutMap<string>) => unknown
			data: CreativeCanvasDataWithoutMap<string>
			includePrescannedField: boolean
			CustomFormGroup?: (props: {
				data: CreativeCanvasDataWithoutMap<string>
				onChange: (newData: CreativeCanvasDataWithoutMap<string>) => unknown
			}) => ReactNode
	  }

type DataEditorProps<T> = ContentEditorProps<T> & {
	className?: string | undefined
	onDelete: () => void
}

/**
 * Provides an editor for a single data object.
 */
export function DataEditor<
	T extends CreativeCanvasDataWithoutMap | MapDataContentType | ObjectContentType
>({ onDelete, className, ...passAlongProps }: DataEditorProps<T>): JSX.Element {
	return (
		<div css="display: flex; gap: 16px;" className={className}>
			<div css="display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;">
				<MediaTypePicker
					mediaType={passAlongProps.data.type}
					onSelect={(option) => {
						if (passAlongProps.type === 'CREATIVE_CANVAS_DATA') {
							if (option === 'TEXT') {
								passAlongProps.onChange({
									_id: passAlongProps.data._id,
									type: 'TEXT',
									text: passAlongProps.data.text,
									relevance: passAlongProps.data.relevance,
								})
							} else {
								passAlongProps.onChange({
									_id: passAlongProps.data._id,
									type: option,
									text: passAlongProps.data.text,
									url: '',
									relevance: passAlongProps.data.relevance,
								})
							}
						} else {
							passAlongProps.onChange(
								(oldState: T): T => ({
									...oldState,
									type: option,
									url: null,
								})
							)
						}
					}}
				/>
				<Button onClick={onDelete} color="danger">
					<FaTrash size={20} />
				</Button>
			</div>

			<ContentEditor {...passAlongProps} />
		</div>
	)
}

/**
 * A dropdown to select the media type
 */
export function MediaTypePicker({
	mediaType,
	onSelect,
}: {
	mediaType: DataType | null | undefined
	onSelect: (arg0: DataType) => void
}): JSX.Element {
	const [open, setOpen] = useState(false)

	const toggle = () => {
		setOpen((state) => !state)
	}

	return (
		<Dropdown isOpen={open} toggle={toggle} sm={3}>
			<DropdownToggle caret>{mediaType || 'Select...'}</DropdownToggle>
			<DropdownMenu>
				{(['VIDEO', 'AUDIO', 'IMAGE', 'TEXT'] as DataType[]).map((option) => {
					return (
						<DropdownItem onClick={() => onSelect(option)} key={String(option)}>
							{option}
						</DropdownItem>
					)
				})}
			</DropdownMenu>
		</Dropdown>
	)
}

// The S3 collection to use for different media types
const mediaTypeToCollection: { [type: string]: Collection } = {
	VIDEO: 'mapVideos',
	AUDIO: 'mapAudio',
	IMAGE: 'mapImages',
}

/**
 * Allows editing the content for a single data object.
 */
function ContentEditor<
	T extends MapDataContentType | ObjectContentType | CreativeCanvasDataWithoutMap
>(props: ContentEditorProps<T>): JSX.Element {
	const [isFileChooserOpen, setIsFileChooserOpen] = useState(false)

	const mediaType = props.data.type

	return (
		<StyledContainer
			{...{
				$includeAsset: mediaType !== 'TEXT',
				$includeRequireScan: props.includePrescannedField,
				$hasCustomFormGroup: 'CustomFormGroup' in props,
			}}>
			{props.type === 'CREATIVE_CANVAS_DATA' && props.CustomFormGroup ? (
				<div className="custom-fields">
					<props.CustomFormGroup onChange={props.onChange} data={props.data} />
				</div>
			) : null}
			{mediaType !== 'TEXT' && (
				<div className="asset">
					<FormText>
						{!props.data.url ? (
							'Add ' + mediaType.toLocaleLowerCase() + ' to the object.'
						) : (
							<MediaDisplay type={mediaType} url={props.data.url} />
						)}
					</FormText>
					<div className="mt-2">
						<Button color="primary" size="sm" onClick={() => setIsFileChooserOpen(true)}>
							Select {prettifyTypeEnum(mediaType)}
						</Button>
					</div>

					<AssetModal
						collection={
							props.type === 'CREATIVE_CANVAS_DATA'
								? 'creativeCanvas'
								: mediaTypeToCollection[mediaType]
						}
						isOpen={isFileChooserOpen}
						onClose={() => setIsFileChooserOpen(false)}
						onFileClick={(file: File) => {
							if (props.type === 'CREATIVE_CANVAS_DATA') {
								if (props.data.type === 'TEXT') {
									return
								}

								props.onChange({
									...props.data,
									url: file.url,
								})
							} else {
								props.onChange(
									(oldState: T): T => ({
										...oldState,
										url: file.url,
									})
								)
							}

							setIsFileChooserOpen(false)
						}}
						restrictions={fileRestrictionsByType[mediaType]}
					/>
				</div>
			)}

			<FormGroup className="relevance">
				<Label>Relevance</Label>
				<LocalFormText>The relevance of the data</LocalFormText>
				<RelevanceSelector
					value={props.data.relevance}
					onChange={(value) => {
						if (props.type === 'CREATIVE_CANVAS_DATA') {
							props.onChange({
								...props.data,
								relevance: value,
							})
						} else {
							props.onChange(
								(oldState: T): T => ({
									...oldState,
									relevance: value,
								})
							)
						}
					}}
					name="relevance"
				/>
			</FormGroup>

			<FormGroup className="text">
				<Label>Text</Label>
				<LocalFormText>Add a description</LocalFormText>

				<MarkdownInput
					value={props.data.text || ''}
					onChange={(value) => {
						if (props.type === 'CREATIVE_CANVAS_DATA') {
							props.onChange({
								...props.data,
								text: value,
							})
						} else {
							props.onChange(
								(oldState: T): T => ({
									...oldState,
									text: value,
								})
							)
						}
					}}
					disabledComponents={['a', 'img']}
				/>
			</FormGroup>
			{props.type !== 'CREATIVE_CANVAS_DATA' && props.includePrescannedField && (
				<div className="require-scan">
					<Label css="margin-bottom: 0;">
						<Input
							type="checkbox"
							checked={'prescanned' in props.data ? !props.data.prescanned : true}
							onChange={(e) => {
								props.onChange((oldState) => ({
									...oldState,
									prescanned: !e.target.checked,
								}))
							}}
						/>{' '}
						Require Scan
					</Label>
				</div>
			)}
		</StyledContainer>
	)
}

/**
 * Displays the given media `url` for the given `type`
 */
function MediaDisplay({ type, url }: { type: DataType; url: string }) {
	if (type === 'AUDIO') {
		return (
			<audio css="width: 185px;" controls>
				<source src={url} />
				Your browser does not support the audio element.
			</audio>
		)
	} else if (type === 'VIDEO') {
		return (
			<video width="120" height="90" controls>
				<source src={url} />
				Your browser does not support the video tag.
			</video>
		)
	} else if (type === 'IMAGE') {
		return <img alt="" src={url} height="70" width="90" css="object-fit: contain" />
	} else {
		return null
	}
}

const StyledContainer = styled.div<{
	$includeAsset: boolean
	$includeRequireScan: boolean
	$hasCustomFormGroup: boolean
}>`
	flex: 1;
	display: grid;
	grid-template: ${({ $includeAsset, $includeRequireScan, $hasCustomFormGroup }) => {
		return `
		     ${$hasCustomFormGroup ? "'custom-form-group custom-form-group'" : ''}
			'text ${$includeAsset ? 'asset' : 'text'}' auto
			'relevance ${$includeRequireScan ? 'require-scan' : '.'}' auto
			/ 1fr auto;
		`
	}}}
	grid-gap: 16px;

	.asset {
		grid-area: asset;
	}

	.relevance {
		grid-area: relevance;
	}

	.require-scan {
		grid-area: require-scan;
	}

	.text {
		grid-area: text;
	}

	.custom-fields {
		grid-area: custom-form-group;
	}
`

const LocalFormText = styled(FormText)`
	display: block;
	@media (min-width: 768px) {
		display: inline;
		margin-left: 16px;
	}
	margin-top: 0;
	margin-bottom: 4px;
`
