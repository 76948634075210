import React, { Component } from 'react'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import type { ClassQuestion } from '../../types'
type QuestProps = {
	index: number
	deleteQuest: (
		e: unknown,
		arg1: {
			index: number
		}
	) => unknown
}

export class QuestThumbnail extends Component<QuestProps> {
	render(): JSX.Element {
		const { index, deleteQuest } = this.props
		const questId = `quest_${index}`
		return (
			<React.Fragment>
				{/* @ts-expect-error TS does not think `ContextMenuTrigger` should have children */}
				<ContextMenuTrigger
					id={questId}
					attributes={{
						className: 'context-menu-wrapper',
					}}>
					<li className="special-thumbnail quest">Quest</li>
				</ContextMenuTrigger>
				{/* @ts-expect-error TS does not think `ContextMenu` should have children */}
				<ContextMenu id={questId} className="context-menu">
					{/* @ts-expect-error TS does not think `MenuItem` should have children */}
					<MenuItem
						data={{
							index,
						}}
						onClick={deleteQuest}
						attributes={{
							className: 'context-menu-item',
						}}>
						Delete Quest
					</MenuItem>
				</ContextMenu>
			</React.Fragment>
		)
	}
}
type ClassQuestionProps = {
	classQuestion: ClassQuestion
	onDelete: () => unknown
	onEdit: () => unknown
}
export function ClassQuestionThumbnail({
	classQuestion,
	onDelete,
	onEdit,
}: ClassQuestionProps): JSX.Element {
	return (
		<>
			{/* @ts-expect-error TS does not think `ContextMenuTrigger` should have children */}
			<ContextMenuTrigger
				id={classQuestion._id}
				attributes={{
					className: 'context-menu-wrapper',
				}}>
				<li className="special-thumbnail class-question">{classQuestion.phrase}</li>
			</ContextMenuTrigger>
			{/* @ts-expect-error TS does not think `ContextMenu` should have children */}
			<ContextMenu id={classQuestion._id} className="context-menu">
				{/* @ts-expect-error TS does not think `MenuItem` should have children */}
				<MenuItem
					attributes={{
						className: 'context-menu-item',
					}}
					onClick={onEdit}>
					Edit Class Question
				</MenuItem>
				{/* @ts-expect-error TS does not think `MenuItem` should have children */}
				<MenuItem
					attributes={{
						className: 'context-menu-item',
					}}
					onClick={onDelete}>
					Delete Class Question
				</MenuItem>
			</ContextMenu>
		</>
	)
}
