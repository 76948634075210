import { ReduxStore } from '../types/ReduxStore'

export type CharacterAttribute = {
	_id: string
	name: string
	value: string
}
export type Character = {
	_id: string
	name: string
	description: string
	iconUrl: string
	url: string
	attributes: CharacterAttribute[]
}
export type CharacterStore = {
	characters: Character[]
}
const INITIAL_STATE = {
	characters: [],
}
const UPDATE_CHARACTERS = 'UPDATE_CHARACTERS'
const ADD_CHARACTER = 'ADD_CHARACTER'
const DELETE_CHARACTER = 'DELETE_CHARACTER'
const UPDATE_CHARACTER = 'UPDATE_CHARACTER'
type SetCharactersAction = {
	type: 'UPDATE_CHARACTERS'
	payload: Character[]
}
type SetCharacterAction = {
	type: 'ADD_CHARACTER'
	payload: Character
}
type DeleteCharactersAction = {
	type: 'DELETE_CHARACTER'
	payload: string
}
type UpdateCharactersAction = {
	type: 'UPDATE_CHARACTER'
	payload: Character
}
type Action =
	| SetCharactersAction
	| SetCharacterAction
	| DeleteCharactersAction
	| UpdateCharactersAction
export default function charactersReducer(
	state: CharacterStore = INITIAL_STATE,
	action: Action
): CharacterStore {
	switch (action.type) {
		case UPDATE_CHARACTERS: {
			return {
				characters: action.payload,
			}
		}

		case ADD_CHARACTER: {
			return {
				characters: [...state.characters, action.payload],
			}
		}

		case DELETE_CHARACTER: {
			const deleteId = action.payload
			return {
				characters: state.characters.filter((character) => character._id !== deleteId),
			}
		}

		case UPDATE_CHARACTER: {
			const { _id } = action.payload
			const updateIndex = state.characters.findIndex((character) => character._id === _id)

			if (updateIndex === -1) {
				return state
			} else {
				const newArray = [...state.characters]
				newArray[updateIndex] = action.payload
				return {
					characters: newArray,
				}
			}
		}

		default: {
			return state
		}
	}
}
export function setCharacters(characters: Character[]): SetCharactersAction {
	return {
		type: UPDATE_CHARACTERS,
		payload: characters,
	}
}
export function setCharacter(character: Character): SetCharacterAction {
	return {
		type: ADD_CHARACTER,
		payload: character,
	}
}
export function deleteCharacter(id: string): DeleteCharactersAction {
	return {
		type: DELETE_CHARACTER,
		payload: id,
	}
}
export function updateCharacter(character: Character): UpdateCharactersAction {
	return {
		type: UPDATE_CHARACTER,
		payload: character,
	}
}
export function getCharacters(state: { characters: CharacterStore }): Array<Character> {
	return state.characters.characters || []
}
export function getCharacterById(
	state: ReduxStore,
	characterId: string | null | undefined
): Character | null | undefined {
	if (!characterId) {
		return null
	}

	return state.characters.characters.find((character) => character._id === characterId)
}
