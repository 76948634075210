import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { OverlayStyled } from '../Screen'
import styled from 'styled-components'
import type { ActionPlayerAction } from '../sharedTypes'
import type { MissionPause, ReachedPhasesAndCheckpoints } from '../ActionPlayer'
import type { EventResult } from '@mission.io/mission-toolkit/actions'

/**
 * The dialog that displays options for a teacher when mission pause is active
 * @returns { React$Node} a react component
 */

export default function MissionPauseDialog({
	playActions,
	missionPause,
	dispatch,
	reachedPhasesAndCheckpoints,
}: {
	playActions: (arg0: EventResult<string>) => void
	missionPause: MissionPause
	dispatch: (arg0: ActionPlayerAction) => unknown
	reachedPhasesAndCheckpoints: Array<ReachedPhasesAndCheckpoints>
}): JSX.Element {
	const [msgError, setMsgError] = useState<string | null>(null)

	const handleRestore = () => {
		const lastCheckpoint = reachedPhasesAndCheckpoints[reachedPhasesAndCheckpoints.length - 1]

		if (lastCheckpoint) {
			dispatch({
				type: 'REVIVE',
				payload: {
					previousState: lastCheckpoint.previousState,
				},
			})
			playActions([lastCheckpoint.id])
		} else setMsgError('There is no previous Phase or Checkpoint selected')
	}

	const handleClickContinue = () => {
		dispatch({
			type: 'FINISH_MISSION_PAUSE',
		})
		if (missionPause?.onContinue) playActions(missionPause.onContinue)
	}

	return (
		<OverlayStyled>
			<div
				css={`
					flex-direction: ${missionPause.allowGoBack ? 'row' : 'column'};
				`}>
				{missionPause.allowGoBack && (
					<StyledButton size="lg" onClick={handleRestore} disabled={!missionPause.allowGoBack}>
						{missionPause.goBackButtonText.trim() || 'Go Back'}
					</StyledButton>
				)}
				<div css="display: block !important; height: fit-content !important;">
					<h1>Mission Paused</h1>
					<span>{missionPause?.prompt.trim() || 'Time is up - Press CONTINUE when ready'}</span>
					<h5>{msgError}</h5>
				</div>
				<StyledButton size="lg" onClick={handleClickContinue}>
					{missionPause?.continueButtonText.trim() || 'Continue'}
				</StyledButton>
			</div>
		</OverlayStyled>
	)
}
const StyledButton = styled(Button)`
	margin: 36px;
	min-width: 130px;
`
