import React, { useState } from 'react'

import './StandardList.css'
import { Button } from 'reactstrap'
import Select from 'react-select'

import type { Standard as StandardType } from '../types/AutomatedSimulation'
import { StandardDisplay } from '../features/standards/StandardDisplay'
import { useStandardSets, useStandards } from '../features/standards/hooks'
import { groupBy, keyBy } from 'lodash'
import { toast } from 'react-toastify'
import { FieldArrayFieldsProps } from 'redux-form'

/**
 * A component to be used as the `component` for a redux-form `FieldArray`
 */
export function FieldsStandardList({
	fields: { length, push, map },
	meta: { form },
}: {
	fields: FieldArrayFieldsProps<StandardType>
	meta: { form: string }
}): JSX.Element {
	const [standardsToAdd, setStandardsToAdd] = useState<string[]>([])
	const { data: standards_ } = useStandards()
	const { data: standardSets } = useStandardSets()

	const existingStandardIds = new Set(map((_, i, { get }) => get(i)._id))

	const standards = standards_?.filter((standard) => !existingStandardIds.has(standard._id))

	return (
		<div className="StandardList">
			{length === 0 ? (
				<p className="my-1">
					<i>No standards found</i>
				</p>
			) : (
				map((fieldName, index, { remove, name, get }) => ({
					standard: get(index),
					remove: () => {
						remove(index)
					},
				}))
					.sort(({ standard: standard1 }, { standard: standard2 }) =>
						standard1.standardSet.abbreviation.localeCompare(standard2.standardSet.abbreviation)
					)
					.map(({ standard, remove }) => (
						<StandardDisplay key={standard._id} onDelete={() => remove()} standard={standard} />
					))
			)}
			<div className="d-flex gap-2 align-items-start">
				<StandardSelector
					value={standardsToAdd}
					onChange={setStandardsToAdd}
					standardsToExclude={existingStandardIds}
				/>
				<Button
					onClick={() => {
						const standardsMap = keyBy(standards, '_id')
						let standardsAdded = 0
						standardsToAdd.forEach((standardToAdd) => {
							const standard = standardsMap[standardToAdd]
							const standardSet = standardSets?.[standard?.standardSet || '']
							if (!standard || !standardToAdd || !standardSet) {
								return
							}
							push({ ...standard, standardSet })
							standardsAdded++
						})

						if (standardsAdded === 0) {
							toast.warn('Please select a valid standard')
						} else if (standardsAdded !== standardsToAdd.length) {
							toast.warn('Some standards were not added because they were invalid')
						}

						setStandardsToAdd([])
					}}>
					Add Standards
				</Button>
			</div>
		</div>
	)
}

/**
 * Controlled component to select one or many standards.
 * @param value The id of the currently selected standard
 * @param onChange A callback to be called when the selected standard changes
 * @param standardsToExclude A set of standard ids to exclude from the list. Any standard with an id in this set will not be shown.
 */
function StandardSelector({
	value,
	onChange,
	standardsToExclude,
	className,
}: {
	value: string[]
	onChange: (newStandards: string[]) => unknown
	standardsToExclude?: Set<string>
	className?: string
}) {
	const { data: standards_ } = useStandards()
	const { data: standardSets } = useStandardSets()

	const standards = standards_?.filter((standard) => !standardsToExclude?.has(standard._id))
	const standardsObject = keyBy(standards, '_id')
	const values = value
		.map((id) => {
			const standard = standardsObject[id]
			if (!standard) {
				return null
			}
			const standardSet = standardSets?.[standard.standardSet]?.abbreviation

			return {
				value: id,
				label: `${standardSet || 'Unknown'} ${standard.name}`,
			}
		})
		.filter((option): option is NonNullable<{ value: string; label: string }> => Boolean(option))

	const groupedStandards = standards
		? groupBy(
				standards,
				(standard) => standardSets?.[standard.standardSet]?.abbreviation || 'Unknown'
		  )
		: {}

	const groups = Object.keys(groupedStandards)
		.sort()
		.map((standardSetAbbreviation) => {
			return {
				label: standardSetAbbreviation,
				options: [...groupedStandards[standardSetAbbreviation]]
					.sort((s1, s2) => s1.name.localeCompare(s2.name))
					.map((standard) => ({
						value: standard._id,
						label: standardSetAbbreviation + ' ' + standard.name,
					})),
			}
		})

	return (
		<Select
			value={values}
			onChange={(options) => onChange(options?.map((option) => option.value) || [])}
			options={groups}
			className={className}
			closeMenuOnSelect={false}
			isMulti
		/>
	)
}
