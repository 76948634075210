import React, { useCallback, useContext } from 'react'
import { useDragLayer } from 'react-dnd'
import { findEntry, getTimeFormat, pointInsideRect } from '../../../helpers/functions'
import styled, { ThemeContext } from 'styled-components'
import { getDropFeature } from '../PhaseCreator/helpers/canvasHelpers'
export default function DragLayer({
	editorRefs,
}: {
	editorRefs?: {
		current: Record<string, HTMLDivElement | null | undefined>
	}
}): JSX.Element | null {
	const { isDragging, currentOffset } = useDragLayer((monitor) => ({
		item: monitor.getItem(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}))
	const currentEditorRefOfItem = useCallback(() => {
		if (editorRefs && editorRefs.current) {
			return findEntry(editorRefs.current, (editorRef) => {
				if (currentOffset && editorRef) {
					if (pointInsideRect(currentOffset, editorRef.getBoundingClientRect())) {
						return true
					}
				}

				return false
			})
		}
	}, [currentOffset, editorRefs])

	if (!isDragging) {
		return null
	}

	const editorRef = currentEditorRefOfItem()
	return <>{editorRef && <Line currentOffset={currentOffset} boundaryRef={editorRef} />}</>
}
type Rect = {
	x: number
	y: number
	height: number
	width: number
	top: number
}

const Line = ({
	currentOffset,
	boundaryRef,
}: {
	currentOffset:
		| {
				x: number
				y: number
		  }
		| null
		| undefined
	boundaryRef: HTMLDivElement
}) => {
	const DROP_FEATURE_HEIGHT = 24
	const theme = useContext(ThemeContext)
	if (!theme) {
		throw new Error('ThemeContext should only be used inside of a ThemeProvider with a value')
	}
	const drawLineBounds: Rect | null | undefined = boundaryRef.getBoundingClientRect()

	if (!drawLineBounds || !currentOffset) return null
	const { x: currentXOffset, y: currentYOffset } = currentOffset
	const x = currentXOffset - drawLineBounds.x
	const y = currentYOffset - drawLineBounds.y
	const timeMs = Math.round(x / theme.pixelsPerMs)
	let dropFeature = getDropFeature(x, boundaryRef)
	if (y + DROP_FEATURE_HEIGHT < parseInt(theme.screenContainerHeight, 10)) dropFeature = null // Do not display drop feature if current offset is in the screen container

	return (
		<Layer
			as="svg"
			style={{
				left: currentXOffset,
				top: drawLineBounds.y + window.pageYOffset,
			}}
			height={drawLineBounds.height}
			viewBox="0 0 100 100">
			<text
				x="-1"
				y="-1"
				style={{
					font: '8px sans-serif',
					color: '#707070',
				}}>
				{getTimeFormat(timeMs, theme.pixelsPerMs >= 0.01)}
			</text>
			{dropFeature && (
				<foreignObject
					x="-30"
					y={(y * 100) / drawLineBounds.height}
					width="30"
					height={DROP_FEATURE_HEIGHT}>
					<DropMessage $dropFeature={dropFeature}>{DROP_MESSAGES[dropFeature]}</DropMessage>
				</foreignObject>
			)}
			<line x1="0" y1="0" x2="0" y2="100" stroke="#707070" strokeWidth="1" />
		</Layer>
	)
}

const DROP_MESSAGES = {
	onStart: 'On Start',
	onEnd: 'On End',
}
const DropMessage = styled.div<{
	$dropFeature: ('onEnd' | 'onStart') | null | undefined
}>`
	position: absolute;
	right: 0;
	background-color: ${({ $dropFeature }) => ($dropFeature === 'onEnd' ? 'red' : '#59D442')};
	padding: 2px;
	color: white;
	font-size: 6px;
	width: fit-content;
	border-radius: 2px;
	z-index: 100;
`
const Layer = styled.div`
	position: absolute;
	pointer-events: none;
	z-index: 100;
	left: 0;
	top: 0;
	overflow: visible;
`
