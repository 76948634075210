import React from 'react'
import {
	CommunicationsIcon,
	TractorBeamIcon,
	ThrustersIcon,
	DefenseIcon,
	SensorsIcon,
	RepairsIcon,
	PowerIcon,
	TransporterIcon,
} from '../../../images/launchpadGuides/jrStations'
import {
	DefensePlusIcon,
	TransporterPlusIcon,
	TractorBeamPlusIcon,
	DecksIcon,
	ScanningIcon,
	PowerPlusIcon,
	ThrustersPlusIcon,
	CommunicationsPlusIcon,
} from '../../../images/launchpadGuides/jrPlusStations'
import {
	MicrophoneIcon,
	QuestionIcon,
	EffectIcon,
	SummaryIcon,
	ProceedIcon,
	TriggerIcon,
} from '../../../images/launchpadGuides'
export function getJrIcon(type?: string): JSX.Element {
	switch (type) {
		case 'COMMUNICATION':
			return <CommunicationsIcon />

		case 'TRACTOR_BEAM':
			return <TractorBeamIcon />

		case 'DEFENSE':
			return <DefenseIcon />

		case 'THRUSTERS':
			return <ThrustersIcon />

		case 'SCANNER':
			return <SensorsIcon />

		case 'REPAIRS':
			return <RepairsIcon />

		case 'POWER':
			return <PowerIcon />

		case 'TRANSPORTER':
			return <TransporterIcon />

		default:
			return <></>
	}
}
export function getJrPlusIcon(type?: string): JSX.Element {
	switch (type) {
		case 'COMMUNICATION':
			return <CommunicationsPlusIcon />

		case 'TRANSPORTER':
			return <TransporterPlusIcon />

		case 'DECKS':
			return <DecksIcon />

		case 'TRACTOR_BEAM':
			return <TractorBeamPlusIcon />

		case 'SCANNER':
			return <ScanningIcon />

		case 'POWER':
			return <PowerPlusIcon />

		case 'THRUSTERS':
			return <ThrustersPlusIcon />

		case 'DEFENSE':
			return <DefensePlusIcon />

		case 'SENSOR':
			return <ScanningIcon />

		default:
			return <></>
	}
}
export function getIcon(type: string): JSX.Element {
	switch (type) {
		case 'CHARACTER':
			return <MicrophoneIcon />

		case 'QUESTION':
			return <QuestionIcon />

		case 'EFFECT':
			return <EffectIcon />

		case 'AUTO_EFFECT':
			return <EffectIcon />

		case 'SUMMARY':
			return <SummaryIcon />

		case 'TRIGGER':
			return <TriggerIcon />

		case 'PROCEED':
			return <ProceedIcon />

		default:
			return <></>
	}
}
