import React from 'react'
import styled, { keyframes } from 'styled-components'
type Props = {
	centered?: boolean
}

export function Spinner({ centered = true }: Props): JSX.Element {
	return (
		<div
			className={`mt-5 ${centered ? 'mx-auto' : ''}`}
			style={{
				display: 'table',
			}}>
			<LoadingIndicator>
				<div />
				<div />
			</LoadingIndicator>
		</div>
	)
}

// loading animation copied from https://loading.io/css/
const animation = keyframes`
	0% {
		top: 36px;
		left: 36px;
		width: 8px;
		height: 8px;
		opacity: 0;
	}
	4.9% {
		top: 36px;
		left: 36px;
		width: 8px;
		height: 8px;
		opacity: 0;
	}
	5% {
		top: 36px;
		left: 36px;
		width: 8px;
		height: 8px;
		opacity: 1;
	}
	100% {
		top: 0;
		left: 0;
		width: 80px;
		height: 80px;
		opacity: 0;
	}
`

const LoadingIndicator = styled.div`
	color: #1c4c5b
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	&, & div {
		box-sizing: border-box;
	}
	& div {
		position: absolute;
		border: 4px solid currentColor;
		opacity: 1;
		border-radius: 50%;
		animation: ${animation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
	& div:nth-child(2) {
		animation-delay: -0.5s;
	}
`
