import React, { Component } from 'react'
import { FormGroup, Button, Input, Label, Row, Col } from 'reactstrap'
import EffectSelector, { parseCamelCase } from '../LaunchpadGuide/EffectSelector'
import { Modal as AssetModal } from '../../../common/AssetManager'
import styled from 'styled-components'
import { FieldArrayFieldsProps } from 'redux-form'
type SpecialEffect = {
	delay: number
	effect: string
}
type SpecialEffectsProps = {
	type: 'effects'
	options: string[]
}
type ExpositionProps = {
	type: 'expositions'
}
type Props = (SpecialEffectsProps | ExpositionProps) & {
	fields: FieldArrayFieldsProps<SpecialEffect>
	display?: ((arg0: string) => string) | null | undefined
}
type State = {
	delay: string
	effect: string
	expositionSelectorOpen: boolean
}
export default class SpecialEffects extends Component<Props, State> {
	state: State = {
		delay: '',
		effect: '',
		expositionSelectorOpen: false,
	}

	render(): JSX.Element {
		return (
			<div className="SpecialEffects">
				<h3>{this.props.type === 'expositions' ? 'Expositions' : 'Special Effects'}</h3>
				{this.props.fields.getAll().length === 0 ? (
					<div>No {this.props.type === 'expositions' ? 'Expositions' : 'Effects'}!</div>
				) : (
					<StyledTable>
						<thead>
							<tr>
								<th>Delay (ms)</th>
								<th>Effect</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{this.props.fields
								.getAll()
								.map((value, index) => ({ ...value, originalIndex: index }))
								.sort((a, b) => (a.delay > b.delay ? 1 : -1))
								.map((value) => {
									return (
										<tr key={value.delay + value.effect}>
											<td>{value.delay}</td>
											<td>{this.getEffectDisplay(value.effect)}</td>
											<td>
												<Button
													color="danger"
													onClick={() => {
														this.props.fields.remove(value.originalIndex)
													}}>
													Delete
												</Button>
											</td>
										</tr>
									)
								})}
						</tbody>
					</StyledTable>
				)}
				{this.renderEffectInput()}
			</div>
		)
	}

	renderEffectInput(): JSX.Element {
		return (
			<StyledEffectInput>
				<Col>
					<FormGroup row>
						<Label for="delay" sm={5}>
							Delay (ms):
						</Label>
						<Col sm={7}>
							<Input
								type="number"
								id="delay"
								value={this.state.delay}
								onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
									this.setState({
										delay: e.currentTarget.value,
									})
								}}
							/>
						</Col>
					</FormGroup>
				</Col>
				<Col>
					<FormGroup row>
						<Label for="effect" sm={4}>
							Effect:
						</Label>
						<Col sm={8}>{this.renderEffectSelector()}</Col>
					</FormGroup>
				</Col>
				<Col>
					<Button
						onClick={() => {
							const delay = parseInt(this.state.delay, 10)
							const { effect } = this.state

							if ((!delay && delay !== 0) || !effect) {
								return alert('Please provide a delay and an effect')
							}

							this.props.fields.push({
								delay,
								effect,
							})
							this.setState({
								delay: '',
								effect: '',
							})
						}}>
						Add {this.props.type === 'expositions' ? 'Exposition' : 'Special Effect'}
					</Button>
				</Col>
				<AssetModal
					collection="expositions"
					isOpen={this.state.expositionSelectorOpen}
					onClose={this.toggleExpositionSelectorOpen}
					onFileClick={(file) => {
						this.setState({
							effect: file.url,
							expositionSelectorOpen: false,
						})
					}}
				/>
			</StyledEffectInput>
		)
	}

	renderEffectSelector(): JSX.Element {
		if (this.props.type === 'expositions') {
			return (
				<Button
					onClick={() =>
						this.setState({
							expositionSelectorOpen: true,
						})
					}>
					{this.getEffectDisplay(this.state.effect) || 'Select Exposition'}
				</Button>
			)
		}

		return (
			<EffectSelector
				effect={this.state.effect}
				display={this.props.display}
				onChange={(effect: string) => {
					this.setState({
						effect: effect,
					})
				}}
			/>
		)
	}

	toggleExpositionSelectorOpen: () => void = () => {
		this.setState((state) => ({
			expositionSelectorOpen: !state.expositionSelectorOpen,
		}))
	}
	getEffectDisplay: (effect: string) => string = (effect: string) => {
		const { display } = this.props
		return display ? parseCamelCase(display(effect)) : effect
	}
}
const StyledEffectInput = styled(Row)`
	margin-top: 16px;
`
const StyledTable = styled.table`
	td,
	th {
		padding: 8px;
		border: 1px solid black;
	}
`
