import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import MapObjectCopyModal from './MapObjectCopyModal'
import { actions, selectors } from '../../../../../setup/tiles'
import MapObjectForm from '../MapObjectForm'
import './MapObjectsContainer.css'
import type { MapObjectType, FullMap } from '../../../../../types'
import type { NormalizedObjects } from '../../../../../types/MapTypes'
import type { Category } from './MapObjectCopyModal'
import type { ReduxStore } from '../../../../../types/ReduxStore'
import { TileActions } from '../../../../../types/tiles'
type MapObjectData = {
	type: MapObjectType
	title: string
	addButtonText: string
	key: Category
}
const automatedSimulationData: MapObjectData[] = [
	{
		type: 'OTHER',
		title: 'Other',
		addButtonText: 'Object',
		key: 'other',
	},
]
// Data for all map object types. Used to display data on the map form
export const mapObjectsData: MapObjectData[] = [
	...automatedSimulationData,
	{
		type: 'CELESTIAL_BODY',
		title: 'Celestial Bodies',
		addButtonText: 'Celestial Body',
		key: 'celestialBodies',
	},
	{
		type: 'SHIP',
		title: 'Ships',
		addButtonText: 'Ship',
		key: 'ships',
	},
	{
		type: 'CONTACT',
		title: 'Contacts',
		addButtonText: 'Contact',
		key: 'contacts',
	},
]
type Props = {
	mapId: string
	objects: NormalizedObjects
	onUpdate: (arg0: NormalizedObjects) => void
	objectDelete?: (objectId: string) => void
	isAutomated?: boolean
}
type ReduxProps = {
	adding: Record<MapObjectType, boolean>
	addObject: TileActions['maps']['addObject']
	deleteObject: (arg0: { mapId: string; objectId: string }) => Promise<FullMap>
}

class MapObjectsContainer extends Component<Props & ReduxProps> {
	render(): JSX.Element {
		const objectData = this.props.isAutomated ? automatedSimulationData : mapObjectsData
		return (
			<div className="MapObjectsContainer">
				{objectData.map(({ type, title, addButtonText, key }: MapObjectData) => {
					const mapObjects = this.props.objects[key]
					return (
						<div className={key + '-map-objects'} key={key}>
							{!this.props.isAutomated ? <h4>{title}</h4> : null}
							<MapObjectsList
								objects={mapObjects}
								mapId={this.props.mapId}
								objectClassification={key}
								disableButton={this.props.adding[type]}
								addObject={() =>
									this.props.addObject({
										mapId: this.props.mapId,
										type,
									})
								}
								deleteObject={(objectId: string) => {
									if (this.props.objectDelete) {
										this.props.objectDelete(objectId)
									}

									this.props.deleteObject({
										mapId: this.props.mapId,
										objectId: objectId,
									})
								}}
								addButtonText={addButtonText}
								type={title}
								onUpdate={(objectsList) => {
									this.props.onUpdate({ ...this.props.objects, [key]: objectsList })
								}}
							/>
						</div>
					)
				})}
			</div>
		)
	}
}

type MapObjectsListProps = {
	objects: string[]
	disableButton: boolean
	addObject: () => unknown
	deleteObject: (objectId: string) => unknown
	addButtonText: string
	objectClassification: Category
	onUpdate: (arg0: string[]) => void
	mapId: string
	type: string
}
type MapObjectsListState = {
	modalOpen: boolean
	newItem: boolean
}

class MapObjectsList extends Component<MapObjectsListProps, MapObjectsListState> {
	constructor(props: MapObjectsListProps) {
		super(props)
		this.state = {
			modalOpen: false,
			newItem: false,
		}
	}

	componentDidUpdate(prevProps: MapObjectsListProps) {
		const numberPrevObjects = prevProps.objects.length
		const numberCurrentObjects = this.props.objects.length

		if (numberCurrentObjects > numberPrevObjects) {
			this.setState({
				newItem: true,
			})
		}
	}

	render(): JSX.Element {
		const {
			objects,
			disableButton,
			addObject,
			addButtonText,
			deleteObject,
			objectClassification,
			mapId,
		} = this.props
		return (
			<div className="MapObjectsList">
				<MapObjectCopyModal
					isOpen={this.state.modalOpen}
					onClose={this.onModalClose}
					mapId={mapId}
					category={objectClassification}
				/>
				{objects.map((object, index) => (
					<MapObjectForm
						key={object}
						mapId={mapId}
						objectClassification={objectClassification}
						objectId={object}
						deleteObject={() => {
							deleteObject(object)
						}}
						newItem={index === objects.length - 1 && this.state.newItem} // Here we make the assumption that the last item of the list is the last object added
					/>
				))}
				<div className="object-container-button-container">
					<Button disabled={disableButton} onClick={addObject} color="primary">
						Add {addButtonText}
					</Button>
					<Button disabled={disableButton} onClick={this.openModal} color="info">
						Add Preexisting {addButtonText}
					</Button>
				</div>
			</div>
		)
	}

	openModal = () => {
		this.setState({
			modalOpen: true,
		})
	}
	onModalClose = () => {
		this.setState({
			modalOpen: false,
		})
	}
}

const mapStateToProps = (state: ReduxStore, ownProps: Props) => {
	return {
		adding: {
			CELESTIAL_BODY: selectors.maps.addObject(state, {
				mapId: ownProps.mapId,
				type: 'CELESTIAL_BODY',
			}).isPending,
			SHIP: selectors.maps.addObject(state, {
				mapId: ownProps.mapId,
				type: 'SHIP',
			}).isPending,
			OTHER: selectors.maps.addObject(state, {
				mapId: ownProps.mapId,
				type: 'OTHER',
			}).isPending,
			CONTACT: selectors.maps.addObject(state, {
				mapId: ownProps.mapId,
				type: 'CONTACT',
			}).isPending,
		},
	}
}

const mapDispatchToProps = {
	addObject: actions.maps.addObject,
	deleteObject: actions.maps.deleteObject,
}
export default connect(mapStateToProps, mapDispatchToProps)(MapObjectsContainer)
