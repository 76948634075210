import axios from 'axios'

import type { Character } from '../../reducers/characters'
import { toast } from 'react-toastify'
import { config } from '../../config'

type CharacterType = {
	name: string
	url: string
	iconUrl: string
	description: string
	attributes: ReadonlyArray<
		Readonly<{
			name: string
			value: string
		}>
	>
}
export function createCharacter(
	newCharacter: Readonly<CharacterType>
): Promise<Character | null | undefined> {
	return axios
		.post(`${config.simulationsApiUrl}/api/characters`, newCharacter)
		.then((response) => {
			return response.data
		})
		.catch((err) => {
			console.error(err)
			toast.error('Could not create character. Please check the name and try again.')
			return null
		})
}
export function getAllCharacters(): Promise<Character[]> {
	return axios
		.get(`${config.simulationsApiUrl}/api/characters`)
		.then((response) => {
			return response.data
		})
		.catch((err) => {
			console.error(err)
			toast.error('Could not get all characters from server.')
			return []
		})
}
export function patchCharacter(
	characterId: string,
	updateObject: CharacterType
): Promise<Character | null> {
	return axios
		.patch(`${config.simulationsApiUrl}/api/characters/` + characterId, updateObject)
		.then((response) => {
			return response.data
		})
		.catch((err) => {
			console.error(err)
			toast.error('Could not update character. Please try again.')
			return null
		})
}
export function deleteCharacter(characterId: string): Promise<boolean> {
	return axios
		.delete(`${config.simulationsApiUrl}/api/characters/` + characterId)
		.then(() => {
			return true
		})
		.catch((err) => {
			console.error(err)
			toast.error('Could not delete character. Please try again.')
			return false
		})
}
