import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Input } from 'reactstrap'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { toast } from '../../../../helpers/uiFunctions'
import { actions, selectors } from '../../../../setup/tiles'
import {
	selectors as simulationSelectors,
	deleteMap as deleteMapFromSimulation,
	deleteObject as deleteObjectFromSimulation,
	useMaps,
} from '../../../../reducers/simulationEditor'
import Modal from '../Modal'
import Map from '../../../maps/Map/Map'
import MapImage from '../../../maps/Map/MapImage'
import type { NormalizedMap as MapType } from '../../../../types/MapTypes'
import { useUpdateAutomatedSimulation } from '../../queries'
import { debounceUpdateServerObject, updateMapObjectStore } from '../../../../reducers/mapObjects'
type MapActionEditorProps = {
	value: string | null | undefined
	onChange: (val: string | null | undefined) => void
	onObjectClick?: (objectId: string, mapId: string) => void
	canCreateMap: boolean
	showHidden: boolean
	disabled: boolean
	canEditMap: boolean
	showMap?: boolean
}

/**
 * @param props
 * @param props.canEditMap - Whether the map can be edited. If false, the user will only be able to see the static map.
 * @param props.showMap - Whether to show the map. If false, only display the input
 */
export default function MapActionEditor({
	value: mapId,
	onChange,
	onObjectClick,
	canCreateMap,
	showHidden,
	disabled,
	canEditMap,
	showMap = true,
}: MapActionEditorProps): JSX.Element {
	const maps = useMaps()
	const currentMap = mapId && maps.find((map) => map._id === mapId)
	const dispatch = useDispatch()
	return (
		<div>
			<Input
				className="mb-2"
				type="select"
				disabled={disabled}
				onChange={(e) => {
					onChange(e.currentTarget.value || null)
				}}
				value={mapId || ''}>
				{maps.map((map, index) => {
					return (
						<option key={map._id} value={map._id}>
							{map.name}
						</option>
					)
				})}
				<option value={''}>None</option>
			</Input>
			{showMap && currentMap && (
				<div>
					{showHidden ? <div>Showing hidden objects</div> : null}
					<MapImage
						objectIds={currentMap.objects}
						color={currentMap.color}
						backgroundImage={currentMap.backgroundImage}
						allowDrag={canEditMap}
						onDrag={(key: string, id: string, [x, y]: [number, number]) => {
							dispatch(
								updateMapObjectStore({
									[key]: {
										[id]: {
											location: {
												$set: {
													x,
													y,
												},
											},
										},
									},
								})
							)
							dispatch(debounceUpdateServerObject(key, id))
						}}
						onObjectClick={(objectId: string) => {
							if (onObjectClick) onObjectClick(objectId, currentMap._id)
						}}
						showHidden={showHidden}
					/>
					{canCreateMap && canEditMap && (
						<div>Click on a map object to edit its event results.</div>
					)}
				</div>
			)}
			{canCreateMap && canEditMap && !disabled && (
				<AddOrEditMapButton
					mapId={mapId}
					onAddMap={(mapId) => onChange(mapId)}
					onDeleteMap={() => onChange(null)}
				/>
			)}
		</div>
	)
}
/**
 * A button that allows editing the map associated with `mapId`, or if there is no `mapId`, creates a new map.
 *
 * @param props
 * @param {?string} props.mapId If provided, this is the map that will be edited when the button is pressed. If not provided,
 *               the button will be a button to create a new map
 * @param props.onDeleteMap A function that's called when the map being edited/created is deleted
 * @param props.onAddMap A function that is called with the new map id when a new map is created
 */

function AddOrEditMapButton({
	mapId,
	onDeleteMap,
	onAddMap,
}: {
	mapId: string | null | undefined
	onDeleteMap: () => unknown
	onAddMap: (mapId: string) => unknown
}) {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const dispatch = useDispatch()
	const { id: simulationId } = useParams<{ id: string }>()
	const maps = useSelector(selectors.maps.store)
	const allMapIds = useSelector(simulationSelectors.getMapIds)

	const { mutate: updateSimulation } = useUpdateAutomatedSimulation()

	const toggleModal = () => {
		setModalIsOpen((state) => !state)
	}

	const deleteMap = () => {
		if (mapId) {
			dispatch(deleteMapFromSimulation(mapId))
		}

		onDeleteMap()
		toggleModal()
	}

	const addMap = async () => {
		// Create map and add it to the simulation on the server
		const { data: newMap } = await dispatch(actions.maps.addMap())
		dispatch(actions.maps.store.addMap(newMap))
		updateSimulation(
			{ simulationId, simulationData: { mapIds: [...allMapIds, newMap._id] } },
			{
				onError: () => {
					toast.error('Unable to save map to simulation. You will need to create this map again')
				},
			}
		)
		onAddMap(newMap._id)
	}

	const deleteObject = (objectId: string) => {
		dispatch(deleteObjectFromSimulation(objectId))
	}

	return (
		<div>
			<div className="d-inline">
				{!mapId ? (
					<Button
						color="primary"
						size="sm"
						onClick={() => {
							addMap()
							toggleModal()
						}}>
						Create Map
					</Button>
				) : (
					<Button color="primary" size="sm" onClick={() => toggleModal()}>
						Edit Map
					</Button>
				)}
			</div>

			{maps && mapId && maps[mapId] && (
				<Modal isOpen={modalIsOpen} onClose={toggleModal} title="Map Editor">
					<div>
						<Bolded>Map Automatically Saves on Change</Bolded>
						<Map
							objectDelete={deleteObject}
							map={maps[mapId]}
							deleteMap={deleteMap}
							onUpdate={(newMap: MapType) => {
								dispatch(actions.maps.store.updateMap(newMap))
								dispatch(
									actions.maps.patch({
										id: newMap._id,
										...newMap,
									})
								)
							}}
							isAutomated={true}
						/>
					</div>
				</Modal>
			)}
		</div>
	)
}

const Bolded = styled.div`
	font-family: open-sans, sans-serif;
	font-weight: bold;
	font-size: 1.6rem;
`
