import React, { Component } from 'react'
import { Alert } from 'reactstrap'
import type { Card as CardType, Quest as QuestType, ClassQuestion } from '../../types'
import Card from './Card'
import CardsSidebar from './CardsSidebar'
import styled from 'styled-components'
type Props = {
	simulationId: string
	cards: CardType[]
	quests: QuestType[]
	classQuestions: ClassQuestion[] | null | undefined
}
type State = {
	selectedCardId: string | null | undefined
}

class CardsContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			selectedCardId: null,
		}
	}

	render(): JSX.Element {
		const { simulationId, cards, quests, classQuestions } = this.props
		const { selectedCardId } = this.state
		const selectedIndex = cards.findIndex((c) => c._id === selectedCardId)
		const selectedCard = cards[selectedIndex]
		return (
			<StyledCardsContainer>
				<StyledAside>
					<StyledSticky>
						<CardsSidebar
							simulationId={simulationId}
							cards={cards}
							quests={quests}
							classQuestions={classQuestions}
							selectedCardId={selectedCardId}
							onCardClick={(id: string) =>
								this.setState({
									selectedCardId: id,
								})
							}
						/>
					</StyledSticky>
				</StyledAside>

				<StyledMain>
					{this.hasUnmatchedJumpEnds() && (
						<Alert color="danger">
							You have unmatched jump starts and ends. This will <Bold>BREAK</Bold> the mission.
							Ensure each jump start has exactly one jump end.
						</Alert>
					)}
					{selectedCard && ( // @ts-expect-error This one looks like an actual error, but I'm not going to touch it because it's for old simulations
						<Card
							cardList={this.props.cards.map((card) => {
								return {
									cardId: card._id,
									cardName: card.name,
								}
							})}
							simulationId={simulationId}
							card={selectedCard}
							prevMapId={this.getPreviousMapIdForCardAtIndex(selectedIndex)}
						/>
					)}
					{!selectedCard && <i>Select a card on the left to edit it.</i>}
				</StyledMain>
			</StyledCardsContainer>
		)
	}

	/**
	 * For a given index in the cards array, find the mapId of the closest predecessor
	 * that has a mapId. If no card prior to the card represented by index has a mapId,
	 * return null.
	 * @param  {number} index The index of the card for which you want the previous mapId
	 * @return {?string}       The mapId of the most recent card that has a mapId, or null
	 */
	getPreviousMapIdForCardAtIndex(index: number): string | null | undefined {
		const cardMapIds = this.props.cards.map((card) => card.mapId)

		for (let i = index - 1; i >= 0; i--) {
			if (cardMapIds[i]) {
				return cardMapIds[i]
			}
		}

		return null
	}

	/**
	 * Sums all of the starts and ends for jumps in the deck. If there is an odd number, returns true.
	 * To be valid, each jump should have exactly one start and one end.
	 */
	hasUnmatchedJumpEnds(): boolean {
		let jumpPreps = 0
		let jumpEnds = 0
		this.props.cards.forEach((card) => {
			if (card.jumpPrep) jumpPreps++
			if (card.jumpEnd) jumpEnds++
		})
		return jumpPreps !== jumpEnds
	}
}

export default CardsContainer
const StyledCardsContainer = styled.div`
	width: 100%;
	display: flex;
`
const StyledAside = styled.aside`
	flex: 0 0 230px;
	max-height: 600px;
	overflow-x: auto;
`
const StyledSticky = styled.div`
	position: sticky;
	top: 20px;
	z-index: 1;
`
const StyledMain = styled.main`
	margin-left: 15px;
	flex: auto;
`
const Bold = styled.em`
	font-weight: bold;
`
