import React, { useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Button, Card, Label } from 'reactstrap'
import {
	fetchCategories,
	createCategory,
	getCategories,
	getBadges,
	updateCategory,
	deleteCategory,
	BADGE_ICON_MAP,
} from '../../reducers/categories'
import { FaPencilAlt } from 'react-icons/fa'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FaTrash } from 'react-icons/fa'
import type { BadgeIconType, Category as CategoryType } from '../../reducers/categories'
import styled from 'styled-components'
import ConfirmModal from '../../common/ConfirmModal'

/**
 * getCategoryOrdering - the the order of the categories (by id)
 *
 * @param  {?Array<CategoryType>} categories - the categories to get the order of
 *
 * @returns string[] - the ordering of the categories by id
 */
function getCategoryOrdering(categories: Array<CategoryType> | null): string[] {
	return (
		[...(categories ?? [])]
			?.sort(
				(category1, category2) => (category1.order ?? Infinity) - (category2.order ?? Infinity)
			)
			.map(({ _id }) => _id) ?? []
	)
}

export default function CategorySelect({
	input: { value: selectedCategories, onChange },
}: {
	input: {
		value: string[]
		onChange: (value: string[]) => void
	}
}): JSX.Element {
	const dispatch = useDispatch()
	const [isCreatingNewCategory, setIsCreatingNewCategory] = useState(false)
	const reduxCategories: Array<CategoryType> | null = useSelector(getCategories)
	const availableBadges: string[] | null | undefined = useSelector(getBadges)
	const [categoryOrder, setCategoryOrder] = useState<string[]>([])
	useEffect(() => {
		setCategoryOrder(getCategoryOrdering(reduxCategories))
	}, [reduxCategories])

	if (!reduxCategories) {
		dispatch(fetchCategories())
	}

	const serverCategories: Array<CategoryType> = reduxCategories || []
	const categoryLookup = useMemo(() => {
		const lookup: Record<string, CategoryType> = {}
		reduxCategories?.forEach((category) => {
			lookup[category._id] = category
		})
		return lookup
	}, [reduxCategories])
	return (
		<FormGroup>
			<h4>Categories</h4>
			<DragDropContext
				onDragEnd={({ draggableId, destination, source }) => {
					if (!destination) {
						return
					}
					const newOrdering = [...categoryOrder]
					newOrdering.splice(source.index, 1)
					newOrdering.splice(destination.index, 0, draggableId)
					newOrdering.forEach((categoryId, index) => {
						dispatch(
							updateCategory(categoryId, {
								order: index + 1,
							})
						)
					})
					setCategoryOrder(newOrdering)
				}}>
				<Droppable droppableId="categories">
					{(provided, snapshot) => (
						<OrderableCategoryList>
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{categoryOrder.map((categoryId, index) => {
									const category = categoryLookup[categoryId]

									if (!category) {
										// this should be impossible
										return null
									}

									return (
										<Draggable key={categoryId} draggableId={categoryId} index={index}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}>
													<Category
														category={category}
														key={categoryId}
														serverCategories={serverCategories}
														change={onChange}
														categories={selectedCategories}
														availableBadges={availableBadges}
													/>
												</div>
											)}
										</Draggable>
									)
								})}
								{provided.placeholder}
								{isCreatingNewCategory ? (
									<CategoryInput
										key="add-category"
										availableBadges={availableBadges}
										onCancel={() => setIsCreatingNewCategory(false)}
									/>
								) : null}
							</div>
						</OrderableCategoryList>
					)}
				</Droppable>
			</DragDropContext>
			<ButtonGroup>
				<div />
				<Button
					onClick={() => setIsCreatingNewCategory(true)}
					color="primary"
					disabled={isCreatingNewCategory}>
					New Category
				</Button>
			</ButtonGroup>
		</FormGroup>
	)
}

function CategoryInput({
	availableBadges,
	onCancel,
}: {
	availableBadges: string[] | null | undefined
	onCancel: () => unknown
}) {
	const dispatch = useDispatch()
	const [value, setValue] = useState('')
	const [badge, setBadge] = useState<string | null>(null)
	const [isAvailableForSimulations, setIsAvailableForSimulations] = useState(true)
	const [isAvailableForQuestions, setIsAvailableForQuestions] = useState(true)
	const resetFields = useCallback(() => {
		setValue('')
		setBadge(null)
		setIsAvailableForSimulations(true)
		setIsAvailableForQuestions(true)
	}, [])
	return (
		<StyledCategoryWrapper $editing={true}>
			<Label>
				Category Name
				<Input
					type="text"
					onChange={(e) => setValue(e.target.value)}
					value={value}
					placeholder={'name'}
					required
				/>
			</Label>
			{availableBadges ? (
				<Label>
					Badge
					<Input
						type="select"
						onChange={(e) => setBadge(e.target.value === 'NONE' ? null : e.target.value)}
						value={badge || 'NONE'}>
						{availableBadges.map((badgeId) => (
							<option value={badgeId || 'NONE'} key={badgeId}>
								{normalizeString(badgeId || 'None')}
							</option>
						))}
					</Input>
				</Label>
			) : null}
			<div>
				Visible on dashboard for:
				<Indent>
					<Label>
						<input
							type="checkbox"
							onChange={(e) => {
								setIsAvailableForSimulations(e.target.checked)
							}}
							checked={isAvailableForSimulations}
						/>
						Simulations
					</Label>
					<Label>
						<input
							type="checkbox"
							onChange={(e) => {
								setIsAvailableForQuestions(e.target.checked)
							}}
							checked={isAvailableForQuestions}
						/>
						Questions
					</Label>
				</Indent>
			</div>
			<FlexRow>
				<div />
				<Group>
					<Button
						outline={true}
						onClick={() => {
							resetFields()
							onCancel()
						}}>
						Cancel
					</Button>
					<Button
						disabled={!value}
						color="primary"
						onClick={() => {
							dispatch(
								createCategory({
									name: value,
									badge,
									availableForSimulations: isAvailableForSimulations,
									availableForQuestions: isAvailableForQuestions,
								})
							)
							resetFields()
							onCancel()
						}}>
						Save
					</Button>
				</Group>
			</FlexRow>
		</StyledCategoryWrapper>
	)
}

function Category({
	category,
	change,
	categories,
	serverCategories,
	availableBadges,
	children,
}: {
	category: CategoryType
	change: (value: string[]) => void
	serverCategories: Array<CategoryType>
	categories: string[]
	availableBadges: string[] | null | undefined
	children?: ReactNode
}) {
	const dispatch = useDispatch()
	const [value, setValue] = useState(category.name)
	const [editBadge, setEditBadge] = useState<BadgeIconType | null | undefined>(category.badge)
	const [isAvailableForSimulations, setIsAvailableForSimulations] = useState(
		!!category.availableForSimulations
	)
	const [isAvailableForQuestions, setIsAvailableForQuestions] = useState(
		!!category.availableForQuestions
	)
	const resetFields = useCallback(() => {
		setValue(category.name)
		setEditBadge(category.badge)
		setIsAvailableForSimulations(!!category.availableForSimulations)
		setIsAvailableForQuestions(!!category.availableForQuestions)
	}, [category])
	const [isEditing, setIsEditing] = useState(false)
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false)
	const categoryId = category._id
	const isSelected = useMemo(() => categories.includes(categoryId), [categoryId, categories])
	return (
		<>
			<StyledCategoryWrapper $editing={isEditing}>
				{!isEditing ? (
					<FlexRow>
						<Group>
							<Input
								id={categoryId}
								type="checkbox"
								onChange={(e) => {
									const checked = e.target.checked
									let newCategories = [...categories]

									if (checked) {
										if (!newCategories.includes(categoryId)) {
											newCategories.push(categoryId)
										}
									} else {
										newCategories = newCategories.filter((id) => id !== categoryId)
									}

									change(newCategories)
								}}
								checked={isSelected}
							/>
							<label htmlFor={categoryId}>{category.name}</label>
							{BADGE_ICON_MAP[editBadge || 'NONE'] || null}
						</Group>
						<ClickableEditIcons
							className="edit"
							onClick={() => {
								resetFields()
								setIsEditing(true)
							}}
						/>
					</FlexRow>
				) : (
					<>
						<Label>
							Category Name
							<Input
								type="text"
								onChange={(e) => setValue(e.target.value)}
								value={value}
								required
								innerRef={(ref) => ref && ref.focus()}
							/>
						</Label>
						{availableBadges ? (
							<Label>
								Badge
								<Input
									type="select"
									id={categoryId + 'badge'}
									onChange={(e) =>
										setEditBadge(
											e.target.value === 'NONE' ? null : (e.target.value as BadgeIconType)
										)
									}
									value={editBadge || 'NONE'}>
									{availableBadges.map((badgeId) => (
										<option value={badgeId || 'NONE'} key={badgeId}>
											{normalizeString(badgeId || 'None')}
										</option>
									))}
								</Input>
							</Label>
						) : null}
						<div>
							Visible on dashboard for:
							<Indent>
								<Label>
									<input
										type="checkbox"
										onChange={(e) => {
											setIsAvailableForSimulations(e.target.checked)
										}}
										checked={isAvailableForSimulations}
									/>
									Simulations
								</Label>
								<Label>
									<input
										type="checkbox"
										onChange={(e) => {
											setIsAvailableForQuestions(e.target.checked)
										}}
										checked={isAvailableForQuestions}
									/>
									Questions
								</Label>
							</Indent>
						</div>
						<FlexRow>
							<Button
								color="danger"
								outline={true}
								onClick={() => {
									setConfirmModalIsOpen(true)
								}}>
								<FaTrash />
							</Button>
							<Group>
								<Button outline={true} onClick={() => setIsEditing(false)}>
									Cancel
								</Button>
								<Button
									color="primary"
									onClick={() => {
										setIsEditing(false)

										if (
											value !== category.name ||
											editBadge !== category.badge ||
											category.availableForSimulations !== isAvailableForSimulations ||
											category.availableForQuestions !== isAvailableForQuestions
										) {
											dispatch(
												updateCategory(categoryId, {
													name: value,
													badge: editBadge,
													availableForSimulations: isAvailableForSimulations,
													availableForQuestions: isAvailableForQuestions,
												})
											)
										}
									}}>
									Save
								</Button>
							</Group>
						</FlexRow>
					</>
				)}
			</StyledCategoryWrapper>
			<ConfirmModal
				{...{
					isOpen: confirmModalIsOpen,
					prompt: `Deleting category "${category.name}" will remove the category and all subcategories from every simulation.\nAre you sure you wish to delete "${category.name}"?`,
					callback: (goAhead: boolean) => {
						setConfirmModalIsOpen(false)
						if (!goAhead) {
							return
						}

						dispatch(deleteCategory(categoryId))
						change(categories.filter((id) => id !== categoryId))
					},
				}}
			/>
		</>
	)
}

const StyledCategoryWrapper = styled(Card)<{ $editing: boolean }>`
	padding: 8px;
	margin: 8px;
	border-radius: 4px;
	background-color: white;

	.edit {
		opacity: 0;
	}

	${({ $editing }) =>
		!$editing
			? `&:hover {
		background-color: var(--bs-gray-100);

		.edit {
			opacity: 1;
		}
	}`
			: ''}
`
const ClickableEditIcons = styled(FaPencilAlt)`
	cursor: pointer;
`
const OrderableCategoryList = styled(Card)`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px;
	box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
`

function normalizeString(str: string) {
	const words = str.split('_')
	let result = ''

	for (let i = 0; i < words.length; i++) {
		const word = words[i].toLowerCase()

		if (!word) {
			continue
		}

		result += ' ' + word[0].toUpperCase() + word.substr(1)
	}

	return result
}

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 4px;
`
const Group = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`
const Indent = styled.div`
	margin: 8px;

	> * {
		margin-right: 16px;
	}

	input {
		margin-right: 4px;
	}
`
const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 4px;
	justify-content: space-between;
`
