import React from 'react'

/**
 * A wrapper of GradeSelect which allows it to be passed a the `component` prop of a
 * redux-form Field.
 */
export function GradeSelectReduxForm({
	input: { value, onChange },
	...props
}: {
	input: {
		value: string[] | string
		onChange: (grades: string[] | string) => void
	}
}): JSX.Element {
	return <GradeSelect value={value} onChange={onChange} {...props} />
}

/**
 * A controlled select component that lets the user pick a grade.
 * @param id - The id of the select element
 * @param value - The value of the select element
 * @param onChange - The function to call when the value changes
 * @param multi - Whether or not the select element should allow multiple selections
 * @param extraOptions - Extra options to display in the select element on top of the standard grades
 */
export function GradeSelect({
	id,
	value,
	onChange,
	multi = false,
	extraOptions,
	...props
}: {
	value: string[] | string
	onChange: (grades: string[] | string) => unknown
	multi?: boolean
	extraOptions?: string[]
	id?: string
}): JSX.Element {
	return (
		<select
			{...props}
			id={id}
			className="form-control"
			multiple={multi}
			value={value}
			onChange={(e) => {
				if (multi) {
					onChange(Array.from(e.currentTarget.selectedOptions).map((option) => option.value))
					return
				}
				onChange(e.target.value)
			}}>
			{!multi && <option></option>}
			<option>K</option>
			<option>1</option>
			<option>2</option>
			<option>3</option>
			<option>4</option>
			<option>5</option>
			<option>6</option>
			<option>7</option>
			<option>8</option>
			<option>9</option>
			<option>10</option>
			<option>11</option>
			<option>12</option>
			{extraOptions?.map((extraOption) => (
				<option key={extraOption}>{extraOption}</option>
			))}
		</select>
	)
}
