import type { CustomComponentProps } from './Markdown'
import type { CustomComponentDescriptors } from './MarkdownInput'

import React from 'react'
import styled from 'styled-components'

/**
 * StudentName - a component used in markdown parsing used to render a students name
 *
 * @param {CustomComponentProps} _ - props passed in from the markdown parser
 *
 * @return {React$Node}
 */
function StudentName(_: CustomComponentProps): JSX.Element {
	return <StyledName>Student Name</StyledName>
}

/**
 * TeamName - a component used in markdown parsing used to render a team's name
 *
 * @param {CustomComponentProps} _ - props passed in from the markdown parser
 *
 * @return {React$Node}
 */
function TeamName(_: CustomComponentProps): JSX.Element {
	return <StyledName>Team Name</StyledName>
}

/**
 * getStudentNameCustomComponent - get a descriptor for rendering a custom StudentName component in markdown
 *
 * @param {string} description - the description of what the studentName component does when it is rendered
 *
 * @return {CustomComponentDescriptors}
 */
export function getStudentNameCustomComponent(description: string): CustomComponentDescriptors {
	return {
		studentName: {
			component: StudentName,
			description,
			token: '{{studentName}}',
		},
	}
}

/**
 * getTeamNameCustomComponent - get a descriptor for rendering a custom teamName component in markdown
 *
 * @param {string} description - the description of what the teamName component does when it is rendered
 *
 * @return {CustomComponentDescriptors}
 */
export function getTeamNameCustomComponent(description: string): CustomComponentDescriptors {
	return {
		teamName: {
			component: TeamName,
			description,
			token: '{{teamName}}',
		},
	}
}

/**
 * getSpeakingTaskPreparser - get a markdown preparser to act on a string to make studentName (if allowed) and teamName (if allowed) renderable by the markdown parser.
 *
 * @param {Object} permissions - what should be made renderable
 * @param {boolean} permissions.allowStudentName - if true, will allow rendering the studentName component in markdown
 * @param {boolean} permissions.allowTeamName - if true, will allow rendering the teamName component in markdown
 *
 * @return {(string) => string} - a markdown perparser which will take in a string with custom component tokens and convert it to a string that markdown can process
 */
export function getSpeakingTaskPreparser(permissions: {
	allowStudentName: boolean
	allowTeamName: boolean
}): (markdownText: string) => string {
	return (markdownText: string): string => {
		let convertedText = markdownText
		if (permissions.allowStudentName) {
			convertedText = convertedText.replaceAll(/{{student( |_){0,1}name}}/gi, '<studentName />')
		}
		if (permissions.allowTeamName) {
			convertedText = convertedText.replaceAll(/{{team( |_){0,1}name}}/gi, '<teamName />')
		}
		return convertedText
	}
}

const StyledName = styled.span`
	color: var(--primary);
	font-weight: bold;
`
