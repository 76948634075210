import React, { useState } from 'react'
import { Col, Button, FormGroup, Label } from 'reactstrap'
import FontAwesome from 'react-fontawesome'
import Select from 'react-select'

import styled from 'styled-components'
import { runWithConfirmationMessage } from '../../helpers/uiFunctions'
import { values } from '../../helpers/functions'
import MarkdownInput from '../../common/MarkdownInput'
import type { Standard } from '../../types/AutomatedSimulation'
import { useDeleteStandard, useStandardSets, useUpdateStandard } from './hooks'
import { GradeSelect } from '../../common/GradeSelect'
import { toast } from 'react-toastify'
import { difference, isEqual } from 'lodash'
import {
	useAutomatedSimulations,
	useUpdateAutomatedSimulation,
} from '../automatedSimulations/queries'

/**
 * A form for editing a single standard
 * @param standard The standard to edit
 * @param setStandard optional - A function to call when the standard is updated. If `setStandard` is not provided,
 *                 this component will be "uncontrolled" therefore managing its own state, as well as updating the standard on the server.
 * @param collapsible Whether or not to show a button to collapse the standard
 */
export function EditStandard({
	standard: standard_,
	setStandard: setStandardArg,
	collapsible = true,
	startsExpanded = false,
}: {
	standard: Standard<string>
	setStandard?: (standard: Standard<string>) => void
	collapsible?: boolean
	startsExpanded?: boolean
}): JSX.Element {
	const [isExpanded_, setIsExpanded] = useState(startsExpanded)
	const [localStandard_, setLocalStandard_] = useState<Standard<string>>(standard_)
	const localStandard = setStandardArg ? standard_ : localStandard_
	const setLocalStandard = setStandardArg || setLocalStandard_
	const { mutateAsync: updateStandard, isLoading: isUpdating } = useUpdateStandard()
	const { mutateAsync: deleteStandard } = useDeleteStandard()
	const { data: simulations, isLoading: isLoadingSimulations } = useAutomatedSimulations()
	const updateSimulation = useUpdateAutomatedSimulation()

	const isExpanded = !collapsible || isExpanded_

	const { data: standardSets } = useStandardSets()

	const idPrefix = `standard-${localStandard._id}-`

	const simulationsWithStandard = simulations
		?.filter((sim) => sim.standards.some((standard) => standard._id === localStandard._id))
		.map((sim) => sim._id)

	return (
		<div
			className="p-2"
			css={`
				&:not(:last-child) {
					margin-bottom: var(--spacing);
				}
			`}
			id={localStandard._id}>
			<h4
				css={`
					display: flex;
					justify-content: space-between;
					align-items: center;
					${isExpanded ? `` : `margin-bottom: 0;`}
				`}>
				<div css="display: flex; align-items: center;">
					{collapsible && (
						<Button
							css="margin-right: 8px;"
							size="sm"
							aria-label={isExpanded ? 'Collapse' : 'Expand'}
							onClick={() => setIsExpanded((isExpanded) => !isExpanded)}>
							<FontAwesome name={isExpanded ? 'chevron-up' : 'chevron-down'} />
						</Button>
					)}
					{standardSets?.[localStandard.standardSet]?.abbreviation}{' '}
					{localStandard.name || 'Unnamed Standard'}
				</div>
				{isExpanded && !setStandardArg && (
					<div>
						{isUpdating ? (
							'Saving...'
						) : (
							<Button
								color="link"
								disabled={isEqual(localStandard, standard_)}
								onClick={() => {
									updateStandard(localStandard).catch((e) => {
										toast.error(`Error updating standard: ${e.message}`)
									})
								}}>
								Save
							</Button>
						)}

						<Button
							css="margin-left: 16px;"
							color="danger"
							size="sm"
							onClick={() =>
								runWithConfirmationMessage(
									`Are you sure you want to delete the standard ${
										standardSets?.[localStandard.standardSet]?.abbreviation ?? ''
									} ${localStandard.name}?`,
									() => deleteStandard(standard_._id),
									'Delete'
								)
							}>
							<FontAwesome name="trash" />
						</Button>
					</div>
				)}
			</h4>
			{isExpanded && (
				<>
					<InputRow>
						<FormGroup>
							<Label for={idPrefix + 'type'}>Standard Type</Label>
							<Col>
								<select
									id={idPrefix + 'type'}
									className="form-control"
									value={localStandard.standardSet}
									onChange={(e) => {
										const standardSet = standardSets?.[e.target.value]
										if (!standardSet) {
											return
										}

										setLocalStandard({
											...localStandard,
											standardSet: e.target.value,
										})
									}}>
									{/* The empty option will be selected if standard.type is not in standardSets */}
									<option />
									{values(standardSets || {}).map(({ _id, abbreviation }) => (
										<option value={_id} key={_id}>
											{abbreviation}
										</option>
									))}
								</select>
							</Col>
						</FormGroup>

						<FormGroup>
							<Label for={idPrefix + 'name'}>Standard Name</Label>
							<Col>
								<input
									id={idPrefix + 'name'}
									className="form-control"
									value={localStandard.name}
									onChange={(e) => {
										setLocalStandard({
											...localStandard,
											name: e.target.value,
										})
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Label for={idPrefix + 'grade'}>Grade</Label>
							<Col>
								<GradeSelect
									id={idPrefix + 'grade'}
									extraOptions={['K-2', '3-5', '6-8', '9-12', 'MS', 'HS']}
									value={localStandard.grade}
									onChange={(grade) => {
										if (typeof grade !== 'string') {
											throw new Error('Grade should be a string here')
										}
										setLocalStandard({
											...localStandard,
											grade,
										})
									}}
								/>
							</Col>
						</FormGroup>
					</InputRow>

					<InputRow>
						<FormGroup>
							<Label for={idPrefix + 'keyword'}>Keyword</Label>
							<Col>
								<input
									id={idPrefix + 'keyword'}
									className="form-control"
									value={localStandard.keyword}
									onChange={(e) => {
										setLocalStandard({
											...localStandard,
											keyword: e.target.value,
										})
									}}
								/>
							</Col>
						</FormGroup>

						<FormGroup>
							<Label for={idPrefix + 'url'}>Url</Label>
							<Col>
								<input
									id={idPrefix + 'url'}
									className="form-control"
									value={localStandard.url}
									onChange={(e) => {
										setLocalStandard({
											...localStandard,
											url: e.target.value,
										})
									}}
								/>
							</Col>
						</FormGroup>
					</InputRow>

					<FormGroup>
						<Label for={idPrefix + 'summary'}>Summary</Label>
						<Col>
							<input
								id={idPrefix + 'summary'}
								className="form-control"
								value={localStandard.summary}
								onChange={(e) => {
									setLocalStandard({
										...localStandard,
										summary: e.target.value,
									})
								}}
							/>
						</Col>
					</FormGroup>

					<FormGroup>
						<Label for={idPrefix + 'full'}>Full Text</Label>
						<Col>
							<MarkdownInput
								id={idPrefix + 'full'}
								value={localStandard.full}
								onChange={(value) => {
									setLocalStandard({
										...localStandard,
										full: value,
									})
								}}
								className="form-control"
							/>
						</Col>
					</FormGroup>
					<FormGroup>
						<Label for={idPrefix + 'simulations'}>Simulations</Label>
						<Col>
							<MultiSimulationSelector
								selectedSimulationIds={simulationsWithStandard || []}
								onChange={(simulationIds) => {
									// Don't do anything when the simulations are not available
									if (!simulationsWithStandard) {
										return
									}

									const simulationsAdded = difference(simulationIds, simulationsWithStandard)
									const simulationsRemoved = difference(simulationsWithStandard, simulationIds)

									simulationsAdded.forEach((simulationId) => {
										const simulation = simulations?.find((sim) => sim._id === simulationId)
										const standardSet = standardSets?.[localStandard.standardSet]
										if (!simulation || !standardSet) {
											toast.error('Could not find simulation and standard set')
											return
										}

										updateSimulation.mutate({
											simulationId,
											simulationData: {
												standards: [
													...simulation.standards,
													{
														...localStandard,
														standardSet,
													},
												],
											},
										})
									})

									simulationsRemoved.forEach((simulationId) => {
										const simulation = simulations?.find((sim) => sim._id === simulationId)
										if (!simulation) {
											toast.error('Could not find simulation')
											return
										}

										updateSimulation.mutate({
											simulationId,
											simulationData: {
												standards: simulation.standards.filter(
													(standard) => standard._id !== localStandard._id
												),
											},
										})
									})
								}}
								isLoading={isLoadingSimulations || updateSimulation.isLoading}
							/>
						</Col>
					</FormGroup>
				</>
			)}
		</div>
	)
}

const InputRow = styled.div`
	display: flex;
	gap: 8px;
	& > * {
		flex: 1;
	}
`

/**
 * A controlled component that allows adding and removing multiple simulations from a list
 */
function MultiSimulationSelector({
	selectedSimulationIds,
	onChange,
	isLoading,
}: {
	selectedSimulationIds: string[]
	onChange: (simulationIds: string[]) => unknown
	isLoading?: boolean
}) {
	const { data: simulations = [] } = useAutomatedSimulations()
	const selectedSimulationsSet = new Set(selectedSimulationIds)

	const selectedSimulations = []
	const unselectedSimulations = []
	for (const simulation of simulations) {
		if (selectedSimulationsSet.has(simulation._id)) {
			selectedSimulations.push(simulation)
		} else {
			unselectedSimulations.push(simulation)
		}
	}

	return (
		<Select
			isMulti
			isLoading={isLoading}
			options={unselectedSimulations.map((sim) => ({ value: sim._id, label: sim.name }))}
			value={selectedSimulations.map((sim) => ({ value: sim._id, label: sim.name }))}
			onChange={(selectedOptions) => {
				if (!selectedOptions) {
					onChange([])
					return
				}

				onChange(selectedOptions.map((option) => option.value))
			}}
		/>
	)
}
