type Config = { skipLogin: boolean; isDev: boolean; simulationsApiUrl: string }

const IS_RUNNING_ON_MISSION_IO = window.location.hostname.endsWith('mission.io')

const config: Config = {
	skipLogin: false,
	isDev: false,
	simulationsApiUrl: `https://simulations-api.${
		IS_RUNNING_ON_MISSION_IO ? 'mission.io' : 'infinid.us'
	}`,
}

if (process.env.NODE_ENV === 'development') {
	config.isDev = true
	config.skipLogin = true
	config.simulationsApiUrl = 'http://localhost:5001'
}

export { config }
