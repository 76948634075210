import React, { useRef, useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import { PAUSE_STATE } from '../ActionPlayer/ActionPlayer'
import { ReviveDialog, MissionPauseDialog, PauseDialog } from './ScreenDialogs'
import VideoImageOverlay from './Overlays/ImageVideoOverlays'
import { indexToAlpha } from '../../../helpers/functions'

import type { ActionPlayerAction } from './sharedTypes'
import type {
	DeathState,
	OverlayType,
	PausedState,
	ReachedPhasesAndCheckpoints,
} from '../ActionPlayer/ActionPlayer'
import { useTeams } from '../../simulations/Teams'
import type {
	CreativeCanvasStationData,
	CreativeCanvasIssue,
	CreativeCanvasIssueWithTeams,
	CollaborativeCulminatingMomentAction,
	EventResult,
	ActionMap,
	ActivateStationActionWithStationData,
	ScreenAction,
} from '@mission.io/mission-toolkit/actions'
import { COLLABORATIVE_CULMINATING_MOMENT } from '@mission.io/mission-toolkit/constants'
import { getTitle } from '../actionDefinitions'

const VIDEO_VOLUME = 0.7

/**
 * A simple wrapper component for the current  Screen
 */
export default function ScreenWrapper({
	currentScreen,
	playActions,
	className,
	pausedState,
	deathState,
	dispatch,
	reachedPhasesAndCheckpoints,
	overlays,
	actions,
}: {
	currentScreen: ScreenAction<string> | null
	playActions: (actions: EventResult<string>) => void
	className?: string
	pausedState?: PausedState
	deathState: DeathState | null
	dispatch: (action: ActionPlayerAction) => unknown
	reachedPhasesAndCheckpoints: Array<ReachedPhasesAndCheckpoints>
	overlays: Array<OverlayType>
	actions: ActionMap<string>
}) {
	return (
		<div css="flex: 1; position: relative;" className={className}>
			{pausedState?.state === PAUSE_STATE.PAUSED && <PauseDialog />}
			{deathState?.status && (
				<ReviveDialog
					playActions={playActions}
					deathState={deathState}
					dispatch={dispatch}
					reachedPhasesAndCheckpoints={reachedPhasesAndCheckpoints}
				/>
			)}
			{pausedState?.missionPause && (
				<MissionPauseDialog
					missionPause={pausedState.missionPause}
					dispatch={dispatch}
					playActions={playActions}
					reachedPhasesAndCheckpoints={reachedPhasesAndCheckpoints}
				/>
			)}
			{overlays.map((overlay: OverlayType, index: number) => (
				<VideoImageOverlay
					key={`${overlay.actionId}-${index}`}
					playActions={playActions}
					dispatch={dispatch}
					overlay={overlay}
				/>
			))}
			<CurrentScreen
				deathState={deathState}
				currentScreen={currentScreen}
				pausedState={pausedState}
				playActions={playActions}
				actions={actions}
			/>
		</div>
	)
}

/**
 * A component that handles showing the current screen (e.g. <video> tag for video screens)
 */
function CurrentScreen({
	currentScreen,
	playActions,
	pausedState,
	deathState,
	actions,
}: {
	currentScreen: ScreenAction<string> | null
	playActions: (actions: EventResult<string>) => void
	pausedState?: PausedState
	deathState: DeathState | null
	actions: ActionMap<string>
}) {
	const timerIdRef = useRef<NodeJS.Timeout>()

	const setOnEndTimeout = useCallback(
		(delay: number, onEndEventResult: EventResult<string>) => {
			timerIdRef.current = setTimeout(() => {
				playActions(onEndEventResult)
			}, delay)
		},
		[playActions]
	)

	useEffect(() => {
		if (timerIdRef.current) {
			clearTimeout(timerIdRef.current)
		}

		if (!currentScreen) {
			return
		}

		if (currentScreen.type !== 'VIDEO_SCREEN' || currentScreen.looping) {
			let actionsToPlayAfterTimer = currentScreen.onEnd
			if (
				currentScreen.type === 'CULMINATING_MOMENT_SCREEN' &&
				currentScreen.questionInfo.options[0]
			) {
				actionsToPlayAfterTimer = [
					...currentScreen.questionInfo.options[0].result,
					...currentScreen.onEnd,
				]
			}
			if ('timeLimit' in currentScreen) {
				setOnEndTimeout(currentScreen.timeLimit, actionsToPlayAfterTimer)
			}
		}
	}, [currentScreen, setOnEndTimeout])

	const videoRef = useRef<HTMLVideoElement>(null)
	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.volume = VIDEO_VOLUME
		}
	}, [])

	useEffect(() => {
		if (videoRef.current && pausedState) {
			if (pausedState.state === PAUSE_STATE.PAUSED || pausedState.missionPause)
				videoRef.current.pause()
			else videoRef.current.play()
		}
	}, [pausedState])

	useEffect(() => {
		if (videoRef.current) {
			if (deathState?.status) videoRef.current.pause()
			else videoRef.current.load()
		}
	}, [deathState])

	if (!currentScreen) {
		return <TextBox>No screen action has played...</TextBox>
	}

	switch (currentScreen.type) {
		case 'VIDEO_SCREEN':
			return (
				<video
					ref={videoRef}
					src={currentScreen.url}
					autoPlay
					loop={currentScreen.looping}
					onEnded={() => {
						if (currentScreen.looping) {
							return
						}

						if (currentScreen.finalFrameDuration > 0) {
							setOnEndTimeout(currentScreen.finalFrameDuration, currentScreen.onEnd)
						} else {
							playActions(currentScreen.onEnd)
						}
					}}
					css="height: inherit; width: 100%;"
				/>
			)
		case 'IMAGE_SCREEN':
			return (
				<img
					src={currentScreen.url}
					alt="Screen"
					css="height: 100%; width: 100%; object-fit: contain;"
				/>
			)
		case 'CULMINATING_MOMENT_SCREEN':
			const media = currentScreen.questionInfo.media[0]
			if (media && media.type !== 'IMAGE') {
				throw new Error('invalid media type for Culminating Moment Question')
			}
			return (
				<div css="padding: 16px;">
					<h2
						title={currentScreen.questionInfo.description || 'Question has no description'}
						css="text-align: center;">
						{currentScreen.questionInfo.phrase}
					</h2>
					<div
						css={`
							display: grid;
							grid-template-columns: ${media ? '60% 1fr' : '1fr'};
							gap: 8px;
							align-items: start;
						`}>
						{media && (
							<div css="display: flex; justify-content: center;">
								<img src={media.url} alt="Culminating Moment context" height="200px" />
							</div>
						)}
						<div css="display: grid; grid-template-columns: 1fr; grid-gap: 8px;">
							{currentScreen.questionInfo.options.map((option, i) => (
								<button
									onClick={() => playActions([...option.result, ...currentScreen.onEnd])}
									key={option._id}
									title={option.description || 'Option has no description'}
									css="display: flex; align-items: center; gap: 8px;">
									<span css="background-color: gray; border-radius: 50%; width: 1.5em;">
										{indexToAlpha(i)}
									</span>
									{option.imageUrl && (
										<img src={option.imageUrl} width="100px" alt={`Icon for option ${i + 1}`} />
									)}
									<span>
										{option.text}
										{option.correct && <span> (correct)</span>}
									</span>
								</button>
							))}
						</div>
					</div>
				</div>
			)
		case 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN':
			const creativeCanvasStation = actions[currentScreen.canvasActionId]

			return (
				<div css="padding: 16px; overflow: scroll; height: 100%;">
					<div css="display: flex; justify-content: space-between; align-items: center;">
						<h2>Collaborative Culminating Moment</h2>
						<div css="display: flex; gap: var(--spacing2x);">
							<button
								onClick={() => playActions([...currentScreen.onSuccess, ...currentScreen.onEnd])}>
								Simulate Success
							</button>
							<button
								onClick={() => playActions([...currentScreen.onFailure, ...currentScreen.onEnd])}>
								Simulate Failure
							</button>
						</div>
					</div>
					{creativeCanvasStation?.type === 'ACTIVATE_STATION' &&
					creativeCanvasStation.stationData.stationId === 'CREATIVE_CANVAS' ? (
						<CollaborativeCulminatingMoment
							collaborativeCulminatingMoment={currentScreen}
							// @ts-expect-error - we know this is a creative canvas station
							creativeCanvasStation={creativeCanvasStation}
						/>
					) : (
						// No valid canvas action selected
						<>
							{!creativeCanvasStation ? (
								<div>No Canvas Action Selected</div>
							) : creativeCanvasStation.type !== 'ACTIVATE_STATION' ||
							  creativeCanvasStation.stationData.stationId !== 'CREATIVE_CANVAS' ? (
								<div>Invalid Canvas Action Selected</div>
							) : (
								<div>Invalid Canvas Action - Unknown Reason</div>
							)}
						</>
					)}
				</div>
			)
		default:
			return <TextBox>{getTitle(currentScreen)}</TextBox>
	}
}

type GradingOptionId = string
type IssueGrade = { [criterionId: string]: GradingOptionId }
type IssueType = CreativeCanvasIssue<string> | CreativeCanvasIssueWithTeams<string>

/**
 * Displays the necessary context for the collaborative culminating moment screen
 */
function CollaborativeCulminatingMoment({
	collaborativeCulminatingMoment,
	creativeCanvasStation,
}: {
	collaborativeCulminatingMoment: CollaborativeCulminatingMomentAction<string>
	creativeCanvasStation: ActivateStationActionWithStationData<
		CreativeCanvasStationData<string>,
		string
	>
}) {
	const [gradingState, setGradingState] = useState<{
		issues: { [issueId: string]: IssueGrade }
	}>({
		issues: {},
	})

	const issues =
		'issues' in creativeCanvasStation.stationData
			? creativeCanvasStation.stationData.issues
			: [creativeCanvasStation.stationData.issue]

	return (
		<div>
			<div>
				Percent of Teams Required to Pass:{' '}
				{collaborativeCulminatingMoment.ending !==
				COLLABORATIVE_CULMINATING_MOMENT.ENDING.CLASS_VOTE
					? `${collaborativeCulminatingMoment.requiredPercentForSuccess}%`
					: 'N/A'}
			</div>
			<h3>Prompts</h3>
			{issues.map((issue) => (
				<Issue
					key={issue._id}
					issue={issue}
					grading={gradingState.issues[issue._id]}
					gradeCriterion={({
						criterionId,
						gradingOptionId,
					}: {
						criterionId: string
						gradingOptionId: string
					}) => {
						setGradingState((state) => ({
							...state,
							issues: {
								...state.issues,
								[issue._id]: {
									...state.issues[issue._id],
									[criterionId]: gradingOptionId,
								},
							},
						}))
					}}
				/>
			))}
		</div>
	)
}

/**
 * Tells whether the given issue is correct and whether it is sufficiently graded, based on the given `grading` object.
 * @return `isCorrect` is true if all required criteria are correct and the score is higher than the required score.
 *         `isSufficientlyGraded` is true if `isCorrect` is true or if all criteria are graded.
 */
function issueIsCorrect(
	issue: IssueType,
	grading: IssueGrade
): { isCorrect: boolean; isSufficientlyGraded: boolean } {
	let allRequiredCriteriaAreGraded = true
	const allRequiredCriteriaAreCorrect = issue.rubric.criteria
		.filter((criterion) => criterion.required)
		.every((criterion) => {
			const selectedOptionId = grading[criterion._id]
			if (!selectedOptionId) {
				allRequiredCriteriaAreGraded = false
				return false
			}

			return criterion.gradingOptions.find((option) => option._id === selectedOptionId)?.correct
		})

	if (!allRequiredCriteriaAreCorrect)
		return { isCorrect: false, isSufficientlyGraded: allRequiredCriteriaAreGraded }

	let totalScore = 0
	let isSufficientlyGraded = true
	issue.rubric.criteria.forEach((criterion) => {
		const selectedOptionId = grading[criterion._id]
		if (!selectedOptionId) {
			isSufficientlyGraded = false
			return
		}

		totalScore +=
			criterion.gradingOptions.find((option) => option._id === selectedOptionId)?.score || 0
	})

	return { isCorrect: totalScore >= issue.rubric.requiredScore, isSufficientlyGraded }
}

/**
 * Displays a single issue and its criteria and allows grading the criteria.
 */
function Issue({
	issue,
	grading,
	gradeCriterion,
}: {
	issue: CreativeCanvasIssue<string> | CreativeCanvasIssueWithTeams<string>
	grading: { [criterionId: string]: string } | null
	gradeCriterion: (arg: { criterionId: string; gradingOptionId: string }) => unknown
}) {
	const teams = useTeams()

	const { isCorrect, isSufficientlyGraded } = issueIsCorrect(issue, grading || {})

	return (
		<div css="border: 2px solid; border-radius: 6px; padding: var(--spacing2x); &:not(:last-child) { margin-bottom: var(--spacing2x); }">
			<h4>
				{issue.prompt}
				{'teams' in issue && (
					<>
						{' '}
						(
						{issue.teams.length
							? issue.teams
									.map((teamId) => teams.find((team) => team._id === teamId)?.name)
									.join(', ')
							: 'No Teams Selected'}
						)
					</>
				)}
				{isSufficientlyGraded && !isCorrect && ' ❌'}
				{isCorrect && ' ✅'}
			</h4>
			<div css="margin-bottom: var(--spacing);">
				Required Criteria to Pass: {issue.rubric.requiredScore}
			</div>
			{issue.rubric.criteria.map((criterion) => {
				const selectedGradingOption = criterion.gradingOptions.find(
					(option) => option._id === grading?.[criterion._id]
				)

				return (
					<div
						key={criterion._id}
						css="display: flex; align-items: center; margin-bottom: var(--spacing);">
						<select
							css="margin-right: var(--spacing);"
							value={grading?.[criterion._id]}
							onChange={(e) =>
								gradeCriterion({
									criterionId: criterion._id,
									gradingOptionId: e.currentTarget.value,
								})
							}>
							<option value=""></option>
							{criterion.gradingOptions.map((option) => (
								<option key={option._id} value={option._id}>
									{option.text}
								</option>
							))}
						</select>
						<h5 css="margin-bottom: 0;">{criterion.text}</h5>
						{criterion.required && (
							<span css="margin-left: var(--spacing); font-size: 0.9em;">(Required)</span>
						)}
						{selectedGradingOption && (
							<span css="margin-left: var(--spacing);">
								{selectedGradingOption.correct ? '✅' : '❌'}
							</span>
						)}
					</div>
				)
			})}
		</div>
	)
}

export const OverlayStyled = styled.div<{ $noBackground?: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	${({ $noBackground }) =>
		$noBackground ? `background-color: transparent;` : `background-color: rgb(174 174 174 / 72%);`}

	div {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
		text-align: center;
	}
`

const TextBox = styled.div`
	height: 100%;
	width: 100%;
	font-size: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
`
