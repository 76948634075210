import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { usePhaseContext } from '../PhaseContext'
import { selectors } from '../../../reducers/simulationEditor'
import type { ScreenActionId } from '../actionDefinitions'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { Tooltip } from 'reactstrap'
import styled from 'styled-components'
import { ReduxStore } from '../../../types/ReduxStore'
import type { ScreenAction } from '@mission.io/mission-toolkit/actions'

const HELP_TEXT = `To add a phase, navigate to a screen form of your choice and give that screen a phase
name.`
export default function PhaseList(): JSX.Element {
	const { currentPhase, setCurrentPhase, phases } = usePhaseContext()
	const currentPhaseScreen: ScreenAction<string> | null | undefined = useSelector(
		(state: ReduxStore) => (currentPhase ? selectors.getScreenAction(state, currentPhase) : null)
	)
	const currentPhaseName = currentPhaseScreen?.newPhase
	return (
		<PhaseListStyle>
			{phases && phases.length > 0 ? (
				<FlexRow>
					{currentPhaseName && (
						<div css="display: flex;">
							<Span>Current Phase:</Span>
							<DecoratedSpan>{currentPhaseName}</DecoratedSpan>
						</div>
					)}
					<div css="display: flex;">
						{phases.map((phase, i) => {
							return (
								<PhaseIcon
									key={i}
									phaseId={phase.screenId}
									active={phase.screenId === currentPhase}
									onClick={() => {
										setCurrentPhase(phase.screenId)
									}}
								/>
							)
						})}
					</div>
					<TooltipItem
						item={{
							text: HELP_TEXT,
						}}
						id="help">
						<IoIosHelpCircleOutline size="20" />
					</TooltipItem>
				</FlexRow>
			) : (
				HELP_TEXT
			)}
		</PhaseListStyle>
	)
}

const PhaseIcon = (props: { active: boolean; phaseId: ScreenActionId; onClick: () => void }) => {
	const action = useSelector((state: ReduxStore) => selectors.getAction(state, props.phaseId))
	return (
		<TooltipItem
			item={{
				text: (action && 'newPhase' in action && action.newPhase) || 'undefined',
			}}
			id={props.phaseId}>
			<PhaseDot $active={props.active} onClick={props.onClick} />
		</TooltipItem>
	)
}

const TooltipItem = (props: {
	item: {
		text: string
	}
	id: string
	children: JSX.Element
}) => {
	const { item, id, children } = props
	const [tooltipOpen, setTooltipOpen] = useState(false)

	const toggle = () => setTooltipOpen(!tooltipOpen)

	return (
		<span>
			<div id={'Tooltip-' + id}>{children}</div>
			<Tooltip placement="top" isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
				{item.text}
			</Tooltip>
		</span>
	)
}

const PhaseDot = styled.div<{ $active: boolean }>`
	height: 20px;
	width: 20px;
	margin-right: 20px;
	border-radius: 50%;
	cursor: pointer;
	background-color: ${({ $active }) => ($active ? '#42CFD4' : 'grey')};
`
const PhaseListStyle = styled.div`
	text-align: center;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	flex: 1;
`
const FlexRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`
const Span = styled.span`
	padding: 4px;
`
const DecoratedSpan = styled(Span)`
	background-color: #42cfd4;
	color: white;
	border-radius: 4px;
`
