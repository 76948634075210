import React from 'react'
import Draggable from './Draggable'
import { DraggableType } from './types'
import styled from 'styled-components'

export default function DragDrop(): JSX.Element {
	const draggables: Array<{
		type: DraggableType
		title: string
		meta?: { testTimeLength?: number }
	}> = [
		{
			type: DraggableType.SCREEN_TYPE,
			title: '1 (Can be Screen)',
			meta: {
				testTimeLength: 60,
			},
		},
		{
			type: DraggableType.SCREEN_TYPE,
			title: '2 (Can be Screen)',
			meta: {
				testTimeLength: 120,
			},
		},
		{
			type: DraggableType.ACTION_TYPE,
			title: '3 (Action only)',
		},
		{
			type: DraggableType.ACTION_TYPE,
			title: '4 (Action only)',
		},
	]
	return (
		<TestContainer>
			{draggables.map((item, index) => (
				<Draggable key={`drag-test-${index}`} item={item}>
					<div>{item.title}</div>
				</Draggable>
			))}
		</TestContainer>
	)
}
const TestContainer = styled.div`
	margin-top: 12px;
`
