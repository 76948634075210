import { ActionMap } from '@mission.io/mission-toolkit/actions'
import React, { useState, useCallback, useContext } from 'react'

type Context = {
	undo: () => unknown
	redo: () => unknown
	update: () => unknown
	onSave: () => unknown
	needsSave: boolean
	lastSavedActions: ActionMap<string>
	setLastSavedActions: (arg0: ActionMap<string>) => void
	lastSavedMapOrdering: string[]
	setLastSavedMapOrdering: (ordering: string[]) => void
}
const EditorSimulationSaveContext = React.createContext<Context | null>(null)
/**
 * A context provider for the EditorSimulationSaveContext. This is used to track whether the editor simulation
 * needs to be saved or not. Tries to take into account undos and redos as well
 *
 * NOTE: An alternative implementation could store the entire editor simulation when it is saved, and then
 * do a deep equality comparison between the current editor simulation and the last saved one to see if
 * it requires a save
 */

export default function EditorSimulationSaveContextProvider({
	actions,
	mapIds,
	children,
}: {
	actions: ActionMap<string>
	mapIds: string[]
	children: JSX.Element
}): JSX.Element {
	// `changeCount` is the number of changes since the last save. It can be positive or negative because
	// the user can undo and redo their changes. It will be `Infinity` indicating the needs for change after an undo followed by an update
	const [changeCount, setChangeCount] = useState(0)
	const [lastSavedActions, setLastSavedActions] = useState(actions)
	const [lastSavedMapOrdering, setLastSavedMapOrdering] = useState(mapIds)
	const update = useCallback(() => {
		setChangeCount((current) => (current < 0 ? Infinity : current + 1))
	}, [setChangeCount])
	const undo = useCallback(() => {
		setChangeCount((current) => current - 1)
	}, [setChangeCount])
	const redo = useCallback(() => {
		setChangeCount((current) => current + 1)
	}, [setChangeCount])
	const onSave = useCallback(() => {
		setChangeCount(0)
	}, [setChangeCount])
	return (
		<EditorSimulationSaveContext.Provider
			value={{
				update,
				undo,
				redo,
				onSave,
				needsSave: changeCount !== 0,
				lastSavedActions,
				setLastSavedActions,
				lastSavedMapOrdering,
				setLastSavedMapOrdering,
			}}>
			{children}
		</EditorSimulationSaveContext.Provider>
	)
}

function useEditorSimulationSaveContext(): Context {
	const context = useContext(EditorSimulationSaveContext)

	if (!context) {
		throw new Error(
			'EditorSimulationSaveContext must be used from within EditorSimulationSaveContextProvider'
		)
	}

	return context
}

/**
 * Get the undo and redo functions used to notify the editor simulation save context of an undo/redo event
 */
export function useSaveContextUndoRedo(): {
	undo: () => unknown
	redo: () => unknown
} {
	const { undo, redo } = useEditorSimulationSaveContext()
	return {
		undo,
		redo,
	}
}

/**
 * Get the update function used to notify the editor simulation save context of an update event. This function should
 * be used when making any change to the editor simulation
 */
export function useSaveContextUpdate(): () => unknown {
	const { update } = useEditorSimulationSaveContext()
	return update
}

/**
 * Get the necessary pieces of the context for saving, namely the onSave function to call after a save, and the `needsSave`
 * variable to know whether a save is required
 */
export function useSaveData(): {
	onSave: () => unknown
	needsSave: boolean
} {
	const { onSave, needsSave } = useEditorSimulationSaveContext()
	return {
		onSave,
		needsSave,
	}
}
export function useLastSavedActions(): {
	lastSavedActions: ActionMap<string>
	setLastSavedActions: (arg0: ActionMap<string>) => void
} {
	const { lastSavedActions, setLastSavedActions } = useEditorSimulationSaveContext()
	return {
		lastSavedActions,
		setLastSavedActions,
	}
}

/**
 * useHasMapOrderingChangedSinceLastSave - check if the current map ordering differs from the saved map ordering
 *
 * @param {?(string[]} newMapOrdering - the new/current map ordering
 *
 * @return {boolean} - true if the ordering has changed since last save, false otherwise
 */
export function useHasMapOrderingChangedSinceLastSave(
	newMapOrdering: string[] | null | undefined
): boolean {
	const { lastSavedMapOrdering } = useEditorSimulationSaveContext()

	if (!newMapOrdering) {
		return false
	}

	return !(
		newMapOrdering.length === lastSavedMapOrdering.length &&
		newMapOrdering.every((mapId, index) => mapId === lastSavedMapOrdering[index])
	)
}

/**
 * useSetLastSavedMapOrdering - set the ordering of the maps on the simulation which have been saved to the database
 *
 * @return {(newMapOrdering: string[]) => void - a callback to set the saved map ordering
 */
export function useSetLastSavedMapOrdering(): (newMapOrdering: string[]) => void {
	return useEditorSimulationSaveContext().setLastSavedMapOrdering
}
