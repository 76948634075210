import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import type { FullMap } from '../../../types'
import MapCopyModal from '../modals/MapCopyModal'
import MapsList from './MapsList'
import { actions } from '../../../setup/tiles'
import './style.css'
type Props = {
	mapIds: string[]
	simulationId: string
}
type ReduxProps = {
	postNewMap: () => Promise<{
		data: FullMap
	}>
	addMap: (arg0: FullMap) => void
	deleteMap: (arg0: string) => void
	addMapIdToSimulation: (arg0: { id: string; mapId: string }) => void
	removeMapIdFromSimulation: (arg0: { id: string; mapId: string }) => void
}
type State = {
	copyPanelOpen: boolean
}
/**
 * Displays a list of maps, as well as an add map button
 */

class MapsContainer extends Component<Props & ReduxProps, State> {
	constructor(props: Props & ReduxProps) {
		super(props)
		this.state = {
			copyPanelOpen: false,
		}
	}

	onCopyClose = () => {
		this.setState({
			copyPanelOpen: false,
		})
	}

	render(): JSX.Element {
		return (
			<div className="MapsContainer">
				<MapCopyModal
					isOpen={this.state.copyPanelOpen}
					copyTo={this.props.simulationId}
					onClose={this.onCopyClose}
				/>
				<MapsList mapIds={this.props.mapIds} deleteMap={this.deleteMap} />
				<div className="map-action-buttons-container">
					<Button onClick={this.addMap} className="map-action-button">
						Add Map
					</Button>
					<Button onClick={this.copyMap} className="map-action-button">
						Copy Map
					</Button>
				</div>
			</div>
		)
	}

	addMap = async () => {
		const { data: newMap } = await this.props.postNewMap()
		this.props.addMap(newMap)
		this.props.addMapIdToSimulation({
			id: this.props.simulationId,
			mapId: newMap._id,
		})
	}
	deleteMap = (mapId: string) => {
		this.props.deleteMap(mapId)
		this.props.removeMapIdFromSimulation({
			id: this.props.simulationId,
			mapId: mapId,
		})
	}
	copyMap = () => {
		this.setState({
			copyPanelOpen: true,
		})
	}
}

const mapDispatchToProps = {
	postNewMap: actions.maps.addMap,
	addMap: actions.maps.store.addMap,
	addMapIdToSimulation: actions.simulations.addMap,
	deleteMap: actions.maps.deleteMap,
	removeMapIdFromSimulation: actions.simulations.deleteMap,
}
export default connect(null, mapDispatchToProps)(MapsContainer)
