import React, { ReactNode, useState } from 'react'
import {
	FormGroup,
	Label as RS_Label,
	Button,
	UncontrolledTooltip,
	LabelProps as RS_LabelProps,
} from 'reactstrap'
import styled from 'styled-components'
import { BiInfoCircle } from 'react-icons/bi'

type Size = 'small'
/**
 * This component is used to wrap a form component with a label
 *
 * @param {Size} size - The size of the label. Use 'small' for nested labels
 * @param collapsible - Allows the form component to be collapsed, hiding the children when collapsed
 * @param children - The form component to be wrapped. If a function is provided, it will always be rendered and passed the isCollapsed prop.
 *                     this is useful for showing a collapsed version of the component
 * @param description - An optional description that will be accessible by hovering. Requires an id to be provided
 */

export default function FormComponentWrapper({
	label,
	component,
	id,
	children,
	size,
	className,
	collapsible = false,
	description,
}: {
	label: ReactNode
	component?: ReactNode
	id?: string
	children?: ReactNode | ((arg: { isCollapsed: boolean }) => ReactNode)
	size?: Size
	className?: string | undefined
	collapsible?: boolean
	description?: string
}): JSX.Element {
	const [isCollapsed, setIsCollapsed] = useState(collapsible)

	return (
		<FormGroup className={className}>
			<div className="d-flex gap-5 align-items-baseline">
				<Label size={size} htmlFor={id} description={description}>
					{label}
				</Label>
				{collapsible && (
					<Button onClick={() => setIsCollapsed(!isCollapsed)} size="sm">
						{isCollapsed ? 'Show' : 'Hide'}
					</Button>
				)}
			</div>
			{!isCollapsed && (
				<>
					{component}
					{typeof children !== 'function' && children}
				</>
			)}
			{typeof children === 'function' && children({ isCollapsed })}
		</FormGroup>
	)
}

/**
 * A generic label that can have a tooltip with the given `description`.
 * @param size - The size of the label
 * @param children - The content to be displayed in the label
 * @param description - The description to be displayed in the tooltip. Can only be displayed if either id or htmlFor is provided.
 * @param htmlFor - The id of an input element that this is the label for
 * @param id - The id of the element to be used for the tooltip
 *
 */
export function Label({
	size,
	children,
	description,
	id,
	htmlFor,
	...props
}: {
	children: ReactNode
	size?: Size
	description?: string
	id?: string
	htmlFor?: string
	className?: string
} & RS_LabelProps): JSX.Element {
	const uniqueId = id || htmlFor
	const descriptionId = uniqueId && `${uniqueId}-description`
	const showDescription = description && descriptionId
	return (
		<>
			<StyledLabel {...props} $size={size} htmlFor={htmlFor} id={id}>
				<span id={descriptionId}>
					{children}
					{showDescription && (
						<>
							<BiInfoCircle size={20} />
						</>
					)}
				</span>
			</StyledLabel>
			{showDescription && (
				<UncontrolledTooltip placement="top" target={descriptionId}>
					{description}
				</UncontrolledTooltip>
			)}
		</>
	)
}

export const StyledLabel = styled(RS_Label)<{ $size: Size | null | undefined }>`
display: flex;
	> span {
		display: flex;
		align-items: center;
		gap: var(--spacingHalf);
	}
	font-size: 0.9rem;
	font-weight: 900;
	${({ $size }) => ($size === 'small' ? 'font-size: 0.8rem; font-weight: 500;' : '')}})}
`
