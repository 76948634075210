import React, { type ReactNode } from 'react'

/**
 * Collapse - a wrapper which will only show the children if `isOpen` is true
 *
 * @param {boolean} props.isOpen - true if the children should be visible, false otherwise
 * @param {ReactElement} props.children - the child to show if `isOpen` is true
 *
 * @return {null | JSX.Element}
 */
export function Collapse({
	isOpen,
	children,
}: {
	isOpen: boolean
	children: ReactNode
}): null | JSX.Element {
	return <div className={isOpen ? 'visible' : 'collapse'}>{children}</div>
}
