import React from 'react'
import { FaImage } from 'react-icons/fa'
import { MenuItem } from './styles'
type Props = {
	onClick: () => void
	isSelected: boolean
}
export default function DrawMedia({ isSelected, onClick }: Props): JSX.Element {
	return (
		<MenuItem onClick={onClick}>
			<FaImage color={isSelected ? 'green' : 'blue'} />
		</MenuItem>
	)
}
