import React, { useRef, useState } from 'react'
import { Button } from 'reactstrap'
import { Card } from '../common/components'
import { VIDEO } from '../constants'
import type { Category, ActionBankItem } from '../../actionDefinitions'

type VideoCardProps = {
	category: Category
	url: string
	name: string
	type: 'VIDEO' | 'IMAGE'
	item: ActionBankItem
}
export default function MediaCard({
	category,
	url,
	type,
	name,
	item,
}: VideoCardProps): JSX.Element {
	const mediaRef = useRef(null)
	const [shouldLoad, setShouldLoad] = useState(false)
	return (
		<Card item={item} category={category}>
			{type === VIDEO ? (
				shouldLoad ? (
					<video ref={mediaRef} src={url} preload="metadata" width="100%" height="100%" controls />
				) : (
					<Button onClick={() => setShouldLoad(true)}>Click to Preview</Button>
				)
			) : (
				<img src={url} alt="Screen" css="height: 80%; width: fit-content; margin: auto;" />
			)}
			{name}
		</Card>
	)
}
