import styled from 'styled-components'
export const MenuItem = styled.div`
	font-size: 20px;
	border: 1px solid black;
	border-radius: 4px;
	padding: 4px;

	&:hover {
		cursor: pointer;
		background-color: rgba(0, 254, 25, 0.34);
	}
`
