import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Label, Input } from 'reactstrap'
import { startCase, toLower } from 'lodash'
import { ALL, COLLECTIONS_URL, SONG_CHANGE, EFFECTS } from './constants'
import { searchActionBankItems } from './common/functions'
import Spinner from './common/Spinner'
import ActionContainer from './ActionContainer'
import { type ActionBankEffectItem, getCategoryItems } from '../actionDefinitions'
import type { Directory } from '../../../common/AssetManager/AssetManager'
import type { File } from '../../../common/AssetManager/FileDetails'
const category = EFFECTS
export default function EffectsActionContainer(): JSX.Element {
	const [songs, setSongs] = useState<ActionBankEffectItem[] | null>(null)
	const [filter, setFilter] = useState(ALL)
	const [error, setError] = useState<string | null>(null)
	const [loading, setLoading] = useState(false)
	const [search, setSearch] = useState('')
	const effectItems: ActionBankEffectItem[] = getCategoryItems(category) as ActionBankEffectItem[]
	const effectFilters = effectItems.map((item) => item.type)
	const uniqueEffectFilters = [...new Set(effectFilters)]
	useEffect(() => {
		const loadCollectionData = async () => {
			setLoading(true)

			try {
				setError(null)
				const res = await axios.get(`${COLLECTIONS_URL}/songs`)
				const songData: ActionBankEffectItem[] = parseData(res.data.root, SONG_CHANGE, [])
				setSongs(songData)
			} catch (err) {
				setError(`Error loading songs: ${err}`)
			} finally {
				setLoading(false)
			}
		}

		if (!songs && !error && !loading) loadCollectionData()
	}, [songs, loading, error])
	let filteredItems: ActionBankEffectItem[] = []

	if (songs) {
		if (filter === ALL) {
			filteredItems = [...effectItems, ...songs]
		} else if (filter === SONG_CHANGE) {
			filteredItems = [...songs]
		} else {
			filteredItems = effectItems.filter((item) => item.type === filter)
		}
	} else {
		if (filter === ALL) {
			filteredItems = [...effectItems]
		} else {
			filteredItems = effectItems.filter((item) => item.type === filter)
		}
	}

	if (search.length > 0) {
		filteredItems = searchActionBankItems(search, filteredItems)
	}

	return (
		<div css="display: flex; flex-direction: column; height: 100%;">
			{loading ? (
				<Spinner />
			) : (
				<div className="w-100">
					<HeaderRow>
						<div>
							<Label className="mb-0">Filter</Label>
							<Input
								type="select"
								value={filter}
								onChange={(e) => setFilter(e.currentTarget.value)}>
								<option value={ALL}>All</option>
								<option value={SONG_CHANGE}>Song Change</option>
								{uniqueEffectFilters.map((type) => (
									<option value={type} key={type}>
										{startCase(toLower(type))}
									</option>
								))}
							</Input>
						</div>
						<div>
							<Label className="mb-0">Search</Label>
							<Input
								value={search}
								type="text"
								placeholder="Search..."
								onChange={(e) => setSearch(e.currentTarget.value)}
							/>
						</div>
					</HeaderRow>
					{error && <div>{error}</div>}
				</div>
			)}
			<div css="flex: 1;">
				<ActionContainer category={category} items={filteredItems} />
			</div>
		</div>
	)
}

function parseData(
	item: Directory | File,
	category: 'SONG_CHANGE',
	result: ActionBankEffectItem[]
): ActionBankEffectItem[] {
	if (item.type === 'file') {
		return [
			{
				type: category,
				display: item.name,
				url: item.url,
			},
		]
	}

	if (!item.contents) {
		return []
	}

	item.contents.forEach((subItem) => {
		result = result.concat(parseData(subItem, category, result))
	})
	return result
}

const HeaderRow = styled.div`
	display: grid;
	grid-template-columns: 24% 74%;
	grid-column-gap: 5px;
	padding: 0 12px 10px 7px;
	font-size: 0.85rem;
`
