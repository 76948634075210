import React from 'react'
import { FaCircle } from 'react-icons/fa'
import { MenuItem } from './styles'
type Props = {
	onClick: () => void
	isSelected: boolean
}
export default function DrawEllipse({ isSelected, onClick }: Props): JSX.Element {
	return (
		<MenuItem onClick={onClick}>
			<FaCircle color={isSelected ? 'green' : 'blue'} />
		</MenuItem>
	)
}
