import React from 'react'
import { isScreenAction } from '../../../reducers/simulationEditor'
import { getTitle } from '../actionDefinitions'
import { GiBananaPeeled, GiExplosionRays } from 'react-icons/gi'
import { MdInfoOutline } from 'react-icons/md'
import { IoIosPlay } from 'react-icons/io'
import { getTimeFormat } from '../../../helpers/functions'
import type { TriggeredActionType } from './sharedTypes'
import type { ActionMap, Action } from '@mission.io/mission-toolkit/actions'
import { IconType } from 'react-icons'

/**
 * A component that displays all actions that have been triggered as part of the Action Player
 */

export default function TriggeredActions({
	triggeredActions,
	actions,
}: {
	actions: ActionMap<string>
	triggeredActions: TriggeredActionType[]
}): JSX.Element {
	const startTime = triggeredActions[0]?.timestamp ?? Date.now()
	return (
		<div css="width: 20%; display: flex; flex-direction: column; justify-content: flex-end; border-right: 1px solid gray;">
			<TriggeredAction delay={0} showDelay={false} initial />
			{triggeredActions.map(({ id, timestamp }) => (
				<TriggeredAction
					action={id ? actions[id] : undefined}
					delay={timestamp - startTime}
					key={id}
				/>
			))}
		</div>
	)
}
/**
 * A single triggered action from the list
 */

function TriggeredAction({
	delay,
	action,
	showDelay = true,
	initial,
}: {
	delay: number
	action?: Action<string> | null | undefined
	showDelay?: boolean
	initial?: boolean
}) {
	const { text, info, Icon } = getTriggeredActionContent(action, initial)
	return (
		<div css="display: flex; flex-direction: row; align-items: baseline; padding: 4px; border-top: 1px solid gray; font-size: 18px;">
			<Icon
				color="white"
				style={{
					alignSelf: 'center',
					marginRight: '4px',
				}}
			/>
			<span>{text}</span>
			<span css="margin-left: auto;">
				{info && <MdInfoOutline color="white" css="margin-right: 8px;" title={info} />}
				{showDelay && (
					<span css="font-size: 9px; justify-self: end;">+{getTimeFormat(delay, true)}</span>
				)}
			</span>
		</div>
	)
}

/**
 * Gets content required to display a triggered action, such as the text and icon
 */
function getTriggeredActionContent(
	action: Action<string> | null | undefined,
	isInitial: boolean | null | undefined
): {
	text: string
	info?: string | null | undefined
	Icon: IconType
} {
	if (isInitial) {
		return {
			text: 'Action Player Start',
			Icon: IoIosPlay,
		}
	}

	if (!action) {
		return {
			text: 'Invalid Action 🤢',
			info: 'This should not have happened. Try refreshing',
			Icon: GiBananaPeeled,
		}
	}

	let text = getTitle(action)
	let info

	if (isScreenAction(action)) {
		text = 'Screen Change'
		info = getTitle(action)
	}

	return {
		text,
		info,
		Icon: icons[action.type] || GiBananaPeeled,
	}
}

const icons: Record<string, IconType> = {
	SPECIAL_EFFECT: GiExplosionRays,
}
