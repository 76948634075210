import React from 'react'
import { Button } from 'reactstrap'
import GuideDetail from './GuideDetail'
import styled from 'styled-components'
import type { SubHeader } from '../../../types'
import { FieldArrayFieldsProps } from 'redux-form'

export default function GuideSubHeader({
	fields,
}: {
	fields: FieldArrayFieldsProps<SubHeader>
}): JSX.Element {
	return (
		<div className="clearfix">
			<ContentWrapper>
				{fields.length > 0 && <h6 className="ml-4">Details</h6>}
				<div className="clearfix mb-2">
					{fields.map((subHeader, index) => (
						<GuideDetail
							key={index}
							detail={fields.get(index)}
							selectionChange={(newSubheader: SubHeader) => {
								fields.remove(index)
								fields.insert(index, newSubheader)
							}}
							subHeaderName={subHeader}
							remove={() => fields.remove(index)}
						/>
					))}
				</div>
				<Button
					className="float-right"
					size="sm"
					color="primary"
					onClick={() => {
						fields.push({
							type: 'QUESTION',
							text: '',
						})
					}}>
					Add Detail
				</Button>
			</ContentWrapper>
		</div>
	)
}
const ContentWrapper = styled.div`
	width: 92%;
	float: right;
`
