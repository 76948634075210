import { findEntry } from '../../../../helpers/functions'
const PIXEL_DEVIATION_TO_ALLOW_ON_START_END = 8

/**
 * Given a position x on the application window and refs to all the 'screen' elements in the action editor,
 * determines the screen which x follows under, then returns the value x relative to that screen, and the screens width
 * @param {number} x
 * @param {{ [screenId: string]: ?HTMLDivElement }} screenElementMap
 * @return {?[string, number, number]} [screenId, relativeXPosition, screenWidth]
 */
export function deriveScreenAndRelativePositionBasedOnX(
	x: number,
	screenElementMap: Record<string, HTMLDivElement | null | undefined>
): [string, number, number] | null | undefined {
	let screenId = null
	const screen = findEntry(screenElementMap, (screenElement, id) => {
		if (x && screenElement) {
			if (isXWithinScreen(x, screenElement)) {
				screenId = id
				return true
			}
		}

		return false
	})

	if (screen && screenId) {
		return [screenId, x - screen.getBoundingClientRect().left, screen.getBoundingClientRect().width]
	}

	return null
}

/**
 * Return whether x is within the given element's bounding rect x plain.
 * @param {number} x
 * @param {HTMLDivElement} screenElement
 */
export function isXWithinScreen(x: number, screenElement: HTMLDivElement): boolean {
	const { left: refX, width } = screenElement.getBoundingClientRect()
	return isValueWithinBounds(x, [refX, refX + width])
}

function isValueWithinBounds(x: number, bounds: [number, number]) {
	return bounds[0] < x && x < bounds[1]
}

/**
 * Given value timeMs relative to the screen, determines
 * if the time is close enough to the element boundaries in order to set the
 * onEnd or onStart values for the given screen. This function is used by the Drag Layer to
 * display special UI and it is also used by the ActionCanvas for updating the state
 * @param {number} x
 * @param {number} pixelsPerMs
 * @param {?HTMLDivElement} screenElement
 */
export function getDropFeature(
	pixelsIntoScreen: number,
	screenElement: HTMLDivElement | null | undefined
): ('onEnd' | 'onStart') | null | undefined {
	if (!screenElement) return
	const rect = screenElement.getBoundingClientRect()
	const screenLength = rect.width

	if (screenLength - pixelsIntoScreen <= PIXEL_DEVIATION_TO_ALLOW_ON_START_END) {
		return 'onEnd'
	} else if (pixelsIntoScreen <= PIXEL_DEVIATION_TO_ALLOW_ON_START_END) {
		return 'onStart'
	}
}
