import React, { useState } from 'react'
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row,
	Col,
	FormGroup,
	Label,
	Input,
} from 'reactstrap'
import styled from 'styled-components'
import type { Card as CardType, ClassQuestion, Option, TriggerType, Trigger } from '../../types'
type ClassQuestionModalProps = {
	initialValues: ClassQuestion | null | undefined
	closeModal: () => unknown
	preCardIndex: number
	cards: CardType[]
	onSave: (arg0: { phrase: string; options: Option[] }) => void
}
const CARD_CHANGE = 'CARD_CHANGE'
export default function ClassQuestionModal({
	closeModal,
	initialValues,
	cards,
	preCardIndex,
	onSave,
}: ClassQuestionModalProps): JSX.Element {
	const [phrase, setPhrase] = useState(initialValues ? initialValues.phrase : '')
	const [options, setOptions] = useState(
		initialValues
			? initialValues.options
			: cards && cards.length > 1
			? [
					{
						correct: false,
						text: '',
						trigger: {
							type: CARD_CHANGE,
							cardId: cards[0]._id,
						},
					},
					{
						correct: false,
						text: '',
						trigger: {
							type: CARD_CHANGE,
							cardId: cards[1]._id,
						},
					},
			  ]
			: cards && cards.length > 0
			? [
					{
						correct: false,
						text: '',
						trigger: {
							type: CARD_CHANGE,
							cardId: cards[0]._id,
						},
					},
			  ]
			: []
	)

	if (initialValues) {
		preCardIndex = cards.findIndex((card) => card._id === initialValues.previousCardId)
	}

	const setOptionCorrectAtIndex = (correct: boolean, index: number) => {
		// @ts-expect-error ignoring errors for old simulation code
		const newOption: Option = { ...options[index], correct }
		setOptions([...options.slice(0, index), newOption, ...options.slice(index + 1)])
	}

	const setOptionTextAtIndex = (text: string, index: number) => {
		// @ts-expect-error ignoring errors for old simulation code
		const newOption: Option = { ...options[index], text }
		setOptions([...options.slice(0, index), newOption, ...options.slice(index + 1)])
	}

	const setOptionTypeAtIndex = (type: TriggerType, index: number) => {
		// @ts-expect-error We are making a valid Trigger
		const trigger: Trigger =
			type === CARD_CHANGE
				? {
						type,
						cardId: cards[0]._id,
				  }
				: {
						type,
				  }
		const newOption: Option = { ...options[index], trigger }
		setOptions([...options.slice(0, index), newOption, ...options.slice(index + 1)])
	}

	const setOptionCardIdAtIndex = (cardId: string, index: number) => {
		// @ts-expect-error ignoring errors for old simulation code
		const oldOption: Option = options[index]
		const newOption: Option = { ...oldOption, trigger: { ...oldOption.trigger, cardId } }
		setOptions([...options.slice(0, index), newOption, ...options.slice(index + 1)])
	}

	const deleteOptionAtIndex = (index: number) => {
		setOptions([...options.slice(0, index), ...options.slice(index + 1)])
	}

	return (
		<Modal isOpen={true} toggle={closeModal} centered size="lg">
			<ModalHeader toggle={closeModal}>
				Class Question after card {preCardIndex + 1}, “{cards[preCardIndex].name}”
			</ModalHeader>
			<ModalBody>
				<Col>
					<FormGroup row>
						<Label for="class-question-phrase" className="font-weight-bold">
							Question
						</Label>
						<Input
							id="class-question-phrase"
							type="text"
							value={phrase}
							onChange={(e) => setPhrase(e.currentTarget.value)}
						/>
					</FormGroup>
					<Row className="align-items-center">
						<span className="mr-2 font-weight-bold">Options</span>
					</Row>
					{options.map((option, i) => {
						const trigger = option.trigger
						return (
							<Row key={i}>
								<FormGroup className="mr-2">
									<Label for={`class-question-option-text-${i}`}>Text</Label>
									<Input
										id={`class-question-option-text-${i}`}
										type="text"
										placeholder={`Option ${i + 1}`}
										value={option.text}
										onChange={(e) => setOptionTextAtIndex(e.currentTarget.value, i)}
									/>
								</FormGroup>
								<FormGroup className="mr-2">
									<Label for={`class-question-option-triggerType-${i}`}>Result</Label>
									<Input
										id={`class-question-option-triggerType-${i}`}
										type="select"
										value={option.trigger.type}
										onChange={(e) =>
											setOptionTypeAtIndex(e.currentTarget.value as 'CARD_CHANGE', i)
										}>
										<option value={CARD_CHANGE}>Change Card</option>
									</Input>
								</FormGroup>
								{trigger.type === CARD_CHANGE && (
									<FormGroup className="mr-2">
										<Label for={`class-question-option-cardId-${i}`}>Card to jump to</Label>
										<Input
											id={`class-question-option-cardId-${i}`}
											type="select"
											value={trigger.cardId}
											onChange={(e) => setOptionCardIdAtIndex(e.currentTarget.value, i)}>
											{cards.map((card, j) => (
												<option key={card._id} value={card._id}>
													{card.name} ({j + 1})
												</option>
											))}
										</Input>
									</FormGroup>
								)}
								<FormGroup className="mr-2">
									<Label for={`class-question-option-correct-${i}`}>Correct</Label>
									<CorrectCheckbox
										id={`class-question-option-correct-${i}`}
										className="form-control"
										type="checkbox"
										checked={option.correct}
										onChange={() => setOptionCorrectAtIndex(!option.correct, i)}
									/>
								</FormGroup>
								<div className="align-self-center pt-3">
									<Button color="danger" onClick={() => deleteOptionAtIndex(i)}>
										X
									</Button>
								</div>
							</Row>
						)
					})}
					<Row>
						<Button
							onClick={() => {
								setOptions((options) => [
									...options,
									{
										correct: false,
										text: '',
										trigger: {
											type: CARD_CHANGE,
											cardId: cards[0] ? cards[0]._id || '' : '',
										},
									},
								])
							}}>
							Add Option
						</Button>
					</Row>
				</Col>
			</ModalBody>
			<ModalFooter>
				<Button
					color="primary"
					onClick={() =>
						onSave({
							phrase,
							// @ts-expect-error ignoring errors for old simulation code
							options,
						})
					}>
					Save
				</Button>
				<Button color="secondary" onClick={closeModal}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	)
}
const CorrectCheckbox = styled(Input)`
	margin: 0;
	width: 40%;
`
