import React, { ReactNode } from 'react'
import { toast as _toast } from 'react-toastify'
import SimpleConfirmation from '../common/SimpleConfirmation'
import OptionMessage from '../common/OptionMessage'
import { ButtonProps } from 'reactstrap'
export const toast = {
	error(mainMessage: ReactNode, detailMessage?: string | null | undefined) {
		let message = mainMessage

		if (detailMessage && typeof message === 'string') {
			message += `\n\nMessage: ${detailMessage}`
		}

		_toast.error(message, {
			autoClose: false,
		})
	},

	success(mainMessage: string) {
		_toast.success(`${mainMessage} 🎉`)
	},
}

/**
 * Provides a toast with confirmation message and runs an onConfirm event.
 * @param {string} message
 * @param {() => void} onConfirm
 */
export function runWithConfirmationMessage(
	message: string,
	onConfirm: () => unknown,
	confirmText = 'Yes',
	intensity: 'ERROR' | 'INFO' | 'WARN' = 'ERROR'
) {
	const toastFn = intensity === 'INFO' ? 'info' : intensity === 'WARN' ? 'warn' : 'error'

	const toastId = _toast[toastFn](
		<SimpleConfirmation
			secondButton={true}
			onCancel={() => _toast.dismiss(toastId)}
			message={message}
			confirmText={confirmText}
			onConfirm={() => {
				onConfirm()

				_toast.dismiss(toastId)
			}}
		/>,
		{
			position: _toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		}
	)
}

/**
 * Opens a toast with the given options as buttons letting the user select an option. Adds 'Cancel' to the list of buttons
 * to close the toast.
 */
export function openOptionSelector(message: string, options: Array<[string, () => unknown]>) {
	const toastId = _toast.warn(
		<OptionMessage
			message={message}
			buttons={[
				...options.map(([message, func]): [string, ButtonProps] => [
					message,
					{
						onClick: func,
						color: 'primary',
					},
				]),
				[
					'Cancel',
					{
						onClick: () => _toast.dismiss(toastId),
						color: 'secondary',
					},
				],
			]}
		/>,
		{
			position: _toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
		}
	)
}
