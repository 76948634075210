// This module exports a map of javascript keycodes to JavaFX keycodes.
// **WHILE LAUNCHPAD IS STILL IN JAVAFX** the values for key bindings need to match the enums from the JavaFX KeyCode class (https://docs.oracle.com/javafx/2/api/javafx/scene/input/KeyCode.html).
// For letters in the alphabet, values in the map are the uppercase forms of the letter ('A', 'B', 'C', etc).
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
// The codes of those keys whose `code`, after uppercasing, is the same as the corresponding javafx KeyCode enum
const matchingCodes = [
	'Digit1',
	'Digit2',
	'Digit3',
	'Digit4',
	'Digit5',
	'Digit6',
	'Digit7',
	'Digit8',
	'Digit9',
	'Digit0',
	'Comma',
	'Period',
	'Slash',
	'Semicolon',
	'Quote',
	'Minus',
	'Space',
]
const codesToKeyCodes: Record<string, string> = {}
alphabet.split('').forEach((char, i) => {
	codesToKeyCodes['Key' + char] = char
})
matchingCodes.forEach((code) => {
	codesToKeyCodes[code] = code.toUpperCase()
})
export default codesToKeyCodes
