import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useEditorSimulation } from '../../reducers/simulationEditor'
import PhaseCreator from './PhaseCreator'
import { PhaseContext } from './PhaseContext'
import PhaseList from './PhaseList'
import type { Action as CanvasAction, ScreensList, DurationMap } from './PhaseCreator/types'
import type { AutomatedSimulation } from '../../types/AutomatedSimulation'
import { useCancellablePromise } from '../../helpers/hooks'
import {
	getScreenList,
	getGuaranteedOtherActionsForScreens,
	buildDurationMap,
	getHighLevelSimulationInfo,
} from './helpers/algorithms'
import { getTimeFormat } from '../../helpers/functions'
import type { FormConfig } from './FormContext'
export default function AutomatedSimulationEditor({
	selectedForm,
}: {
	selectedForm?: FormConfig | null | undefined
}): JSX.Element {
	// The simulation from the simulationEditor store
	const simulation: AutomatedSimulation | null | undefined = useEditorSimulation()
	const cancellablePromise = useCancellablePromise<DurationMap>()
	const [currentPhase, setCurrentPhase] = useState<string | null | undefined>()
	const [durationMap, setDurationMap] = useState<DurationMap | null | undefined>(null)
	const durationMapRef = useRef(durationMap)
	const actions = simulation?.actions
	useEffect(() => {
		if (actions) {
			setDurationMap((map) => (map ? { ...map, loading: true } : null))
			cancellablePromise(buildDurationMap(actions, durationMapRef.current))
				.then((map) => {
					setDurationMap(map)
					durationMapRef.current = map
				})
				.catch()
		}
	}, [actions, cancellablePromise])
	const screensList: ScreensList = useMemo(() => {
		return simulation && currentPhase ? getScreenList(simulation, currentPhase) : []
	}, [simulation, currentPhase])
	// Actions that are not screen actions, formatted in the way that the Action Canvas, in the Phase Creator expects
	const canvasActions: CanvasAction[] = useMemo(() => {
		return simulation && durationMap && screensList.length > 0
			? getGuaranteedOtherActionsForScreens(simulation, screensList, durationMap)
			: []
	}, [simulation, durationMap, screensList])
	const { phases, estimatedSimulationLength } = getHighLevelSimulationInfo(simulation, durationMap)
	// If the current phase is removed, switch to a default phase
	useEffect(() => {
		if (phases && !phases.some((phase) => phase.screenId === currentPhase)) {
			setCurrentPhase(phases[0]?.screenId || simulation?.initialScreenActionId)
		} else if (!phases && currentPhase) {
			setCurrentPhase(null)
		}
	}, [phases, currentPhase, simulation])

	if (!simulation) {
		return <div>Waiting...</div>
	}

	return (
		<div>
			<PhaseContext.Provider
				value={{
					currentPhase,
					setCurrentPhase,
					phases,
				}}>
				{simulation.initialScreenActionId && (
					<div css="display: flex; justify-content: space-between; align-items: center; padding: 12px;">
						<PhaseList />
						<span css="margin-left: 15vw;">
							Total Estimated Mission Time (minutes):{' '}
							{getTimeFormat(estimatedSimulationLength, false)}
						</span>
					</div>
				)}
				<PhaseCreator
					screensList={screensList}
					actions={canvasActions}
					durationMap={durationMap}
					selectedForm={selectedForm}
				/>
			</PhaseContext.Provider>
		</div>
	)
}
