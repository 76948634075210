import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import MoverEditor from './MoverEditor'
import type { Mover } from '../../../types/Simulation'
import { FieldArrayFieldsProps } from 'redux-form'
type Props = {
	fields: FieldArrayFieldsProps<Mover>
	cardVideoUrl: string
}
type State = {
	moverEditorOpen: boolean
	selectedMoverId: string | null | undefined
}
export default class MoversEditor extends Component<Props, State> {
	state: State = {
		moverEditorOpen: false,
		selectedMoverId: null,
	}

	render(): JSX.Element {
		const movers = this.props.fields.getAll()
		const selectedMover: Mover | null | undefined = this.state.selectedMoverId
			? movers.find((mover) => mover._id === this.state.selectedMoverId)
			: null
		return (
			<div className="MoversEditor">
				<MoversList
					movers={movers}
					onSelectMover={(selectedMoverId) =>
						this.setState({
							selectedMoverId,
							moverEditorOpen: true,
						})
					}
					onDeleteMover={(index) => this.props.fields.remove(index)}
				/>
				<Button
					onClick={() =>
						this.setState({
							moverEditorOpen: true,
						})
					}>
					Add Mover
				</Button>
				<MoverEditor
					isOpen={this.state.moverEditorOpen}
					mover={selectedMover}
					onSave={this.onSaveMover}
					onCancel={this.closeMoverEditor}
					cardVideoUrl={this.props.cardVideoUrl}
				/>
			</div>
		)
	}

	onSaveMover: (mover: Mover) => void = (mover: Mover) => {
		if (!this.state.selectedMoverId) {
			this.addMover(mover)
			this.closeMoverEditor()
			return
		}

		const { fields } = this.props
		const moverIndex = fields
			.getAll()
			.findIndex((mover) => mover._id === this.state.selectedMoverId)

		if (moverIndex < 0 || moverIndex > fields.getAll().length) {
			toast.error('Something went wrong. Please refresh and try again', {
				autoClose: false,
			})
			return
		}

		fields.remove(moverIndex)
		fields.insert(moverIndex, mover)
		this.closeMoverEditor()
	}
	closeMoverEditor: () => unknown = () => {
		this.setState({
			selectedMoverId: null,
			moverEditorOpen: false,
		})
	}
	addMover: (mover: Mover) => void = (mover: Mover) => {
		this.props.fields.push(mover)
	}
}
type MoversListProps = {
	movers: Array<Mover>
	onSelectMover: (moverId: string) => void
	onDeleteMover: (index: number) => unknown
}

const MoversList = ({ movers, onSelectMover, onDeleteMover }: MoversListProps): JSX.Element => {
	return (
		<>
			{movers.length !== 0 && (
				<StyledMoversList>
					{movers.map((mover, i) => {
						return (
							<StyledListItem
								key={mover._id || mover.xmlId}
								onClick={() => onSelectMover(mover._id)}
								className="d-flex justify-content-between">
								<div>
									<img src={mover.mediaUrl} height={50} alt={`Icon for ${mover.name}`} />
									{mover.name}
								</div>
								<Button
									color="danger"
									size="sm"
									onClick={(e) => {
										e.stopPropagation()
										onDeleteMover(i)
									}}>
									<FontAwesome name="trash" />
								</Button>
							</StyledListItem>
						)
					})}
				</StyledMoversList>
			)}
		</>
	)
}

const StyledMoversList = styled.ul`
	list-style-type: none;
	padding-left: 0;
`
const StyledListItem = styled.li`
	cursor: pointer;
	border: 1px solid gray;
	padding: 4px;

	&:hover {
		background-color: lightgray;
	}
`
