import type { Standard } from '../../types/AutomatedSimulation'

import React, { ReactNode } from 'react'
import { Button } from 'reactstrap'
import FontAwesome from 'react-fontawesome'
import Markdown from '../../common/Markdown'
import { Link } from 'react-router-dom'

/**
 * Displays information for a single standard.
 */
export function StandardDisplay({
	standard,
	onDelete,
}: {
	standard: Standard
	onDelete?: () => unknown
}): JSX.Element {
	return (
		<div className="bg-light rounded shadow p-3 mb-3">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex gap-2">
					<h5>
						{standard.standardSet.abbreviation} {standard.name || 'Unnamed Standard'}
					</h5>
					<Link to={`/standards#${standard._id}`}>edit</Link>
				</div>
				{onDelete && (
					<Button color="danger" size="sm" onClick={() => onDelete()}>
						<FontAwesome name="trash" />
					</Button>
				)}
			</div>
			<LabeledField label="Summary" value={standard.summary} />
			<div className="d-flex gap-4">
				<LabeledField label="Keyword" value={standard.keyword} />
				<LabeledField label="Grade" value={standard.grade} />
				<LabeledField label="URL" value={standard.url} />
			</div>
			<LabeledField
				label="Full Text"
				value={
					<div>
						<Markdown>{standard.full || 'Not Set'}</Markdown>
					</div>
				}
			/>
		</div>
	)
}

/**
 * Displays the bolded label and the value.
 */
function LabeledField({ label, value }: { label: string; value: ReactNode }): JSX.Element {
	return (
		<span>
			<b>{label}:</b> {value || 'Not Set'}
		</span>
	)
}
