export const CAMERA_WIDTH = 1920
export const CAMERA_HEIGHT = 1080
// what types of worldComponents are there
export enum PRIMITIVE_TYPES {
	RECTANGLE = 'RECTANGLE',
	ELLIPSE = 'ELLIPSE',
	MEDIA = 'MEDIA',
	ENTITY = 'ENTITY',
	CAMERA = 'CAMERA',
	WORLD = 'WORLD',
}
// customized property editors for specific types of data
export const PROPERTIES_EDITOR = {
	IGNORE: 'IGNORE',
	COLOR: 'COLOR',
	PROPORTION: 'PROPORTION',
	ENTITY: 'ENTITY',
	COLLISION: 'COLLISION',
	CONTROL: 'CONTROL',
	FOLLOW: 'FOLLOW',
	HEALTH: 'HEALTH',
	MEDIA: 'MEDIA',
	ROTATION: 'ROTATION',
	INITIAL_VELOCITY: 'INITIAL_VELOCITY',
}
export const COLLISION_TYPES = {
	PLAYER: 'PLAYER',
	// element is the Player
	SOLID: 'SOLID',
	// running into this will stop the player
	SOLID_DAMAGE: 'SOLID_DAMAGE',
	// running into this will stop the player and damage the player
	PASSIVE: 'PASSIVE',
	// player can phase through this, but a collision with this object will cause an event to be emitted
	PASSIVE_DAMAGE: 'PASSIVE_DAMAGE',
	// player can phase through this, but will damage the player a certain amount per second
	SUCCESS: 'SUCCESS',
	// will cause the onSuccess event to be emitted, ending the navigation map
	NONE: 'NONE', // this object has no collision
}
// a map of collision types to their description
export const COLLISION_DAMAGE: Record<string, string> = {
	[COLLISION_TYPES.PLAYER]: 'Damage Multiplier',
	[COLLISION_TYPES.SOLID_DAMAGE]: 'Damage Amount',
	[COLLISION_TYPES.PASSIVE_DAMAGE]: 'DPS',
	[COLLISION_TYPES.PASSIVE]: '',
	[COLLISION_TYPES.NONE]: '',
	[COLLISION_TYPES.SOLID]: '',
	[COLLISION_TYPES.SUCCESS]: '',
}
// what can control the component during run time
export const CONTROL_TYPES = {
	CAMERA_DISPLACEMENT: 'CAMERA_DISPLACEMENT',
	// base the position off of the displacement of the camera from the starting location
	THRUSTERS: 'THRUSTERS',
	PHYSICS: 'PHYSICS',
	NONE: 'NONE',
}
export const HEALTH_LOCATIONS = {
	TOP: 'TOP',
	// display health bar on top of the entity
	LEFT: 'LEFT',
	// display health bar to the left of the entity
	RIGHT: 'RIGHT',
	// display health bar to the right of the entity
	BOTTOM: 'BOTTOM',
	// display health bar at the bottom of the entity
	SCREEN: 'SCREEN',
	// display health bar on the HUD on the main screen
	HIDDEN: 'HIDDEN', // do not display the health
}
export const DEFAULT_COLOR = '#fc7303'
export const DEFAULT_CAMERA_COLOR = '#052cd1'
export const DEFAULT_URL =
	'https://resources-cdn.mission.io/simulations/card-thumbnails/scienceMissions/9thGrade/choke/rotatingEmblem.png'
export const DEFAULT_COLLISION = {
	type: COLLISION_TYPES.NONE,
	damageScale: 1,
}
export const DEFAULT_CONTROL = {
	by: CONTROL_TYPES.NONE,
	scale: 1,
}
export const DEFAULT_HEALTH = {
	useHealth: false,
	isShipHealth: false,
	startingAmount: 1,
	show: HEALTH_LOCATIONS.HIDDEN,
}
export const DEFAULT_INITIAL_VELOCITY = {
	x: 0,
	y: 0,
	r: 0,
}
export const DEFAULT_ROTATION = 0
export const DEFAULT_MASS = 1
export enum Handles {
	RIGHT = 'RIGHT',
	LEFT = 'LEFT',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM',
}
export enum EditorType {
	ENTITY = 'ENTITY',
	WORLD = 'WORLD',
}
// what the default props for each worldComponent type is
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DEFAULT_DRAWING_DATA: Record<string, Record<string, any>> = {
	[EditorType.ENTITY]: {
		[PRIMITIVE_TYPES.RECTANGLE]: {
			collision: false,
			color: DEFAULT_COLOR,
		},
		[PRIMITIVE_TYPES.ELLIPSE]: {
			collision: false,
			color: DEFAULT_COLOR,
		},
		[PRIMITIVE_TYPES.MEDIA]: {
			url: DEFAULT_URL,
		},
		[PRIMITIVE_TYPES.ENTITY]: {
			entity: '',
		},
		[PRIMITIVE_TYPES.CAMERA]: {},
		[PRIMITIVE_TYPES.WORLD]: {},
	},
	[EditorType.WORLD]: {
		[PRIMITIVE_TYPES.RECTANGLE]: {
			color: DEFAULT_COLOR,
			collision: DEFAULT_COLLISION,
			control: DEFAULT_CONTROL,
			rotation: DEFAULT_ROTATION,
			mass: DEFAULT_MASS,
			initialVelocity: DEFAULT_INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.ELLIPSE]: {
			color: DEFAULT_COLOR,
			collision: DEFAULT_COLLISION,
			control: DEFAULT_CONTROL,
			rotation: DEFAULT_ROTATION,
			mass: DEFAULT_MASS,
			initialVelocity: DEFAULT_INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.MEDIA]: {
			url: DEFAULT_URL,
			collision: DEFAULT_COLLISION,
			control: DEFAULT_CONTROL,
			rotation: DEFAULT_ROTATION,
			mass: DEFAULT_MASS,
			initialVelocity: DEFAULT_INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.ENTITY]: {
			entity: '',
			collision: DEFAULT_COLLISION,
			control: DEFAULT_CONTROL,
			health: DEFAULT_HEALTH,
			rotation: DEFAULT_ROTATION,
			mass: DEFAULT_MASS,
			initialVelocity: DEFAULT_INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.CAMERA]: {
			color: DEFAULT_CAMERA_COLOR,
			collision: DEFAULT_COLLISION,
			control: DEFAULT_CONTROL,
			follow: '',
			rotation: DEFAULT_ROTATION,
			mass: DEFAULT_MASS,
			initialVelocity: DEFAULT_INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.WORLD]: {},
	},
}
// customize what key in an object leads to what type of property editor
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PRIMITIVE_PROPERTY_OVERRIDES: Record<string, Record<string, any>> = {
	[EditorType.ENTITY]: {
		[PRIMITIVE_TYPES.RECTANGLE]: {
			color: PROPERTIES_EDITOR.COLOR,
		},
		[PRIMITIVE_TYPES.ELLIPSE]: {
			color: PROPERTIES_EDITOR.COLOR,
		},
		[PRIMITIVE_TYPES.MEDIA]: {
			url: PROPERTIES_EDITOR.MEDIA,
		},
		[PRIMITIVE_TYPES.ENTITY]: {
			entity: PROPERTIES_EDITOR.ENTITY,
		},
		[PRIMITIVE_TYPES.CAMERA]: {},
		[PRIMITIVE_TYPES.WORLD]: {},
		DEFAULT: {},
	},
	[EditorType.WORLD]: {
		[PRIMITIVE_TYPES.RECTANGLE]: {
			color: PROPERTIES_EDITOR.COLOR,
			collision: PROPERTIES_EDITOR.COLLISION,
			control: PROPERTIES_EDITOR.CONTROL,
			rotation: PROPERTIES_EDITOR.ROTATION,
			initialVelocity: PROPERTIES_EDITOR.INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.ELLIPSE]: {
			color: PROPERTIES_EDITOR.COLOR,
			collision: PROPERTIES_EDITOR.COLLISION,
			control: PROPERTIES_EDITOR.CONTROL,
			rotation: PROPERTIES_EDITOR.ROTATION,
			initialVelocity: PROPERTIES_EDITOR.INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.MEDIA]: {
			control: PROPERTIES_EDITOR.CONTROL,
			collision: PROPERTIES_EDITOR.IGNORE,
			url: PROPERTIES_EDITOR.MEDIA,
			rotation: PROPERTIES_EDITOR.ROTATION,
			mass: PROPERTIES_EDITOR.IGNORE,
			initialVelocity: PROPERTIES_EDITOR.INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.ENTITY]: {
			entity: PROPERTIES_EDITOR.ENTITY,
			collision: PROPERTIES_EDITOR.COLLISION,
			control: PROPERTIES_EDITOR.CONTROL,
			health: PROPERTIES_EDITOR.HEALTH,
			rotation: PROPERTIES_EDITOR.ROTATION,
			initialVelocity: PROPERTIES_EDITOR.INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.CAMERA]: {
			color: PROPERTIES_EDITOR.COLOR,
			control: PROPERTIES_EDITOR.CONTROL,
			follow: PROPERTIES_EDITOR.FOLLOW,
			collision: PROPERTIES_EDITOR.IGNORE,
			width: PROPERTIES_EDITOR.IGNORE,
			height: PROPERTIES_EDITOR.IGNORE,
			rotation: PROPERTIES_EDITOR.IGNORE,
			mass: PROPERTIES_EDITOR.IGNORE,
			initialVelocity: PROPERTIES_EDITOR.INITIAL_VELOCITY,
		},
		[PRIMITIVE_TYPES.WORLD]: {},
		DEFAULT: {
			rotation: PROPERTIES_EDITOR.ROTATION,
		},
	},
}
// the handle size in svg coordinate units
export const HANDLE_SIZE = 10
// the allowed file types allowed for the media worldComponent
export const MEDIA_FILE_TYPES = [
	'png',
	'jpg',
	'jpeg',
	'jfif',
	'pjpeg',
	'pjp',
	'gif',
	'apng',
	'bmp',
	'webp',
	'mp4',
	'webm',
	'm4v',
]
export const VIDEO_WARNING =
	'Chrome does not render videos on the correct layer, please use firefox if using videos'
export const GIF_WARNING =
	'DO NOT USE GIF, they are too expensive to render. Please use a video instead.'
export const SERVER_UPDATE_TIME = 300 // server should update navigation map every 300 milliseconds (taken from mission-server batch updated time)

export const BEFORE_UNLOAD_MESSAGE = 'Are you sure? You will lose any unsaved data.'
