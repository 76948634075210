import type { Action, EventResult } from '@mission.io/mission-toolkit/actions'
import { SHARED_STATION_IDS } from '@mission.io/mission-toolkit/constants'
import {
	CommunicationsIcon as CommunicationIcon,
	DefenseIcon,
	PowerIcon,
	SensorsIcon as ScanningIcon,
	TractorBeamIcon,
	TransporterIcon,
	RepairsIcon,
} from '../../../images/launchpadGuides/jrStations'
import type { ActionPlayerState, PAUSE_STATE } from '../ActionPlayer/ActionPlayer'
import type { ActionId } from '../actionDefinitions'
export type TriggeredActionType = {
	id: ActionId | null | undefined
	timestamp: number
}
export type ActionPlayerAction =
	| {
			type: 'SET_PAUSE'
			payload: {
				nextPauseState: PAUSE_STATE
			}
	  }
	| {
			type: 'REVIVE'
			payload: {
				previousState: ActionPlayerState
			}
	  }
	| {
			type: 'CHEAT_DEATH'
	  }
	| {
			type: 'PLAY_ACTION'
			payload: {
				action: Action<string>
			}
	  }
	| {
			type: 'END_VOCAL_TRACK'
			payload: ActionId
	  }
	| {
			type: 'END_SPECIAL_EFFECT'
			payload: ActionId
	  }
	| {
			type: 'END_OVERLAY'
			payload: ActionId
	  }
	| {
			type: 'STATION_COMPLETED'
			payload: {
				stationId: keyof typeof STATION_ICONS
			}
	  }
	| {
			type: 'FINISH_MISSION_PAUSE'
	  }
	| {
			type: 'DISMISS_LITERACY_EVENT'
			payload: ActionId
	  }

export const STATION_ICONS = {
	DEFENSE: DefenseIcon,
	DEFENSE_PLUS: DefenseIcon,
	TRANSPORTER: TransporterIcon,
	POWER: PowerIcon,
	TRACTOR_BEAM: TractorBeamIcon,
	TRACTOR_BEAM_PLUS: TractorBeamIcon,
	COMMUNICATION: CommunicationIcon,
	SCANNING: ScanningIcon,
	REPAIRS: RepairsIcon,
	// TODO: use the correct icon for this
	[SHARED_STATION_IDS.CREATIVE_CANVAS]: RepairsIcon,
}

export type ActiveStations = {
	[iconType in keyof typeof STATION_ICONS]?: { onComplete?: EventResult<string> }
}
