import React, { useState, useEffect } from 'react'
import { useEntities } from './hooks'
import Preview from './Preview'
import { getNeededEntities } from '@mission.io/navigation-map-server'
import type { NavigationMap } from '@mission.io/navigation-map-server'

type Props = {
	map: NavigationMap | null | undefined
	allowDebugging: boolean
}
export default function QuickPreview({ map, allowDebugging }: Props): JSX.Element {
	const [entities, setEntities] = useState<string[] | null | undefined>(null)
	const loadedEntities = useEntities(entities)
	useEffect(() => {
		if (!map) {
			setEntities(null)
			return
		}

		setEntities(getNeededEntities(map))
	}, [map])

	if (!map) {
		return <h2>Loading NavigationMap...</h2>
	}

	if (!loadedEntities) {
		return <h2>Loading NavigationMap Entities...</h2>
	}

	return <Preview navigationMap={map} entities={loadedEntities} />
}
