import { createContext } from 'react'
import type { OnSelect } from './types'

const noop = () => {
	// noop
}

const initialValue: {
	onSelect: OnSelect
	selected: string | null | undefined
	showVideoWarning: () => void
	showGifWarning: () => void
	rotation?: number
	rotateAround?: {
		x: number
		y: number
	}
} = {
	onSelect: noop,
	selected: null,
	showVideoWarning: noop,
	showGifWarning: noop,
}

const EditorContext = createContext(initialValue)
export default EditorContext
