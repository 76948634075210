import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import FontAwesome from 'react-fontawesome'
import Upload from 'rc-upload'
import { Input } from 'reactstrap'
import { isEqual } from 'lodash'
import { toast } from 'react-toastify'
import { produce } from 'immer'

import FolderSelector from './FolderSelector'
import FolderBreadcrumbs from './FolderBreadcrumbs'
import FileDetails from './FileDetails'
import Uploads from './Uploads'
import { values } from '../../helpers/functions'
import type { SimpleFolder } from './FolderSelector'
import type { File } from './FileDetails'
import type { Upload as UploadStatus } from './Uploads'
import styles from './AssetManager.module.css'
import type { FileRestriction } from '../../features/automatedSimulations/helpers/algorithms'
import { config } from '../../config'

const COLLECTIONS_URL = `${config.simulationsApiUrl}/api/collections`

export type Directory = {
	type: 'directory'
	name: string
	path: string
	contents: Array<Directory | File>
}
// if you are adding to this, make sure you add to it to collections.js in simAPI as well
export type Collection =
	| 'videos'
	| 'movers'
	| 'expositions'
	| 'songs'
	| 'cardThumbnails'
	| 'automatedSimulationMedia' // Maps collections
	| 'mapVideos'
	| 'mapAudio'
	| 'mapImages'
	| 'backgroundImages'
	| 'mapIcons' // Other collections
	| 'characterImages'
	| 'stationImages'
	| 'navigationMaps'
	| 'teamIcons'
	| 'creativeCanvas'

type CollectionData = {
	root: Directory
}

export default function AssetManager({
	collection,
	restrictions,
	onFileClick,
}: {
	collection: Collection
	restrictions?: FileRestriction
	onFileClick?: (file: File) => void
}): ReactNode {
	const uploadRef = useRef<Upload>(null)
	const [crumbs, setCrumbs] = useState<Array<string>>([])
	const [isLoading, setIsLoading] = useState(false)
	const [loadingError, setLoadingError] = useState<null | string>(null)
	const [collectionData, setCollectionData] = useState<null | CollectionData>(null)
	const [search, setSearch] = useState('')
	const [showUploads, setShowUploads] = useState(false)
	const [uploads, setUploads] = useState<{ [uid: string]: UploadStatus }>({})

	const loadCollectionData = useCallback(async () => {
		setIsLoading(true)

		try {
			const res = await axios.get(`${COLLECTIONS_URL}/${collection}`)

			setIsLoading(false)
			setCollectionData(res.data)

			if (crumbs.length === 0) {
				setCrumbs([res.data.root.name])
			}
		} catch (err) {
			setIsLoading(false)
			setLoadingError('Unable to load collection')
		}
	}, [collection, crumbs.length])

	useEffect(() => {
		loadCollectionData()
	}, [loadCollectionData])

	const filteredFiles: File[] = useMemo(() => {
		if (!collectionData) {
			return []
		}

		let fileRoot = collectionData.root

		for (let i = 1; i < crumbs.length; i++) {
			const testFolder = fileRoot.contents.find(
				(item) => item.type === 'directory' && item.name === crumbs[i]
			)

			if (!testFolder || testFolder.type !== 'directory') {
				return []
			}

			fileRoot = testFolder
		}

		const files: File[] = []
		parseDirectoryContent(fileRoot.contents, files)
		return files
	}, [collectionData, crumbs])

	const handleFolderClick: (newCrumbs: string[]) => void = (newCrumbs: string[]) => {
		if (!isEqual(newCrumbs, crumbs)) setCrumbs(newCrumbs)
	}

	const acceptsFileExtension: (url: string) => boolean = (url: string): boolean => {
		if (!restrictions) return true // no restrictions
		const { allowedFileExtensions } = restrictions
		if (!allowedFileExtensions) return true // Accept all extensions by default

		const fileExtension = url.slice(url.lastIndexOf('.') + 1).toLowerCase()
		const accepted = allowedFileExtensions.includes(fileExtension)

		if (!accepted) {
			toast.error(
				`.${fileExtension} is not an accepted file format (Use ${allowedFileExtensions
					.map((ext) => '.' + ext)
					.join(', ')})`
			)
			return false
		}

		return true
	}

	if (isLoading) {
		return <div>Loading...</div>
	}

	if (loadingError) {
		return <p>Error occurred: {loadingError}.</p>
	}

	if (!collectionData) {
		return <p>Something went wrong!</p>
	}

	return (
		<div className={styles.assetManager}>
			{showUploads && (
				<Uploads
					uploads={values(uploads)}
					onCloseClick={() => setShowUploads(false)}
					onClearUpload={(file) => {
						const newUploads = { ...uploads }

						delete newUploads[file.uid]

						if (Object.keys(newUploads).length === 0) {
							setShowUploads(false)
						}
						setUploads(newUploads)
					}}
					onCancelUpload={(file) => {
						if (!uploadRef.current) {
							return
						}

						uploadRef.current.abort(file)
						setUploads((uploads) => {
							return {
								...uploads,
								[file.uid]: {
									...uploads[file.uid],
									status: 'cancel',
								},
							}
						})
					}}
				/>
			)}

			<FolderSelector
				className={styles.folderSelector}
				collection={collection}
				folders={(() => {
					if (!collectionData) {
						return []
					}

					const root = parseCollectionData(collectionData.root)
					if (root) {
						return [root]
					} else {
						console.error(`No root for collection ${collection} found`)

						return []
					}
				})()}
				selectedCrumbs={crumbs}
				onFolderClick={handleFolderClick}
				onCreateFolder={(parentCrumbs: string[], name: string) => {
					if (!collectionData) {
						return
					}

					if (!/^[a-zA-Z0-9_-\s]+$/.test(name)) {
						return alert('Invalid folder name: ' + name)
					}

					const newCollectionData = produce(collectionData, (draft: CollectionData) => {
						let root = draft.root
						for (let i = 1; i < parentCrumbs.length; i++) {
							const testRoot = root.contents.find(
								(item) => item.type === 'directory' && item.name === parentCrumbs[i]
							)

							if (!testRoot || testRoot.type !== 'directory') {
								return console.error('Unable to find path from crumbs: ' + parentCrumbs.join('/'))
							}

							root = testRoot
						}

						if (root.contents.find((item) => item.name === name)) {
							return alert('File or directory already exists')
						}

						const path = root.path === '' ? name : `${root.path}/${name}`
						root.contents.push({
							type: 'directory',
							name,
							path,
							contents: [],
						})
					})

					setCollectionData(newCollectionData)
				}}
			/>

			<div className={styles.detailsColumn}>
				<div className={styles.toolsRow}>
					<FolderBreadcrumbs crumbs={crumbs} onFolderClick={handleFolderClick} />

					<div className={styles.toolIcons}>
						<FontAwesome
							name="image"
							title="In Progress Uploads"
							onClick={() => setShowUploads(true)}
						/>

						<FontAwesome name="refresh" title="Refresh" onClick={loadCollectionData} />

						<Upload
							ref={uploadRef}
							className="fa fa-upload"
							action={`${COLLECTIONS_URL}/${collection}`}
							data={{ path: crumbs.join('/') }}
							multiple={true}
							onStart={(file) => {
								setShowUploads(true)
								setUploads((uploads) => {
									return {
										...uploads,
										[file.uid]: {
											file,
											status: 'start',
											percent: 0,
										},
									}
								})
							}}
							onError={(err, res, file) => {
								setUploads((uploads) => {
									return {
										...uploads,
										[file.uid]: {
											...uploads[file.uid],
											file: uploads[file.uid]?.file || file,
											status: 'fail',
										},
									}
								})

								console.error(err)
							}}
							onSuccess={(res, file) => {
								setUploads((uploads) => {
									return {
										...uploads,
										[file.uid]: {
											...uploads[file.uid],
											file: uploads[file.uid]?.file || file,
											status: 'finish',
											percent: 100,
										},
									}
								})
							}}
							onProgress={(event, file) => {
								setUploads((uploads) => {
									return {
										...uploads,
										[file.uid]: {
											...uploads[file.uid],
											file: uploads[file.uid]?.file || file,
											status: 'progress',
											percent: event.percent,
										},
									}
								})
							}}
							accept={restrictions ? restrictions.mime : '*'}
							beforeUpload={(file): boolean => {
								const isCorrectExtension = acceptsFileExtension(file.name)
								if (!isCorrectExtension) {
									return false
								} else return true
							}}
							withCredentials={true}
						/>
					</div>
				</div>

				<div className={styles.searchWrap}>
					<Input
						value={search}
						type="text"
						placeholder="Search..."
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>

				<FileDetails
					searchTerm={search}
					className={styles.fileDetails}
					files={filteredFiles}
					onFileClick={(file: File) => {
						if (!acceptsFileExtension(file.url)) {
							return
						}

						if (onFileClick) {
							onFileClick(file)
						}
					}}
				/>
			</div>
		</div>
	)
}

function parseCollectionData(item: Directory | File): SimpleFolder | null | undefined {
	if (item.type === 'file') {
		return null
	}

	const folder: SimpleFolder = {
		name: item.name,
		children: [],
	}
	item.contents.forEach((subItem) => {
		const result = parseCollectionData(subItem)

		if (result) {
			folder.children.push(result)
		}
	})
	return folder
}

function parseDirectoryContent(contents: ReadonlyArray<Directory | File>, files: File[]) {
	if (!contents || contents.length === 0) return
	contents.forEach((item) => {
		if (item.type === 'file') {
			files.push(item)
		} else {
			parseDirectoryContent(item.contents, files)
		}
	})
}
