import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, Container, Col, Row } from 'reactstrap'
import { sortBy } from 'lodash'
import NewSimulationModal from './NewSimulationModal'
import AutomatedSimulationList from './AutomatedSimulationList'
import { Card, CardBody, CardFooter, CardHeader } from './SimulationCard'
import { actions, selectors } from '../../setup/tiles'
import { Spinner } from '../../common/Spinner'
import { values } from '../../helpers/functions'
import type { Simulation as SimulationType } from '../../types'
import { UploadAllMissionGuidesButton } from '../automatedSimulations/UploadAllMissionGuidesButton'
import { useAutomatedSimulations } from '../automatedSimulations/queries'
import { ReduxStore } from '../../types/ReduxStore'
export default function SimulationList(): JSX.Element {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [simulationFilter, setSimulationFilter] = useState('')
	const { data: simulations, isLoading } = useAutomatedSimulations()

	const toggleModal = () => {
		setModalIsOpen((state) => !state)
	}

	/**
	 * A filter function that returns whether a simulation should be shown in the list
	 */
	const filter = useCallback(
		(simulation: { name: string }) =>
			simulation.name.toLowerCase().includes(simulationFilter.toLowerCase()),
		[simulationFilter]
	)
	const inputRef = useRef<HTMLInputElement>()
	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [])
	return (
		<Container>
			<Row>
				<Col>
					<Input
						// @ts-expect-error the types for innerRef in reactstrap do not allow this perfectly valid ref
						innerRef={inputRef}
						placeholder="Search Simulations"
						value={simulationFilter}
						onChange={(e) => setSimulationFilter(e.currentTarget.value)}
					/>
				</Col>
				<Col css="display: flex; justify-content: flex-end;">
					{!isLoading && !!simulations && (
						<UploadAllMissionGuidesButton simulations={simulations} />
					)}
					<Button onClick={toggleModal} className="shadow" css="margin-left: 8px;">
						New Simulation
					</Button>
				</Col>
			</Row>
			<AutomatedSimulationList filter={filter} />
			<OldSimulationsList filter={filter} />

			<NewSimulationModal isOpen={modalIsOpen} onClose={toggleModal} />
		</Container>
	)
}
/**
 * Displays a list of all old (non automated) simulations. If the list has not
 * yet been fetched, dispatches an action to fetch it.
 */

function OldSimulationsList({ filter }: { filter: (simulation: SimulationType) => boolean }) {
	let oldSimulations: Array<SimulationType> = useSelector((state: ReduxStore) =>
		values(selectors.simulations.store(state))
	)
	oldSimulations = sortBy(oldSimulations, 'name')
	const { fetched: hasLoaded, isPending: isLoading } = useSelector(selectors.simulations.getAll)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!hasLoaded) {
			dispatch(actions.simulations.getAll())
		}
	}, [hasLoaded, dispatch])
	return (
		<>
			<h2 className="text-2xl font-bold my-2">Old Simulations</h2>
			{isLoading && oldSimulations.length === 0 ? (
				<Spinner />
			) : (
				oldSimulations.filter(filter).map((simulation) => (
					<Card key={simulation._id}>
						<CardHeader text={simulation.name} />
						<CardBody
							data={[
								['Questions', simulation.questions.length],
								['Cards', simulation.deck.cards.length],
								['Created on', new Date(simulation.createdAt).toLocaleDateString()],
							]}
						/>
						<CardFooter id={simulation._id} />
					</Card>
				))
			)}
		</>
	)
}
