import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'

type SelectedActions = Set<string> | null
type SetSelectedActions = (selectedActions: Set<string> | null) => void

const SelectedActionContext = createContext<{
	selectedActions: SelectedActions
	setSelectedActions: SetSelectedActions
} | null>(null)

/**
 * SelectedActionContextProvider - a context provider to allow modifying what actions are selected in the graph visualizations
 *
 * @param {{children:ReactNode}} {children} - the children which can access the context
 *
 * @return JSX.Element
 */
export function SelectedActionContextProvider({ children }: { children: ReactNode }): JSX.Element {
	const [selectedActions, setSelectedActions] = useState<SelectedActions>(null)
	const memoizedValue = useMemo(
		() => ({
			selectedActions,
			setSelectedActions,
		}),
		[selectedActions, setSelectedActions]
	)
	return (
		<SelectedActionContext.Provider value={memoizedValue}>
			{children}
		</SelectedActionContext.Provider>
	)
}

/**
 * useSelectedActions - get the set of currently selected actions
 *
 * @return {Set<string> | null}
 */
export function useSelectedActions(): Set<string> | null {
	return useContext(SelectedActionContext)?.selectedActions ?? null
}

/**
 * useSetSelectedActions - get a callback used to updated the selected actions in the graph visualizations
 *
 * @return {SetSelectedActions}
 */
export function useSetSelectedActions(): SetSelectedActions {
	const context = useContext(SelectedActionContext)
	if (!context) {
		throw new Error(
			`useSetSelectedActions needs the SelectedActionContext to be available to this react component. Make sure that the contexts's provider is a parent of this react component.`
		)
	}
	return context.setSelectedActions
}
