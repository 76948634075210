import React, { Dispatch, SetStateAction } from 'react'
import { startCase } from 'lodash'
import type { FormState, MapEventFormState } from '../types'
import FormComponentWrapper from '../FormComponentWrapper'
import { MapObjectResultType, MapObjectResultTypes } from '../../../../types/AutomatedSimulation'
import { EventResultInput } from './FormComponents'
import type { ActionId, ActionReference } from '../../actionDefinitions'
import type { EventResult, Action } from '@mission.io/mission-toolkit/actions'

type ObjectEventsProps = {
	formState: MapEventFormState
	setFormState: Dispatch<SetStateAction<FormState>>
	objectId: string
	onLeave: (arg0: (arg0: boolean) => void) => () => void
}
export default function ObjectResultsForm({
	formState,
	setFormState,
	objectId,
	onLeave,
}: ObjectEventsProps): JSX.Element {
	const { createdActions, modifiedMapObjectResult } = formState

	//* This function for setting state is for flow: We know that this form will only be rendered if state.type is 'MAP_EVENT'
	const setMapEventFormState = (cb: (arg0: MapEventFormState) => MapEventFormState) => {
		setFormState((state) => (state.type === 'MAP_EVENT' ? cb(state) : state))
	}

	const handleChange = (
		event: {
			type: 'EVENT_RESULT'
			newAction?: Action<string> | ActionReference
			deletedActionId?: ActionId
			modifiedEventResult: EventResult<string>
		},
		eventKey: string
	) => {
		if (event.deletedActionId) {
			setMapEventFormState((state) => ({
				...state,
				createdActions: state.createdActions.filter((action) => {
					if ('reference' in action && action.reference === event.deletedActionId) return false
					else if ('_id' in action && action._id === event.deletedActionId) return false
					return true
				}),
			}))
		}

		if (event.newAction) {
			setMapEventFormState((state) => ({
				...state,
				createdActions: event.newAction
					? state.createdActions.concat([event.newAction])
					: state.createdActions,
			}))
		}

		setMapEventFormState((state) => ({
			...state,
			modifiedMapObjectResult: {
				...state.modifiedMapObjectResult,
				[eventKey]: event.modifiedEventResult,
			},
		}))
	}

	return (
		<div>
			<div>Click on action in event result to edit that action form</div>
			{Object.keys(MapObjectResultTypes).map((eventKey) => {
				const formComponent = (
					<EventResultInput
						value={
							(modifiedMapObjectResult &&
								modifiedMapObjectResult[eventKey as MapObjectResultType]) ||
							[]
						}
						onChange={(event) => handleChange(event, eventKey)}
						label={eventKey}
						actionId={'MAP'}
						actions={createdActions}
						onLeave={onLeave}
					/>
				)
				return (
					<FormComponentWrapper
						key={`${objectId}_${eventKey}`}
						label={startCase(eventKey)}
						component={formComponent}
					/>
				)
			})}
		</div>
	)
}
