import React, { useContext } from 'react'
import { renderObject } from './router'
import type { Box, ParentData, WorldComponent } from '../types'
import BoundingBox from './BoundingBox'
import EditorContext from '../context'
import { normalize } from '@mission.io/navigation-map-server'
import { useEntity, usePositioningData } from '../hooks'
type Props = Box &
	ParentData & {
		_id: string
		entity: string | null | undefined
	}
export default function Entity(props: Props): JSX.Element | null {
	const {
		entity,
		realParentWidth,
		width,
		realParentHeight,
		height,
		parentWidth,
		parentHeight,
		_id,
	} = props
	const { onSelect, selected, showVideoWarning, showGifWarning } = useContext(EditorContext)
	const currentEntity = useEntity(entity)
	const positioningData = usePositioningData(props)
	const childData = {
		realParentWidth: realParentWidth * width,
		realParentHeight: realParentHeight * height,
		realParentX: positioningData.x,
		realParentY: positioningData.y,
		parentWidth: parentWidth * width,
		parentHeight: parentHeight * height,
	}

	if (positioningData.width === 0 || positioningData.height === 0) {
		return null
	}

	return (
		<EditorContext.Provider
			value={{
				onSelect: () => onSelect(_id),
				selected,
				showVideoWarning,
				showGifWarning,
				rotation: positioningData.rotation,
				rotateAround: {
					x: positioningData.x + positioningData.width / 2,
					y: positioningData.y + positioningData.height / 2,
				},
			}}>
			{!currentEntity ? (
				<text {...positioningData} x="20" y="35" className="small">
					{!entity ? 'No Entity Specified' : 'loading'}
				</text>
			) : (
				normalize(currentEntity).components.map((component: WorldComponent) =>
					renderObject(component, childData)
				)
			)}
			<BoundingBox
				{...positioningData}
				visible={_id === selected}
				scaling={_id === selected}
				_id={_id}
				selectHandle={onSelect}
			/>
		</EditorContext.Provider>
	)
}
