import React, { useContext } from 'react'
import BoundingBox from './BoundingBox'
import type { Box, ParentData, CollisionType } from '../types'
import { PRIMITIVE_TYPES } from '../constants'
import { isCollision } from '../utilities'
import EditorContext from '../context'
import { usePositioningData } from '../hooks'
type Props = Box &
	ParentData & {
		_id: string
		type: string
		color: string
		isSelectable: number
		collision: boolean | CollisionType
	}
export default function Rectangle(props: Props): JSX.Element | null {
	const { width, parentWidth, height, parentHeight, collision, type, color, _id } = props
	const { onSelect, selected } = useContext(EditorContext)
	const positioningData = usePositioningData(props)

	if (width * parentWidth === 0 || height * parentHeight === 0) {
		return null
	}

	const style: {
		fill?: string
		stroke?: string
		strokeDasharray?: string
		strokeWidth?: number
	} = {}

	if (!isCollision(collision)) {
		style.fill = color
	} else {
		style.stroke = color
		style.strokeDasharray = '5,5'
		style.strokeWidth = 4
		style.fill = 'None'
	}

	if (type === PRIMITIVE_TYPES.CAMERA) {
		style.stroke = color
		style.strokeWidth = 4
		style.fill = 'None'
		delete style.strokeDasharray
	}

	return (
		<>
			<rect {...positioningData} style={style} onMouseDown={() => onSelect(_id)} />
			<BoundingBox
				{...positioningData}
				visible={selected === _id}
				scaling={selected === _id && type !== PRIMITIVE_TYPES.CAMERA}
				_id={_id}
				selectHandle={onSelect}
			/>
		</>
	)
}
