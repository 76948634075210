import React from 'react'
import { FormGroup, FormText, Label, Row } from 'reactstrap'
import { FieldArray } from 'redux-form'
import CardEffectHolder from '../CardEffects/CardEffectHolder'
import type { Card } from '../../../types'
type Props = {
	name: string
	cardList: Card[]
}
export default function Trigger({ cardList, name }: Props): JSX.Element {
	return (
		<Row>
			<br />
			<FormGroup className="w-100">
				<Label>Special Card Features</Label>
				<FormText>Choose which card to go to or video to show after a choice</FormText>
				<br />
				<FieldArray
					component={CardEffectHolder}
					name={name + 'cardEffects'}
					props={{
						cardList: cardList.map((card) => ({
							cardId: card._id,
							cardName: card.name,
						})),
					}}
				/>
			</FormGroup>
		</Row>
	)
}
