import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import type { ActionId } from './actionDefinitions'

export type FormConfig = {
	id: ActionId
	graph?: boolean
	objectId?: string
	mapId?: string
}
type FormContextType = {
	formStack: FormConfig[]
	setFormStack: Dispatch<SetStateAction<FormConfig[]>>
}
const FormContext = React.createContext<FormContextType | null>(null)

/**
 * A context provider for the form context.
 */
export function FormContextProvider({
	selectedForm,
	children,
}: {
	selectedForm?: FormConfig | null | undefined
	children: JSX.Element
}): JSX.Element {
	const [formStack, setFormStack] = useState<FormConfig[]>([])
	useEffect(() => {
		if (selectedForm) {
			setFormStack([selectedForm])
		}
	}, [selectedForm])
	return (
		<FormContext.Provider
			value={{
				formStack,
				setFormStack,
			}}>
			{children}
		</FormContext.Provider>
	)
}

/**
 * useFormContext provides all the necessary tools in order for components to display an action in the form.
 * Components may call setCurrentActionId, which adds an actionId selected to the formStack which will by default display the graph of that action.
 * If the desire is to not display a graph with an actionId, than a component may push directly onto the form context stack with the
 * proper configuration.
 * Example:
 * { push } = useFormContext();
 * push({id: <actionId>, graph: false})
 */
export function useFormContext(): {
	currentActionId: ActionId | null | undefined
	setCurrentActionId: (arg0: ActionId | null | undefined) => void
	currentConfig: FormConfig | null | undefined
	formStack: FormConfig[]
	push: (arg0: FormConfig) => void
	pop: () => void
} {
	const context = useContext(FormContext)

	if (!context) {
		throw new Error('Form Context must be used from within FormContextProvider')
	}

	const currentConfig = context.formStack[context.formStack.length - 1]
	const currentActionId = currentConfig?.id
	return {
		currentConfig,
		currentActionId,
		setCurrentActionId: (id: ActionId | null | undefined) => {
			if (id) {
				context.setFormStack((stack) =>
					stack.concat([
						{
							id,
							graph: true,
						},
					])
				)
			} else {
				context.setFormStack([])
			}
		},
		formStack: context.formStack,
		push: (config) => context.setFormStack((stack) => stack.concat([config])),
		pop: () => context.setFormStack((stack) => stack.slice(0, -1)),
	}
}
