import React from 'react'
import { OverlayStyled } from '../Screen'
/**
 * The dialog that displays the pause dialog
 * @returns { React$Node} a react component
 */

export default function PauseDialog(): JSX.Element {
	return (
		<OverlayStyled>
			<div>
				<h1>Paused</h1>
			</div>
		</OverlayStyled>
	)
}
