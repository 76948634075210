import React from 'react'
import { BackgroundCircle, FilledPath, FilledCircle } from './helpers'
import type { Props } from './helpers'
export default function ScanningIcon({ iconOnly, ...props }: Props): JSX.Element {
	return (
		<svg
			{...props}
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 94 94"
			fill="#00658a">
			{!iconOnly && <BackgroundCircle cx="47" cy="47" r="47" />}
			<FilledPath
				d="M72,70.84V119A47,47,0,0,1,29.23,91.51Z"
				transform="translate(-25 -25)"
				iconOnly={iconOnly}
			/>
			<FilledCircle cx="47" cy="46" r="6" iconOnly={iconOnly} />
			<FilledCircle cx="14" cy="37" r="5" iconOnly={iconOnly} />
			<FilledCircle cx="73" cy="65" r="5" iconOnly={iconOnly} />
			<FilledCircle cx="78" cy="38" r="5" iconOnly={iconOnly} />
		</svg>
	)
}
