import axios from 'axios'
// flag to check if axios has already been setup
let isAxiosSetup = false

/**
 * setupAxios - setup the interceptors for axios
 */
export function setupAxios(): void {
	if (isAxiosSetup) {
		console.warn('axios is already setup')
		return
	}

	isAxiosSetup = true
	// used for authentication
	axios.interceptors.request.use(
		(config) => {
			// send all requests with credentials
			return { ...config, withCredentials: true }
		},
		(error) => Promise.reject(error)
	)
	// Promise which gets resolved after authentication attempt
	let authenticatingPromise: null | Promise<void> = null
	// Add a response interceptor
	axios.interceptors.response.use(
		(response) => response,
		(error) => {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// if error is due to not being authenticated, trigger authentication flow in a new tab
			if (
				error.response &&
				error.response.status === 401 &&
				error.response.data.authenticationUrl
			) {
				if (!authenticatingPromise) {
					let usingWindow = window.open(error.response.data.authenticationUrl, '_blank')
					authenticatingPromise = new Promise((resolve) => {
						const interval = setInterval(() => {
							try {
								// the next line of code will throw a cross origin frame restriction error (as we are trying to access a tab displaying a google page) during authentication,
								// the tab gets redirected to a reachable url on authentication which causes the next line to evaluate without any errors
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								const ref = usingWindow.location.href.host
								// we are authenticated at this point, close the spawned window and resolve the authentication promises
								usingWindow?.close()
								clearInterval(interval)
								authenticatingPromise = null
								resolve()
							} catch (err) {
								// if this error is a cross origin frame restriction caused by trying to access a window pointing to a different domain then we are still authenticating, ignore the error
								if (
									(err instanceof Error && err.name === 'SecurityError') ||
									usingWindow?.location.href
								) {
									return
								}
								// window was closed without authenticating, open the window again
								usingWindow = window.open(error.response?.data.authenticationUrl, '_blank')
							}
						}, 1000)
					})
				}

				// once authenticated, resend requests
				return authenticatingPromise.then(() => axios(error.config))
			} else {
				// the error was not due to authentication
				return Promise.reject(error)
			}
		}
	)
}
