import { useEffect, useReducer, useMemo } from 'react'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import { actions } from '../../setup/tiles'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import { useQueryClient } from 'react-query' // A reducer form for the questions to be updated, added, and removed

export enum ACTIONS {
	ADD_QUESTION = 'ADD_QUESTION',
	DELETE_QUESTION = 'DELETE_QUESTION',
	UPDATE_QUESTION = 'UPDATE_QUESTION',
	RESET_STATE = 'RESET_STATE',
}

export default function useQuestionReducer(
	questions: Question[] | null | undefined,
	id: string
): {
	questions: Question[]
	isDirty: boolean
	add: (arg0: Question) => void
	update: (arg0: Question, arg1: number) => void
	submit: () => void
	remove: (arg0: number) => void
} {
	const queryClient = useQueryClient()
	const reduxDispatch = useDispatch()
	const [localQuestions, dispatch] = useReducer(reducer, questions || [])

	const add = (question: Question) => {
		dispatch({
			type: ACTIONS.ADD_QUESTION,
			payload: question,
		})
	}

	const update = (question: Question, index: number) => {
		dispatch({
			type: ACTIONS.UPDATE_QUESTION,
			payload: {
				index,
				question,
			},
		})
	}

	const remove = (index: number) => {
		dispatch({
			type: ACTIONS.DELETE_QUESTION,
			payload: {
				index,
			},
		})
	}

	const submit = () => {
		reduxDispatch(
			actions.simulations.putQuestions({
				id: id,
				questions: localQuestions,
				queryClient,
			})
		)
	}

	const isDirty = useMemo(() => !isEqual(questions, localQuestions), [questions, localQuestions])
	useEffect(() => {
		if (questions) {
			// When the questions are updated, we want the local questions to update too.
			dispatch({
				type: ACTIONS.RESET_STATE,
				payload: questions,
			})
		}
	}, [questions])
	return {
		questions: localQuestions,
		add,
		update,
		remove,
		submit,
		isDirty,
	}
}

type AddQuestion = {
	type: 'ADD_QUESTION'
	payload: Question
}
type DeleteQuestion = {
	type: 'DELETE_QUESTION'
	payload: {
		index: number
	}
}
type UpdateQuestion = {
	type: 'UPDATE_QUESTION'
	payload: {
		index: number
		question: Question
	}
}
type ResetState = {
	type: 'RESET_STATE'
	payload: Question[]
}
export type Action = AddQuestion | DeleteQuestion | UpdateQuestion | ResetState

/**
 * Handles endpoints for all question reducer actions.
 * @param {Question[]} state
 * @param {Action} action
 * @returns {Question[]}
 */
function reducer(state: Question[], action: Action): Question[] {
	switch (action.type) {
		case ACTIONS.ADD_QUESTION: {
			const question = action.payload
			return [...state, question]
		}

		case ACTIONS.DELETE_QUESTION: {
			const index = action.payload.index

			if (index < state.length && index > -1) {
				return [...state.slice(0, index), ...state.slice(index + 1)]
			}

			return state
		}

		case ACTIONS.UPDATE_QUESTION: {
			const { index, question } = action.payload
			const copy = [...state]

			if (index < copy.length && index > -1) {
				copy[index] = question
			}

			return copy
		}

		case ACTIONS.RESET_STATE: {
			return action.payload
		}

		default:
			throw new Error(`An unknown question action type was dispatched, ${action.type}`)
	}
}
