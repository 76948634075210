import styled from 'styled-components'
import { Card } from 'reactstrap'

export const List = styled.div`
	padding: 16px;
	border: 1px solid lightgrey;
	border-radius: 4px;
`

export const StyledCard = styled(Card)`
	margin-bottom: 8px;
`
