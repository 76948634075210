import React from 'react'
import DrawRectangle from './DrawRectangle'
import DrawEllipse from './DrawEllipse'
import DrawMedia from './DrawMedia'
import DrawEntity from './DrawEntity'
import Save from './Save'
import Preview from './Preview'
import { EditorType, PRIMITIVE_TYPES } from '../constants'
import type { PrimitiveTypes, SaveData } from '../types'
import type { NavigationMap, Entity } from '@mission.io/navigation-map-server'

type Props = {
	editorType: EditorType
	currentlySelected: PrimitiveTypes | null | undefined
	onSelect: (type: PrimitiveTypes) => void
	getSaveData: () => SaveData
	afterSave: (savedEntityOrMap: NavigationMap | Entity) => void
	setPreview: (isPreviewing: boolean) => void
}
export default function Menu({
	editorType,
	currentlySelected,
	onSelect,
	getSaveData,
	afterSave,
	setPreview,
}: Props): JSX.Element {
	return (
		<div>
			<Save getSaveData={getSaveData} afterSave={afterSave} editorType={editorType} />
			<DrawRectangle
				isSelected={currentlySelected === PRIMITIVE_TYPES.RECTANGLE}
				onClick={() => onSelect(PRIMITIVE_TYPES.RECTANGLE)}
			/>
			<DrawEllipse
				isSelected={currentlySelected === PRIMITIVE_TYPES.ELLIPSE}
				onClick={() => onSelect(PRIMITIVE_TYPES.ELLIPSE)}
			/>
			<DrawMedia
				isSelected={currentlySelected === PRIMITIVE_TYPES.MEDIA}
				onClick={() => onSelect(PRIMITIVE_TYPES.MEDIA)}
			/>
			{editorType === EditorType.WORLD ? (
				<>
					<DrawEntity
						isSelected={currentlySelected === PRIMITIVE_TYPES.ENTITY}
						onClick={() => onSelect(PRIMITIVE_TYPES.ENTITY)}
					/>
					<Preview
						onClick={() => {
							setPreview(true)
						}}
					/>
				</>
			) : null}
		</div>
	)
}
